import { NotificationModule } from "ditmer-embla";
import { useDispatch, useSelector } from "react-redux";
import Button from "@components/embla/button";
import { useLocalization } from "@components/localization/localizationProvider";
import { bulkAddSelector, bulkAddStop } from "@pages/pdfviewer/component/pdfViewerSlice";
import { useBulkAddToPresentationsMutation } from "@services/api/casePresentation/casePresentationApi";
import { Tooltip } from "@components/tooltip/tooltip";
import styles from "./BulkAddButtons.module.scss";

type BulkAddButtonsProps = {
  caseId: string;
};

const BulkAddButtons = ({ caseId }: BulkAddButtonsProps) => {
  const dispatch = useDispatch();
  const bulkAddState = useSelector(bulkAddSelector);
  const localizer = useLocalization();

  const [bulkAddToPresentations, bulkAddRequest] = useBulkAddToPresentationsMutation();

  const closeBulkAdd = () => dispatch(bulkAddStop());

  const nothingSelected = !bulkAddState?.pageIndexes.length || !bulkAddState?.presentations.length;

  const submitBulkAdd = async () => {
    if (bulkAddState) {
      await bulkAddToPresentations({ caseId: caseId, model: bulkAddState }).unwrap();
      NotificationModule.showSuccessSmall(localizer.pagesAddedToPresentations());
      closeBulkAdd();
    }
  };

  return (
    <>
      <Tooltip message={nothingSelected && localizer.selectPageAndPresentation()}>
        <Button
          key="submit-bulk-add"
          theme="primary"
          onClick={submitBulkAdd}
          disabled={nothingSelected}
          status={bulkAddRequest.isLoading ? "loading" : undefined}
          className={styles.noBreakWord}
        >
          {localizer.addPages()}
        </Button>
      </Tooltip>
      <Button key="cancel-bulk-add" onClick={closeBulkAdd} disabled={bulkAddRequest.isLoading}>
        {localizer.cancel()}
      </Button>
    </>
  );
};

export default BulkAddButtons;

