import { useCallback } from "react";
import { v4 as uuid } from "uuid";
import { useAppSelector } from "@hooks";
import { MarkingEvent, MarkingEventType } from "@pages/pdfviewer/component/models/markingEvent";
import { MarkingModel, ReplyModel } from "@pages/pdfviewer/component/models/markingModel";
import { pdfTypeIdSelector } from "@pages/pdfviewer/component/pdfViewerSlice";
import { UndoRedoToolType } from "@pages/pdfviewer/component/models/pdfTool";
import { useAuth } from "@components/auth/authProvider";
import { usePostMarkingEventMutation } from "../markingsApi";

const useMarkingMutation = () => {
  const documentId = useAppSelector(pdfTypeIdSelector)!;

  const { user: { id: userId } } = useAuth();
  
  const [postMarkingEvent] = usePostMarkingEventMutation(documentId);

  return {
    postEvent: useCallback((markingEvent: MarkingEvent, isUndoRedo?: UndoRedoToolType) => {
      return postMarkingEvent({ event: markingEvent,
        isUndoRedo: isUndoRedo,
        userId: userId,
      });
    }, [postMarkingEvent, userId]),
    createMarking: useCallback((marking: MarkingModel<false>, isUndoRedo?: UndoRedoToolType) => {
      return postMarkingEvent({ event: {
        id: marking.id, // creation event has same id as marking
        documentId,
        eventType: MarkingEventType.Create,
        eventCreationDate: marking.creationDate,
        eventCreator: marking.owner,
        marking },
      isUndoRedo: isUndoRedo,
      userId: userId,
      });
    }, [documentId, postMarkingEvent, userId]),
    editMarking: useCallback((marking: MarkingModel<false>, isUndoRedo?: UndoRedoToolType) => {
      return postMarkingEvent({ event: {
        id: uuid(),
        documentId,
        eventType: MarkingEventType.Edit,
        markingRefId: marking.id,
        marking },
      isUndoRedo: isUndoRedo,
      userId: userId,
      });
    }, [documentId, postMarkingEvent, userId]),
    deleteMarking: useCallback((marking: MarkingModel<false>, isUndoRedo?: UndoRedoToolType) => {
      return postMarkingEvent({ event: {
        id: uuid(),
        documentId,
        eventType: MarkingEventType.Delete,
        marking: marking,
        markingRefId: marking.id },
      isUndoRedo: isUndoRedo,
      userId: userId,
      });
    }, [documentId, postMarkingEvent, userId]),
    createReply: useCallback((reply: ReplyModel) => {
      return postMarkingEvent({ event: {
        id: uuid(),
        documentId,
        eventType: MarkingEventType.CreateReply,
        marking: reply,
        eventCreator: reply.owner,
        markingRefId: reply.id,
      },
      userId: userId,
      });
    }, [documentId, postMarkingEvent, userId]),
    editReply: useCallback((reply: ReplyModel) => {
      return postMarkingEvent({ event: {
        id: uuid(),
        documentId,
        eventType: MarkingEventType.EditReply,
        marking: reply,
        markingRefId: reply.id },
      userId: userId,
      });
    }, [documentId, postMarkingEvent, userId]),
  };
};

export default useMarkingMutation;
