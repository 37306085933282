import classNames from "classnames";
import { ButtonHTMLAttributes, forwardRef, Ref, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { NotificationModule } from "ditmer-embla";
import ReactDom from "react-dom";
import { usePopper } from "react-popper";
import Button from "@components/embla/button";
import { CommentMarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import useForwardedRef from "src/hooks/useForwardedRef";
import useDateFormatter from "src/hooks/useDateFormatter";
import { useLocalization } from "@components/localization/localizationProvider";
import { useAddPresentationPageNoteMutation } from "@services/api/casePresentation/casePresentationApi";
import Badge from "@components/embla/badge";
import { Tooltip } from "@components/tooltip/tooltip";
import { ParticipantBadge } from "@components/case/participant/participantBadge/participantBadge";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import { useOnClickOutside } from "@pages/pdfviewer/component/hooks/useClickOutside";
import MarkingIcon from "../../../../shared/markingIcon";
import useMarkingDnd from "../../MarkingsPageOverlay/hooks/useMarkingDnd/useMarkingDnd";
import styles from "./markingIconButton.module.scss";

interface MarkingIconBtnProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  marking: CommentMarkingModel<false>;
  scale: number;
  active: boolean;
  isPresentationMode?: boolean;
}

const MarkingIconBtn = forwardRef(({ marking, scale, active, className, style, isPresentationMode, ...buttonProps }: MarkingIconBtnProps, forwardedRef: Ref<HTMLButtonElement>) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { caseId, pageId, seqId } = useParams();
  
  
  const localizer = useLocalization();

  const [addNote] = useAddPresentationPageNoteMutation();

  const popoverRef = useRef<HTMLDivElement>(null);
  const markingElRef = useForwardedRef(forwardedRef);
  const { markingPosition, markingClassName, onPointerDown, dropBoundaryEl } = useMarkingDnd({
    marking,
    scale,
    canDrag: active,
    markingElRef,
  });

  const { styles: popperStyles, attributes, update } = usePopper(markingElRef.current, popoverRef.current, {
    placement: "bottom",
    strategy: "fixed",
  });

  useEffect(() => {
    if (update && isExpanded) {
      update();
    }
  }, [isExpanded, scale, update]);

  useOnClickOutside(popoverRef, () => setIsExpanded(false));

  const { shortDateWithoutYearWithShortTime } = useDateFormatter();

  const tags = marking.tags.map((tag) => tag.tagName).join(", ");

  const createSpeechnoteFromComment = () => {
    if (caseId && seqId) {
      if (pageId) {
        addNote({
          caseId,
          presentationId: seqId,
          presentationPageId: pageId,
          model: {
            note: marking.data.text,
          },
        })
          .unwrap()
          .then(() => {
            NotificationModule.showSuccessSmall(localizer.speakerNoteCreated());
          });
      }
    }
  };

  return (
    <>
      <Button
        ref={markingElRef}
        iconBtn
        theme="primary"
        className={classNames(markingClassName, className, { [styles.opaque]: isExpanded })}
        style={{
          ...style,
          left: markingPosition.x,
          top: markingPosition.y,
          opacity: isExpanded && isPresentationMode ? 1 : 0.5,
        }}
        onPointerDown={onPointerDown}
        {...buttonProps}
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <MarkingIcon markingType={marking.type} />
      </Button>
      {dropBoundaryEl}
      {(ReactDom.createPortal(
        <div
          ref={popoverRef}
          style={{ ...popperStyles.popper, zIndex: 1050, visibility: isExpanded && isPresentationMode ? "visible" : "hidden" }}
          {...attributes.popper}
          className={classNames("card", styles.commentContainer)}
        >
          <div className="d-flex justify-content-between align-items-center px-2 pb-1">
            <div className="d-flex align-items-center gap-2 pt-1">
              <EmblaIcon iconName="dialog" color={IconColor.White} additionalClasses={styles.badge} />
              <p className={classNames("small m-1 text-nowrap")}>{marking.owner.name}</p>
              <p className={classNames("small m-1 subtle text-nowrap")}>{shortDateWithoutYearWithShortTime(marking.creationDate)}</p>
            </div>
            <Tooltip message={tags} placement="top-start">
              <Badge appearance="success-light" pill size="wide" className={styles.tagNameOverflow}>
                {tags}
              </Badge>
            </Tooltip>
          </div>
          <div className={classNames("px-2 pb-2", styles.borderBottom)}>
            <p>{marking.data.text}</p>
            <Button onClick={createSpeechnoteFromComment} theme="default">
              {localizer.createAsSpeakerNote()}
            </Button>
          </div>
          {marking.replies?.map((reply) => (
            <div className={styles.borderBottom} key={reply.id}>
              <div className="px-2 p-2 d-flex gap-s align-items-center">
                <ParticipantBadge name={reply.owner.name} profilePictureUrl={reply.profilePictureUrl} hideNameField={true} />
                <div className={classNames("small", "margin-bottom-0", "text-nowrap")}>{reply.owner.name}</div>
                <p className="small margin-bottom-0 subtle">{shortDateWithoutYearWithShortTime(reply.creationDate)}</p>
              </div>
              <p className="px-2">{reply.content}</p>
            </div>
          ))}
        </div>, document.body)
      )}
    </>
  );
});

export default MarkingIconBtn;
