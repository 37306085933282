import { useEffect, useState } from "react";
import { DownloadButton } from "@components/button/downloadButton";
import Button from "@components/embla/button";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import { EmblaIllustration, IllustrationColor } from "@components/embla/emblaIllustration/emblaIllustration";
import { useLocalization } from "@components/localization/localizationProvider";
import Modal from "@components/modal/modal";
import { Spinner } from "@components/spinner/spinner";
import { useGetCaseDocumentExportDownloadUrlQuery } from "@services/api/document/caseDocumentApi";


type DownloadExportDocumentsProps = {
  caseId: string;
  caseDocumentId: string;
  caseDocumentExportId: string;
  caseDocumentTitle: string;
  onBackClicked: () => void;
};

const DownloadExportCaseDocument = ({
  caseId,
  caseDocumentId,
  caseDocumentExportId,
  caseDocumentTitle,
  onBackClicked,
}: DownloadExportDocumentsProps) => {
  const localizer = useLocalization();
  const [ pollingIntervalSeconds, setPollingIntervalSeconds ] = useState(4);
  const { data: downloadUrlModel  } = useGetCaseDocumentExportDownloadUrlQuery({ caseId, caseDocumentExportId, caseDocumentId }, { pollingInterval: pollingIntervalSeconds * 1000 });

  const downloadIsReady = !!(downloadUrlModel && downloadUrlModel.downloadUrl);

  useEffect(() => {
    if (downloadIsReady) {
      // After we get downloadUrl, only poll when token is about to expire
      setPollingIntervalSeconds(60 * 10);
    }
  }, [downloadIsReady]);

  return (
    <>
      <Modal.Header>{`${localizer.exportCaseDocument()}: ${caseDocumentTitle}`}</Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column h-100">
          <div>
            <div className="subtle">{downloadIsReady ? localizer.exportPresentationDownloadReadyHint1() : localizer.exportPresentationDownloadHint1()}</div>
            <div className="subtle">{downloadIsReady ? localizer.exportPresentationDownloadReadyHint2() : localizer.exportPresentationDownloadHint2()}</div>
          </div>

          <div className="d-flex h-100 justify-content-center align-items-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
              {downloadIsReady ?
                <>
                  <EmblaIllustration illustrationName="business-paper" color={IllustrationColor.Blue}/>
                  <a
                    className="margin-top-m"
                    href={downloadUrlModel?.downloadUrl}>
                    <EmblaIcon iconName="download" color={IconColor.Blue}/>
                    {caseDocumentTitle}
                  </a>
                </>
                :
                <Spinner/>
              }
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onBackClicked}>{downloadIsReady ? localizer.back() : localizer.cancel()}</Button>
        {downloadIsReady &&
      <DownloadButton theme="primary" downloadUrl={downloadUrlModel?.downloadUrl}>{localizer.downloadPdf()}</DownloadButton>
        }
      </Modal.Footer>
    </>
  );
};

export default DownloadExportCaseDocument;
