import baseApi, { apiTags } from "../baseApi";
import { EmailTemplateModel } from "./models/emailTemplateModel";
import { PreviewRequestModel } from "./models/previewRequestModel";
import { PreviewResponseModel } from "./models/previewResponseModel";
import { TextMergefield } from "./models/textMergefield";

const getEmailTemplatesUrl = "api/emailTemplate";
const getEmailTemplateUrl = (type: string, culture: string) => `api/emailTemplate/${type}/${culture}`;
const getMergefieldsUrl = (type: string, culture: string) => `api/emailTemplate/${type}/${culture}/mergefields`;
const getPreview = (type: string, culture: string) => `api/emailTemplate/${type}/${culture}/preview`;
const updateEmailTemplatesUrl = "api/emailTemplate";

// Unique key for emailTemplate
const emailTemplateIdString = (type: number, culture: string) => `${type}-${culture}`;

type EmailTemplateParams = { type: string, culture: string };

const emailTemplateApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmailTemplates: builder.query<EmailTemplateModel[], void>({
      query: () => getEmailTemplatesUrl,
      providesTags: (result) => result ? [...result.map((u) => ({ type: apiTags.emailTemplate, id: emailTemplateIdString(u.type, u.culture) })), apiTags.emailTemplate] : [],
    }),
    getEmailTemplate: builder.query<EmailTemplateModel, EmailTemplateParams>({
      query: ({ type, culture }) => getEmailTemplateUrl(type, culture),
      providesTags: (result) => result ? [{
        type: apiTags.emailTemplate,
        id: emailTemplateIdString(result.type, result.culture),
      }] : [],
    }),
    getMergefields: builder.query<TextMergefield[], EmailTemplateParams>({
      query: ({ type, culture }) => getMergefieldsUrl(type, culture),
    }),
    generatePreview: builder.mutation<PreviewResponseModel, EmailTemplateParams & { model: PreviewRequestModel }>({
      query: ({ type, culture, model }) => ({
        url: getPreview(type, culture),
        method: "POST",
        body: model,
      }),
    }),
    updateEmailTemplate: builder.mutation<EmailTemplateModel, EmailTemplateModel>({
      query: (model) => ({
        url: updateEmailTemplatesUrl,
        method: "PUT",
        body: model,
      }),
      invalidatesTags: (result) => result ? [{
        type: apiTags.emailTemplate,
        id: emailTemplateIdString(result.type, result.culture),
      }] : [],
    }),
  }),
});

export default emailTemplateApi;
export const { useGetEmailTemplatesQuery, useGetEmailTemplateQuery, useGetMergefieldsQuery, useGeneratePreviewMutation, useUpdateEmailTemplateMutation } = emailTemplateApi;
export const { resetApiState } = emailTemplateApi.util;