import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { UserService } from "@services/userService";
import { IHubConnectionManager } from "./IHubConnectionManager";

export abstract class HubConnectionManager implements IHubConnectionManager {
  private connections: Partial<Record<string, HubConnection>> = {};
  abstract apiUrl: string;
  abstract endpointName: string;
  abstract userService: UserService;
  private groupSubscribe = "GroupSubscribe";
  private groupUnsubscribe = "GroupUnsubscribe";

  private hubUrl = () => `${this.apiUrl}/${this.endpointName}`;

  getSignalRHeader = (groupId: string) => {
    // add signalR connection id to avoid getting back events that were already processed locally
    return { signalRConnectionId: this.getConnectionId(groupId) };
  };

  async startConnection(groupId: string): Promise<void> {
    const connection = this.connections[groupId];

    if (connection) {
      if (connection.state === HubConnectionState.Connected || connection.state === HubConnectionState.Connecting) {
        return;
      }
    } else {
      const newConnection = new HubConnectionBuilder()
        .withUrl(this.hubUrl(), {
          withCredentials: false,
          accessTokenFactory: async () => await this.userService.getUserToken() ?? "",
        })
        .build();

      this.connections[groupId] = newConnection;
    }

    try {
      await this.connections[groupId]?.start();
      await this.connections[groupId]?.invoke(this.groupSubscribe, groupId);
    } catch (error) {
      console.error("Failed to start connection:", error);
      delete this.connections[groupId];
    }
  }

  on(groupId: string, method: string, listener: (...args: any[]) => void) {
    this.connections[groupId]?.on(method, listener);
  }

  off(groupId: string, methodName: string, method: (...args: any[]) => void) {
    this.connections[groupId]?.off(methodName, method);
  }

  getConnectionId(groupId: string) {
    return this.connections[groupId]?.connectionId ?? undefined;
  }

  async stopConnection(groupId: string) {
    const connection = this.connections[groupId];

    if (connection !== undefined) {
      delete this.connections[groupId];
      if (connection.state === HubConnectionState.Connected) {
        await connection.invoke(this.groupUnsubscribe, groupId);
      }
      await connection.stop();
    }
  }

  async unsubscribe(groupId: string) {
    const connection = this.connections[groupId];
    if (connection?.state === HubConnectionState.Connected) {
      await connection.invoke(this.groupUnsubscribe, groupId);
    }
  }
}