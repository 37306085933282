import { useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";
import { PdfType } from "@pages/pdfviewer/component/pdfViewerSlice";
import { useGetMarkingsQuery } from "@pages/pdfviewer/component/markingsApi";

type UsePageMarkingsProps = {
  pageIndex: number;
  employeeIds?: string[];
  markingTypes?: MarkingType[];
  pdfType: PdfType;
  documentId?: string;
};

const usePageMarkings = ({ pageIndex, pdfType, employeeIds, markingTypes, documentId } : UsePageMarkingsProps) => {

  const { data, isLoading, isFetching } = useGetMarkingsQuery((documentId && pdfType) ? { pdfTypeId: documentId, origin: pdfType } : skipToken);

  const markings = useMemo(() => {
    let pageMarkings = data?.[pageIndex] ?? [];

    //filter markings
    if (markingTypes)
      pageMarkings = pageMarkings.filter((x) => markingTypes.includes(x.type));

    //filter owners
    if (employeeIds)
      pageMarkings = pageMarkings.filter((x) => employeeIds.includes(x.owner.employeeId));

    return pageMarkings;
  }, [data, employeeIds, markingTypes, pageIndex]);

  return { markings, isLoading, isFetching };
};

export default usePageMarkings;
