import { CaseDocumentPaginatedListInput } from "@services/api/document/models/CaseDocumentPaginatedListInput";
import { CaseElementTypeEnum } from "@services/api/case/models/caseElementTypeEnum";
import { useLocalization } from "@components/localization/localizationProvider";
import { DocumentList } from "../documents/documentList";

type DocumentListTabProps = {
  caseId: string;
};

const DocumentListTab = ({ caseId }: DocumentListTabProps) => {
  const localizer = useLocalization();

  const common = {
    draw: 0,
    start:  0,
    search: {
      value: "",
      regex: false,
    },
    order: [],
  };

  const initialFilterNoExtracts: CaseDocumentPaginatedListInput = {
    ...common,
    length: 20,
    excludedTypes: [CaseElementTypeEnum.ExtractDraft, CaseElementTypeEnum.Extract],
    types: [],
  };

  const initialFilterExtracts: CaseDocumentPaginatedListInput = {
    ...common,
    length: 5,
    excludedTypes: [CaseElementTypeEnum.HelpAppendix, CaseElementTypeEnum.MaterialCollection, CaseElementTypeEnum.Other],
    types: [CaseElementTypeEnum.Extract, CaseElementTypeEnum.ExtractDraft],
  };

  return (
    <div className="mt-3">
      <DocumentList caseId={caseId} initialFilterInput={initialFilterExtracts} hideSearchFilter useExtraFilters tableTitle={localizer.caseExtract()}/>
      <DocumentList caseId={caseId} initialFilterInput={initialFilterNoExtracts} useExtraFilters tableTitle={localizer.documents()} multiselectDelete/>
    </div>
  );
};

export default DocumentListTab;