
import { useState } from "react";
import { NotificationModule } from "ditmer-embla";
import Button from "@components/embla/button";
import FormSubmitButton from "@components/forms/FormSubmitButton";
import { useLocalization } from "@components/localization/localizationProvider";
import Modal from "@components/modal/modal";
import presentationApi from "@services/api/presentation/presentationApi";
import casePresentationApi from "@services/api/casePresentation/casePresentationApi";
import { markingsApi } from "@pages/pdfviewer/component/markingsApi";
import { PdfType } from "@pages/pdfviewer/component/pdfViewerSlice";
import { IHubConnectionManager } from "@services/signalRClient/IHubConnectionManager";
import useHubConnection from "src/hooks/useHubConnection";
import { HubConnectionType } from "@services/signalRClient/hubConnectionFactory";
import { PresentationHubEventType } from "@services/signalRClient/presentationHubConnectionManager";
import { Spinner } from "@components/spinner/spinner";
import { EmblaIllustration, IllustrationColor } from "@components/embla/emblaIllustration/emblaIllustration";
import { StorageKeys } from "@infrastructure/storageKeys";
import { apiTags } from "@services/api/baseApi";
import { useAppDispatch } from "@hooks";
import { useAuth } from "@components/auth/authProvider";

type SavePresentationForOfflineUseProps = {
  presentationTitle: string
  presentationId: string
  caseId: string
  onBackClicked: () => void;
};

const SavePresentationForOfflineUse = ({
  presentationTitle,
  presentationId,
  caseId,
  onBackClicked,
}: SavePresentationForOfflineUseProps) => {
  const localizer = useLocalization();

  const [isDownloading, setIsDownloading] = useState(false);
  const [hasDownloaded, setHasDownloaded] = useState(false);

  const [hubConnectionManager ] = useState<IHubConnectionManager>(useHubConnection(HubConnectionType.Presentation));

  const [getPresentationPagesQuery] = presentationApi.useLazyGetPresentationPagesQuery();
  const [getPresentationPageFilesQuery] = presentationApi.useLazyGetPresentationPageFilesQuery();
  const [getMarkingsQuery] = markingsApi.useLazyGetMarkingsQuery();
  const [getCasePresentationsQuery] = casePresentationApi.useLazyGetCasePresentationsQuery();
  const [ presentFileExists ] = presentationApi.usePresentfileExistsMutation();
  const [getPresentfileQuery] = presentationApi.useLazyGetPresentfileQuery();
  const { user } = useAuth();

  const dispatch = useAppDispatch();

  const fetchForOfflineUseClicked = () => {

    setIsDownloading(true);

    const getPresentationPagesQueryPromise = getPresentationPagesQuery(presentationId);
    const getPresentationPageFilesQueryPromise = getPresentationPageFilesQuery(presentationId);
    const getMarkingsQueryPromise = getMarkingsQuery({ markingsArgs: { pdfTypeId: presentationId, origin: PdfType.Presentation }, userId: user.id });
    const getCasePresentationsQueryPromise = getCasePresentationsQuery(caseId);

    const fileExistsPromise = new Promise<void>((resolve) => {

      const presentFileExistsRequest = presentFileExists(presentationId);

      presentFileExistsRequest.then((data) => {
        const fileExist = JSON.parse(JSON.stringify(data)).data.fileExist;
        const fileOutdated = JSON.parse(JSON.stringify(data)).data.fileOutdated;

        if (fileExist && !fileOutdated) {
          getPresentfileQuery(presentationId).then((t) => {
            resolve();
          });
        } else {
          hubConnectionManager.startConnection(presentationId);
          hubConnectionManager.on(
            presentationId,
            PresentationHubEventType.PresentationEvent,
            () => {
              hubConnectionManager.stopConnection(presentationId);
              getPresentfileQuery(presentationId).then(() => {
                resolve();
              });
            });
        }
      });
    });

    Promise.all([getPresentationPagesQueryPromise, getPresentationPageFilesQueryPromise, getMarkingsQueryPromise, getCasePresentationsQueryPromise, fileExistsPromise])
      .then(() => {
        setHasDownloaded(true);
        setIsDownloading(false);
        window.localStorage.setItem(StorageKeys.offlineLastPresentationSaveStorage(presentationId), new Date().toISOString());
        dispatch(casePresentationApi.util.invalidateTags([apiTags.casePresentation])); // we invalidate so that the list of presentations are refetched with the new timestamp
      })
      .catch(() => {
        NotificationModule.showErrorSmall(localizer.errorDocumentMarkingUpdate());
      });
  };

  return (
    <>
      <Modal.Header>{`${localizer.savePresentation()}: ${presentationTitle}`}</Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column h-100 justify-content-center">
          <div className="subtle">{localizer.presentationSaveHint()}</div>
          <div className="subtle">{localizer.presentationSaveHint2()}</div>
          <div className="d-flex h-100 justify-content-center align-items-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
              {isDownloading ?
                <Spinner/>
                :
                hasDownloaded ?
                  <>
                    <EmblaIllustration illustrationName="success-mark" color={IllustrationColor.Blue}/>
                    <div>
                      {localizer.presentationSaved()}
                    </div>
                  </>
                  : ""
              }
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onBackClicked}>{localizer.cancel()}</Button>
        <FormSubmitButton
          state={{
            isSubmitting: isDownloading,
            isSubmitSuccessful: hasDownloaded,
          }}
          isPrimary={true}
          onClick={fetchForOfflineUseClicked}
        >
          {localizer.save()}
        </FormSubmitButton>
      </Modal.Footer>
    </>
  );
};

export default SavePresentationForOfflineUse;
