import { ReactNode, useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import IndicatorLine from "./IndicatorLine/IndicatorLine";

type DragNDropIndicatorProps<T> = {
  children: ReactNode;
  dndType: string;
  disableDrag?: boolean;
  onDrop?: (item: T, position: DragNDropIndicatorPosition, index?: number) => unknown;
  item: T;
  droppedIndex?: number;
};

export type DragNDropIndicatorPosition = "above" | "below" | null;

export type Dimensions = {
  width: number;
  height: number;
};

type DragItemWithDimensions<T> = T & Dimensions;

// eslint-disable-next-line @typescript-eslint/comma-dangle
const DragNDropIndicator = <T,>({ children, dndType, disableDrag = false, item, onDrop, droppedIndex }: DragNDropIndicatorProps<T>) => {
  const [position, setPosition] = useState<"above" | "below" | null>(null);
  const emptyDragPreview = new Image();
  const divRef = useRef<HTMLDivElement>(null);

  const boundingClient = divRef.current?.getBoundingClientRect();

  const getWidthAndHeight = () => {
    if (!boundingClient) {
      return { width: 0, height: 0 };
    }

    const { width, height } = boundingClient;
    return { width, height };
  };

  const [ , drag, preview] = useDrag({
    type: dndType,
    item: { ...item, ...getWidthAndHeight() },
    canDrag: !disableDrag,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }, [disableDrag, boundingClient]);

  const [{ isOver }, drop] = useDrop({
    accept: dndType,
    hover: (it, monitor) => {
      if (!divRef.current) {
        return;
      }

      const hoverBoundingRect = divRef.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      if (clientOffset) {
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        if (hoverClientY < hoverMiddleY) {
          setPosition("above");
        } else {
          setPosition("below");
        }
      }
    },
    drop: (droppedItem: DragItemWithDimensions<T>) => {
      onDrop?.(droppedItem, position, droppedIndex);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  useEffect(() => {
    preview(emptyDragPreview);
  }, [preview]);
  drop(divRef);
  drag(divRef);

  return (
    <div>
      <IndicatorLine isVisible={position === "above" && isOver} />
      <div ref={divRef} >
        {children}
      </div>
      <IndicatorLine isVisible={position === "below" && isOver} />
    </div>
  );
};

export default DragNDropIndicator;

