import classNames from "classnames";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import React from "react";
import { useLocalization } from "@components/localization/localizationProvider";
import uploadIcon from "@content/icons/feather_upload-cloud.svg";
import Button from "@components/embla/button";
import styles from "./dropSelectFiles.module.scss";

export interface DropSelectFilesProps {
  filesDroppedCallback: (files: File[]) => void;
  filesSelectedCallback: (files:  FileList) => void;
  hint1?: string;
  hint2?: string;
}

export const DropSelectFiles = ({ filesSelectedCallback, filesDroppedCallback, hint1, hint2 }: DropSelectFilesProps) => {
  const localizer = useLocalization();
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const [, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop: (item: { files: File[] }) => {
        const pdfFiles = Array.from(item.files).filter((file) => file.type === "application/pdf");
        filesDroppedCallback(pdfFiles);
      },
    }),
    [filesDroppedCallback],
  );

  const filesSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      filesSelectedCallback(files);
    }

    e.target.value = ""; // reset file input
  };

  return (
    <div className="margin-top-m">
      <div ref={drop} className={classNames("margin-top-l", styles.uploadFilesCard)}>
        <div>
          <img className="margin-right-l" alt="upload-icon" src={uploadIcon} />
          <div>
            <div>
              {hint1 ?? localizer.uploadFilesHint1()}
            </div>
            <div className="subtle small">
              {hint2 ?? localizer.uploadFilesHint2()}
            </div>
          </div>
        </div>
        <input
          type="file"
          accept="application/pdf"
          hidden
          ref={hiddenFileInput}
          onChange={filesSelected}
          multiple={true}
        />
        <Button theme="primary" onClick={(_) => hiddenFileInput.current?.click()}>
          {localizer.chooseFiles()}
        </Button>
      </div>
    </div>
  );
};
