import classNames from "classnames";
import { EmblaIcon } from "@components/embla/emblaIcon";
import styles from "./pagination.module.scss";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  handlePagination: (page: number) => void;
}

export const Pagination = ({ currentPage, totalPages, handlePagination }: PaginationProps) => {

  const renderSeparator = () => {
    return (
      <span className={classNames("btn", "btn-default", styles.separator)}>
        ..
      </span>
    );
  };

  const renderPageItem = (page: number, active = false) => {
    return (
      <button type="button" className={classNames("btn", "btn-default", active && "active")}
        onClick={() => handlePagination(page)}>
        {page}
      </button>
    );
  };

  const renderLeft = (disabled = false) => {
    return (
      <button type="button" className="btn btn-default btn-icon" disabled={disabled} aria-label="Left"
        onClick={() => handlePagination(currentPage - 1)}>
        <EmblaIcon iconName={"arrow-left"} />
      </button>
    );
  };

  const renderRight = (disabled = false) => {
    return (
      <button type="button" className="btn btn-default btn-icon" aria-label="Right" disabled={disabled}
        onClick={() => handlePagination(currentPage + 1)}>
        <EmblaIcon iconName={"arrow-right"} />
      </button>
    );
  };

  //Note: Implementation inspired by: https://javascript.plainenglish.io/building-a-pagination-component-in-react-with-typescript-2e7f7b62b35d

  return (
    <div className={styles.wrapper}>
      <div
        className="btn-group"
        role="group"
        aria-label="Pagination"
      >

        {renderLeft(currentPage === 1)}

        {renderPageItem(1, (currentPage === 1))}

        {currentPage > 3 && totalPages !== 4 &&
          renderSeparator()
        }

        {currentPage === totalPages && totalPages > 3 && (
          renderPageItem(currentPage - 2)
        )}

        {currentPage > 2 && (
          renderPageItem(currentPage - 1)
        )}

        {currentPage !== 1 && currentPage !== totalPages && (
          renderPageItem(currentPage, true)
        )}

        {currentPage < totalPages - 1 && (
          renderPageItem(currentPage + 1)
        )}

        {currentPage === 1 && totalPages > 3 && (
          renderPageItem(currentPage + 2)
        )}

        {currentPage < totalPages - 2 && totalPages !== 4 &&
          renderSeparator()
        }

        {totalPages > 1 &&
          renderPageItem(totalPages, (currentPage === totalPages))
        }

        {renderRight(currentPage >= totalPages)}
      </div>
    </div>
  );
};
