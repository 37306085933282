import { Cell, Row } from "react-table";
import classNames from "classnames";
import { forwardRef, Ref } from "react";

type TableRowProps<T extends object> = {
  row: Row<T>
  onClickCallback?: (rowData: T) => void;
  additionalClasses?: string;
};

const TableRowComponent = <T extends object>({ row, onClickCallback, additionalClasses }: TableRowProps<T>, ref: Ref<HTMLTableRowElement>) => {

  const onClickCell = (cell: Cell<T>) => {
    if (!cell.column.disableClick) {
      onClickCallback?.(row.original);
    }
  };

  return (
    <tr
      ref={ref}
      className={classNames(
        onClickCallback ? "clickable-row" : "",
        additionalClasses,
      )}
      {...row.getRowProps()}
    >
      {row.cells.map((cell) => {
        return <td onClick={(e) => onClickCell(cell)} className={cell.column.disableClick ? "exclude-click-event" : ""} {...cell.getCellProps()} >{cell.render("Cell")}</td>;
      })}
    </tr>
  );
};

// Cast to desired type (where generics works correctly)
// https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
export const TableRow = forwardRef(TableRowComponent) as
  <T extends object>({ row, onClickCallback, additionalClasses }: TableRowProps<T> & { ref?: Ref<HTMLTableRowElement> }) => JSX.Element;

