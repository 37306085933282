import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { useEffect, useMemo, useState } from "react";
import { useGeneratePreviewMutation } from "@services/api/emailTemplate/emailTemplateApi";

type HtmlIFrameProps = Omit<React.HTMLProps<HTMLIFrameElement>, "type">;
type Props = {
  titleLabel: string;
  templateType: string;
  templateCulture: string;
  bodyMarkdown: string;
} & HtmlIFrameProps;

// TODO: Gør generisk, så den også kan bruges for SMS-skabelon --> mutation logik (generateEmailPreview, request) skal evt. passes ind som props?

const iFrameId = "email-template-preview";

// Has to be used inside ValidationForm
export const EmailTemplatePreview: React.FC<Props> = ({ titleLabel, templateType, templateCulture, bodyMarkdown, ...htmlIFrameProps }) => {
  const [generateEmailPreview, request] = useGeneratePreviewMutation();
  const [bodyHtml, setBodyHtml] = useState<string>("");
  const previewRequest = useMemo(() => ({ bodyMarkdown: bodyMarkdown ?? "" }), [bodyMarkdown]);

  // When previewRequest changes --> generate email preview via API
  useEffect(() => {
    if (request.isUninitialized || (request.status === QueryStatus.fulfilled && request.originalArgs && request.originalArgs.model !== previewRequest)) {
      generateEmailPreview({ type: templateType, culture: templateCulture, model: previewRequest }); // Only call when args change
    }
  }, [templateCulture, templateType, generateEmailPreview, previewRequest, request.isUninitialized, request.originalArgs, request.status]);

  // When a new preview is generated (request.data) --> set html-body (used in iframe)
  useEffect(() => {
    if (request.data) {
      setBodyHtml(request.data.emailBodyHtml);
    }
  }, [request.data]);

  const handleResize = () => {
    const iframe = $(`#${iFrameId}`);
    const contentHeight = iframe.contents().find("body #backgroundTable > tbody > tr > td").height();
    if (contentHeight) {
      iframe.height(Math.ceil(contentHeight));
    }
  };

  const disableLinks = () => {
    $(`#${iFrameId}`).contents().find("a").each((index, el) => {
      $(el).on("click", (aTagEvent) => {
        aTagEvent.stopImmediatePropagation();
        aTagEvent.preventDefault();
      });
    });
  };

  return (
    <>
      <h3>{titleLabel}</h3>
      <iframe
        id={iFrameId}
        onLoad={(e) => {
          handleResize();
          disableLinks();
        }}
        title="preview-frame"
        srcDoc={bodyHtml}
        width={"100%"}
        style={{ border: "none", marginBottom: "-6px" }}
        {...htmlIFrameProps}
      >
      </iframe>
    </>

  );
};