import {
  createContext, ReactNode, useContext,
} from "react";


// eslint-disable-next-line @typescript-eslint/naming-convention
const PdfViewportElementContext = createContext<HTMLDivElement | null>(null);

export const usePdfViewportElement = () => {
  return useContext(PdfViewportElementContext);
};

export const PdfViewportElementProvider = ( props : { children : ReactNode, pdfViewportElement : HTMLDivElement | null }) => {
  return (
    <PdfViewportElementContext.Provider value={props.pdfViewportElement}>
      {props.children}
    </PdfViewportElementContext.Provider>
  );
};
