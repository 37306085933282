import { Outlet, useLocation } from "react-router-dom";
import { fullscreenMode } from "@components/routing/routes";
import "./layout.scss";
import PausedPresentationOverlay from "@pages/present/pausedPresentationOverlay";
import { TitleBar } from "./titleBar/titleBar";
import TopMenu from "./topMenu/topMenu";

export const Layout = () => {
  const { pathname: currentPath } = useLocation();
  const isFullscreenMode = fullscreenMode(currentPath);

  return (
    <div className="body-container">
      <TopMenu />

      <div className={`page-content ${!isFullscreenMode ? "fullscreenMode" : ""}`}>
        <div className={"main-content"}>

          {isFullscreenMode &&
            <TitleBar simple={true} />
          }

          <div className={"body-content"}>
            {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
            <Outlet/>
          </div>
        </div>
      </div>
      <PausedPresentationOverlay />
    </div>
  );
};

