import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./tabs.scss";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { TabItem } from "@components/tab/tabModel";

export interface TabsProps {
  tabs: TabItem[];
  hideBorder?: boolean;
  hideTopBorder?: boolean;
  disableTabClick?: boolean;
  additionalClasses?: string;
}

export const Tabs = (props: TabsProps) => {
  const [active, setActive] = useState<TabItem>(props.tabs.find((x) => x.active) ?? props.tabs[0]);
  const navigate = useNavigate();

  useEffect(() => {
    setActive(props.tabs.find((x) => x.active) ?? props.tabs[0]);
  }, [props.tabs]);

  const renderTabContent = (tab: TabItem) => (
    <div className="tab-content" key={tab.id}>
      <div role="tabpanel" className={classNames("tab-pane", active && "active")} id={`${tab.id}`} key={tab.id}>
        {tab.content}
      </div>
    </div>
  );

  const onClickTab = (tab: TabItem) => {
    if (props.disableTabClick) {
      return;
    }

    if (tab.url) {
      navigate(tab.url);
    } else {
      setActive(tab);
    }

    props.tabs.find((x) => x.id === tab.id)?.onClick?.();
  };

  return <Tab.Group defaultIndex={props.tabs.findIndex((x)=>x.id === active.id)}>
    <Tab.List className={classNames("nav nav-tabs navbar-top tab-group", props.hideBorder && "hide-border", props.hideTopBorder && "hide-top-border", props.additionalClasses)} >
      {props.tabs.map((tab) =>
        (
          <Tab onClick={() => onClickTab(tab)}
            key={tab.id}
            className={classNames("padding-s", "w-100",
              "tab-notactive",
              active.id === tab.id
                ? "bg-white tab-pane active tab-active"
                : "",
              props.hideBorder && "hide-border",
              props.hideTopBorder && "hide-top-border",
            )}
          >
            {tab.title}
          </Tab>
        ),
      )}
    </Tab.List>
    <Tab.Panels>
      {renderTabContent(active)}
    </Tab.Panels>
  </Tab.Group>;
};
