import { NotificationModule } from "ditmer-embla";
import { useRef } from "react";
import { SelectParticipantsForm, SelectParticipantsFormRef } from "@components/case/participant/selectParticipantsForm";
import { SelectParticipantsModel } from "@components/case/participant/selectParticipantsModel";
import FormSubmitButton from "@components/forms/FormSubmitButton";
import { useLocalization } from "@components/localization/localizationProvider";
import Modal, { useModalContext } from "@components/modal/modal";
import WizardPanelContextProvider from "@components/embla/wizard/wizardPanelContextProvider";
import { useAddParticipantsMutation } from "@services/api/participant/caseParticipantApi";
import { CaseParticipantModel } from "@services/api/participant/models/caseParticipantModel";

type AddParticipantsProps = {
  caseId: string;
};

export const AddParticipants: React.FC<AddParticipantsProps> = ({ caseId }) => {
  const localizer = useLocalization();
  const [addParticipants, addParticipantsRequest] = useAddParticipantsMutation();
  const { close } = useModalContext();
  const formRef = useRef<SelectParticipantsFormRef>(null);

  const onSubmitParticipantsSelected = (model: SelectParticipantsModel) => {
    const participantModels: CaseParticipantModel[] = model.selectedParticipants.map((p) => ({
      employeeId: p.employeeId,
      userId: p.userId,
      name: p.name,
      caseAccessRights: p.caseAccessRights,
    }));
    addParticipants({ caseId, model: { participants: participantModels } })
      .then(() => {
        NotificationModule.showSuccessSmall(localizer.theParticipantsWasAdded());
        close();
      });
  };

  return (
    <>
      <Modal.Header>{localizer.addParticipants()}</Modal.Header>
      <Modal.Body>
        <WizardPanelContextProvider>
          <SelectParticipantsForm
            ref={formRef}
            initialValues={[]}
            onSubmitCallback={onSubmitParticipantsSelected}
            caseId={caseId}
            renderFormActions={
              <div></div>
            }
          />
        </WizardPanelContextProvider>
      </Modal.Body>
      <Modal.Footer>
        <FormSubmitButton
          state={{
            isSubmitting: addParticipantsRequest.isLoading,
            isSubmitSuccessful: addParticipantsRequest.isSuccess,
          }}
          onClick={(e) => formRef.current?.submit(e)}
        >
          {localizer.add()}
        </FormSubmitButton>
      </Modal.Footer>
    </>
  );
};
