import { useMemo } from "react";
import { useAuth } from "@components/auth/authProvider";
import HubConnectionFactory, { HubConnectionType } from "@services/signalRClient/hubConnectionFactory";
import { useConfig } from "@components/config/configProvider";


const useHubConnection = (connectionType: HubConnectionType) => {

  const config = useConfig();
  const { userService } = useAuth();
 
  const connection = useMemo(() => HubConnectionFactory.createHub(connectionType, userService, config.apiUrl), [config.apiUrl, connectionType, userService]);

  return connection;
};

export default useHubConnection;