import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@app";

export interface TitlebarState {
  breadcrumbs: [
    {
      text: string;
      url: string;
    },
  ],
  title: string,
  withBackButton: boolean,
  actions: [
    {
      text: string;
      url: string;
      type: string;
    },
  ],
}

const initialState: TitlebarState = {
  breadcrumbs: [{ text: "", url: "" }],
  title: "",
  withBackButton: false,
  actions: [{ text: "", url: "", type: "" }],
};

export const titlebarSlice = createSlice({
  name: "titlebar",
  initialState,
  reducers: {
    newBreadcrumb: (state, action: PayloadAction<{ text: string, url: string }>) => {
      state.breadcrumbs = [{ text: action.payload.text, url: action.payload.url }];
    },

    addBreadcrumb: (state, action: PayloadAction<{ text: string, url: string }>) => {
      state.breadcrumbs.push({ text: action.payload.text, url: action.payload.url });
    },

    clearBreadcrumbs: (state) => {
      state.breadcrumbs = initialState.breadcrumbs;
    },

    setTitle: (state, { payload }: PayloadAction<string | { title: string, withBackButton: boolean }>) => {
      if (typeof payload === "string") {
        state.title = payload;
        state.withBackButton = false;
      } else {
        state.title = payload.title;
        state.withBackButton = payload.withBackButton;
      }
    },

    clearTitle: (state) => {
      state.title = initialState.title;
    },

    newAction: (state, action: PayloadAction<{ text: string, url: string, type: "primary" | "secondary" }>) => {
      state.actions = [{ text: action.payload.text, url: action.payload.url, type: action.payload.type }];
    },

    addAction: (state, action: PayloadAction<{ text: string, url: string, type: "primary" | "secondary" }>) => {
      state.actions.push({ text: action.payload.text, url: action.payload.url, type: action.payload.type });
    },

    removeActions: (state) => {
      state.actions = initialState.actions;
    },
  },
});

export const { addBreadcrumb, newBreadcrumb, clearBreadcrumbs, setTitle, clearTitle, newAction, addAction, removeActions } = titlebarSlice.actions;
export const titlebarStateSelector = (state: RootState) => state.titlebar;
export default titlebarSlice.reducer;
