import classNames from "classnames";
import styles from "./activationHandles.module.scss";

interface ActivationHandlesProps {
  disabled?: boolean;
  west?: boolean;
  east?: boolean;
  north?: boolean;
  south?: boolean;
}

const ActivationHandles = ({
  disabled,
  north,
  east,
  south,
  west,
}: ActivationHandlesProps) => {
  if (disabled) {
    return null;
  }
  return (
    <>
      {north && <div className={classNames(styles.handle, styles.north)} />}
      {east && <div className={classNames(styles.handle, styles.east)} />}
      {south && <div className={classNames(styles.handle, styles.south)} />}
      {west && <div className={classNames(styles.handle, styles.west)} />}
    </>
  );
};

export default ActivationHandles;
