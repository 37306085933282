import { useEffect, useState } from "react";
import Modal, { ModalSizeEnum } from "@components/modal/modal";
import { ExportDocument } from "./exportDocument";
import DownloadExportCaseDocument from "./downloadExportDocuments";

type ExportCaseDocumentModalProps = {
  openModal: boolean;
  onCloseCallback: () => void;
  caseId: string;
  caseDocumentId: string;
  caseDocumentTitle: string;
};


enum ExportCaseDocumentModalTab {
  Export,
  PollDownload,
}

const ExportCaseDocumentModal = ({
  openModal,
  onCloseCallback,
  ...sharedProps
}: ExportCaseDocumentModalProps) => {
  const [ currentTab, setCurrentTab ] = useState<ExportCaseDocumentModalTab>(ExportCaseDocumentModalTab.Export);
  const [ caseDocumentExportId, setCaseDocumentExportId ] = useState<string>();

  const onExportResult = (caseDocumentExportIdparam: string) => {
    setCaseDocumentExportId(caseDocumentExportIdparam);
    setCurrentTab(ExportCaseDocumentModalTab.PollDownload);
  };

  const onCloseModal = () => {
    onCloseCallback();
    setCurrentTab(ExportCaseDocumentModalTab.Export);
  };
 

  return (
    <Modal open={openModal} onClose={onCloseModal} size={ModalSizeEnum.Large}>
      {currentTab === ExportCaseDocumentModalTab.Export &&
        <ExportDocument {...sharedProps}
          onExportResult={onExportResult} onBackClicked={() => onCloseCallback()}/>
      }
      {(currentTab === ExportCaseDocumentModalTab.PollDownload && caseDocumentExportId) &&
        <DownloadExportCaseDocument {...sharedProps}
          caseDocumentExportId={caseDocumentExportId}
          onBackClicked={() => setCurrentTab(ExportCaseDocumentModalTab.Export)}/>
      }
    </Modal>
  );
};

export default ExportCaseDocumentModal;
