import classNames from "classnames";
import { InputHTMLAttributes } from "react";

const InlineSwitch = ({
  className,
  ...inputProps
}: InputHTMLAttributes<HTMLInputElement>) => (
  <label className="switch-container">
    <input
      type="checkbox"
      className={classNames("switch", className)}
      {...inputProps}
    />
    <div className="slider"></div>
  </label>
);

export default InlineSwitch;
