import classNames from "classnames";
import { useMemo, useRef } from "react";
import { createVerticalStrength, useDndScrolling } from "react-dnd-scrolling";
import { ExtractDraftModel } from "@services/api/extractDraft/models/extractDraftModel";
import { useLocalization } from "@components/localization/localizationProvider";
import { DndSectionSingle } from "../DndSectionSingle/DndSectionSingle";
import DndSectionMultiple from "../DnDSectionMultiple/DndSectionMultiple";
import { DndFrontpage } from "../DndFrontpage/DndFrontpage";
import { TableOfContents } from "../../TableOfContents/TableOfContents";
import styles from "./SectionContainer.module.scss";

type SectionsContainerProps = {
  extractDraft: ExtractDraftModel;
  onlySelectVerticalLines: boolean;
};

export const SectionsContainer = ({ extractDraft, onlySelectVerticalLines }: SectionsContainerProps) => {
  const localizer = useLocalization();

  const scrollingComponentRef = useRef<HTMLDivElement>(null);
  const linearVerticalStrength = createVerticalStrength(150);

  useDndScrolling(scrollingComponentRef, { verticalStrength: linearVerticalStrength });

  const orderedSections = useMemo(() => [...extractDraft.sections].sort((a, b) => a.sortOrder - b.sortOrder), [extractDraft]);
  
  return (
    <div className="h-100 pt-3">
      <div ref={scrollingComponentRef} className={classNames("d-flex gap-1 flex-column scrollbar h-100", styles.container)}>
        <DndFrontpage name={localizer.frontpage()} extractDraft={extractDraft} />
        <TableOfContents tableOfContents={extractDraft.tableOfContents} caseId={extractDraft.caseId} extractDraftId={extractDraft.id} frontpage={extractDraft.frontpage}/>
        {orderedSections.map((section) =>
          section.isMultiFile ? (
            <DndSectionMultiple extractDraft={extractDraft} key={section.id} sectionData={section} onlySelectVerticalLines={onlySelectVerticalLines} />
          ) : (
            <DndSectionSingle key={section.id} sectionData={section} extractDraft={extractDraft} onlySelectVerticalLines={onlySelectVerticalLines} />
          ),
        )}
      </div>
    </div>
  );
};
