import { MouseEvent, useEffect, useRef, useState } from "react";
import Button from "@components/embla/button";
import { EmblaIcon } from "@components/embla/emblaIcon";
import styles from "./clampedParagraph.module.scss";

interface ClampedParagraphProps extends React.HTMLAttributes<HTMLParagraphElement> {
  lineClamp: number;
  notExpandable?: boolean;
  onToggle?: (collapsed: boolean, e: MouseEvent) => void;
  isInitiallyClamped?: boolean;
}

const ClampedParagraph = ({ lineClamp, notExpandable = false, onToggle, isInitiallyClamped = true, children, ...paragraphProps }: ClampedParagraphProps) => {
  const [collapsed, setCollapsed] = useState(isInitiallyClamped);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(contentRef.current?.scrollHeight > contentRef.current?.clientHeight);
    }
  }, []);

  const showCollapseToggle = !notExpandable && isOverflowing;

  const handleCollapseToggle = (e: MouseEvent) => {
    e.stopPropagation();
    const newState = !collapsed;
    setCollapsed(newState);
    onToggle?.(newState, e);
  };

  return (
    <p {...paragraphProps}>
      <span
        ref={contentRef}
        className={styles.collapsible}
        // eslint-disable-next-line @typescript-eslint/naming-convention
        style={{ WebkitLineClamp: collapsed ? lineClamp : undefined }}
      >
        {children}
      </span>
      {showCollapseToggle && (
        <span className="d-flex justify-content-center">
          <Button linkBtn className="p-0" onClick={handleCollapseToggle}>
            <EmblaIcon iconName={collapsed ? "arrow-down" : "arrow-up"} />
          </Button>
        </span>
      )}
    </p>
  );
};

export default ClampedParagraph;
