import classNames from "classnames";
import FormRadio from "@components/forms/FormRadio";
import styles from "../radioGroup.module.scss";

type ColorSelectionProps = {
  name: string;
  colors: string[];
  methods: any;
  id: string;
  top?: number;
  externalOnChange?: () => void;
  noContainerStyle?: boolean;
};

const ColorSelection = ({ colors, name, methods, id, externalOnChange, top, noContainerStyle } : ColorSelectionProps) => {
  return (
    <div style={{ position: top === undefined ? "static" : "absolute", top: `${top ?? 0}px` }} className={classNames("d-flex flex-row gap-8 px-2 pb-0 pt-2 m-0", !noContainerStyle && "card")} >
      {colors.map((color) =>
        <label key={color}>
          <FormRadio
            methods={methods}
            id={`${id}-${color}`}
            name={name}
            value={color}
            externalOnChange={externalOnChange}
          >
            <div
              className={classNames(styles.radio, "rounded-circle")}
              style={{ backgroundColor: color }}
            />
          </FormRadio>
        </label>,
      )}
    </div>
  );
};

export default ColorSelection;