import { useEffect, useRef, useState } from "react";
import { UploadFileChunkModel } from "@services/api/file/models/uploadFileChunkModel";
import { FinalizeFileChunksModel } from "@services/api/file/models/FinalizeFileChunksModel";
import { FileContentType } from "@services/api/file/models/FileContentType";
import { ErrorTypes } from "@infrastructure/errorTypes";
import { useFinalizeFileChunksMutation, useUploadFileChunkMutation } from "@services/api/file/fileStorageApi";
import { useCreateDocumentUploadUriMutation } from "@services/api/document/caseDocumentApi";
import { UploadFileChunkedUriViewModel } from "@services/api/document/models/uploadFileChunkedUrlViewModel";


const useFileUpload = (caseId: string, fileUploadedCallback?: (blobId: string, uploadedFile: File) => void, chunkSizeParam?: number, fileId?: string) => {
  const [ createDocumentUploadUri ] = useCreateDocumentUploadUriMutation();
  const [ uploadFileChunk ] = useUploadFileChunkMutation();
  const [ finalizeFile ] = useFinalizeFileChunksMutation();

  const [ uploadProgress, setUploadProgress ] = useState(0);
  const [ uploadFinished, setUploadFinished ] = useState(false);
  const [ uploadFailed, setUploadFailed ] = useState<any>();
  const [ uploadCanceled, setUploadCanceled ] = useState(false);
  const [ isFinalizing, setIsFinalizing ] = useState(false);
  const [ uploadedFileBlobId, setUploadedFileBlobId ] = useState<string | undefined>();
  const [ fileToUpload, setFileToUpload ] = useState<File | undefined>();

  const cancelPromise = useRef<any>(null);
  const defaultChunkSize = 1048576 * 3;

  useEffect(() => {
    if (!fileToUpload) {
      return;
    }

    const chunkSize = chunkSizeParam ?? defaultChunkSize;

    const chunkCount =
      fileToUpload.size % chunkSize === 0
        ? fileToUpload.size / chunkSize
        : Math.floor(fileToUpload.size / chunkSize) + 1;

    const fileUpload = async () => {
      const uploadUriModel = await createDocumentUploadUri({ caseId: caseId }).unwrap();

      let endOfTheChunk = chunkSize;
      let beginingOfTheChunk = 0;

      for (let counter = 1; counter <= chunkCount; counter++) {
        const chunk = fileToUpload.slice(beginingOfTheChunk, endOfTheChunk) as File;
        await uploadChunk(chunk, counter, uploadUriModel);

        beginingOfTheChunk = endOfTheChunk;
        endOfTheChunk = endOfTheChunk + chunkSize;

        const percentage = (counter / chunkCount) * 100;
        setUploadProgress(percentage);
      }

      await uploadCompleted(uploadUriModel);
    };

    const uploadChunk = async (chunk: File, counter : number, uploadUriModel: UploadFileChunkedUriViewModel) => {

      const uploadFileModel: UploadFileChunkModel = {
        file: chunk,
        blobGuid: uploadUriModel.fileId,
        containerName: uploadUriModel.containerName,
        index: counter,
        totalCount: chunkCount,
      };
      const uploadFileChunkReq = uploadFileChunk({ uploadFileUri: uploadUriModel.uploadUri, model: uploadFileModel });
      cancelPromise.current = uploadFileChunkReq;
      await uploadFileChunkReq.unwrap();
    };

    const uploadCompleted = async (uploadUriModel: UploadFileChunkedUriViewModel) => {

      setIsFinalizing(true);

      const finalizeFileChunksModel: FinalizeFileChunksModel = {
        fileBlobGuid: uploadUriModel.fileId,
        containerName: uploadUriModel.containerName,
        contentType: FileContentType.Pdf,
        totalChunkCount: chunkCount,
      };

      if (uploadCanceled) {
        cancelPromise.current?.abort();
        return;
      }
      const finalizeFileReq = finalizeFile({ uploadFileUri: uploadUriModel.finalizeUri, model: finalizeFileChunksModel });
      cancelPromise.current = finalizeFileReq;
      const createdFileId = await finalizeFileReq.unwrap();

      setUploadedFileBlobId(createdFileId);
      setUploadFinished(true);
      setIsFinalizing(false);

      fileUploadedCallback?.(createdFileId, fileToUpload);
    };

    if (!uploadFailed) {
      fileUpload().catch((e) => {
        if (e.name !== ErrorTypes.AbortError) {
          setUploadFailed(e);
          setIsFinalizing(false);
        }

      });
    }
  },
  [caseId, chunkSizeParam, createDocumentUploadUri, defaultChunkSize, fileToUpload, finalizeFile, uploadCanceled, uploadFailed, uploadFileChunk, fileId, fileUploadedCallback]);


  const resetUpload = async () => {
    setUploadProgress(0);
    setUploadFailed(undefined);
  };

  const cancelUpload = async () => {
    setUploadCanceled(true);
    await cancelPromise.current?.abort();
  };

  const beginUploadFile = (file: File) => {
    setFileToUpload(file);
  };

  return { resetUpload, cancelUpload, uploadFailed, uploadFinished, uploadProgress, isFinalizing, uploadedFileBlobId, beginUploadFile };
};

export default useFileUpload;
