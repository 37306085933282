import { NotificationModule } from "ditmer-embla";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addBreadcrumb, newBreadcrumb, setTitle } from "@components/layout/titleBar/titlebarSlice";
import { useLocalization } from "@components/localization/localizationProvider";
import { RoutePaths } from "@components/routing/routes";
import { useAppDispatch, useAppSelector } from "@hooks";
import { AccessRight } from "@infrastructure/AccessRight";
import { useDeleteEmployeeProfilePictureMutation, useEditEmployeeMutation, useGetEmployeeQuery, useUpdateEmployeeProfilePictureMutation } from "@services/api/employee/employeeApi";
import { CreateEditEmployeeModel } from "@services/api/employee/models/createEditEmployeeModel";
import ChangeProfilePicture from "@pages/profile/changeProfilePicture";
import userApi from "@services/api/user/userApi";
import { useAuth } from "@components/auth/authProvider";
import { apiTags } from "@services/api/baseApi";
import { EmployeeCreateEditForm } from "./employeeCreateEditForm";

export const EmployeeEditPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [dontFetch, setDontFetch] = useState(false);
  const { data: employee, isLoading } = useGetEmployeeQuery(id!, { skip: dontFetch });
  const [editEmployee] = useEditEmployeeMutation();
  const [updateProfilePicture] = useUpdateEmployeeProfilePictureMutation();
  const [deleteProfilePicture] = useDeleteEmployeeProfilePictureMutation();
  const { userService, user: currentUser } = useAuth();

  const dispatch = useAppDispatch();
  const localizer = useLocalization();

  useEffect(() => {
    dispatch(newBreadcrumb({ text: localizer.list(), url: RoutePaths.employees.url }));
    dispatch(addBreadcrumb({ text: localizer.edit(), url: "" }));
    dispatch(setTitle({ title: localizer.employee(), withBackButton: true }));
  }, [dispatch, localizer]);

  const onSubmit = async (model: CreateEditEmployeeModel) => {
    const result = await editEmployee(model).unwrap();

    if (result) {
      if (
        currentUser?.id === model.employee.userProfile.id &&
        model.employee.accessRights.every((right) => right !== AccessRight.SysAdmin && right !== AccessRight.AdministerOrg)
      ) {
        navigate(RoutePaths.index.url);
      }
      updateUserToken(model);
      NotificationModule.showSuccessSmall(localizer.theEmployeeWasUpdated());
    }
  };

  const updateUserToken = async (model: CreateEditEmployeeModel) => {
    if (
      currentUser?.id === model.employee.userProfile.id
    ) {
      await userService.silentlyLogin();
      dispatch(userApi.util.invalidateTags([apiTags.user]));
    }
  };

  if (isLoading || !employee)
    return null;

  const onUpdateProfilePicture = async (croppedFile: File) => {
    const result = await updateProfilePicture({ id: employee?.employee.userProfile.id, employeeId: employee?.employee.id, profilePictureFile: croppedFile }).unwrap();
      
    if (result) {
      NotificationModule.showSuccessSmall(localizer.employeeProfilePictureChangedSuccessfully());
    }
  };

  const onDeleteProfilePicture = async (resetForm: () => void) => {
    await deleteProfilePicture(employee?.employee.id).unwrap();
    NotificationModule.showSuccessSmall(localizer.employeeProfilePictureDeletedSuccessfully());
    resetForm();
  };

  return (
    <>
      <EmployeeCreateEditForm
        initialValues={employee}
        onSubmit={onSubmit}
        isEdit={true}
        setNoRefetch={() => setDontFetch(true)}
      />

      <ChangeProfilePicture
        existingProfilePictureUrl={employee.profilePictureUrl}
        onSubmit={onUpdateProfilePicture}
        onDelete={onDeleteProfilePicture}
      />
    </>
  );
};
