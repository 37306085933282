import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CursorIcon } from "@content/icons/cursor.svg";
import { ReactComponent as PointerIcon } from "@content/icons/pointer-icon.svg";
import { ReactComponent as ZoomIcon } from "@content/icons/zoom-icon.svg";
import { Key } from "@infrastructure/Key";
import { HIGHLIGHT_COLOR_VALUES } from "@pages/pdfviewer/component/models/markingModel";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";
import { activeToolSelector, setActiveToolColor } from "@pages/pdfviewer/component/pdfViewerSlice";
import MarkingIcon from "@pages/pdfviewer/component/shared/markingIcon";
import ToolboxCard from "@pages/pdfviewer/component/toolboxCard";
import useShortcut from "src/hooks/useShortcut";
import { isDevelopment } from "src/utility/env";
import styles from "./presenterToolbox.module.scss";

export enum PresenterTool {
  Cursor = "cursor",
  Zoom = "zoom",
  Pointer = "pointer",
  Highlight = "highlight",
}

interface PresenterToolboxProps {
  activeTool: PresenterTool;
  className?: string,
  onToolChange: (t: PresenterTool) => void;
  isShowingTempPage: boolean;
}

const PresenterToolbox = ({
  activeTool,
  className,
  onToolChange,
  isShowingTempPage,
}: PresenterToolboxProps) => {
  const activePdfTool = useSelector(activeToolSelector);
  const selectedColor = activePdfTool.type === MarkingType.Highlight && activePdfTool.template.data.color;
  const dispatch = useDispatch();

  useShortcut({
    key: Key.Escape,
    allowOnInputs: true,
    callback: () => onToolChange(PresenterTool.Cursor),
  });

  const toggleTool = (tool: PresenterTool) => {
    onToolChange(tool === activeTool ? PresenterTool.Cursor : tool);
  };

  return (
    <div className={classNames(styles.container, className)}>
      <div>
        <ToolboxCard>
          <ToolboxCard.Btn
            active={activeTool === PresenterTool.Cursor}
            onClick={() => onToolChange(PresenterTool.Cursor)}
          >
            <CursorIcon />
          </ToolboxCard.Btn>
          <ToolboxCard.Btn
            active={activeTool === PresenterTool.Pointer}
            onClick={() => onToolChange(PresenterTool.Pointer)}
          >
            <PointerIcon />
          </ToolboxCard.Btn>
          {isDevelopment && !isShowingTempPage &&
            <ToolboxCard.Btn
              active={activeTool === PresenterTool.Highlight}
              onClick={() => onToolChange(PresenterTool.Highlight)}
            >
              <MarkingIcon markingType={MarkingType.Highlight} />
            </ToolboxCard.Btn>
          }
        </ToolboxCard>
        {!isShowingTempPage &&
          <ToolboxCard>
            <ToolboxCard.Btn
              active={activeTool === PresenterTool.Zoom}
              onClick={() => toggleTool(PresenterTool.Zoom)}
            >
              <ZoomIcon />
            </ToolboxCard.Btn>
          </ToolboxCard>
        }
      </div>
      {selectedColor &&
        <ToolboxCard>
          {HIGHLIGHT_COLOR_VALUES.map((color) =>
            <ToolboxCard.Btn onClick={() => dispatch(setActiveToolColor(color))}>
              <div
                className={classNames(styles.colorBtn, "rounded-circle", selectedColor === color && styles.active)}
                style={{ backgroundColor: color }}
              />
            </ToolboxCard.Btn>,
          )}
        </ToolboxCard>
      }
    </div>
  );
};

export default PresenterToolbox;
