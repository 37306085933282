import { SearchInput } from "@components/search/searchInput";
import { Dropdown, DropdownOption } from "@components/dropdown/dropdown";

export type PageSizeFilterOptions = {
  pageSize: Readonly<number>;
  onPageSizeChanged: (size: number) => void;
  selectPageSizeLabel: string;
  selectablePageSizes?: number[]
};

export type SearchFilterOptions = {
  searchQuery: Readonly<string>;
  onSearchChanged: (searchQuery: string) => void;
  searchLabel?: string;
  searchPlaceholder?: string;
};

type TableFilterRowProps = {
  pageSizeFilterOptions?: PageSizeFilterOptions;
  searchFilterOptions?: SearchFilterOptions;
} & React.HTMLProps<HTMLDivElement>;

export const TableFilterRow = ({ pageSizeFilterOptions, searchFilterOptions, children, ...props }: TableFilterRowProps) => {

  const pageSizeOptions = pageSizeFilterOptions?.selectablePageSizes ?? [10, 25, 50, 100];
  const pageSizeDropdownOptions: DropdownOption<number>[] = pageSizeOptions.map(
    (size) => {
      return { label: `${size}`, value: size } as DropdownOption<number>;
    },
  );

  const handleNewLength = (newLength: number | undefined) => {
    if (newLength) {
      pageSizeFilterOptions?.onPageSizeChanged(newLength);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const searchQuery = e.target.value;

    if (searchQuery !== undefined) {
      searchFilterOptions?.onSearchChanged(searchQuery);
    }
  };

  return (
    <div className="d-flex flex-wrap gap-m" {...props}>
      <div className="d-flex flex-wrap gap-m mr-auto">
        {pageSizeFilterOptions &&
          <label>
            {pageSizeFilterOptions.selectPageSizeLabel}
            <Dropdown
              options={pageSizeDropdownOptions}
              onChange={(newVal) => handleNewLength(newVal?.value)}
              value={{ label: `${pageSizeFilterOptions.pageSize}`, value: pageSizeFilterOptions.pageSize }}
              minWidth={60}
            />
          </label>
        }
        {children}
      </div>
      {searchFilterOptions &&
        <label>
          {searchFilterOptions.searchLabel}
          <SearchInput
            value={searchFilterOptions.searchQuery}
            placeholder={searchFilterOptions.searchPlaceholder ?? searchFilterOptions.searchLabel}
            onChangeCallback={handleSearch}
            useDebounce={true} />
        </label>
      }
    </div>
  );
};
