import classNames from "classnames";
import { InputHTMLAttributes } from "react";
import { FieldValues, useController } from "react-hook-form";
import { FormControlProps } from "./FormControlProps.model";
import { FormInputLabel } from "./formInputLabel";
import useCheckedState from "./useCheckedState";
import styles from "./formCheckbox.module.scss";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormCheckbox = <T extends FieldValues = any>({
  methods: { control },
  label,
  name,
  value: checkboxValue,
  formGroupClassName,
  className,
  labelClassName,
  ...inputProps
}: FormControlProps<T, InputHTMLAttributes<HTMLInputElement>>) => {
  // use controller instead of uncontrolled inputs to work with number type arrays
  const { field, fieldState: { error } } = useController({ name, control });
  const { isChecked, onChange } = useCheckedState({ field, checkboxValue });

  return (
    <div className={classNames("form-group", formGroupClassName)}>
      <input
        type="checkbox"
        className={classNames("form-field", className)}
        {...inputProps}
        {...field}
        checked={isChecked}
        onChange={onChange}
        value={checkboxValue}
      />
      <FormInputLabel
        htmlFor={inputProps.id}
        labelText={label ?? ""}
        className={classNames("checkbox-label", !label && styles.checkboxWithNoLabel, labelClassName)}
      />
      {error && <span className="errorMessage field-validation-error">{error.message}</span>}
    </div>
  );
};

export default FormCheckbox;
