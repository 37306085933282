import FormInput from "@components/forms/FormInput";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useGetDocumentVerticalIntervalsQuery } from "@services/api/document/caseDocumentApi";

type VerticalLinesDisplayProps = {
  caseId: string;
  documentId: string;
};

const VerticalLinesDisplay = ({ caseId, documentId }: VerticalLinesDisplayProps)=> {
  const { data } = useGetDocumentVerticalIntervalsQuery({ caseId, documentId });
  const intervals = data ? data.map((interv) => `${interv.firstPageIndex + 1}-${interv.lastPageIndex + 1}`).join(", ") : "";

  const methods = useValidatedForm({
    defaultValues: {
      verticalLines: intervals,
    },
  },
  );

  return (
    <FormInput
      methods={methods}
      id={`edit-document-${documentId}-vertical-input`}
      name="verticalLines" disabled />
  );
};

export default VerticalLinesDisplay;