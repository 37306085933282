import { useEffect } from "react";
import ReactDOM from "react-dom";
import { useExtractCompositionContext } from "@pages/extractCompositionPage/extractCompositionContextProvider";
import Modal from "@components/modal/modal";
import FormInput from "@components/forms/FormInput";
import FormSubmitButton from "@components/forms/FormSubmitButton";
import { useLocalization } from "@components/localization/localizationProvider";
import useValidatedForm from "@components/forms/useValidatedForm";
import {
  addSectionModelValidation,
  ExtractDraftSectionCreateModel,
} from "@services/api/extractDraft/models/extractDraftSectionCreateModel";
import { useCreateSectionMutation } from "@services/api/extractDraft/extractDraftApi";

export type AddSectionFormProps = {
  caseId: string;
  extractId: string;
  actionsContainer?: Element;
  onSuccess?: () => void;
};


export const AddSectionForm = ({ caseId, extractId, actionsContainer, onSuccess }: AddSectionFormProps) => {

  const localizer = useLocalization();
  const { sectionReducer } = useExtractCompositionContext();

  const [ createSection, createSectionRequest ] = useCreateSectionMutation();

  const methods = useValidatedForm({
    validationSchema: addSectionModelValidation(localizer),
    defaultValues: {
      title: "",
      parentId: sectionReducer.state.createSectionParentId,
    } as ExtractDraftSectionCreateModel,
  });

  useEffect(() => {
    methods.setValue("parentId", sectionReducer.state.createSectionParentId);
  }, [methods, sectionReducer.state.createSectionParentId]);

  const submitAddSection = (model: ExtractDraftSectionCreateModel) => {
    createSection({ caseId: caseId, extractDraftId: extractId, model: model }).unwrap().then((responseData) => {
      if (responseData) {
        resetSectionState();
        onSuccess?.();
      }

    });
  };

  const resetSectionState = () => {
    methods.reset();

  };

  const actionRender = (
    <FormSubmitButton isPrimary onClick={methods.handleSubmit(submitAddSection)} state={{ isSubmitting: createSectionRequest.isLoading, isSubmitSuccessful: createSectionRequest.isSuccess }}>
      {localizer.addToDraft()}
    </FormSubmitButton>
  );

  return (
    <>
      <div>
        <p className="subtle">{localizer.createSectionHint1()}</p>
        <p className="subtle">{localizer.createSectionHint2()}</p>

        <form onSubmit={methods.handleSubmit(submitAddSection)} className="pt-4">
          <FormInput methods={methods} name={"title"} id={"title"} className="col-6 d-flex" required label={localizer.title()}/>
        </form>
      </div>
      {actionsContainer ?
        ReactDOM.createPortal(actionRender, actionsContainer)
        :
        actionRender
      }
    </>
  );
};
