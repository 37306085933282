import { useEffect } from "react";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { useAppDispatch } from "@hooks";
import { initPdfViewer, PdfType, setTotalPages } from "@pages/pdfviewer/component/pdfViewerSlice";

const usePresentationDocument = (presentationId: string) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(initPdfViewer({ pdfTypeId: presentationId, pdfType: PdfType.Presentation }));
  }, [dispatch, presentationId]);

  const handleLoadSuccess = (pdf: DocumentCallback) => {
    dispatch(setTotalPages(pdf.numPages));
  };

  return {
    handleLoadSuccess,
  };
};

export default usePresentationDocument;
