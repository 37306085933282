const formatBytes = (bytes: number, decimalPoints: number) => {
  const units = ["B", "KB", "MB", "GB", "TB", "PB"];
  
  let bytesTemp = bytes;
  let i = 0;
  
  for (i; bytesTemp > 1024; i++) {
    bytesTemp /= 1024;
  }

  return parseFloat(bytesTemp.toFixed(decimalPoints)) + " " + units[i];
};

export default formatBytes;