import { RefObject, useEffect } from "react";
import { Event } from "@infrastructure/Event";

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(ref: RefObject<T>, handler: () => void, enabled = true) => {
  useEffect(
    () => {
      if (!enabled) {
        return;
      }
      const listener = (event: MouseEvent | TouchEvent) => {
        // call handler if clicked element is not current element or it's descendant
        if (
          ref.current
          && event.target instanceof Element
          && !ref.current.contains(event.target)
        ) {
          handler();
        }
      };
      document.addEventListener(Event.Pointerdown, listener);
      return () => {
        document.removeEventListener(Event.Pointerdown, listener);
      };
    }, [ref, handler, enabled]);
};
