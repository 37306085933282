import classNames from "classnames";
import { useState } from "react";
import { useDrop } from "react-dnd";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import { ExtractDraftModel } from "@services/api/extractDraft/models/extractDraftModel";
import { useAddExtractDraftDocumentsMutation, useDeleteDocumentFromSectionExtractDraftMutation } from "@services/api/extractDraft/extractDraftApi";
import { IconButton } from "@components/button/IconButton/IconButton";
import { ExtractDraftSectionModel } from "@services/api/extractDraft/models/extractDraftSectionModel";
import { useGetDocumentDownloadUrlQuery } from "@services/api/document/caseDocumentApi";
import { useDragDropSectionReorder } from "@pages/extractCompositionPage/hooks/useDragDropSectionReorder";
import { ReorderExtractDraftSectionDocument, pageIntervalsToString } from "@pages/extractCompositionPage/hooks/useDragDropSectionDocumentReorder";
import { CaseDocumentModel } from "@services/api/case/models/caseDocumentModel";
import { AddDocumentsToDraftModel, IntervalExtractionType } from "@components/case/extractDraft/addDocumentsToDraftModel";
import { handleDragEnd, handleDragStartRemoveGhost } from "@pages/extractCompositionPage/util/util";
import IndicatorLine from "@components/dnd/DragNDropIndicator/IndicatorLine/IndicatorLine";
import { useExtractCompositionContext } from "@pages/extractCompositionPage/extractCompositionContextProvider";
import DocumentModal from "../../DocumentModal/DocumentModal";
import { dndItemTypes } from "../DndItemTypes";
import styles from "./DndSectionSingle.module.scss";

type DndSectionProps = {
  extractDraft: ExtractDraftModel;
  sectionData: ExtractDraftSectionModel;
  onlySelectVerticalLines: boolean;
};

export const DndSectionSingle = ({ sectionData, extractDraft, onlySelectVerticalLines }: DndSectionProps) => {
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const documentData = sectionData.documents[0];
  const [deleteDocument] = useDeleteDocumentFromSectionExtractDraftMutation();
  const [addDocumentsToDraft] = useAddExtractDraftDocumentsMutation();

  const { selectedDocumentsReducer } = useExtractCompositionContext();
  const selectedDocuments = selectedDocumentsReducer.state.selectedDocuments;
  
  const documentExists = !!documentData;

  const localizer = useLocalization();

  const { divRef, position, isDragging } = useDragDropSectionReorder(sectionData, extractDraft);

  const handleDeleteDocument = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    deleteDocument({
      caseId: extractDraft.caseId,
      documentId: documentData.id,
      extractDraftId: extractDraft.id,
      sectionId: sectionData.id,
    });
  };

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: dndItemTypes.document,
      drop: async (dropped: CaseDocumentModel | ReorderExtractDraftSectionDocument) => {
        if (sectionData.documents[0]) {
          await deleteDocument({
            caseId: extractDraft.caseId,
            documentId: documentData.id,
            extractDraftId: extractDraft.id,
            sectionId: sectionData.id,
            shouldInvalidate: false,
          });
        }

        if ("sectionId" in dropped) {
          const model: AddDocumentsToDraftModel = {
            sectionId: sectionData.id,
            documents: [
              {
                caseDocumentId: dropped.document.caseDocumentId,
                pageIntervals: pageIntervalsToString(dropped.document.pageIntervals),
                intervalExtractionType: onlySelectVerticalLines ? IntervalExtractionType.PagesWithVerticalLines : IntervalExtractionType.AllDocument,
              },
            ],
          };

          await deleteDocument({
            caseId: extractDraft.caseId,
            documentId: dropped.document.id,
            extractDraftId: extractDraft.id,
            sectionId: dropped.sectionId,
            shouldInvalidate: false,
          });
          await addDocumentsToDraft({
            caseId: extractDraft.caseId,
            extractDraftId: extractDraft.id,
            model,
          });
        } else {
          const model: AddDocumentsToDraftModel = {
            sectionId: sectionData.id,
            documents: [
              {
                caseDocumentId: dropped.id,
                intervalExtractionType: onlySelectVerticalLines ? IntervalExtractionType.PagesWithVerticalLines : IntervalExtractionType.AllDocument,
                pageIntervals: "",
              },
            ],
          };

          await addDocumentsToDraft({
            caseId: extractDraft.caseId,
            extractDraftId: extractDraft.id,
            model,
          });
        }
      },
      canDrop: () => selectedDocuments.length <= 1,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [sectionData, extractDraft, onlySelectVerticalLines, selectedDocuments],
  );

  drop(divRef);
  
  return (
    <>
      <IndicatorLine isVisible={position === "above"}/>
      <div
        onDragStart={handleDragStartRemoveGhost}
        onDragEnd={handleDragEnd}
        onMouseOver={() => setShowDeleteButton(documentExists)}
        onMouseLeave={() => setShowDeleteButton(false)}
        onClick={() => setIsModalOpen(documentExists)}
        ref={divRef}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        className={classNames(styles.container, {
          [styles.noDocument]: !documentExists,
          [styles.withDocument]: documentExists,
          [styles.hoverWithDocument]: isOver && canDrop,
          [styles.dropNotAllowed]: !canDrop && isOver,
        })}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-8">
            <EmblaIcon color={IconColor.Gray} iconName="document" />
            <h4 className="m-0">{sectionData.title}</h4>
          </div>
          <div className="flex-grow-1 text-center">{!documentExists && <span>{localizer.dragFileHereFromCase()}</span>}</div>
          <div className={classNames("d-flex")}>
            {documentExists && <div>{sectionData.documents[0].caseDocumentName}</div>}
            <div className={styles.deleteIconContainer}>
              {showDeleteButton && <IconButton onClick={handleDeleteDocument} iconName="delete" color={IconColor.Gray} />}
            </div>
          </div>
        </div>
      </div>
      <IndicatorLine isVisible={position === "below"}/>
      {documentExists && (
        <DocumentModal
          title={sectionData.title}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          documentQuery={useGetDocumentDownloadUrlQuery}
          documentQueryParams={{
            documentId: sectionData.documents[0].caseDocumentId,
            caseId: extractDraft.caseId,
          }}
        />
      )}
    </>
  );
};
