import React from "react";
import { UseFormReturn } from "react-hook-form/dist/types/form";
import { ExportBaseModel } from "@services/api/case/models/exportBaseModel";
import { ExportBaseFormFields } from "@components/Export/exportBaseFormFields";
import { useLocalization } from "@components/localization/localizationProvider";
import FormSwitch from "@components/forms/formSwitch";
import { CaseParticipantListModel } from "@services/api/participant/models/caseParticipantListModel";
import { MarkingType } from "@pages/pdfviewer/component/models/markingType";
import { ExportCaseDocumentModel } from "@services/api/case/models/exportCaseDocumentModel";


export type ExportCaseDocumentFormProps<TFormModel extends ExportBaseModel>  = {
  methods: UseFormReturn<TFormModel & any>;
  onSubmitCallback?: () => void;
  fieldIdPrepend: string;
  participants?: CaseParticipantListModel[];
};

export const ExportCaseDocumentForm = <TFormModel extends ExportCaseDocumentModel> ({
  methods,
  onSubmitCallback,
  fieldIdPrepend,
  participants,
}: ExportCaseDocumentFormProps<TFormModel>) => {
  const localizer = useLocalization();

  return (
    <form onSubmit={onSubmitCallback}>
      <ExportBaseFormFields methods={methods} fieldIdPrepend={fieldIdPrepend} participants={participants} excludeComments />

      <div className="row mt-4">
        <div className="col-lg-12">
          <h4>{localizer.shouldConvertColoredLinesToBlack()}</h4>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-lg-12">
          <FormSwitch
            methods={methods}
            id={`${fieldIdPrepend}-convert-verticalLines-black`}
            label={localizer.yesConvertAllIncludedLinesToBlack()}
            name="convertLinesToBlack"
            value={MarkingType.FocusBox}
            overwriteMarginTopBottom="xs"
            defaultLabelMarginLeft
          />
        </div>
      </div>

    </form>
  );
};
