import { PDFPageProxy } from "pdfjs-dist/types/src/display/api";
import { PageOrientation } from "@services/api/document/models/rotateCaseDocumentModel";

const getPageDimensions = ({ rotate, view }: PDFPageProxy, pageOrientation: PageOrientation) => {
  let totalRotation = rotate + pageOrientation;

  while (totalRotation > 270) {
    totalRotation -= 360;
  }

  const flipWidthAndHeight = (totalRotation - 90) % 180 === 0;

  const pageDimensions = {
    width: view[flipWidthAndHeight ? 3 : 2],
    height: view[flipWidthAndHeight ? 2 : 3],
    rotation: totalRotation as PageOrientation,
  };

  return pageDimensions;
};

export default getPageDimensions;
