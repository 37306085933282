import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { setGoToIndex } from "@pages/pdfviewer/component/pdfViewerSlice";
import { DocumentBookmarkInfoModel } from "@services/api/document/models/documentBookmarkInfoModel";
import { EmblaIcon } from "@components/embla/emblaIcon";
import treeRightLane from "@content/images/treeRightLane.svg";
import styles from "./bookmarkTree.module.scss";


export const Bookmark = (props: { bookmark: DocumentBookmarkInfoModel, className?: string }) => {
  const bookmark = props.bookmark;
  const hasChildren = bookmark.children?.length > 0;

  const dispatch = useDispatch();
  const [showChildren, setShowChildren] = useState<boolean>(false);


  const toggleShowBookmark = () => {
    setShowChildren((c) => !c);
  };

  const bookmarkOnClick = () => {
    toggleShowBookmark();

    //because we count page index starting at 0, and the bookmark counts index starting at 1
    const adjustedDistinationPageIndex = bookmark.destinationPageNumber - 1;
    dispatch(setGoToIndex(adjustedDistinationPageIndex));
  };

  return (
    <div className={classNames("position-relative", props.className)}>
      <div onClick={bookmarkOnClick} className={"d-flex"} role="button">
        {hasChildren &&
          <EmblaIcon iconName={showChildren ? "arrow-down" : "arrow-right"}/>}
        {!hasChildren &&
          <EmblaIcon iconName="document" additionalClasses="flex-shrink-0"/>}
        <p className={classNames("margin-left-s text-truncate", hasChildren && "font-weight-bold")}>{bookmark.title}</p>
      </div>
      {showChildren &&
        <div className={classNames("padding-left-m", styles.bookmarkChildren)}>
          {bookmark.children &&
            bookmark.children.map((bookmarkChild) => (<Bookmark bookmark={bookmarkChild} />))}
        </div>
      }
    </div>
  );
};
