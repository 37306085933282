import { FieldValues } from "react-hook-form";
import classNames from "classnames";
import { FormControlProps } from "@components/forms/FormControlProps.model";
import FormRadio from "@components/forms/FormRadio";
import { LineAppearance } from "@pages/pdfviewer/component/models/markingModel";
import radioStyles from "../../shared/radioGroup.module.scss";
import styles from "./VerticalLinesSelect.module.scss";

type VerticalLinesSelectProps = {
  name: string;
  id: string;
  externalOnChange?: () => void;
  top?: number;
  noContainerStyle?: boolean;
};

const lineStyles = Object.values(LineAppearance).map(Number).filter(Number.isFinite); // filter out numeric enum reverse mapped string

const VerticalLinesSelect = <T extends FieldValues = any>({ methods, name, id, externalOnChange, top, noContainerStyle } : FormControlProps<T, VerticalLinesSelectProps>) => {

  return (
    <div style={{ position: top !== undefined ? "absolute" : "static", top: `${top ?? 0}px` }} className={classNames(radioStyles.radioGroup, styles.linesContainer, "flex-row px-2 pt-2 pb-0 m-0", !noContainerStyle && "card")}>
      {lineStyles.map((style) => (
        <label key={style}>
          <FormRadio methods={methods} id={`${id}-${style}`} name={name} value={style} externalOnChange={externalOnChange}>
            {style === LineAppearance.Brackets ? (
              <div className={classNames(radioStyles.radio, styles.bracketsContainer, "rounded")}>
                <div className={styles.brackets} />
              </div>
            ) : (
              <div className={classNames(radioStyles.radio, styles.lineStyle, "rounded", styles[LineAppearance[style]])} />
            )}
          </FormRadio>
        </label>
      ))}
    </div>
  );
};

export default VerticalLinesSelect;