import { Placement } from "@popperjs/core";
import { ReactNode, useRef, useState } from "react";
import { usePopper } from "react-popper";
import ReactDOM from "react-dom";

interface PopoverProps {
  content: ReactNode;
  children: ReactNode;
  placement?: Placement;
  hoverDelay?: number;
}

const Popover = ({ content, children, placement, hoverDelay }: PopoverProps) => {
  const [show, setShow] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement || "auto",
  });

  const hoverTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleMouseOver = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    hoverTimeout.current = setTimeout(() => setShow(true), hoverDelay);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
    setShow(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setShow(true);
  };

  return (
    <div onClick={(e) => e.stopPropagation()} onMouseLeave={handleMouseLeave}>
      <div ref={setReferenceElement} onClick={handleClick} onMouseOver={handleMouseOver} >
        {children}
      </div>
      {show && ReactDOM.createPortal(
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          {content}
        </div>,
        document.body,
      )}
    </div>
  );
};

export default Popover;