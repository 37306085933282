import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";
import { AsyncThunkPayloadCreator, createAsyncThunk } from "@reduxjs/toolkit";
import type { AppDispatch, RootState } from "@app";
import type { ThunkExtras } from "@store";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;


type ThunkPayloadExtras = { extra: ThunkExtras };
export const createAppAsyncThunk = <TReturnValue, TArgs>(typePrefix: string, payloadCreator: AsyncThunkPayloadCreator<TReturnValue, TArgs, ThunkPayloadExtras>) => createAsyncThunk<TReturnValue, TArgs, ThunkPayloadExtras>(typePrefix, payloadCreator);
