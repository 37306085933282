import classNames from "classnames";
import styles from "./IndicatorLine.module.scss";

type IndicatorLineProps = {
  isVisible: boolean;
  additionalClassName?: string;
};

const IndicatorLine = ({ isVisible, additionalClassName }: IndicatorLineProps) => {
  return (
    <div
      className={classNames(styles.hoverLine, additionalClassName, {
        [styles.hoverLineVisible]: isVisible,
      })}
    />
  );
};

export default IndicatorLine;

