export enum Event {
  Load = "load",
  Error = "error",
  Unhandledrejection = "unhandledrejection",
  Keydown = "keydown",
  Pointerdown = "pointerdown",
  Pointerup = "pointerup",
  Selectionchange = "selectionchange",
  Wheel = "wheel",
}
