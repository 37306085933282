import { Language } from "@infrastructure/language";
import baseApi, { apiTags } from "./baseApi";

const getLocalizationUrl = (language: Language) => `/api/localization/${language}`;

const localizationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocalization: builder.query<Record<string, string>, Language>({
      query: (language) => getLocalizationUrl(language),
      providesTags: [apiTags.localization],
    }),
  }),
});

export default localizationApi;
export const { useLazyGetLocalizationQuery, useGetLocalizationQuery } = localizationApi;
