import { useParams } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import ExtractComposition from "./components/ExtractComposition/ExtractComposition";
import ExtractCompositionContextProvider from "./extractCompositionContextProvider";

export const ExtractCompositionPage = () => {
  const { caseId, extractId } = useParams();
  if (!caseId || !extractId) {
    throw new Error("Params \"caseId\" and \"extractId\" are required");
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <ExtractCompositionContextProvider>
        <ExtractComposition caseId={caseId} extractId={extractId} />
      </ExtractCompositionContextProvider>
    </DndProvider>
  );
};
