import React from "react";
import classNames from "classnames";
import styles from "./progressLine.module.scss";

export enum ProgressLineColor{
  Info, Secondary, Danger, Warning, Success, Primary, PrimaryLight, Gray,
}

export class ProgressLineProps {
  percentage: number;
  color: ProgressLineColor;
  title?: string;
}

export const ProgressLine = (props: ProgressLineProps) => {

  const getStyleVariables = (percentage: number) => {
    return {
      "--progressPercentage": `${percentage}%`,
    } as React.CSSProperties;
  };

  return (
    <div className={classNames(styles.progressLine, "progress")}>
      <div className={classNames(styles.progressBar, styles[ProgressLineColor[props.color]])} style={getStyleVariables(props.percentage)}/>
    </div>
  );
};