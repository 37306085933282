import { configureStore } from "@reduxjs/toolkit";
import titlebarReducer from "@components/layout/titleBar/titlebarSlice";
import localizationReducer from "@components/localization/localizationSlice";
import errorReducer from "@components/error/errorSlice";
import configReducer from "@components/config/configSlice";
import createCaseReducer from "@pages/dashboardPage/createCase/createCaseSlice";
import pdfViewerReducer from "@pages/pdfviewer/component/pdfViewerSlice";
import pdfSidebarReducer from "@pages/pdfviewer/component/pdfSidebar/pdfSidebarSlice";
import { UserService } from "@services/userService";
import baseApi from "@services/api/baseApi";
import { errorHandlingMiddleware } from "@services/api/errorHandlingMiddleware";
import fileStorageApi from "@services/api/file/fileStorageApi";
import presentationReducer from "@pages/present/presentationSlice";
import { IHubConnectionManager } from "@services/signalRClient/IHubConnectionManager";
import { localizationMiddleware } from "@services/api/localizationMiddleware";
import { Localizer } from "@components/localization/localizer";

export type ThunkExtras = {
  userService: UserService,
  markingsHubConnectionManager: IHubConnectionManager,
  presentationHubConnectionManager: IHubConnectionManager,
  localizer: Localizer,
};

export const createStore = (extras: ThunkExtras) => {
  const store = configureStore({
    reducer: {
      titlebar: titlebarReducer,
      localization: localizationReducer,
      error: errorReducer,
      config: configReducer,
      createCase: createCaseReducer,
      pdfViewer: pdfViewerReducer,
      presentation: presentationReducer,
      pdfSidebar: pdfSidebarReducer,
      [baseApi.reducerPath]: baseApi.reducer,
      [fileStorageApi.reducerPath]: fileStorageApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      thunk: { extraArgument: extras },
    }).concat(baseApi.middleware).concat(fileStorageApi.middleware).concat(errorHandlingMiddleware).concat(localizationMiddleware),
  });

  return store;
};

export type StoreType = ReturnType<typeof createStore>;
export type StoreDispatchType = StoreType["dispatch"];
export type RootStateType = ReturnType<StoreType["getState"]>;
