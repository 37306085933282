import { Localizer } from "@components/localization/localizer";
import { DropdownOption } from "@components/dropdown/dropdown";
import { CaseElementTypeEnum } from "@services/api/case/models/caseElementTypeEnum";

export enum DateTypeEnum {
  YearMonthDay = 1,
  YearMonth = 2,
  Year = 3,
  UnDated = 4,
}

export const getDateTypeTypes = () => {
  return [DateTypeEnum.YearMonthDay, DateTypeEnum.YearMonth, DateTypeEnum.Year, DateTypeEnum.UnDated];
};

export const getDateTypeDropdownOptions = (localizer: Localizer, types: DateTypeEnum[]) => {
  const result: DropdownOption<number>[] = [];

  types.forEach((type) => {
    switch (type) {
      case DateTypeEnum.YearMonthDay:
        result.push({
          label: localizer.yearMonthDayDateType(),
          value: DateTypeEnum.YearMonthDay,
        });
        break;
      case DateTypeEnum.YearMonth:
        result.push({
          label: localizer.yearMonthDateType(),
          value: DateTypeEnum.YearMonth,
        });
        break;
      case DateTypeEnum.Year:
        result.push({
          label: localizer.yearDateType(),
          value: DateTypeEnum.Year,
        });
        break;
      case DateTypeEnum.UnDated:
        result.push({
          label: localizer.noDateDateType(),
          value: DateTypeEnum.UnDated,
        });
        break;
      default:
        throw new Error(`CaseDocumentTypeEnum.${type} has not been implemented`);
    }
  });

  return result;
};

export const convertDateTypeToString = (type: DateTypeEnum, localizer: Localizer) => {
  switch (type) {
    case DateTypeEnum.YearMonthDay:
      return localizer.yearMonthDayDateType();
    case DateTypeEnum.YearMonth:
      return localizer.yearMonthDateType();
    case DateTypeEnum.Year:
      return localizer.yearDateType();
    case DateTypeEnum.UnDated:
      return localizer.noDateDateType();
    default:
      throw new Error(`DateTypeEnum.${type} has not been implemented`);
  }
};

