import { forwardRef, InputHTMLAttributes, Ref } from "react";
import { FieldValues, useController } from "react-hook-form";
import classNames from "classnames";
import { EmblaIcon } from "@components/embla/emblaIcon";
import useForwardedRef from "src/hooks/useForwardedRef";
import { FormControlProps } from "./FormControlProps.model";
import styles from "./FormFile.module.scss";
import { FormInputLabel } from "./formInputLabel";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormFile = forwardRef(<T extends FieldValues = any>({
  methods: { control },
  label,
  name,
  required,
  formGroupClassName,
  placeholder = "",
  ...inputProps
}: FormControlProps<T, InputHTMLAttributes<HTMLInputElement>>, ref: Ref<HTMLInputElement>) => {
  const { field, fieldState: { error } } = useController({ name, control });


  const formFileRef = useForwardedRef(ref);

  return (
    <div className={classNames("form-group", formGroupClassName)}>
      {label &&
        <FormInputLabel
          htmlFor={inputProps.id}
          labelText={label}
          isRequired={required}
        />
      }

      <input
        type="file"
        ref={formFileRef}
        className="inputfile"
        {...inputProps}
        onChange={(e) => {
          field.onChange(e.target.files?.[0]);
          field.onBlur();
          inputProps.onChange?.(e);
        }}
      />

      <label
        htmlFor={inputProps.id}
        className={error ? styles.error : ""}
      >
        <div className="text">
          {(field.value as File)?.name ?? placeholder}
        </div>
        <div className={"icon " + styles.icon}>
          <EmblaIcon iconName="plus" />
        </div>
      </label>

      {error && <span className="errorMessage field-validation-error">{error.message}</span>}
    </div>
  );
});

export default FormFile;
