import { useMemo, useState } from "react";
import { DropdownOption } from "@components/dropdown/dropdown";
import FormDropdown from "@components/forms/FormDropdown";
import FormInput from "@components/forms/FormInput";
import FormTextarea from "@components/forms/FormTextarea";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import Modal from "@components/modal/modal";
import ModalMessage from "@components/modal/modalMessage";
import { useEditCaseMutation } from "@services/api/case/caseApi";
import { CaseBaseReturnModel } from "@services/api/case/models/caseBaseReturnModel";
import { CaseClientModel } from "@services/api/case/models/caseClientModel";
import { CaseEditModel } from "@services/api/case/models/caseModel";
import { CaseStatusEnum, convertCaseStatusToString, getCaseStatusDropdownOptions } from "@services/api/case/models/caseStatusEnum";
import { useGetClientsQuery } from "@services/api/client/clientApi";
import useAutosave from "src/hooks/useAutosave";
import useDateFormatter from "src/hooks/useDateFormatter";
import styles from "./editCase.module.scss";
import { editCaseValidation } from "./editCaseValidation";

type EditCaseFormModalProps = {
  case: CaseBaseReturnModel;
  setSkipRefetch: (skip: boolean) => void;
};

const EditCase: React.FC<EditCaseFormModalProps> = (props) => {
  const localizer = useLocalization();
  const dateFormatter = useDateFormatter();

  const [statusModalOpen, setStatusModalOpen] = useState(false);

  const { data: clients, isLoading: clientsIsLoading } = useGetClientsQuery();
  const [editCase, editRequest] = useEditCaseMutation();

  const [oldStatus, setOldStatus] = useState<CaseStatusEnum>(props.case.status); //Used as a rollback (if user cancels)
  const [newStatus, setNewStatus] = useState<CaseStatusEnum>(props.case.status); //Used in the description of the confirm dialogue

  const methods = useValidatedForm({
    validationSchema: editCaseValidation(localizer),
    defaultValues: {
      id: props.case.id,
      title: props.case.title,
      description: props.case.description,
      client: {
        id: props.case.client.id,
        name: props.case.client.name,
      },
      counterparts: props.case.counterParts.map((x) => x.name),
      courtCaseNumber: props.case.courtCaseNumber,
      internalCaseNumber: props.case.internalCaseNumber,
      status: props.case.status,
    } as CaseEditModel,
  });

  const getStatusOptions: DropdownOption<number>[] = useMemo(() => {
    return getCaseStatusDropdownOptions(localizer);
  }, [localizer]);

  const onSubmit = () => {
    const onSubmitCaseData = async (model: CaseEditModel) => {
      const result = await editCase(model).unwrap();

      // Reset form with result-model (client.id needs to be up-to-date)
      //Only reset client data, so live user data isn't lost if the user keeps typing while we're updating
      methods.reset({
        ...methods.getValues(),
        client: result.client,
      });
    };

    methods.handleSubmit((model) => onSubmitCaseData(model))();
  };

  const { debouncedSubmit, getFieldId } = useAutosave({
    queryStatus: editRequest.status,
    fieldIdPrefix: "case-edit-form",
    watch: methods.watch,
    onChange: (fieldName, value) => {
      if (fieldName === "status") {
        if (value !== oldStatus) {
          setStatusModalOpen(true);
        }
        return false; // don't trigger autosave - user needs to confirm
      }
    },
    onSubmit,
  });

  // Called before the status is updated and the "watch" is triggered
  const onStatusChanged = (previousValue: CaseStatusEnum, newValue: CaseStatusEnum | undefined) => {
    setOldStatus(previousValue);
    setNewStatus(newValue ?? previousValue);
  };

  const editCaseModalContent = (
    <form className={styles.form}>
      <div className="row">
        <div className="col-lg-12">
          <FormInput
            methods={methods}
            id={getFieldId("title")}
            name="title"
            label={localizer.title() + ":"}
            placeholder={localizer.titlePlaceholder()}
            required
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <FormTextarea
            methods={methods}
            id={getFieldId("description")}
            name="description"
            label={localizer.description() + ":"}
            placeholder={localizer.descriptionPlaceholder()}
            rows={3}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <FormDropdown
            methods={methods}
            id={getFieldId("client")}
            name="client"
            valueToOptionTransform={(value: CaseClientModel) => value ? { label: value?.name, value: value } : null}
            options={clients?.map((c) => ({ label: c.name, value: c }))}
            label={localizer.clientPlaceholder()}
            noOptionsMessage={() => localizer.typeNameOfClientToAdd()}
            placeholder={localizer.clientPlaceholder()}
            allowCreate
            getNewOptionData={(name: string) => ({ label: name, value: { name } })}
            isLoading={clientsIsLoading}
            required
          />
        </div>
        <div className="col-lg-6">
          <FormDropdown
            methods={methods}
            id={getFieldId("counterparts")}
            name="counterparts"
            label={localizer.counterparts() + ":"}
            noOptionsMessage={() => localizer.typeNameOfCounterpartToAdd()}
            placeholder={localizer.counterpartsPlaceholder()}
            hideDropdownIndicator
            allowCreate
            isMulti
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <FormInput
            methods={methods}
            id={getFieldId("courtCaseNumber")}
            label={localizer.courtCaseNumber() + ":"}
            placeholder={localizer.courtCaseNumberPlaceholder()}
            name="courtCaseNumber"
          />
        </div>
        <div className="col-lg-6">
          <FormInput
            methods={methods}
            id={getFieldId("internalCaseNumber")}
            label={localizer.internalCaseNumber() + ":"}
            placeholder={localizer.internalCaseNumberPlaceholder()}
            name="internalCaseNumber"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <FormDropdown
            methods={methods}
            id={getFieldId("status")}
            name="status"
            valueToOptionTransform={(value: CaseStatusEnum) => value ? { label: convertCaseStatusToString(value, localizer), value: value } : null}
            options={getStatusOptions.map((c) => ({ label: c.label, value: c.value }))}
            label={localizer.status() + ":"}
            onChangeCallback={onStatusChanged}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group">
            <label>{localizer.creationDate() + ":"}</label>
            <p>{dateFormatter.date(props.case.creationDate)}</p>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label>{localizer.createdBy() + ":"}</label>
            <p>{props.case.createdBy.name}</p>
          </div>
        </div>
      </div>
    </form>
  );

  const onChangeStatusConfirmModalAccept = () => {
    debouncedSubmit("status");
  };

  const onChangeStatusConfirmModalCancel = () => {
    methods.setValue("status", oldStatus); // Revert to previous value
  };

  return (
    <>
      <Modal.Header>{localizer.caseDetails()}</Modal.Header>
      <Modal.Body>{editCaseModalContent}</Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
      <ModalMessage
        open={statusModalOpen}
        onClose={() => setStatusModalOpen(false)}
        title={localizer.areYouSure()}
        description={localizer.areYouSureChangeCaseStatusTo(convertCaseStatusToString(newStatus, localizer))}
        acceptButtonText={localizer.changeStatus()}
        acceptAction={onChangeStatusConfirmModalAccept}
        cancelAction={onChangeStatusConfirmModalCancel}
      />
    </>
  );
};

export default EditCase;
