import classNames from "classnames";
import { HeaderGroup } from "react-table";
import styles from "./tableHeader.module.css";

type TableHeaderProps<T extends object> = {
  header: HeaderGroup<T>
  isSortable: boolean;
};

export const TableHeader = <T extends object>({ header, isSortable }: TableHeaderProps<T>) => {
  return (
    <th
      {...(isSortable && header.getHeaderProps(header.getSortByToggleProps({ title: undefined })))}
      {...header.getHeaderProps({ style: { minWidth: header.minWidth, width: header.width } })}
      className={classNames(
        styles.header,
        {
          [styles.clickableHeader]: isSortable && !header.disableSortBy,
          [styles.sortIcon]: isSortable && !header.disableSortBy,
          [styles.sortDesc]: header.isSorted && header.isSortedDesc,
          [styles.sortAsc]: header.isSorted && !header.isSortedDesc,
        },
      )}
      onKeyPress={(event) => {
        if (isSortable && event.key === "Enter") {
          header.toggleSortBy();
        }
      }}
      tabIndex={isSortable ? 0 : undefined}
    >
      {header.render("Header")}
    </th>
  );
};