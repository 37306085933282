import classNames from "classnames";
import { TextBreak } from "src/utility/constants";
import styles from "./InfoNote.module.scss";

interface InfoNoteProps {
  title: string;
  children?: string | string[];
  textBreak?: TextBreak;
}

const InfoNote = ({
  title,
  children,
  textBreak = TextBreak.None,
}: InfoNoteProps) => {
  const notes = Array.isArray(children) ? children : [children];

  return (
    <div
      className={classNames("d-flex", "flex-column", styles["info-note"], {
        [styles["info-note--fit-content"]]: textBreak === TextBreak.None,
      })}
    >
      <p className={classNames("m-0", "text-nowrap", styles["info-note__title"])}>{title}</p>
      {notes.map((note, index) => (
        <span
          key={index}
          className={classNames({
            "text-truncate": textBreak === TextBreak.Truncate,
            "text-break": textBreak === TextBreak.Break,
          })}
          title={note}
        >
          {note}
        </span>
      ))}
    </div>
  );
};

export default InfoNote;
