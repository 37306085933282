import Button from "@components/embla/button";
import { useLocalization } from "@components/localization/localizationProvider";
import { Tooltip } from "@components/tooltip/tooltip";

const MarkingDeleteBtn = ({ cantBeDeleted, onDelete }: { cantBeDeleted?: boolean, onDelete: (() => void) | undefined }) => {
  const localizer = useLocalization();

  return (
    <Tooltip
      message={cantBeDeleted && localizer.cantDeleteMarkingUsedInPresentation()}
      placement="top-start"
    >
      <Button
        onClick={onDelete}
        disabled={cantBeDeleted}
      >
        {localizer.delete()}
      </Button>
    </Tooltip>
  );
};

export default MarkingDeleteBtn;
