import {
  createContext, useContext, useEffect, useMemo,
} from "react";
import { Config, ConfigManager } from "@config/configManager";
import { useAppDispatch, useAppSelector } from "@hooks";
import { configStateSelector, setConfig } from "./configSlice";

// eslint-disable-next-line @typescript-eslint/naming-convention
const ConfigContext = createContext<Config>({} as Config);

export const useConfig = () => useContext(ConfigContext);

export const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const config = useAppSelector(configStateSelector);

  useEffect(() => {
    new ConfigManager().loadConfig()
      .then((newConfig) => {
        dispatch(setConfig(newConfig));
      });
  }, [dispatch]);

  const configProviderValue = useMemo(() => config, [config]);

  if (!configProviderValue)
    return null;

  return (
    <ConfigContext.Provider value={configProviderValue}>
      {children}
    </ConfigContext.Provider>
  );
};
