import baseApi, { apiTags } from "../baseApi";
import { ClientModel } from "./models/clientModel";

const clientUrl = "/api/client";

const clientApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query<ClientModel[], void>({
      query: () => clientUrl,
      providesTags: (result) => result ? [...result.map((u) => ({ type: apiTags.client, id: u.id })), apiTags.client] : [],
    }),
  }),
});

export default clientApi;
export const { useGetClientsQuery } = clientApi;
export const { resetApiState } = clientApi.util;
