import { FieldValues, useController } from "react-hook-form";
import classNames from "classnames";
import DatePicker, { DatePickerProps } from "@components/datePicker/datePicker";
import { FormControlProps } from "./FormControlProps.model";
import { FormInputLabel } from "./formInputLabel";

const FormDatePicker = <T extends FieldValues>({
  methods: { control },
  label,
  name,
  required,
  formGroupClassName,
  ...pickerProps
}: FormControlProps<T, DatePickerProps>) => {
  const { field, fieldState: { error } } = useController({ name, control });
  return (
    <div className={classNames("form-group", formGroupClassName)}>
      {label &&
        <FormInputLabel
          htmlFor={pickerProps.id}
          labelText={label}
          isRequired={required}
        />
      }

      <DatePicker
        className={error ? "input-validation-error" : undefined}
        {...pickerProps}
        {...field}
      />

      {error && <span className="errorMessage field-validation-error">{error.message}</span>}
    </div>
  );
};

export default FormDatePicker;
