import { EmblaIcon, IconColor, IconSize } from "@components/embla/emblaIcon";
import styles from "./IconButton.module.scss";

type IconButtonProps = {
  iconName: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  color?: IconColor;
  size?: IconSize;
  disabled?: boolean;
};


export const IconButton = ({ iconName, onClick, color, size, disabled } : IconButtonProps) => {
  return (
    <button
      className={styles.button}
      disabled={disabled}
      onClick={onClick}
    >
      <EmblaIcon size={size} iconName={iconName} color={color} />
    </button>
  );
};