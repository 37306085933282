import { MarkingActiveStateType } from "@pages/pdfviewer/component/models/activeMarkingInfo";
import { FocusBoxMarkingModel } from "../../../models/markingModel";
import CardProps from "../shared/cardProps.model";
import MarkingPreview from "../shared/markingPreview";
import MarkingPresentationPreview from "../shared/markingPresentationPreview";
import FocusBoxEdit from "./focusBoxEdit";

const FocusBoxCardContent = ({ activeState, ...props }: CardProps<FocusBoxMarkingModel>) => {
  return activeState === MarkingActiveStateType.Edit
    ? <FocusBoxEdit {...props} />
    : props.isPresentationEdit
      ? <MarkingPresentationPreview disableCheckbox={props.disableCheckbox} marking={props.marking} toggleMarkingClicked={props.toggleMarkingClicked} toggleMarkingState={props.toggleMarkingState ?? false}/>
      : <MarkingPreview marking={props.marking} editMarkingClicked={props.editMarkingClicked} onReplyCreateEdit={props.onReplyCreateEdit} repliesOpen={activeState === MarkingActiveStateType.Replies}/>;
};

export default FocusBoxCardContent;

