import { ReactNode } from "react";
import classNames from "classnames";
import { EmblaIcon } from "@components/embla/emblaIcon";
import Card from "@components/embla/card";
import Button from "@components/embla/button";
import styles from "./presentationCard.module.scss";

export interface PresentationCardProps {
  index: number;
  startSortOrder?: number;
  deleteCallback: (index: number) => void;
  children: ReactNode;
}

export const PresentationCard: React.FC<PresentationCardProps> = ({ index, startSortOrder = 0, deleteCallback, children }) => {
  return (
    <Card className={classNames(styles.presentationCard, "border-radius")}>
      <Card.Body className={classNames(styles.cardBody)}>
        <div className={classNames(styles.presentationContent)}>
          <div className={classNames(styles.presentationNumber, "small subtle")}>
            {startSortOrder + index + 1}
          </div>
          <div className={classNames(styles.input)}>
            {children}
          </div>
        </div>
        <div>
          <Button
            iconBtn
            className={classNames(styles.deletePresentationBtn)}
            onClick={() => deleteCallback(index)}
            borderless
          >
            <EmblaIcon
              iconName="remove"
            />
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};
