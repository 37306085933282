import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationModule } from "ditmer-embla";
import { RoutePaths } from "@routes";
import { clearBreadcrumbs, clearTitle } from "@components/layout/titleBar/titlebarSlice";
import { useLocalization } from "@components/localization/localizationProvider";
import Modal from "@components/modal/modal";
import { TabItem } from "@components/tab/tabModel";
import { Tabs } from "@components/tab/tabs";
import { useAppDispatch } from "@hooks";
import { useDeleteCaseMutation, useGetCaseQuery } from "@services/api/case/caseApi";
import ModalMessage from "@components/modal/modalMessage";
import EditCase from "./editCase/editCase";
import { ParticipantList } from "./participants/participantList";
import { PresentationList } from "./presentations/presentationList";
import DocumentListTab from "./DocumentListTab/DocumentListTab";
import CaseInfo from "./CaseInfo/CaseInfo";

export const documentTabId = "document";
export const presentationsTabId = "presentations";
export const participantTabId = "participants";

export const CasePage = () => {
  const localizer = useLocalization();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { caseId, tabId, present, seqId } = useParams();
  const [deleteCase, deleteRequest] = useDeleteCaseMutation();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [skipRefetch, setSkipRefetch] = useState(false);

  if (!caseId) {
    throw new Error("Param \"caseId\" is required");
  }

  const { data: caseModel, isLoading } = useGetCaseQuery(caseId, { skip: skipRefetch });

  useEffect(() => {
    dispatch(clearBreadcrumbs());
    dispatch(clearTitle());
  }, [dispatch, localizer]);

  useEffect(() => {
    if (deleteRequest.isUninitialized)
      return;

    if (deleteRequest.isSuccess) {
      NotificationModule.showSuccessSmall(localizer.theCaseWasDeleted());
      navigate(RoutePaths.index.url);
    }

    if (deleteRequest.isError) {
      setSkipRefetch(false);
    }

  }, [deleteRequest.isError, deleteRequest.isSuccess, deleteRequest.isUninitialized, localizer, navigate]);

  if (isLoading || !caseModel)
    return null;

  const tabs: TabItem[] = [
    {
      id: documentTabId,
      title: localizer.documents(),
      active: documentTabId === tabId,
      content:  (
        <DocumentListTab caseId={caseId} />
      ),
      url: RoutePaths.caseDetails.url(caseId, documentTabId),
    },
    {
      id: presentationsTabId,
      title: localizer.presentations(),
      active: presentationsTabId === tabId,
      content: (
        <PresentationList
          initiatePresent={present === "present"}
          initiatePresentPresentationId={seqId}
          caseId={caseId}
        />
      ),
      url: RoutePaths.caseDetails.url(caseId, presentationsTabId),
    },
    {
      id: participantTabId,
      title: localizer.participants(),
      active: participantTabId === tabId,
      content: (
        <ParticipantList caseId={caseId} />
      ),
      url: RoutePaths.caseDetails.url(caseId, participantTabId),
    },
  ];

  const onDeleteCaseConfirmModalAccept = () => {
    setSkipRefetch(true);
    deleteCase({ caseId: caseId });
  };

  return (
    <>
      <div className="margin-top-l">
        <CaseInfo caseModel={caseModel} setDeleteModalOpen={setDeleteModalOpen} setEditModalOpen={setEditModalOpen} />
        <Tabs hideTopBorder tabs={tabs} />
      </div>
      <Modal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
      >
        <EditCase
          case={caseModel}
          setSkipRefetch={(skip) => setSkipRefetch(skip)}
        />
      </Modal>
      <ModalMessage
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title={localizer.areYouSure()}
        description={localizer.areYouSureCaseDelete()}
        modalAcceptType="danger"
        acceptButtonText={localizer.delete()}
        acceptAction={onDeleteCaseConfirmModalAccept}
      />
    </>
  );
};
