import { DropdownOption } from "@components/dropdown/dropdown";

export enum DateFormatEnum {
  DDMMYYYYDash = 1,
  MMDDYYYYDash = 2,
  DDMMYYYYDot = 3,
  MMDDYYYYDot = 4,
  DDMMYYYYSlash  = 5,
  MMDDYYYYSlash = 6,
  DMMMMYYYYDanish = 7,
  MMMMDDthYYYYEnglish = 8,
}

export const getDateFormatEnum = () => {
  return [DateFormatEnum.DDMMYYYYDash, DateFormatEnum.MMDDYYYYDash, DateFormatEnum.DDMMYYYYDot, DateFormatEnum.MMDDYYYYDot, DateFormatEnum.DDMMYYYYSlash, DateFormatEnum.MMDDYYYYSlash, DateFormatEnum.DMMMMYYYYDanish, DateFormatEnum.MMMMDDthYYYYEnglish];
};
export const getDateFormatEnumDropdownOptions = (dateFormat: DateFormatEnum[]) => {
  const result: DropdownOption<number>[] = [];

  dateFormat.forEach((format) => {
    switch (format) {
      case DateFormatEnum.DDMMYYYYDash:
        result.push({
          label: convertDateFormatEnumToString(DateFormatEnum.DDMMYYYYDash),
          value: DateFormatEnum.DDMMYYYYDash,
        });
        break;
      case DateFormatEnum.MMDDYYYYDash:
        result.push(    {
          label: convertDateFormatEnumToString(DateFormatEnum.MMDDYYYYDash),
          value: DateFormatEnum.MMDDYYYYDash,
        });
        break;
      case DateFormatEnum.DDMMYYYYDot:
        result.push(    {
          label: convertDateFormatEnumToString(DateFormatEnum.DDMMYYYYDot),
          value: DateFormatEnum.DDMMYYYYDot,
        });
        break;
      case DateFormatEnum.MMDDYYYYDot:
        result.push({
          label: convertDateFormatEnumToString(DateFormatEnum.MMDDYYYYDot),
          value: DateFormatEnum.MMDDYYYYDot,
        });
        break;
      case DateFormatEnum.DDMMYYYYSlash:
        result.push({
          label: convertDateFormatEnumToString(DateFormatEnum.DDMMYYYYSlash),
          value: DateFormatEnum.DDMMYYYYSlash,
        });
        break;
      case DateFormatEnum.MMDDYYYYSlash:
        result.push({
          label: convertDateFormatEnumToString(DateFormatEnum.MMDDYYYYSlash),
          value: DateFormatEnum.MMDDYYYYSlash,
        });
        break;
      case DateFormatEnum.DMMMMYYYYDanish:
        result.push({
          label: convertDateFormatEnumToString(DateFormatEnum.DMMMMYYYYDanish),
          value: DateFormatEnum.DMMMMYYYYDanish,
        });
        break;
      case DateFormatEnum.MMMMDDthYYYYEnglish:
        result.push({
          label: convertDateFormatEnumToString(DateFormatEnum.MMMMDDthYYYYEnglish),
          value: DateFormatEnum.MMMMDDthYYYYEnglish,
        });
        break;
      default:
        throw new Error(`DateFormatEnum.${format} has not been implemented`);
    }
  });

  return result;
};

export const convertDateFormatEnumToString = (dateFormat: DateFormatEnum) => {
  switch (dateFormat) {
    case DateFormatEnum.DDMMYYYYDash:
      return "DD-MM-YYYY";
    case DateFormatEnum.MMDDYYYYDash:
      return "MM-DD-YYYY";
    case DateFormatEnum.DDMMYYYYDot:
      return "DD.MM.YYYY";
    case DateFormatEnum.MMDDYYYYDot:
      return "MM.DD.YYYY";
    case DateFormatEnum.DDMMYYYYSlash:
      return "DD/MM/YYYY";
    case DateFormatEnum.MMDDYYYYSlash:
      return "MM/DD/YYYY";
    case DateFormatEnum.DMMMMYYYYDanish:
      return "d. MMMM yyyy";
    case DateFormatEnum.MMMMDDthYYYYEnglish:
      return "MMMM d. yyyy";
  }
};
