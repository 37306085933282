import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { NotificationModule } from "ditmer-embla";
import FormSubmitButton from "@components/forms/FormSubmitButton";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import Modal from "@components/modal/modal";
import {
  useAddExtractDraftDocumentsMutation,
  useCreateSectionMutation,
  useGetExtractDraftQuery,
} from "@services/api/extractDraft/extractDraftApi";
import FormInput from "@components/forms/FormInput";
import ClampedParagraph from "@components/clampedParagraph/clampedParagraph";
import { Tooltip } from "@components/tooltip/tooltip";
import { Spinner } from "@components/spinner/spinner";
import FormCheckbox from "@components/forms/FormCheckbox";
import Checkbox from "@components/checkbox/checkbox";
import { useGetCaseDocumentsQuery } from "@services/api/document/caseDocumentApi";
import { ExtractDraftModel } from "@services/api/extractDraft/models/extractDraftModel";
import { AddDocumentsToDraftFormModel, addDocumentsToDraftFormModelValidation, AddDocumentsToDraftModel, DraftDocumentCreateFormModel } from "./addDocumentsToDraftModel";
import styles from "./addDocumentsToDraft.module.scss";

type AddDocumentsToDraftProps = {
  caseId: string;
  extractDraftId: string;
  addToSectionId?: string;
  addToDefaultSection?: boolean;
  onSuccess?: () => void;
  usePortalActionsContainer?: boolean;
  actionsContainer?: Element;
  showInfoText?: boolean;
  singleDocumentId?: string;
};

export const AddDocumentsToDraft = ({
  caseId,
  extractDraftId,
  addToSectionId,
  onSuccess,
  usePortalActionsContainer = false,
  showInfoText = true,
  singleDocumentId,
  actionsContainer,
  addToDefaultSection,
}: AddDocumentsToDraftProps) => {
  const localizer = useLocalization();

  const { data: documents, isLoading: documentsIsLoading } = useGetCaseDocumentsQuery({ caseId: caseId });
  const [ addDocumentsToDraft, addDocumentsRequest ] = useAddExtractDraftDocumentsMutation();
  const [ createSection, createSectionRequest ] = useCreateSectionMutation();
  const [selectedDocumentCount, setSelectedDocumentCount] = useState<number>(0);
  const { data: extractDraft } = useGetExtractDraftQuery({ caseId, extractDraftId });

  const methods = useValidatedForm({
    validationSchema: addDocumentsToDraftFormModelValidation(localizer),
    defaultValues: {
      documents: [],
    } as AddDocumentsToDraftFormModel,
  });

  const watchDocuments = methods.watch("documents");

  useEffect(() => {
    //Track each Document's isSelected property individually,
    //as watchDocuments doesn't trigger on property change, only when the actual list changes.
    if (documents) {
      let selectedCount = 0;

      documents.forEach((document, index) => {
        const documentIsSelected = methods.watch(`documents.${index}.isSelected`);

        if (documentIsSelected) {
          selectedCount++;
        }
      });

      setSelectedDocumentCount(selectedCount);
    }
  }, [documents, methods, methods.watch]);

  useEffect(() => {
    if (documents && documents.length > 0 && watchDocuments.length === 0 && extractDraft) {

      let filteredDocuments = documents;
  
      if (singleDocumentId) {
        filteredDocuments = filteredDocuments.filter((x) => x.id === singleDocumentId);
      }
      
      const findNode = (list: any[], caseExtractDocumentIdList: string[]) => {
      
        for (const item of list) {
          caseExtractDocumentIdList.push(item.caseId);
        }
      };
      const caseExtractDocumentIdList: string[] = [];
      findNode(extractDraft?.sections, caseExtractDocumentIdList);

      const distinctCaseExtractDocumentIdList = caseExtractDocumentIdList.filter((n, i)=>caseExtractDocumentIdList.indexOf(n) === i);

      const initialDocuments: DraftDocumentCreateFormModel[] = filteredDocuments.map((document) => (
        {
          isSelected: !distinctCaseExtractDocumentIdList.includes(document.id),
          document: {
            caseDocumentId: document.id,
            title: document.documentName,
            includePagesWithVerticalLines: false,
            pageIntervals: document.pageCount === 1 ? "1" : `1-${document.pageCount}`,
          },
        }));
        
      methods.setValue("documents", initialDocuments, { shouldTouch: true });
    }

  }, [caseId, documents, extractDraft, methods, singleDocumentId, watchDocuments.length]);

  const submitAddDocumentsToDraft = async (model: AddDocumentsToDraftFormModel) => {
    const documentsToAdd = model.documents.filter((x) => x.isSelected).map((x) => x.document);
    const isValidPageIntervals = documentsToAdd
      .filter((doc) => !doc.includePagesWithVerticalLines);
      // .every((doc) => validateDocumentPageIntervals(doc.caseDocumentId, doc.pageIntervals));

    if (!isValidPageIntervals) {
      return;
    }

    const documentToAddModel = {
      addToSectionId: addToSectionId,
      documents: documentsToAdd,
    } as any;

    const createDocuments = () => {
      addDocumentsToDraft({ caseId: caseId, extractDraftId: extractDraftId, model: documentToAddModel })
        .unwrap()
        .then((addedDocuments) => {
          const skippedDocuments = documentsToAdd.length - addedDocuments.length;

          if (skippedDocuments === 0) {
            //All documents were added
            NotificationModule.showSuccessSmall(localizer.extractDraftDocumentsWasAdded());
          } else {
            //Some documents were skipped because of missing vertical lines.
            NotificationModule.showInfoSmall(`${skippedDocuments} ${localizer.outOf()} ${documentsToAdd.length} ${localizer.missingVerticalLines()}`);
          }
          if (onSuccess) {
            onSuccess();
          }
        });
    };

    createDocuments();
  };


  const validateDocumentPageIntervals = (documentId: string, pageIntervals: string) => {
    if (!pageIntervals) {
      return true;
    }

    const documentIndex = watchDocuments.findIndex((x) => x.document.caseDocumentId === documentId);
    const documentPageCount = documents?.find((x) => x.id === documentId)?.pageCount ?? 1;

    const pageIntervalsList = pageIntervals.trim().replace(RegExp("^,"), "").replace(RegExp(",$"), "").split(",");
    const intervalRangeNumbers = pageIntervalsList.flatMap((interval) => interval.split("-").map((numberString) => parseInt(numberString)));

    const isNumbers = intervalRangeNumbers.every((rangeNumber) => !isNaN(rangeNumber));
    const isLessThanMax = intervalRangeNumbers.every((rangeNumber) => rangeNumber <= documentPageCount);
    const isBiggerThanZero = intervalRangeNumbers.every((rangeNumber) => rangeNumber > 0);

    // if (!isNumbers) {
    //   methods.setError(`documents.${documentIndex}.document.pageIntervals`, { message: localizer.pageIntervalIsInvalid() } );
    //   return false;
    // }

    // if (!isLessThanMax) {
    //   methods.setError(`documents.${documentIndex}.document.pageIntervals`, { message: localizer.pageIntervalOutOfRangeMax(`${documentPageCount}`) } );
    //   return false;
    // }
    // if (!isBiggerThanZero) {
    //   methods.setError(`documents.${documentIndex}.document.pageIntervals`, { message: localizer.pageIntervalOutOfRangeMin("1") } );
    //   return false;
    // }

    return true;
  };

  const handleToggleSelectAll = (selected: boolean) => {
    methods.setValue("documents", watchDocuments.map((x) => ({ document: x.document, isSelected: selected })));
  };

  const handleToggleVerticalLinesAll = (selected: boolean) => {
    methods.setValue("documents", watchDocuments.map((x) => ({ document: { ...x.document, includePagesWithVerticalLines: selected }, isSelected: x.isSelected })));
  };

  const modalFooterActions = (
    <Modal.Footer>
      <FormSubmitButton
        disabled={selectedDocumentCount === 0}
        state={{
          isSubmitting: addDocumentsRequest.isLoading || createSectionRequest.isLoading,
          isSubmitSuccessful: addDocumentsRequest.isSuccess,
        }}
        isPrimary
        onClick={methods.handleSubmit(submitAddDocumentsToDraft)}
      >
        {localizer.addToDraft()}
      </FormSubmitButton>
    </Modal.Footer>
  );

  return (
    <>
      {showInfoText &&
      <>
        <div className="subtle">{localizer.addDocumentsToDraftHint1()}</div>
        <div className="subtle">{localizer.addDocumentsToDraftHint2()}</div>
        <div className="subtle">{localizer.addDocumentsToDraftHint3()}</div>
      </>
      }
      <form onSubmit={methods.handleSubmit(submitAddDocumentsToDraft)}>
        <div className={classNames(styles.documentsContainer)}>
          {documentsIsLoading && <Spinner />}
          {watchDocuments.length <= 0 && !documentsIsLoading && (<b>{localizer.addDocumentsToDraftNoDocumentsFoundHint()}</b>)}
          {watchDocuments.length > 0 &&
          <div className="table-responsive">
            <table className="table">
              <thead className="table-header">
                <tr role="row">
                  <th role="columnheader" className={classNames(styles.documentsTableHeader)}>
                    <div className={styles.documentSwitchWithLabelContainer}>
                      <Checkbox
                        id={"addDocuments-all-isSelected"}
                        checked={watchDocuments.every((x) => x.isSelected)}
                        onChange={(e) => handleToggleSelectAll(e.target.checked)}
                      />
                      <span className="ml-2">
                        {localizer.all()}
                      </span>
                    </div>

                  </th>
                  <th role="columnheader" className={classNames(styles.documentsTableHeader, "text-right")}>
                    <div className={styles.verticallinesSwitchHeaderContainer}>
                      <Tooltip message={localizer.pagesWithVerticalLines()} placement="bottom">
                        <span className={styles.verticallinesSwitchLabel}>
                          {localizer.verticalLines()}
                        </span>
                      </Tooltip>
                      <Checkbox
                        id={"addDocuments-all-includePagesWithVerticalLines"}
                        checked={watchDocuments.every((x) => x.document.includePagesWithVerticalLines)}
                        onChange={(e) => handleToggleVerticalLinesAll(e.target.checked)}
                      />
                    </div>

                  </th>
                  <th role="columnheader" className={classNames(styles.documentsTableHeader)}>
                    {localizer.pages()}
                  </th>
                </tr>
              </thead>
              <tbody>
                {watchDocuments.map((formDocument, i) => {
                  const document = formDocument.document;
                  const documentId = document.caseDocumentId;
                  return (
                    <tr key={document.caseDocumentId} role="row" className={classNames(styles.draftDocumentRow)}>
                      <td role="cell" className="align-middle">
                        <div className="d-flex align-items-center">
                          <FormCheckbox
                            formGroupClassName="mt-auto mb-auto d-flex align-items-center"
                            methods={methods}
                            name={`documents.${i}.isSelected`}
                            id={`document-${documentId}-isSelected`}
                          />
                          <span className="ml-2">
                            <Tooltip message={document.title} placement="bottom">
                              <div>
                                <ClampedParagraph
                                  lineClamp={1}
                                  notExpandable
                                >
                                  {document.title}
                                </ClampedParagraph>
                              </div>
                            </Tooltip>
                          </span>
                        </div>

                      </td>
                      <td role="cell" className="text-right align-middle">
                        <FormCheckbox
                          formGroupClassName="mt-auto mb-auto d-flex justify-content-end"
                          methods={methods}
                          name={`documents.${i}.document.includePagesWithVerticalLines`}
                          id={`document-${documentId}-includeVerticalLines`}
                        />
                      </td>

                      <td role="cell" className="align-middle">
                        {/* <FormInput
                          methods={methods}
                          name={`documents.${i}.document.pageIntervals`}
                          id={`document-${documentId}-pageIntervals`}
                          readOnly={document.includePagesWithVerticalLines}
                          formGroupClassName="mb-0"
                        /> */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          }
        </div>
      </form>
      {!usePortalActionsContainer
        ? modalFooterActions
        : actionsContainer && ReactDOM.createPortal(modalFooterActions, actionsContainer)}
    </>
  );
};
