import { v4 as uuid } from "uuid";
import { KeyboardEvent, useEffect, useState } from "react";
import classNames from "classnames";
import useValidatedForm from "@components/forms/useValidatedForm";
import {
  MarkingModel,
  MarkingReplyModel,
  ReplyCreateModel,
  ReplyEditModel,
  ReplyModel,
} from "@pages/pdfviewer/component/models/markingModel";
import { useAuth } from "@components/auth/authProvider";
import { useGetProfilePictureUrlQuery } from "@services/api/user/userApi";
import { ParticipantBadge } from "@components/case/participant/participantBadge/participantBadge";
import MentionRichTextArea from "@components/mention/mentionRichTextArea";
import { useLocalization } from "@components/localization/localizationProvider";
import useMentionsHandler from "@components/mention/useMentionsHandler";
import { MentionTypeEnum } from "@components/mention/mentionModel";
import styles from "./markingReplies.module.scss";
import MarkingReply from "./markingReply";

interface MarkingRepliesProps {
  markingModel: MarkingModel;
  onReplyCreateEdit?: (data: ReplyModel) => void;
}

const MarkingReplies = ({ markingModel, onReplyCreateEdit } : MarkingRepliesProps) => {
  const { user } = useAuth();
  const localizer = useLocalization();

  const [replies, setReplies] = useState<{ isEditing: boolean, reply: MarkingReplyModel }[]>(markingModel.replies?.map((rep) => ({ isEditing: false, reply: rep })) ?? []);

  const { data: profilePictureUrl } = useGetProfilePictureUrlQuery(user.id);

  const { addMention, commitMentions } = useMentionsHandler(MentionTypeEnum.Reply);
  const methods = useValidatedForm<ReplyCreateModel>({
    defaultValues: {
      data: {
        content: "",
      },
    },
  });

  useEffect(() => {
    setReplies(markingModel.replies?.map((rep) => ({ isEditing: false, reply: rep })) ?? []);
  }, [markingModel.replies]);

  const handleSubmitReply = async (replyCreateModel: ReplyCreateModel) => {
    if (replyCreateModel.data.content && replyCreateModel.data.content.trim().length > 0) {

      methods.reset();

      const replyId = uuid();
      onReplyCreateEdit?.({
        ...markingModel,
        ...replyCreateModel,
        owner: {
          name: user.name,
          userId: user.id,
          employeeId: user.employeeId,
        },
        data: {
          content: replyCreateModel.data.content,
          id: replyId,
        },
      });

      await commitMentions(replyId);
    }
  };

  const handleEdit = (editetReply: ReplyEditModel) => {
    onReplyCreateEdit?.({
      ...markingModel,
      ...editetReply,
      owner: {
        name: user.name,
        userId: user.id,
        employeeId: user.employeeId,
      },
      data: {
        content: editetReply.data.content,
        id: editetReply.data.id,
      },
    });
  };

  const submitHandler =  methods.handleSubmit(handleSubmitReply);

  const changeEditState = (isEdit: boolean, replyId: string ) => {
    setReplies(replies.map((rep) => {
      if (rep.reply.id === replyId) {
        return { ...rep, isEditing: isEdit };
      } else {
        return rep;
      }
    }));
  };

  const replySubmitKeyDownHandler = (e: KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      submitHandler();
    }
  };

  return <div className={classNames(styles.repliesBody)}>
    {replies.map((rep) => (
      <MarkingReply
        key={rep.reply.id}
        onEdit={handleEdit}
        isEditing={rep.isEditing}
        profilePictureUrl={user.id === rep.reply.owner.userId ? profilePictureUrl : undefined}
        onEditingChange={(isEdit: boolean) => changeEditState(isEdit, rep.reply.id)}
        isReplyOwner={user.id === rep.reply.owner.userId}
        reply={rep.reply}
        showEditBtn={!replies.some((t) => t.isEditing)}
      />
    ))}

    {!replies.some((t) => t.isEditing) &&
        <div className={classNames(styles.repliesInputCard)}>
          <div className="row align-items-center">

            <div className="col-12 d-flex align-items-center">
              <div className={classNames("flex-shrink-0", styles.markingIcon)}>
                <ParticipantBadge
                  name={user.name}
                  profilePictureUrl={profilePictureUrl}
                  hideNameField={true}
                />
              </div>
              <div className="margin-left-s flex-grow-1">
                <form
                  onSubmit={submitHandler}
                >
                  <MentionRichTextArea
                    addMentionCallback={addMention}
                    methods={methods}
                    id={`reply-content-${markingModel.id}`}
                    onKeyDown={replySubmitKeyDownHandler}
                    placeholder={localizer.yourAnswer()}
                    name="data.content"
                    useAutoSizeOptions={{
                      minRows: 1,
                      maxRows: 10,
                    }}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
    }

  </div>;
};

export default MarkingReplies;
