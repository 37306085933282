import { ExtractDraftSectionDocumentModel } from "./extractDraftSectionDocumentModel";

export enum DocumentSortType {
  ByDate = 0,
  Custom = 1,
  Alphabetical = 2,
  ByAppendixNumber = 3,
}

export type ExtractDraftSectionModel = {
  documents: ExtractDraftSectionDocumentModel[];
  id: string;
  sortOrder: number;
  title: string;
  isMultiFile: boolean;
  documentsSortType: DocumentSortType;
};
