import { MouseEventHandler, ReactNode, useState } from "react";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { Dimensions } from "@components/resizable/resizable";
import { Spinner } from "@components/spinner/spinner";
import { MinimalPresentationPageModel } from "@services/api/case/models/casePresentationPageModel";
import { MarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import SinglePagePdfView from "@pages/pdfviewer/component/SinglePagePdfView/SinglePagePdfView";
import NewMarkingsPageOverlay from "@pages/pdfviewer/component/page/canvas/MarkingsPageOverlay/MarkingsPageOverlay";
import { MarkingsPageProvider } from "@pages/case/presentations/editPresentationPages/MarkingsPageContext/MarkingsPageContext";
import styles from "./presentPage.module.scss";
import PageInfoHeader from "./pageInfoHeader";

interface PresentPageProps {
  pdfUrl?: string;
  pageIndex: number;
  pageIndexDisplay?: number;
  pageData?: MinimalPresentationPageModel;
  zoomArea?: Dimensions;
  isLoading: boolean;
  pageOverlay?: (scale: number) => ReactNode;
  onLoadSuccess?: (pdf: DocumentCallback) => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
  tempMarkings?: MarkingModel[];
  documentId: string;
}

const PresentPage = ({
  pdfUrl,
  pageIndex,
  pageIndexDisplay,
  pageData,
  zoomArea,
  isLoading,
  pageOverlay,
  onLoadSuccess,
  onClick,
  tempMarkings,
  documentId,
}: PresentPageProps) => {
  const [scale, setScale] = useState(1);
  const pageIsVertical = !pageData?.orientation || pageData.orientation === 180;
  const isZoomedIn = zoomArea !== undefined;

  return (
    <div className={styles.container} onClick={onClick}>
      {isLoading ? (
        <Spinner className="h-100vh" />
      ) : (
        pdfUrl && (
          <>
            {!isZoomedIn && (
              <PageInfoHeader
                className={styles.header}
                pageIndex={pageIndexDisplay ? pageIndexDisplay : pageIndex}
                pageData={pageData}
              />
            )}
            <MarkingsPageProvider>
              <NewMarkingsPageOverlay
                disableEditing={true}
                isBigScreen
                pageIndex={pageIndex}
                scale={scale}
                documentId={documentId}
                markingsIdsToShow={pageData?.markingIds}
                isZoomedIn={isZoomedIn}
              >
                <SinglePagePdfView
                  pdfUrl={pdfUrl}
                  pdfPageIndex={pageIndex}
                  zoomArea={zoomArea}
                  onDocumentLoadSuccess={onLoadSuccess}
                  orientation={pageData?.orientation}
                  pageMargins={pageIsVertical ? 10 : 50}
                  setScale={setScale}
                  pageOverlay={pageOverlay}
                />
              </NewMarkingsPageOverlay>
            </MarkingsPageProvider>
          </>
        )
      )}
    </div>
  );
};

export default PresentPage;
