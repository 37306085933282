import { useCallback } from "react";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { scaleLevels } from "@pages/pdfviewer/component/pdfScales";
import { useAppDispatch, useAppSelector } from "@hooks";
import { pageMargin } from "@pages/pdfviewer/component/pdfViewer";
import { pdfViewerStateSelector, setScale } from "@pages/pdfviewer/component/pdfViewerSlice";
import { DynamicZoomLevel } from "../toolbar/zoomControl";
import { ElementSize } from "../../../../hooks/useElementSize";

export const useZoom = (pdfViewSize: ElementSize, pdf?: DocumentCallback) => {
  const dispatch = useAppDispatch();
  const pdfViewerState = useAppSelector(pdfViewerStateSelector);

  const zoomIn = useCallback(() => {
    const scaleFound = scaleLevels.find((item) => item > pdfViewerState.scale);
    const nextScale = scaleFound || pdfViewerState.scale;
    dispatch(setScale(nextScale));
  }, [dispatch, pdfViewerState.scale]);

  const zoomOut = useCallback(() => {
    const scaleFound = scaleLevels.findIndex((item) => item >= pdfViewerState.scale);
    const nextScale = scaleFound === -1 || scaleFound === 0 ? pdfViewerState.scale : scaleLevels[scaleFound - 1];
    dispatch(setScale(nextScale));
  }, [dispatch, pdfViewerState.scale]);

  const changeZoom = useCallback(async (level: DynamicZoomLevel | number) => {
    // handle numeric zoom levels
    if (typeof level === "number") {
      dispatch(setScale(level));
      return;
    }
    // handle dynamic zoom levels
    const currentPage = pdfViewerState.mostVisiblePageIndex + 1;
    const page = await pdf?.getPage(currentPage);
    if (!page) {
      return;
    }
    const scale = level === DynamicZoomLevel.FitToWidth
      ? (pdfViewSize.width - pageMargin * 2) / page.view[2]
      : (pdfViewSize.height - pageMargin * 2) / page.view[3];
    dispatch(setScale(scale));
  }, [dispatch, pdf, pdfViewSize.height, pdfViewSize.width, pdfViewerState.mostVisiblePageIndex]);

  return {
    zoomIn,
    zoomOut,
    changeZoom,
  };
};


