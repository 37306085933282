import { useEffect, useState } from "react";
import { NotificationModule } from "ditmer-embla";
import { useNavigate } from "react-router-dom";
import { addBreadcrumb, newBreadcrumb, setTitle } from "@components/layout/titleBar/titlebarSlice";
import { useLocalization } from "@components/localization/localizationProvider";
import { RoutePaths } from "@components/routing/routes";
import { useAppDispatch } from "@hooks";
import { useCreateEmployeeMutation } from "@services/api/employee/employeeApi";
import { CreateEditEmployeeModel } from "@services/api/employee/models/createEditEmployeeModel";
import { EmployeeCreateEditForm } from "./employeeCreateEditForm";

const intialEmployeeState: CreateEditEmployeeModel = {
  employee: {
    id: "",
    active: true,
    activatedAt: "",

    userProfile: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
    },
    initials: "",
    phone: "",
    accessRights: [],
    caseAccessRights: [],
  },
  profilePictureUrl: "",
  isTheOnlyAdministrativeEmployee: false,
  isTheOnlySystemAdmin: false,
};

export const EmployeeCreatePage: React.FC = () => {
  const [ employee ] = useState<CreateEditEmployeeModel>(intialEmployeeState);
  const [ createEmployee ] = useCreateEmployeeMutation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const localizer = useLocalization();

  useEffect(() => {
    dispatch(newBreadcrumb({ text: localizer.list(), url: RoutePaths.employees.url }));
    dispatch(addBreadcrumb({ text: localizer.create(), url: "" }));
    dispatch(setTitle({ title: localizer.employee(), withBackButton: true }));
  }, [dispatch, localizer]);

  const onSubmit = async (model: CreateEditEmployeeModel) => {
    const result = await createEmployee(model).unwrap();

    if (result) {
      NotificationModule.showSuccessSmall(localizer.theEmployeeWasCreated());
      navigate(RoutePaths.employeeEdit.url(result.employee.id), { replace: true } );
    }
  };

  return (
    <EmployeeCreateEditForm
      initialValues={employee}
      onSubmit={onSubmit}
      isEdit={false}
    />
  );

};
