import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useMemo } from "react";
import { localizationStateSelector } from "@components/localization/localizationSlice";
import { useAppSelector } from "@hooks";
import { Language } from "@infrastructure/language";

import "dayjs/locale/en-gb.js";
import "dayjs/locale/da.js";
import { DateTypeEnum } from "@services/api/case/models/dateTypeEnum";
import { useLocalization } from "@components/localization/localizationProvider";

dayjs.extend(relativeTime);

type AcceptedTypes = Dayjs | Date | string | number;

const ensureDayjs = (date: AcceptedTypes, language: Language) => {

  const dayjsDate = dayjs.isDayjs(date) ? date : dayjs(date);

  if (!dayjsDate.isValid())
    throw Error(`Invalid dayjs date from input: ${date}`);

  const locale = language === Language.Danish ? "da-DK" : "en-GB";
  return dayjsDate.locale(locale);
};

const getDateSeparator = (language: Language) => {
  if (language === Language.Danish)
    return "/";

  return "/";
};

const formatShortDate = (date: Dayjs, dateSeparator: string) => date.format("D/M/YY".replaceAll("/", dateSeparator));
const formatShortDateWithoutYear = (date: Dayjs, dateSeparator: string) => date.format("D/M".replaceAll("/", dateSeparator));
const formatShortDateWithoutYearWithShortTime = (date: Dayjs, dateSeparator: string) => date.format("D/M HH:mm".replaceAll("/", dateSeparator));

const formatDateWithoutYear = (date: Dayjs, dateSeparator: string) => date.format("DD/MM".replaceAll("/", dateSeparator));
const formatDateWithoutDay = (date: Dayjs, dateSeparator: string) => date.format("MM/YYYY".replaceAll("/", dateSeparator));
const formatDateWithoutDayAndMonth = (date: Dayjs, dateSeparator: string) => date.format("YYYY".replaceAll("/", dateSeparator));
const formatDate = (date: Dayjs, dateSeparator: string) => date.format("DD/MM/YYYY".replaceAll("/", dateSeparator));

const formatTime = (date: Dayjs, useAmPm: boolean) => date.format(useAmPm ? "h:mm:ss A" : "HH:mm:ss");

export type DateFormatter = {
  shortDateWithoutYear: (date: AcceptedTypes) => string,
  shortDateWithoutYearWithShortTime: (date: AcceptedTypes) => string,
  date: (date: AcceptedTypes, hideYearIfCurrent?: boolean, dateType?: DateTypeEnum) => string,
  shortDate: (date: AcceptedTypes) => string,
  time: (date: AcceptedTypes) => string,
  timeSince: (date: AcceptedTypes) => string,
};

const useDateFormatter = () => {
  const { language } = useAppSelector(localizationStateSelector);
  const dateSeparator = getDateSeparator(language);
  const localizer = useLocalization();

  return useMemo(() => ({
    shortDateWithoutYear: (date: AcceptedTypes) => formatShortDateWithoutYear(ensureDayjs(date, language), dateSeparator),
    shortDateWithoutYearWithShortTime: (date: AcceptedTypes) => formatShortDateWithoutYearWithShortTime(ensureDayjs(date, language), dateSeparator),
    date: (date: AcceptedTypes, hideYearIfCurrent?: boolean, dateType?: DateTypeEnum) => {
      const dayjsDate = ensureDayjs(date, language);

      if (dateType !== undefined) {
        switch (dateType) {
          case DateTypeEnum.UnDated:
            return localizer.noDateDateType();
          case DateTypeEnum.YearMonthDay:
            return formatDate(dayjsDate, dateSeparator);
          case DateTypeEnum.YearMonth:
            return formatDateWithoutDay(dayjsDate, dateSeparator);
          case DateTypeEnum.Year:
            return formatDateWithoutDayAndMonth(dayjsDate, dateSeparator);
        }
      } else {
        const hideYear = hideYearIfCurrent && dayjsDate.year() === dayjs().year();
        return hideYear ? formatDateWithoutYear(dayjsDate, dateSeparator) : formatDate(dayjsDate, dateSeparator);
      }
    },
    shortDate: (date: AcceptedTypes) => {
      return formatShortDate(ensureDayjs(date, language), dateSeparator);
    },
    time: (date: AcceptedTypes) => {
      const useAmPm = language !== Language.Danish;
      return formatTime(ensureDayjs(date, language), useAmPm);
    },
    timeSince: (date: AcceptedTypes, automaticDateFormat = true) => {
      const daysjDate =  ensureDayjs(date, language);
      const daysjToday =  ensureDayjs(dayjs(), language);

      if (automaticDateFormat && daysjDate.isBefore(daysjToday.subtract(10, "days")))
        return formatDate(daysjDate, dateSeparator);

      return daysjToday.to(daysjDate);
    },
  } as DateFormatter), [language, dateSeparator]);
};

export default useDateFormatter;
