import classNames from "classnames";
import Button from "@components/embla/button";
import { EmblaIcon } from "@components/embla/emblaIcon";
import ExpandingDropdownMenu from "@components/expandingDropdownMenu/expandingDropdownMenu";
import FormDropdown from "@components/forms/FormDropdown";
import FormSwitch from "@components/forms/formSwitch";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import { useUpdateExtractDraftFrontpageSettingsMutation, useUpdateTableOfContentsExtractDraftMutation } from "@services/api/extractDraft/extractDraftApi";
import { convertDateFormatEnumToString, DateFormatEnum, getDateFormatEnum, getDateFormatEnumDropdownOptions } from "@services/api/extractDraft/models/dateFormatEnum";
import { ExtractDraftFrontpageModel } from "@services/api/extractDraft/models/extractDraftFrontpageModel";
import { ExtractDraftTableOfContentsModel, getPageNumberFontSelectValues, PageNumberFontEnum } from "@services/api/extractDraft/models/extractDraftTableOfContentsModel";
import { FrontpageSettingsModel } from "@services/api/extractDraft/models/frontpageSettingsModel";
import { UpdateCaseExtractDraftTableOfContentsModel } from "@services/api/extractDraft/models/UpdateCaseExtractDraftTableOfContentsModel";
import useAutosave from "src/hooks/useAutosave";
import styles from "./TableOfContentsSettingsRow.module.scss";

type TableOfContentsSettingsRowProps = {
  tableOfContents: ExtractDraftTableOfContentsModel;
  frontpage: ExtractDraftFrontpageModel;
  caseId: string;
  extractDraftId: string;
};

const TableOfContentsSettingsRow = ({ frontpage, tableOfContents, caseId, extractDraftId }:TableOfContentsSettingsRowProps ) => {
  const localizer = useLocalization();
  const formFieldIdPrepend = "extractDetails-toolbar";
  const formFieldIdPrependFrontpage = "extractDetails-frontpage";

  const [updateTableOfContents, updateTableOfContentsRequest] = useUpdateTableOfContentsExtractDraftMutation();
  const [updateFrontpageSettings, updateFrontpageRequest] = useUpdateExtractDraftFrontpageSettingsMutation();

  const defaultValuesTOC: UpdateCaseExtractDraftTableOfContentsModel =  {
    repeatableColumns: tableOfContents.repeatableColumns,
    generate: tableOfContents.generate,
    insertSectionDividers: tableOfContents.insertSectionDividers,
    showDates: tableOfContents.showDates,
    showOwnPageNumber: tableOfContents.showOwnPageNumber,
    showLeader: tableOfContents.showLeader,
    showPageNumbersAsIntervals: tableOfContents.showPageNumbersAsIntervals,
    dateFormatEnum: tableOfContents.dateFormatEnum,
    documentsStartOnOddPages: tableOfContents.documentsStartOnOddPages,
    alternatingPageNumbers: tableOfContents.alternatingPageNumbers,
    showPageNumbers: tableOfContents.showPageNumbers,
    showExhibit: tableOfContents.showExhibit,
    font: tableOfContents.font,
  };

  const defaultValuesFrontpage: FrontpageSettingsModel = {
    showOwnPageNumber: frontpage.showOwnPageNumber,
  };

  const methodsTOC = useValidatedForm({
    defaultValues: defaultValuesTOC,
  });

  const methodsFrontpage = useValidatedForm({
    defaultValues: defaultValuesFrontpage,
  });

  const { getFieldId } = useAutosave({
    queryStatus: updateTableOfContentsRequest.status,
    fieldIdPrefix: formFieldIdPrepend,
    watch: methodsTOC.watch,
    displayStatus: false,
    onSubmit: methodsTOC.handleSubmit((model) => {
      updateTableOfContents({ caseId: caseId, extractDraftId, model }).unwrap().catch((e) => {
        methodsTOC.reset(defaultValuesTOC);
      });
    }),
  });

  const { getFieldId: getFieldIdFrontpage } = useAutosave({
    queryStatus: updateFrontpageRequest.status,
    fieldIdPrefix: formFieldIdPrependFrontpage,
    watch: methodsFrontpage.watch,
    displayStatus: false,
    onSubmit: methodsFrontpage.handleSubmit((model) => {
      updateFrontpageSettings({ caseId: caseId, extractDraftId, model }).unwrap().catch((e) => {
        methodsFrontpage.reset(defaultValuesFrontpage);
      });
    }),
  });

  const showPageNumbers = methodsTOC.watch("showPageNumbers");

  return (
    <div className="d-flex px-4 py-2 align-items-center gap-m">
      <span className="subtle">{localizer.settings()}</span>
      <ExpandingDropdownMenu
        buttonNode={
          <Button className="pl-3 pr-1">
            {localizer.pageNumber()}
            <EmblaIcon iconName="arrow-down" additionalClasses="ml-1" />
          </Button>
        }
        menuDropdownPlacement="bottom"
      >
        <ExpandingDropdownMenu.EmptyItem className="m-2">
          <FormSwitch
            methods={methodsTOC}
            name={"showPageNumbers"}
            id={getFieldId("showPageNumbers")}
            label={localizer.applyPageNumbers()}
          />
        </ExpandingDropdownMenu.EmptyItem>
        <ExpandingDropdownMenu.EmptyItem className="m-2 ml-4">
          <FormSwitch
            disabled={!showPageNumbers}
            methods={methodsFrontpage}
            name="showOwnPageNumber"
            id={getFieldIdFrontpage("showOwnPageNumber")}
            label={localizer.displayOnFrontPage()}
            formGroupClassName={classNames(!showPageNumbers && "subtle")}
            labelClassName={classNames(!showPageNumbers && styles.defaultCursor)}
          />
        </ExpandingDropdownMenu.EmptyItem>
        <ExpandingDropdownMenu.EmptyItem className="m-2 ml-4">
          <FormSwitch
            disabled={!showPageNumbers}
            methods={methodsTOC}
            name={"showOwnPageNumber"}
            id={getFieldId("showOwnPageNumber")}
            label={localizer.displayOnTableOfContents()}
            formGroupClassName={classNames(!showPageNumbers && "subtle")}
            labelClassName={classNames(!showPageNumbers && styles.defaultCursor)}
          />
        </ExpandingDropdownMenu.EmptyItem>
        <ExpandingDropdownMenu.EmptyItem className="m-2 ml-4">
          <FormSwitch
            disabled={!showPageNumbers}
            methods={methodsTOC}
            name={"alternatingPageNumbers"}
            id={getFieldId("alternatingPageNumbers")}
            label={localizer.alternateSides()}
            formGroupClassName={classNames(!showPageNumbers && "subtle")}
            labelClassName={classNames(!showPageNumbers && styles.defaultCursor)}
          />
        </ExpandingDropdownMenu.EmptyItem>
      </ExpandingDropdownMenu>

      <ExpandingDropdownMenu
        menuDropdownPlacement="bottom"
        buttonNode={
          <Button className="pl-3 pr-1">
            {localizer.columns()}
            <EmblaIcon iconName="arrow-down" additionalClasses="ml-1" />
          </Button>
        }
      >
        <ExpandingDropdownMenu.EmptyItem className="m-2">
          <FormSwitch
            methods={methodsTOC}
            name={"showDates"}
            id={getFieldId("showDates")}
            label={localizer.displayDateColumn()}
          />
        </ExpandingDropdownMenu.EmptyItem>
        <ExpandingDropdownMenu.EmptyItem className="m-2">
          <FormSwitch
            methods={methodsTOC}
            name={"showExhibit"}
            id={getFieldId("showExhibit")}
            label={localizer.showAppendixNumberColumn()}
          />
        </ExpandingDropdownMenu.EmptyItem>
      </ExpandingDropdownMenu>

      <ExpandingDropdownMenu
        buttonNode={
          <Button className="pl-3 pr-1">
            {localizer.displaySettings()}
            <EmblaIcon iconName="arrow-down" additionalClasses="ml-1" />
          </Button>
        }
        menuDropdownPlacement="bottom"
      >
        <ExpandingDropdownMenu.EmptyItem className="m-2">
          <FormSwitch
            methods={methodsTOC}
            name={"repeatableColumns"}
            id={getFieldId("repeatableColumns")}
            label={localizer.displayHeadlinesOnAllTableOfContentsPages()}
          />
        </ExpandingDropdownMenu.EmptyItem>
        <ExpandingDropdownMenu.EmptyItem className="m-2">
          <FormSwitch
            methods={methodsTOC}
            name={"showPageNumbersAsIntervals"}
            id={getFieldId("showPageNumbersAsIntervals")}
            label={localizer.displayPageNumbersAsRange()}
          />
        </ExpandingDropdownMenu.EmptyItem>
        <ExpandingDropdownMenu.EmptyItem className="m-2">
          <FormSwitch
            methods={methodsTOC}
            name={"showLeader"}
            id={getFieldId("showLeader")}
            label={localizer.addDotsFromTitleToPageNumber()}
            disabled={!defaultValuesTOC.generate}
          />
        </ExpandingDropdownMenu.EmptyItem>
        <ExpandingDropdownMenu.EmptyItem className="m-2 mb-3">
          <FormSwitch
            methods={methodsTOC}
            name={"documentsStartOnOddPages"}
            id={getFieldId("documentsStartOnOddPages")}
            label={localizer.documentsStartOnOddPages()}
          />
        </ExpandingDropdownMenu.EmptyItem>
        <ExpandingDropdownMenu.ItemDivider />
        <ExpandingDropdownMenu.EmptyItem className="m-2 mt-3">
          <FormDropdown
            label={localizer.dateformat()}
            methods={methodsTOC}
            id={getFieldId("dateFormatEnum")}
            name="dateFormatEnum"
            options={getDateFormatEnumDropdownOptions(getDateFormatEnum())}
            valueToOptionTransform={(value: DateFormatEnum) => value ? { label: convertDateFormatEnumToString(value), value: value } : null}
            formGroupClassName={"d-flex justify-content-between align-items-center gap-m"}
            minWidth={170}
          />
        </ExpandingDropdownMenu.EmptyItem>
        <ExpandingDropdownMenu.EmptyItem className="m-2 mt-3">
          <FormDropdown
            label={localizer.fontFamily()}
            methods={methodsTOC}
            id={getFieldId("font")}
            name="font"
            options={getPageNumberFontSelectValues()}
            valueToOptionTransform={(value: PageNumberFontEnum) => value ? { label: PageNumberFontEnum[value], value: value } : null}
            formGroupClassName="d-flex justify-content-between align-items-center gap-m"
            minWidth={170}
          />
        </ExpandingDropdownMenu.EmptyItem>
      </ExpandingDropdownMenu>
    </div>
  );
};

export default TableOfContentsSettingsRow;