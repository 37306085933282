import { useMemo } from "react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAppSelector } from "@hooks";
import { UserModel } from "@services/api/sharedModels/userModel";
import { pdfTypeIdSelector, pdfTypeSelector } from "../pdfViewerSlice";
import { useGetMarkingsQuery } from "../markingsApi";

const useMarkingOwners = () => {
  const pdfTypeId = useAppSelector(pdfTypeIdSelector);
  const pdfType = useAppSelector(pdfTypeSelector);

  const { data, isLoading } = useGetMarkingsQuery((pdfTypeId && pdfType) ? { pdfTypeId: pdfTypeId, origin: pdfType } : skipToken);

  const markingOwners = useMemo(() => {
    const owners = data ? Object.values(data).flat().map((x) => x.owner) as UserModel[] : [];
    const uniqueOwners = owners.reduce((prev: UserModel[], cur: UserModel) => {
      if (!prev.some((x) => x.employeeId === cur.employeeId)) {
        return [...prev, cur];
      }
      return prev;
    }, []);
    return uniqueOwners;
  }, [data]);

  return {
    markingOwners: markingOwners,
    isLoading,
  };
};

export default useMarkingOwners;
