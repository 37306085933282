import { useLocalization } from "@components/localization/localizationProvider";

export const NotFoundPage = () => {
  const localizer = useLocalization();

  return (
    <div className="body-container">
      <div className="page-content">
        <div className="main-content">
          <div className="body-content">
            <h1>{localizer.pageNotFound()}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

