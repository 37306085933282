import classNames from "classnames";
import { BadgeSize, RoundBadge } from "@components/badge/RoundBadgge";
import { Color } from "src/utility/constants";
import styles from "./participantBadge.module.scss";

interface ParticipantBadgeProps {
  name: string;
  initials?: string;
  profilePictureUrl?: string;
  badgeContainerClassNames?: string;
  nameFieldClassNames?: string;
  hideNameField?: boolean;
  size?: BadgeSize;
}

export const ParticipantBadge: React.FC<ParticipantBadgeProps> = ({
  name,
  initials,
  profilePictureUrl,
  badgeContainerClassNames,
  nameFieldClassNames,
  hideNameField,
  size = BadgeSize.Medium,
}: ParticipantBadgeProps) => {
  const formattedInitials = initials
    ? initials
    : name
      .split(" ")
      .map((word) => word[0])
      .slice(0, 3)
      .join("");

  return (
    <>
      <div
        className={classNames(
          styles.participantBadge,
          badgeContainerClassNames,
        )}
      >
        {profilePictureUrl ? (
          <img
            className={styles["participantBadge.profilePicture"]}
            alt={"profilepicture"}
            src={profilePictureUrl}
          />
        ) : (
          <RoundBadge
            size={size}
            color={Color.SecondaryLight}
            title={name}
          >
            {formattedInitials}
          </RoundBadge>
        )}
      </div>
      {!hideNameField && (
        <div
          className={classNames(
            styles.nameField,
            "margin-left-s",
            nameFieldClassNames,
          )}
          title={name}
        >
          <div>{name}</div>
        </div>
      )}
    </>
  );
};
