import classNames from "classnames";
import { useRef, useState } from "react";
import { NotificationModule } from "ditmer-embla";
import { debounce } from "lodash";
import { CheckboxSwitch } from "@components/checkbox/checkboxSwitch";
import Card from "@components/embla/card";
import { CommentMarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import { useLocalization } from "@components/localization/localizationProvider";
import { useOnClickOutside } from "@pages/pdfviewer/component/hooks/useClickOutside";
import { useAddPresentationPageNoteMutation } from "@services/api/casePresentation/casePresentationApi";
import Button from "@components/embla/button";
import { useMarkingsPageContext } from "../../../MarkingsPageContext/MarkingsPageContext";
import styles from "./CommentCard.module.scss";
import CommentReply from "./CommentReply/CommentReply";

type CommentCardProps = {
  commentData: CommentMarkingModel<false>;
  updateMarkings: (value: boolean, markingIds: string[]) => void;
  isActive: boolean;
  disableEditing?: boolean;
  caseId: string;
  pageId: string;
  documentId: string;
};

const CommentCard = ({
  commentData,
  updateMarkings,
  isActive,
  disableEditing,
  caseId,
  documentId,
  pageId,
}: CommentCardProps) => {
  const [showComments, setShowComments] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  const localizer = useLocalization();
  useOnClickOutside(cardRef, () => setShowComments(false));
  const [addNote, { isLoading }] = useAddPresentationPageNoteMutation();
  
  const { setHoveredMarkingId, hoveredMarkingId } = useMarkingsPageContext();
  const isHovered = commentData.id === hoveredMarkingId;
  
  const handleMouseEnter = () => {
    setHoveredMarkingId(commentData.id);
  };
  
  const handleLeave = () => {
    setHoveredMarkingId(undefined);
  };
  
  const openComments = () => {
    setShowComments(true);
  };

  const createSpeechnoteFromComment = debounce( () => {
    if (caseId && documentId) {
      if (pageId) {
        addNote({
          caseId,
          presentationId: documentId,
          presentationPageId: pageId,
          model: {
            note: commentData.data.text,
          },
        })
          .unwrap()
          .then(() => {
            NotificationModule.showSuccessSmall(localizer.speakerNoteCreated());
          });
      }
    }
  }, 150);
  
  const commentHasReplies = !!commentData.replies?.length;


  return (
    <Card
      ref={cardRef}
      onClick={openComments}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleLeave}
      className={classNames(
        "pb-2",
        commentHasReplies && styles.hover,
        isHovered && styles.selected,
        !isActive && styles.inactive,
      )}
    >
      <div className="d-flex align-items-center justify-content-between p-2">
        <p className="m-0 small">{commentData.owner.name}</p>
        <div className="d-flex align-content-center gap-8">
          <Button disabled={isLoading} onClick={createSpeechnoteFromComment} className={classNames(styles.small)} theme="default">
            {localizer.createAsSpeakerNote()}
          </Button>
          <CheckboxSwitch
            disabled={disableEditing}
            debounceLength={150}
            id={commentData.id}
            externalChecked={isActive}
            onchangeCallback={(value) => updateMarkings(value, [commentData.id])}
          />
        </div>
      </div>
      <p className={classNames("px-2 m-0", styles.breakWord)}>{commentData.data.text}</p>
      {
        commentHasReplies && !showComments ? (
          <>
            <hr className={classNames(styles.hover, "my-1")}/>
            <p className="px-2 m-0 subtle">{commentData.replies?.length} {localizer.replies()}</p>
          </>
        ) : (
          commentData.replies?.map((reply) => <CommentReply reply={reply} />)
        )
      }
    </Card>
  );
};

export default CommentCard;
