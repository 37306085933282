import { useState } from "react";
import classNames from "classnames";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import { ExtractDraftTableOfContentsModel } from "@services/api/extractDraft/models/extractDraftTableOfContentsModel";
import { ExtractDraftFrontpageModel } from "@services/api/extractDraft/models/extractDraftFrontpageModel";
import TableOfContentsModal from "./TableOfContentModal/TableOfContentsModal";
import styles from "./TableOfContents.module.scss";

type TableOfContentsProps = {
  caseId: string;
  extractDraftId: string;
  tableOfContents: ExtractDraftTableOfContentsModel;
  frontpage: ExtractDraftFrontpageModel;
};

export const TableOfContents = ({ caseId, extractDraftId, tableOfContents, frontpage }: TableOfContentsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const localizer = useLocalization();

  return (
    <>
      <div className={classNames(styles.container, "d-flex justify-content-between")} onClick={() => setIsModalOpen(true)}>
        <div className="d-flex align-items-center gap-8">
          <EmblaIcon color={IconColor.Gray} iconName="document" />
          <h4 className="m-0">{localizer.tableOfContents()}</h4>
        </div>
      </div>
      <TableOfContentsModal
        tableOfContents={tableOfContents}
        caseId={caseId}
        extractDraftId={extractDraftId}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        frontpage={frontpage}
      />
    </>
  );
};
