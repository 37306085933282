import { debounce } from "lodash";
import { useState, useEffect } from "react";

type SetValue<T> = (value: T | ((val: T) => T)) => void;

const useLocalStorage = <T>(key: string, initialValue: T, debounceDelay = 0): [T, SetValue<T>] => {
  const storedValue = localStorage.getItem(key);
  const initial = storedValue ? JSON.parse(storedValue) : initialValue;

  const [value, setValue] = useState<T>(initial);

  useEffect(() => {
    debounce(() => localStorage.setItem(key, JSON.stringify(value)), debounceDelay)();
  }, [debounceDelay, key, value]);

  return [value, setValue];
};

export default useLocalStorage;
