import classNames from "classnames";
import { CheckboxSwitch } from "@components/checkbox/checkboxSwitch";
import Card from "@components/embla/card";
import { MarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import MarkingIcon from "@pages/pdfviewer/component/shared/markingIcon";
import { useMarkingsPageContext } from "../../../MarkingsPageContext/MarkingsPageContext";
import styles from "./MarkingSwitchCard.module.scss";

type MarkingSwitchCardProps = {
  marking: MarkingModel<false>;
  updateMarkings: (value: boolean, markingIds: string[]) => void;
  isActive: boolean;
  disableEditing?: boolean;
};

const MarkingSwitchCard = ({
  marking,
  updateMarkings,
  isActive,
  disableEditing,
}: MarkingSwitchCardProps) => {
  const { setHoveredMarkingId, hoveredMarkingId } = useMarkingsPageContext();

  const isHovered = marking.id === hoveredMarkingId;

  const handleMouseEnter = () => {
    setHoveredMarkingId(marking.id);
  };

  const handleLeave = () => {
    setHoveredMarkingId(undefined);
  };

  return (
    <Card
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleLeave}
      className={classNames(
        "flex-row align-items-center justify-content-between align-content-center p-2 gap-s",
        isHovered && styles.hovered,
        !isActive && styles.inactive,
      )}
    >
      <div
        className={classNames("d-flex gap-s", styles.markingIcon)}
      >
        <MarkingIcon markingType={marking.type} />
        <div className="text-truncate">{marking.owner.name}</div>
      </div>
      <CheckboxSwitch
        disabled={disableEditing}
        debounceLength={150}
        id={`checkbox-${marking.id}`}
        onchangeCallback={(value) => updateMarkings(value, [marking.id])}
        externalChecked={isActive}
      />
    </Card>
  );
};

export default MarkingSwitchCard;
