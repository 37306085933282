import { useEffect } from "react";
import { useAppDispatch } from "@hooks";
import { PdfType, initPdfViewer } from "@pages/pdfviewer/component/pdfViewerSlice";
import { useGetPresentationPageFilesQuery } from "@services/api/presentation/presentationApi";

const usePresentationDocument = (presentationId: string, pollingRate?: number | undefined) => {
  const { data: downloadUrlModel, isLoading } = useGetPresentationPageFilesQuery(presentationId, { pollingInterval: pollingRate });

  const dispatch = useAppDispatch();


  useEffect(() => {
    dispatch(initPdfViewer({ pdfTypeId: presentationId, pdfType: PdfType.Presentation }));
  }, [dispatch, presentationId]);

  return {
    isLoading,
    pageFiles: downloadUrlModel?.presentationPageFileUrlModels,
    title: downloadUrlModel?.presentationTitle,
    totalPages: downloadUrlModel?.presentationPageFileUrlModels.length ?? 0,
  };
};

export default usePresentationDocument;
