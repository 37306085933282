import { CommentMarkingModel, FocusBoxMarkingModel, HighlightMarkingModel, VerticalLinesMarkingModel } from "./markingModel";
import { MarkingType } from "./markingType";

export enum PdfToolType {
  SelectMarking = "select-marking",
  SelectText = "select-text",
}

export enum MarkingActionType {
  Delete = "delete",
}

export enum UndoRedoToolType {
  Undo = "undo",
  Redo = "redo",
}

interface NonMarkingTool {
  type: PdfToolType;
}

interface CommentMarkingTool {
  type: MarkingType.Comment;
  template: CommentMarkingModel<true>;
}

interface HighlightMarkingTool {
  type: MarkingType.Highlight;
  template: HighlightMarkingModel<true>;
}

interface VerticalLinesMarkingTool {
  type: MarkingType.VerticalLines;
  template: VerticalLinesMarkingModel<true>;
}

interface FocusBoxMarkingTool {
  type: MarkingType.FocusBox;
  template: FocusBoxMarkingModel<true>;
}

export type ActivePdfTool
  = NonMarkingTool
  | CommentMarkingTool
  | HighlightMarkingTool
  | VerticalLinesMarkingTool
  | FocusBoxMarkingTool;
