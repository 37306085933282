import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { useAppDispatch, useAppSelector } from "@hooks";
import { PdfType, setPdfType, setPdfTypeId, setTotalPages, totalPagesSelector } from "@pages/pdfviewer/component/pdfViewerSlice";
import { useGetPresentfileQuery } from "@services/api/presentation/presentationApi";

const usePresentPresentationDocument = (presentationId: string) => {

  const dispatch = useAppDispatch();

  const totalPages = useAppSelector(totalPagesSelector);

  const { data: downloadUrlModel, isLoading } = useGetPresentfileQuery(presentationId);

  const handleLoadSuccess = (pdf: DocumentCallback) => {
    dispatch(setTotalPages(pdf.numPages));
    dispatch(setPdfType(PdfType.Presentation));
    dispatch(setPdfTypeId(presentationId));
  };

  return {
    isLoading,
    pdfUrl: downloadUrlModel?.downloadUrl,
    title: downloadUrlModel?.caseTitle,
    totalPages: totalPages,
    handleLoadSuccess,
  };
};

export default usePresentPresentationDocument;
