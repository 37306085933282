import { NotificationModule } from "ditmer-embla";
import { CasePresentationModel } from "@components/case/presentation/casePresentationModel";
import FormInput from "@components/forms/FormInput";
import FormSubmitButton from "@components/forms/FormSubmitButton";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import Modal, { useModalContext } from "@components/modal/modal";
import { presentationValidation } from "@components/validation/sharedValidation/presentationValidation";
import { useEditPresentationMutation } from "@services/api/casePresentation/casePresentationApi";

type EditPresentationProps = {
  caseId: string;
  presentationId: string;
  title: string;
};

export const EditPresentation = ({ caseId, presentationId, title }: EditPresentationProps) => {
  const localizer = useLocalization();
  const [editPresentation, editPresentationRequest] = useEditPresentationMutation();
  const { close } = useModalContext();

  const initialValues: CasePresentationModel = {
    title,
  };

  const methods = useValidatedForm({
    validationSchema: presentationValidation(localizer),
    defaultValues: initialValues,
  });

  const submitPresentation = (model: CasePresentationModel) => {
    editPresentation({ caseId, presentationId, model }).then(() => {
      NotificationModule.showSuccessSmall(localizer.thePresentationWasUpdated());
      close();
    });
  };

  return (
    <>
      <Modal.Header>{localizer.editPresentationTitle()}</Modal.Header>
      <Modal.Body>
        <form onSubmit={methods.handleSubmit(submitPresentation)}>
          <div className="row">
            <div className="col-lg-12">
              <FormInput
                methods={methods}
                id={"presentation-edit-form-title"}
                name="title"
                label={localizer.title()}
                placeholder={localizer.presentationTitlePlaceholder()}
                required
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <FormSubmitButton
          state={{
            isSubmitting: editPresentationRequest.isLoading,
            isSubmitSuccessful: editPresentationRequest.isSuccess,
          }}
          onClick={methods.handleSubmit(submitPresentation)}
        >
          {localizer.save()}
        </FormSubmitButton>
      </Modal.Footer>
    </>
  );
};
