/* eslint-disable @typescript-eslint/naming-convention */
import { UserManagerSettings, WebStorageStateStore } from "oidc-client-ts";
import { Config } from "./configManager";
// eslint-disable-next-line import/no-cycle

const loadOidcConfig = (config: Config, baseUrl: string, useLocalStorage = false) => {

  const oidcConfig: UserManagerSettings = {
    // the URL of our identity server
    authority: config.idpUrl,
    // this ID maps to the client ID in the identity client configuration
    client_id: config.idpClientId,
    // URL to redirect to after login
    redirect_uri: `${baseUrl}/signin-oidc`,
    response_type: "code",
    // the scopes or resources we would like access to
    scope: "openid profile offline_access prolegisapi prolegisfilestorage prolegisemail IdentityServerApi",
    // URL to redirect to after logout
    post_logout_redirect_uri: baseUrl,
    // automatically renew the token in the background before it expires, if it is supported by the server. For this mechanism to work, the browser must have third-party cookies support enabled.
    automaticSilentRenew: true,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: useLocalStorage ? localStorage : sessionStorage }),
  };

  return oidcConfig;
};

export { loadOidcConfig };

