import React, { ReactNode } from "react";
import { ParticipantBadge } from "@components/case/participant/participantBadge/participantBadge";
import Button from "@components/embla/button";
import { EmblaIcon } from "@components/embla/emblaIcon";
import ExpandableCard from "./expandableCard";
import styles from "./participantCard.module.scss";

export type ParticipantCardProps = {
  index: number;
  employeeId: string;
  deleteCallback: (index: number) => void;
  profilePictureUrl?: string;
  participantName: string;
  initials?: string;
  canBeDeleted: boolean;
  children: ReactNode;
};

export const ParticipantCard: React.FC<ParticipantCardProps> = (props) => {

  const onDeleteClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    props.deleteCallback(props.index);
  };

  return (
    <ExpandableCard
      defaultExpanded
      header={
        <div className="row align-items-center">
          <div className="col d-flex">
            <ParticipantBadge
              name={props.participantName}
              initials={props.initials}
              profilePictureUrl={props.profilePictureUrl}
              key={props.index}
              nameFieldClassNames="margin-left-m"
            />
          </div>
          {props.canBeDeleted &&
            <div className="col-md-auto">
              <Button
                iconBtn
                className={styles.removeParticipantBtn}
                onClick={onDeleteClicked}
              >
                <EmblaIcon iconName="remove" />
              </Button>
            </div>
          }
        </div>
      }
    >
      {props.children}
    </ExpandableCard>
  );
};
