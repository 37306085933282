import React, {
  createContext, useContext, useMemo,
} from "react";
import { useAppSelector } from "@hooks";
import { StorageKeys } from "@infrastructure/storageKeys";
import { useGetLocalizationQuery } from "@services/api/localizationApi";
import GenericErrorPage from "@components/error/genericErrorPage";
import { localizationStateSelector } from "./localizationSlice";
import { Localizer } from "./localizer";

const getDefaultTranslations = () => {
  const localizationAsJson = localStorage.getItem(StorageKeys.localizations);
  if (localizationAsJson)
    return JSON.parse(localizationAsJson);

  return {};
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const LocalizationContext = createContext<Localizer>(
  new Localizer(getDefaultTranslations()),
);

export const useLocalization = () => useContext(LocalizationContext);


export const LocalizationProvider = ({ children }: { children: React.ReactNode }) => {
  const { language } = useAppSelector(localizationStateSelector);
  const { data: localization, isLoading, error } = useGetLocalizationQuery(language);

  const localizationProviderValue = useMemo(() => {
    return new Localizer(localization ?? null);
  }, [localization]);

  if (isLoading) {
    return null;
  }

  if (error) {
    return <GenericErrorPage />;
  }

  return (
    <LocalizationContext.Provider value={localizationProviderValue}>
      {children}
    </LocalizationContext.Provider>
  );
};
