import { UserService } from "@services/userService";
import { HubConnectionManager } from "./hubConnectionManager";

export enum PresentationHubEventType {
  PresentationEvent = "PresentationEvent",
  PresentationPagesEvent = "PresentationPagesEvent",
}

export default class PresentationHubConnectionManager extends HubConnectionManager {
  endpointName: string;
  userService: UserService;
  apiUrl: string;

  constructor(endpointName: string,
    userService: UserService,
    apiUrl: string) {
    super();

    this.endpointName = endpointName;
    this.userService = userService;
    this.apiUrl = apiUrl;
  }
}
