import { Placement } from "@popperjs/core";
import classNames from "classnames";
import React, { forwardRef, Ref, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";
import mergeRefs from "src/utility/mergeRefs";
import tooltipStyles from "./tooltip.module.scss";

interface TooltipProps {
  children: JSX.Element;
  message?: string | false | null;
  placement?: Placement;
  distance?: number;
  className?: string;
}

export const Tooltip = forwardRef((
  {
    children,
    message,
    placement = "auto",
    distance = 5,
    className,
  }: TooltipProps,
  ref: Ref<HTMLElement>,
) => {
  const [isHover, setIsHover] = useState(false);

  // popper intentionally uses state, not ref, to update when dom node changes
  const [arrow, setArrow] = useState<HTMLDivElement | null>();
  const [referenceEl, setReferenceEl] = useState<HTMLElement | null>();
  const [tooltip, setTooltip] = useState<HTMLDivElement | null>();

  const { styles: popperStyles, attributes } = usePopper(referenceEl, tooltip, {
    placement: placement,
    modifiers: [
      {
        name: "arrow",
        options: {
          element: arrow,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, distance],
        },
      },
    ],
  });

  return (
    <>
      {React.cloneElement(children, {
        onPointerEnter: () => setIsHover(true),
        onPointerLeave: () => setIsHover(false),
        ref: mergeRefs(children, ref, setReferenceEl),
      })}

      {isHover && message &&
        ReactDOM.createPortal((
          <div
            ref={setTooltip}
            style={popperStyles.popper}
            className={classNames(tooltipStyles.tooltip, className)}
            {...attributes.popper}
          >
            {message}
            <div
              ref={setArrow}
              className={tooltipStyles.arrow}
              style={popperStyles.arrow}
            />
          </div>
        ), document.body)
      }
    </>
  );
});
