/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "@content/images/logo-v2.svg";
import { ReactComponent as ImersonateIcon } from "@content/icons/impersonate-icon.svg";
import { useAuth } from "@components/auth/authProvider";
import { DownloadButton } from "@components/button/downloadButton";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import { localizationStateSelector, setLanguage } from "@components/localization/localizationSlice";
import { KNOWLEDGE_BASE_URL, RoutePaths } from "@components/routing/routes";
import { Tooltip } from "@components/tooltip/tooltip";
import { useAppDispatch, useAppSelector } from "@hooks";
import { Language } from "@infrastructure/language";
import { ChangeLanguageModel } from "@services/api/user/models/changeLanguageModel";
import { useGetProfilePictureUrlQuery, useUpdateLanguageMutation } from "@services/api/user/userApi";
import useElectronApi from "src/hooks/useElectronApi";
import useElectronInstallerPath from "src/hooks/useElectronInstallerPath";
import { Enviroment, EnviromentBadge } from "@components/badge/enviromentBadge";
import { useConfig } from "@components/config/configProvider";
import NavItemDropdown from "./navItemDropdown";
import styles from "./topMenu.module.scss";

const TopMenu = () => {
  const [updateLanguage] = useUpdateLanguageMutation();
  const [showCollapsedMenu, setShowCollapsedMenu] = useState(false);
  const { user, signOut, stopImpersonation } = useAuth();
  const localizer = useLocalization();
  const { language } = useAppSelector(localizationStateSelector);
  const dispatch = useAppDispatch();
  const electronApi = useElectronApi();
  const config = useConfig();
  const electronInstallerPath = useElectronInstallerPath(!!electronApi);
  const { data: profilePictureUrl } = useGetProfilePictureUrlQuery(user.id);
  const isEnglish = Language.English === language;

  useEffect(() => {
    const model = {
      language: language,
    } as ChangeLanguageModel;

    updateLanguage(model);

  }, [language, updateLanguage]);

  return (
    <nav className={classNames("navbar navbar-expand-md fixed-top", styles.navBar)}>
      <Link
        to={RoutePaths.index.url}
        className="margin-right-m"
      >
        <Logo title="Home" />
      </Link>

      <button
        className="navbar-toggler ml-auto"
        type="button"
        onClick={() => setShowCollapsedMenu(!showCollapsedMenu)}
      >
        <EmblaIcon iconName="menu" />
      </button>

      <div className={classNames("navbar-collapse", "collapse", "mr-3", "mr-md-0", showCollapsedMenu && "show")}>
        <div className="mr-auto" />
        {user.authenticated && (
          <ul className={classNames("navbar-nav")}>
            {config.environment !== Enviroment.PROD &&
             <li className={classNames("nav-item padding-right-m", styles.enviromentBadge)}>
               <EnviromentBadge/>
             </li>
            }
            <li className="nav-item mr-3">
              <a
                href={KNOWLEDGE_BASE_URL}
                target="_blank"
                rel="noreferrer"
                className="btn-icon bg-dark nav-link ml-0"
              >
                <EmblaIcon iconName="question" />
              </a>
            </li>
            <Tooltip
              message={!electronInstallerPath && localizer.platformNotSupported()}
              placement="bottom"
            >
              <li className="nav-item">
                <DownloadButton
                  downloadUrl={electronInstallerPath}
                  className={classNames("btn-icon", "bg-dark", "nav-link", "ml-0", styles.downloadBtn)}
                >
                  <EmblaIcon iconName="download" additionalClasses="m-0" />
                  <span className="margin-left-s d-md-none">{localizer.downloadApp()}</span>
                </DownloadButton>
              </li>
            </Tooltip>

            {user.isAdmin() &&
              <NavItemDropdown>
                <NavItemDropdown.Button isIcon buttonStyling={"bg-dark"}>
                  <EmblaIcon iconName="settings" />
                  <span className="margin-left-s d-md-none">{localizer.settings()}</span>
                </NavItemDropdown.Button>
                <NavItemDropdown.Items>
                  {user.hasAccessRight(RoutePaths.employees.accessRight) &&
                    <NavItemDropdown.LinkItem to={RoutePaths.employees.url}>
                      <EmblaIcon iconName="user-group" />{" " + localizer.userAdministration()}
                    </NavItemDropdown.LinkItem>
                  }
                  {user.hasAccessRight(RoutePaths.emailTemplates.accessRight) &&
                    <NavItemDropdown.LinkItem to={RoutePaths.emailTemplates.url}>
                      <EmblaIcon iconName="email-open" />{" " + localizer.emailTemplate()}
                    </NavItemDropdown.LinkItem>
                  }
                </NavItemDropdown.Items>
              </NavItemDropdown>
            }

            <NavItemDropdown>
              <NavItemDropdown.Button buttonStyling={classNames(user.isImpersonate() ? styles.impersonateButtonMode : "", "bg-dark")}>
                <div className={classNames("d-flex align-items-center")}>
                  <div className={classNames("d-flex", styles.profilePicture)} >
                    {user.impersonate ?
                      <ImersonateIcon/>
                      : (!profilePictureUrl
                        ? <EmblaIcon iconName="user" />
                        : <img alt={localizer.profileImage()} src={profilePictureUrl} />
                      )
                    }
                  </div>

                  <div className={classNames("margin-left-s", styles.usernameBadge, styles.ellipsisContainer)} title={user.name}>{user.name}</div>
                  <EmblaIcon iconName="arrow-down" />
                </div>
              </NavItemDropdown.Button>
              <NavItemDropdown.Items>
                <NavItemDropdown.LinkItem to={RoutePaths.profile.path}>
                  {localizer.profile()}
                </NavItemDropdown.LinkItem>
                <NavItemDropdown.ExpandingItem
                  buttonText={localizer.language()}
                  subtext={isEnglish ? (localizer.english()) : (localizer.danish())}
                >
                  <NavItemDropdown.ButtonItem onClick={() => dispatch(setLanguage(isEnglish ? Language.Danish : Language.English))}>
                    {isEnglish ? localizer.danish() : localizer.english()}
                  </NavItemDropdown.ButtonItem>
                </NavItemDropdown.ExpandingItem>
                <NavItemDropdown.ItemDivider />
                <NavItemDropdown.ButtonItem onClick={() => signOut()}>
                  {localizer.logout()}
                </NavItemDropdown.ButtonItem>
                {(user.isImpersonate()) &&
                  <NavItemDropdown.ButtonItem onClick={() => stopImpersonation()}>
                    {localizer.stopImpersonate()}
                  </NavItemDropdown.ButtonItem>
                }
              </NavItemDropdown.Items>
            </NavItemDropdown>
          </ul>
        )}
      </div>
    </nav>
  );
};

export default TopMenu;
