import * as yup from "yup";
import { createValidationSchema, testValueArray, testValueShape } from "@components/validation/createValidationSchema";
import { Localizer } from "@components/localization/localizer";
import { CaseCreateModel } from "@services/api/case/models/caseModel";
import { presentationValidation } from "@components/validation/sharedValidation/presentationValidation";
import { CaseClientModel } from "@services/api/case/models/caseClientModel";
import { LengthValidations } from "src/utility/constants";

export const createCaseValidation = (localizer: Localizer) => createValidationSchema<CaseCreateModel>({
  id: yup.string(),
  title: yup.string().required(localizer.titleRequired()).max(LengthValidations.Length150, localizer.maxLength150()),
  client: testValueShape<CaseClientModel>({
    id: yup.string(),
    name: yup.string().max(LengthValidations.Length150, localizer.maxLength150()),
  }).required(localizer.clientRequired()).nullable(),
  description: yup.string().max(LengthValidations.Length2000, localizer.maxLength2000),
  courtCaseNumber: yup.string().max(LengthValidations.Length40, localizer.maxLength40()),
  internalCaseNumber: yup.string().max(LengthValidations.Length40, localizer.maxLength40()),
  presentations: yup.array().of(presentationValidation(localizer)),
  counterparts: testValueArray(yup.string().max(LengthValidations.Length150, localizer.maxLength150())),
  selectedParticipants: yup.array(),
});

