import { NotificationModule } from "ditmer-embla";
import ReactDOM from "react-dom";
import classNames from "classnames";
import FormInput from "@components/forms/FormInput";
import FormSubmitButton from "@components/forms/FormSubmitButton";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import { useCreateExtractDraftMutation } from "@services/api/extractDraft/extractDraftApi";
import Modal from "@components/modal/modal";
import { CreateCaseExtractDraftModel } from "@services/api/extractDraft/models/createCaseExtractDraftModel";
import { ExtractDraftLanguage } from "@services/api/extractDraft/models/extractDraftLanguage";
import FormDropdown from "@components/forms/FormDropdown";
import { extractDraftValidation } from "./extractDraftValidation";
import styles from "./createExtractDraft.module.scss";

type CreateExtractDraftProps = {
  caseId: string;
  onSuccess: (createdExtractDraftId: string | undefined) => void;
  setIsInProgressOfCreatingExtractDraft: (isInProgressOfCreatingExtractDraft: boolean) => void;
  actionsContainer?: Element | null;
};

const initialValues: CreateCaseExtractDraftModel = {
  title: "",
  language: ExtractDraftLanguage.Danish,
};

export const CreateExtractDraft = ({
  caseId,
  onSuccess,
  setIsInProgressOfCreatingExtractDraft,
  actionsContainer,
}: CreateExtractDraftProps) => {

  const localizer = useLocalization();
  const [ createExtractDraft, createExtractDraftRequest ] = useCreateExtractDraftMutation();

  const methods = useValidatedForm({
    validationSchema: extractDraftValidation(localizer),
    defaultValues: initialValues,
  });

  const submitExtractDraft = async (model: CreateCaseExtractDraftModel) => {
    setIsInProgressOfCreatingExtractDraft(true);
    const extractDraftId = await createExtractDraft({ caseId, model }).unwrap();

    onSuccess(extractDraftId);
    setIsInProgressOfCreatingExtractDraft(false);
    NotificationModule.showSuccessSmall(localizer.extractDraftWasCreated());
  };

  return (
    <div className={styles.createExtractDraftContainer}>
      <div className="subtle">{localizer.createExtractDraftHint1()}</div>
      <div className="subtle">{localizer.createExtractDraftHint2()}</div>
      <form onSubmit={methods.handleSubmit(submitExtractDraft)}>
        <div className={classNames(styles.formRow, "row")}>
          <div className="col-lg-8">
            <FormInput
              methods={methods}
              id={"extract-draft-title"}
              name="title"
              label={localizer.title()}
              placeholder={localizer.extractDraftTitlePlaceholder()}
              required
            />
          </div>
          <div className="col-lg-4">
            <FormDropdown
              methods={methods}
              id="extract-draft-language"
              name="language"
              label={localizer.language()}
              options={[{ label: localizer.danish(), value: ExtractDraftLanguage.Danish }, { label: localizer.english(), value: ExtractDraftLanguage.English }]}
              valueToOptionTransform={(value: ExtractDraftLanguage) => value ? { label: value === ExtractDraftLanguage.Danish ? localizer.danish() : localizer.english(), value: value } : null}
              required
              isClearable={false}
            />
          </div>
        </div>
      </form>
      {actionsContainer && ReactDOM.createPortal((
        <Modal.Footer>
          <FormSubmitButton
            state={{
              isSubmitting: createExtractDraftRequest.isLoading,
              isSubmitSuccessful: createExtractDraftRequest.isSuccess,
            }}
            isPrimary
            onClick={methods.handleSubmit(submitExtractDraft)}
          >
            {localizer.create()}
          </FormSubmitButton>
        </Modal.Footer>
      ), actionsContainer)}
    </div>
  );
};
