import { UserModel } from "@services/api/sharedModels/userModel";
import { MarkingModel, ReplyCreateModel, ReplyEditModel } from "./markingModel";

export enum MarkingEventType {
  Create = 1,
  Edit = 2,
  Delete = 3,
  CreateReply = 4,
  EditReply = 5,
  DeleteReply = 6,
}

interface MarkingEventBase {
  id: string;
  eventType: MarkingEventType;
  documentId: string;
}

export interface CreateMarkingEvent extends MarkingEventBase {
  eventType: MarkingEventType.Create;
  marking: MarkingModel<false>;
  eventCreator: UserModel;
  eventCreationDate: string;
  markingRefId?: string;
}

export interface EditMarkingEvent extends MarkingEventBase {
  eventType: MarkingEventType.Edit;
  marking: MarkingModel<false>;
  markingRefId: string;
}

export interface DeleteMarkingEvent extends MarkingEventBase {
  eventType: MarkingEventType.Delete;
  marking: MarkingModel<false>;
  markingRefId: string;
}

export interface CreateReplyEvent extends MarkingEventBase {
  eventType: MarkingEventType.CreateReply;
  marking: ReplyCreateModel;
  markingRefId: string;
  eventCreator: UserModel;
}

export interface EditReplyEvent extends MarkingEventBase {
  eventType: MarkingEventType.EditReply;
  marking: ReplyEditModel;
  markingRefId: string;
}

export interface DeleteReplyEvent extends MarkingEventBase {
  eventType: MarkingEventType.DeleteReply;
  markingRefId: string;
}


export interface EventMemo {
  originalEvent: MarkingEvent;
  undoEvent: MarkingEvent;
  pageIndex: number;
}

export interface EventHistory {
  eventList: EventMemo[];
  currentEventIndex: number;
}

export interface DocumentMarkingEventResultModel {
  id: string;
  success: boolean;
}

export type MarkingEvent = CreateMarkingEvent | EditMarkingEvent | DeleteMarkingEvent | CreateReplyEvent | EditReplyEvent;
