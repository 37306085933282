
import classNames from "classnames";
import FormDropdown from "@components/forms/FormDropdown";
import FormInput from "@components/forms/FormInput";
import FormDatePicker from "@components/forms/formDatePicker";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import { useEditDocumentMutation } from "@services/api/document/caseDocumentApi";
import { EditCaseDocumentModel } from "@services/api/document/models/editCaseDocumentModel";
import useAutosave from "src/hooks/useAutosave";
import {
  CaseElementTypeEnum, convertCaseElementTypeToString,
  getCaseDocumentTypes,
  getCaseElementTypeDropdownOptions,
} from "@services/api/case/models/caseElementTypeEnum";
import { editCaseDocumentValidation } from "@pages/case/documents/editCaseDocumentValidation";
import {
  convertDateTypeToString,
  DateTypeEnum,
  getDateTypeDropdownOptions,
  getDateTypeTypes,
} from "@services/api/case/models/dateTypeEnum";
import styles from "./editUploadedDocument.module.scss";

export interface UploadedDocumentEditRowProps {
  caseId: string;
  documentfileName: string;
  documentType: CaseElementTypeEnum;
  dateType: DateTypeEnum;
  documentDate?: string;
  caseDocumentId: string;
  caseDocumentNumber?: string;
  index: number;
  multiline?: boolean;
}

export const EditUploadedDocument = ({ caseId, documentfileName, documentType, documentDate, caseDocumentId, index, multiline = false, caseDocumentNumber, dateType }: UploadedDocumentEditRowProps) => {

  const localizer = useLocalization();
  const [editDocument, editDocumentRequest] = useEditDocumentMutation();

  const defaultValues: EditCaseDocumentModel = {
    documentName: documentfileName,
    type: documentType,
    date: documentDate,
    caseDocumentNumber: caseDocumentNumber,
    dateType: dateType,
  };

  const methods = useValidatedForm({
    validationSchema: editCaseDocumentValidation(localizer),
    defaultValues,
  });

  const onSubmit = () => {
    const onSubmitCaseData = async (model: EditCaseDocumentModel) => {
      await editDocument({ caseId: caseId, documentId: caseDocumentId, model: model }).unwrap();
    };

    methods.handleSubmit((model) => onSubmitCaseData(model))();
  };

  const { getFieldId } = useAutosave({
    queryStatus: editDocumentRequest.status,
    fieldIdPrefix: `document-edit-form-${index}`,
    watch: methods.watch,
    onSubmit,
  });

  return (
    <form className={classNames(!multiline && `${styles.row} d-flex align-items-baseline gap-s justify-content-center`)}>
      <FormInput
        methods={methods}
        id={getFieldId("documentName")}
        name="documentName"
        label={multiline ? localizer.title() : undefined}
        formGroupClassName={`${styles.formGroup} ${styles.formGroupEditDocumentMinWidth}`}
        required
      />
      <FormInput
        methods={methods}
        id={getFieldId("caseDocumentNumber")}
        name="caseDocumentNumber"
        label={multiline ? localizer.annex() : undefined}
        formGroupClassName={`${styles.formGroup} ${styles.formGroupEditDocumentMinWidth}`}
        placeholder={localizer.annexPlaceholder()}
      />
      <FormDropdown
        methods={methods}
        id={getFieldId("type")}
        name="type"
        options={getCaseElementTypeDropdownOptions(localizer, getCaseDocumentTypes())}
        noOptionsMessage={() => localizer.typeNameOfCounterpartToAdd()}
        placeholder={localizer.counterpartsPlaceholder()}
        valueToOptionTransform={(value: CaseElementTypeEnum) => value ? { label: convertCaseElementTypeToString(value, localizer), value: value } : null}
        label={multiline ? localizer.type() : undefined}
        formGroupClassName={`${styles.formGroup} ${styles.formGroupEditDocumentMinWidth}`}
        required
      />
      {multiline &&
        <FormDropdown
          methods={methods}
          id={getFieldId("dateType")}
          name="dateType"
          options={getDateTypeDropdownOptions(localizer, getDateTypeTypes())}
          valueToOptionTransform={(value: DateTypeEnum) => value ? { label: convertDateTypeToString(value, localizer), value: value } : null}
          label={localizer.dateType()}
          formGroupClassName={`${styles.formGroup} ${styles.formGroupEditDocumentMinWidth}`}
          required
        />
      }
      <FormDatePicker
        methods={methods}
        id={getFieldId("date")}
        name="date"
        label={multiline ? localizer.date() : undefined}
        formGroupClassName={`${styles.formGroup} ${styles.formGroupEditDocumentMinWidth}`}
        placement={"topLeft"}
      />
    </form>

  );
};

