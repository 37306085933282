import classNames from "classnames";
import { ChangeEvent, useRef, useMemo, useState } from "react";
import { CSSObjectWithLabel, SingleValue } from "react-select";
import { useDrop } from "react-dnd";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import Card from "@components/embla/card";
import { Dropdown } from "@components/dropdown/dropdown";
import { useGetFilteredDocumentsQuery } from "@services/api/document/caseDocumentApi";
import Button from "@components/embla/button";
import InlineSwitch from "@components/checkbox/inlineSwitch";
import { ReorderExtractDraftSectionDocument } from "@pages/extractCompositionPage/hooks/useDragDropSectionDocumentReorder";
import { useDeleteDocumentFromSectionExtractDraftMutation } from "@services/api/extractDraft/extractDraftApi";
import { useIsDndObjectOver } from "@pages/extractCompositionPage/hooks/useIsDndObjectOver";
import { useExtractCompositionContext } from "@pages/extractCompositionPage/extractCompositionContextProvider";
import { ExtractDraftModel } from "@services/api/extractDraft/models/extractDraftModel";
import { Tooltip } from "@components/tooltip/tooltip";
import { useIsKeyDown } from "src/hooks/useIsKeyDown";
import { dndItemTypes } from "../ExtractSections/DndItemTypes";
import styles from "./extractContentSidebar.module.scss";
import { Document } from "./document/Document";
import { AddDocumentsModal } from "./addDocumentsModal/addDocumentsModal";

export type ExtractContentSidebarProps = {
  additionalClasses?: string;
  caseId: string;
  setOnlySelectVerticalLines: (value: boolean) => void;
  onlySelectVerticalLines: boolean;
  extractDraft: ExtractDraftModel;
};


export const ExtractContentSidebar = ({ additionalClasses, caseId, onlySelectVerticalLines, setOnlySelectVerticalLines, extractDraft }: ExtractContentSidebarProps) => {
  const localizer = useLocalization();
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState({ label: localizer.all(), value: 5 });

  const [lastSelectedIndex, setLastSelectedIndex] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  const { data: documents, refetch } = useGetFilteredDocumentsQuery({ caseId, filterType: selectedDocumentType.value, containsVerticalLines: onlySelectVerticalLines });
  const [ removeDocument ] = useDeleteDocumentFromSectionExtractDraftMutation();

  const { drop: documentHover, isOver : isDocumentOver } = useIsDndObjectOver({ type: dndItemTypes.document });

  const { selectedDocumentsReducer } = useExtractCompositionContext();

  const unusedDocuments = useMemo(() => {
    if (!documents) {
      return [];
    }
    const usedDocuments = extractDraft.sections.flatMap((section) => section.documents.map((doc) => doc.caseDocumentId));
    if (extractDraft.frontpage?.caseDocumentId) {
      usedDocuments.push(extractDraft.frontpage.caseDocumentId);
    }
    return documents.filter((doc) => !usedDocuments.includes(doc.id));
  }, [extractDraft, documents]);
  
  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const documentFilterTypeOptions = [
    { label: localizer.extract(), value: 1 },
    { label: localizer.helpAppendix(), value: 2 },
    { label: localizer.materialCollection(), value: 3 },
    { label: localizer.other(), value: 4 },
    { label: localizer.all(), value: 5 },
  ] as const;

  const handleDropdownChange = (value: SingleValue< { label: string, value: number }>) => {
    setSelectedDocumentType(value as { label: string, value: number });
  };

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOnlySelectVerticalLines(e.target.checked);
    selectedDocumentsReducer.dispatch({ selectedDocuments: [] });
  };
  const customDropdownStyles = {
    control: (provided: CSSObjectWithLabel) => ({
      ...provided,
      borderRadius: "20px",
    }),
  };

  const [, dropDocument] = useDrop({
    accept: dndItemTypes.document,
    drop: (document: ReorderExtractDraftSectionDocument) => {
      if (!document.sectionId) {
        return;
      }
      removeDocument({
        documentId: document.document.id,
        sectionId: document.sectionId,
        caseId,
        extractDraftId: extractDraft.id,
      });
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
      };
    },
  });

  const { isKeyDown: isControlDown } = useIsKeyDown("Control");
  const { isKeyDown: isShiftDown } = useIsKeyDown("Shift");

  documentHover(dropDocument(containerRef));
  
  return (
    <>
      <Card
        className={classNames(
          styles.sidebarWrapper,
          additionalClasses,
        )}>
        <div className="d-flex flex-column justify-content-between h-100">
          <div className="card-header d-flex justify-content-between flex-column pb-0">
            <div className="d-flex align-items-center justify-content-between w-100 pb-1">
              <h4 className="card-title margin-right-m">{localizer.documents()}</h4>
              <Dropdown options={documentFilterTypeOptions} minWidth={"110px"} onChange={handleDropdownChange} value={selectedDocumentType} stylesOverride={customDropdownStyles}/>
            </div>
            <div className="d-flex justify-content-between w-100 align-items-center pb-2 pt-1">
              <div className="d-flex">
                <InlineSwitch onChange={handleSwitchChange} checked={onlySelectVerticalLines} />
                <p className="m-0 ml-2">{localizer.onlyIncludeVerticalLines()}</p>
              </div>
              <Tooltip message={localizer.verticalLinesInfoButton()}>
                <span>
                  <EmblaIcon iconName="info"/>
                </span>
              </Tooltip>
            </div>
          </div>
          <div ref={containerRef} className={classNames(
            styles.sidebarScroll,
            "d-flex flex-column align-items-start",
            {
              [styles.documentHover]: isDocumentOver,
            },
          )}>
            {
              unusedDocuments?.map((doc, index) =>
                <Document
                  allUnusedDocuments={unusedDocuments}
                  key={doc.id}
                  document={doc}
                  index={index}
                  setLastSelectedIndex={setLastSelectedIndex}
                  isCtrlPressed={isControlDown}
                  isShiftPressed={isShiftDown}
                  lastSelectedIndex={lastSelectedIndex}
                />)
            }
          </div>
          <div className={styles.addDocumentsButtonContainer}>
            <Button className="fw-bold" onClick={toggleModal} inverted>
              <EmblaIcon iconName="plus"/>
              {localizer.addDocuments()}
            </Button>
          </div>
        </div>
      </Card>
      <AddDocumentsModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} caseId={caseId} onFinish={refetch}/>
    </>
  );
};


