import classNames from "classnames";
import { useState } from "react";
import { NotificationModule } from "ditmer-embla";
import Modal from "@components/modal/modal";
import { useLocalization } from "@components/localization/localizationProvider";
import { AddSectionForm } from "@pages/extractCompositionPage/components/contentSidebar/addSection/addSectionForm";

export type AddSectionModalProps = {
  caseId: string;
  extractId: string;
  showModal: boolean;
  onCloseCallback?: () => void;
  onSuccessCallback?: () => void;
};

export const ExtractCompositionAddSectionModal = ({ caseId, extractId, showModal, onCloseCallback, onSuccessCallback }: AddSectionModalProps) => {

  const localizer = useLocalization();
  const [footerElement, setFooterElement] = useState<Element | null>();

  const onSuccess = () => {
    onSuccessCallback?.();

    NotificationModule.showSuccessSmall(localizer.sectionwascreated());
  };


  return (
    <Modal
      open={showModal}
      onClose={() => onCloseCallback?.()}
    >

      <Modal.Header>
        <div> {localizer.section()} </div>
      </Modal.Header>

      <Modal.Body>
      
        <AddSectionForm caseId={caseId} extractId={extractId} onSuccess={onSuccess} actionsContainer={footerElement ?? undefined}/>

      </Modal.Body>

      <Modal.Footer>
        <div ref={setFooterElement}>

        </div>
      </Modal.Footer>


    </Modal>
  );
};
