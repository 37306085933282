import { v4 as uuid } from "uuid";
import { PdfViewerState } from "@pages/pdfviewer/component/pdfViewerSlice";
import { CreateMarkingEvent, DeleteMarkingEvent, EditMarkingEvent, MarkingEvent, MarkingEventType } from "@pages/pdfviewer/component/models/markingEvent";
import { UndoRedoToolType } from "@pages/pdfviewer/component/models/pdfTool";
import { MarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import { MarkingsStateModel } from "../markingsApi";

export const removeMarkingFromEventHistory = (state: PdfViewerState, markingId: string) => {
  if (state.sessionEventHistory.eventList.length > 0) {
    const markingEventsWithoutIncomingMarkingId = state.sessionEventHistory.eventList
      .filter((t) => (t.originalEvent.eventType === MarkingEventType.Create && t.originalEvent.id !== markingId)
      || (t.originalEvent.eventType === MarkingEventType.Edit && (t.originalEvent as EditMarkingEvent).markingRefId !== markingId));

    // an eventindex of -1 means we are at the start of the eventlist and thus do not need to update the index after filtering the eventlist
    if (state.sessionEventHistory.currentEventIndex !== -1) {
      const currentlyPointedEvent = state.sessionEventHistory.eventList[state.sessionEventHistory.currentEventIndex];

      // if we can't find the index of the incoming event, that means we our currentlyPointedEvent is the one we are removing
      const currentIndex = markingEventsWithoutIncomingMarkingId.findIndex((t) => t === currentlyPointedEvent);
      const currentlyPointedEventIndex = currentIndex === -1 ? markingEventsWithoutIncomingMarkingId.length - 1 : currentIndex;

      if (currentlyPointedEventIndex !== -1) {
        state.sessionEventHistory.currentEventIndex = currentlyPointedEventIndex;
      } else {
        const indexesOfMarkingEventsBeforeCurrentIndex = markingEventsWithoutIncomingMarkingId.reduce((a: number[], e, i) => {
          const index = state.sessionEventHistory.eventList.findIndex((t) => t === e);
          if (index !== -1 && index < state.sessionEventHistory.currentEventIndex) {
            a.push(index);
          }
          return a;
        }, []);

        const newIndex = indexesOfMarkingEventsBeforeCurrentIndex.length === 0 ? -1 : state.sessionEventHistory.currentEventIndex - indexesOfMarkingEventsBeforeCurrentIndex.length;

        state.sessionEventHistory.currentEventIndex = newIndex;
      }
    }

    state.sessionEventHistory.eventList = markingEventsWithoutIncomingMarkingId;
  }

};

export const updateEventHistory = (state: PdfViewerState, currentMarkingsData: MarkingsStateModel,  newEvent: MarkingEvent, isUndoRedo?: UndoRedoToolType) => {

  if (isUndoRedo) {
    if (isUndoRedo === UndoRedoToolType.Redo) {

      state.sessionEventHistory.currentEventIndex = state.sessionEventHistory.currentEventIndex + 1;

      //when undoing or redoing create/delete we must update the memos because of the different markingIds
      if (newEvent.eventType === MarkingEventType.Create) {

        // we are leveraging the fact that eventid will be the same as the markingId for create
        const oldMarkingId = state.sessionEventHistory.eventList[state.sessionEventHistory.currentEventIndex].originalEvent.id;

        resetIdsOnEditEvents(state, oldMarkingId, newEvent.id);

        resetIdOnDeleteEvent(state, oldMarkingId, newEvent.id);

        resetIdOnCreateEvent(state, oldMarkingId, newEvent);
      } else if (newEvent.eventType === MarkingEventType.Delete) {

        const repliesToSave = currentMarkingsData[newEvent.marking.page].find((t) => t.id === newEvent.markingRefId);

        const deleteEventToChange = state.sessionEventHistory.eventList
          .find((t) => t.originalEvent.eventType === MarkingEventType.Delete && (t.originalEvent as DeleteMarkingEvent).markingRefId === newEvent.markingRefId);

        if (deleteEventToChange) {
          deleteEventToChange.undoEvent.marking.replies = repliesToSave?.replies;
        }
      }
    } else if (isUndoRedo === UndoRedoToolType.Undo) {

      state.sessionEventHistory.currentEventIndex = state.sessionEventHistory.currentEventIndex - 1;

      if (newEvent.eventType === MarkingEventType.Create) {

        const oldMarkingId = state.sessionEventHistory.eventList[state.sessionEventHistory.currentEventIndex + 1].undoEvent.marking.id;

        resetIdsOnEditEvents(state, oldMarkingId, newEvent.id);

        resetIdOnDeleteEvent(state, oldMarkingId, newEvent.id);

        resetIdOnCreateEvent(state, oldMarkingId, newEvent);
      } else if (newEvent.eventType === MarkingEventType.Delete) {

        const repliesToSave = currentMarkingsData[newEvent.marking.page].find((t) => t.id === newEvent.markingRefId);

        const createEventToChange = state.sessionEventHistory.eventList
          .find((t) => t.originalEvent.eventType === MarkingEventType.Create && (t.originalEvent as CreateMarkingEvent).id === newEvent.markingRefId);

        if (createEventToChange) {
          createEventToChange.originalEvent.marking.replies = repliesToSave?.replies;
          createEventToChange.originalEvent.markingRefId = newEvent.markingRefId;
        }

      }

    } else {
      throw Error("unknown UndoRedoToolType");
    }
  } else {

    let undoEvent!: MarkingEvent;

    if (newEvent.eventType === MarkingEventType.Create) {
      undoEvent = { documentId: newEvent.documentId, id: uuid(), marking: newEvent.marking, markingRefId: newEvent.id, eventType: MarkingEventType.Delete };
    } else if (newEvent.eventType === MarkingEventType.Edit) {
      const incomingEvent: EditMarkingEvent = newEvent;

      const currentMarkingState = currentMarkingsData[newEvent.marking.page].find((t) => t.id === incomingEvent.markingRefId);

      undoEvent = { documentId: newEvent.documentId, id: uuid(), markingRefId: newEvent.markingRefId, eventType: MarkingEventType.Edit, marking: currentMarkingState as MarkingModel };
    } else if (newEvent.eventType === MarkingEventType.Delete) {

      const currentMarkingState = currentMarkingsData[newEvent.marking.page].find((t) => t.id === newEvent.markingRefId);
      if (currentMarkingState === undefined) {
        throw new Error("currentMarkingState is undefined");
      }

      undoEvent = { documentId: newEvent.documentId, id: uuid(), marking: currentMarkingState as MarkingModel, eventType: MarkingEventType.Create,
        eventCreationDate: currentMarkingState.creationDate, eventCreator: newEvent.marking.owner, markingRefId: newEvent.markingRefId };
    }

    if (newEvent.eventType === MarkingEventType.Create || newEvent.eventType === MarkingEventType.Edit || newEvent.eventType === MarkingEventType.Delete) {
      if (state.sessionEventHistory.eventList.length === state.sessionEventHistory.currentEventIndex + 1) {
        state.sessionEventHistory.eventList.push({ originalEvent: newEvent, undoEvent: undoEvent, pageIndex: newEvent.marking.page });
      } else {
        state.sessionEventHistory.eventList = [...state.sessionEventHistory.eventList.slice(0, state.sessionEventHistory.currentEventIndex + 1), { originalEvent: newEvent, undoEvent: undoEvent, pageIndex: newEvent.marking.page }];
      }
  
      state.sessionEventHistory.currentEventIndex = state.sessionEventHistory.currentEventIndex + 1;
    }

  }
};

const resetIdOnDeleteEvent = (state: PdfViewerState, oldMarkingId: string, newEventId: string) => {
  const deleteEventsToChangeOriginalEvent = state.sessionEventHistory.eventList
    .find((t) => t.originalEvent.eventType === MarkingEventType.Delete && (t.originalEvent as DeleteMarkingEvent).markingRefId === oldMarkingId);

  if (deleteEventsToChangeOriginalEvent) {
    (deleteEventsToChangeOriginalEvent.originalEvent as DeleteMarkingEvent).markingRefId = newEventId;
    (deleteEventsToChangeOriginalEvent.originalEvent as DeleteMarkingEvent).marking.id = newEventId;
    deleteEventsToChangeOriginalEvent.undoEvent.id = newEventId;
    deleteEventsToChangeOriginalEvent.undoEvent.marking.id = newEventId;
    deleteEventsToChangeOriginalEvent.undoEvent.markingRefId = newEventId;
  }
};

const resetIdsOnEditEvents = (state: PdfViewerState, oldMarkingId: string, newEventId: string) => {
  const editEventsToChange = state.sessionEventHistory.eventList
    .filter((t) => t.originalEvent.eventType === MarkingEventType.Edit)
    .filter((t) => (t.originalEvent as EditMarkingEvent).markingRefId === oldMarkingId);

  editEventsToChange.forEach((elem) => {
    (elem.undoEvent as EditMarkingEvent).markingRefId = newEventId;
    (elem.undoEvent as EditMarkingEvent).marking.id = newEventId;
    (elem.originalEvent as EditMarkingEvent).markingRefId = newEventId;
    (elem.originalEvent as EditMarkingEvent).markingRefId = newEventId;
  });
};

const resetIdOnCreateEvent = (state: PdfViewerState, oldMarkingId: string, newEvent: CreateMarkingEvent) => {
  const createEventToChange = state.sessionEventHistory.eventList
    .find((t) => t.originalEvent.eventType === MarkingEventType.Create && (t.originalEvent as CreateMarkingEvent).id === oldMarkingId);

  if (createEventToChange) {
    (createEventToChange.undoEvent as DeleteMarkingEvent).markingRefId = newEvent.id;
    createEventToChange.undoEvent.marking.id = newEvent.id;
    createEventToChange.originalEvent.id = newEvent.id;
    createEventToChange.originalEvent.marking.id = newEvent.id;
    createEventToChange.originalEvent.markingRefId = newEvent.id;
  }
};
