import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CSSObjectWithLabel } from "react-select";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { useLocalization } from "@components/localization/localizationProvider";
import { RoutePaths } from "@components/routing/routes";
import Toolbar from "@components/toolbar/toolbar";
import { useAppSelector } from "@hooks";
import { documentTabId } from "@pages/case/casePage";
import { pdfViewerStateSelector } from "@pages/pdfviewer/component/pdfViewerSlice";
import { ElementSize } from "src/hooks/useElementSize";
import MarkingOwnerFilterDropdown from "../markingFilter/markingOwnerFilterDropdown";
import MarkingTypeFilterDropdown from "../markingFilter/markingTypeFilterDropdown";
import SearchPopover from "../searchPopover/searchPopover";
import Toolbox from "../toolbox";
import DocumentStatus from "./documentStatus";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

interface ToolBarProps {
  pdf?: DocumentCallback;
  title: string;
  pdfViewSize: ElementSize;
  subTitle: string;
  visiblePageIndexes: number[];
  runJoyrideCallback: () => void;
}

const DocumentToolbar = (props: ToolBarProps) => {
  const pdfViewerState = useAppSelector(pdfViewerStateSelector);
  const { pathname: currentPath } = useLocation();
  const { seqId, documentId, caseId } = useParams();
  const localizer = useLocalization();
  const navigate = useNavigate();

  const query = useQuery();

  return (
    <Toolbar
      title={props.title}
      titleAddon={documentId && <DocumentStatus documentId={documentId} />}
      backAction={() => {
        const backLocation = query.get("back");
        
        if (backLocation === "hbb" && caseId) {
          const id = query.get("id");
          if (id) {
            return RoutePaths.caseExtract.url(caseId, id);
          }
        }

        if (backLocation === "-1") {
          navigate(-1);
          return "skip-back-navigation";
        }

        // Redirect after deeplink
        if (seqId && caseId && documentId) {
          if (currentPath === RoutePaths.casePresentInDocument.url(caseId, documentId, seqId)) {
            return RoutePaths.caseDetails.url(caseId, documentTabId);
          }
        }

        //Navigate to documentList
        if (pdfViewerState.caseId) {
          return RoutePaths.caseDetails.url(pdfViewerState.caseId, documentTabId);
        }

        return "skip-back-navigation";
      }}
      centerItems={<Toolbox className="align-self-center "/>}
    >
      {/* breaks content into two rows but still allows flex to break more if content does not fit  */}
      {/* <div className="d-md-none w-100" /> */}
      <div className="d-flex gap-8">
        <Toolbar.Item additionalClasses="p-0" id="pdfMarkingTypes" label={localizer.pdfMarkingTypes()}>
          <MarkingTypeFilterDropdown stylesOverride={{ control: controlStyle }} />
        </Toolbar.Item>
        <Toolbar.Item additionalClasses="p-0" id="pdfCreatedbyFilterTitle" label={localizer.pdfCreatedByFilterTitle()}>
          <MarkingOwnerFilterDropdown stylesOverride={{ control: controlStyle }} />
        </Toolbar.Item>
        <Toolbar.Item additionalClasses="p-0">
          <SearchPopover
            pdf={props.pdf}
          />
        </Toolbar.Item>
      </div>
    </Toolbar>
  );
};

const controlStyle = (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
  ...provided,
  borderColor: "var(--toolbar-control-border-color)",
  color: "var(--toolbar-control-text-color)",
  borderRadius: "30px",
  border: "1px solid rgb(179, 179, 179)",
});
 
export default DocumentToolbar;
