import classNames from "classnames";
import { FieldValues } from "react-hook-form";
import { FormControlProps } from "@components/forms/FormControlProps.model";
import styles from "../radioGroup.module.scss";
import ColorSelection from "../ColorSelection/ColorSelection";

interface ColorSelectionModalProps {
  colors: string[];
  onOpen: () => void;
  isOpen: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ColorSelectionModal = <T extends FieldValues = any>({
  id,
  formGroupClassName,
  methods,
  colors,
  name,
  onOpen,
  isOpen,
}: FormControlProps<T, ColorSelectionModalProps>) => {
  return (
    < >
      <div
        role="button"
        onClick={onOpen}
        className={classNames(styles.radio, "rounded-circle", styles.borderDark, "p-1")}
        style={{ backgroundColor: methods.getValues(name) }}
      />

      {
        isOpen && (
          <ColorSelection
            top={45}
            colors={colors}
            methods={methods}
            id={id}
            name={name}
          />
        )
      }
    </>
  );
};

export default ColorSelectionModal;
