import { useState } from "react";
import { CheckboxSwitch } from "@components/checkbox/checkboxSwitch";
import { Dropdown } from "@components/dropdown/dropdown";
import { useLocalization } from "@components/localization/localizationProvider";
import { TableFilterRow } from "@components/table/components/TableFilterRow";
import { PaginatedListInput } from "@models/paginatedList/paginatedListInput";
import { CasePaginatedListInput } from "@services/api/case/models/casePaginatedListInput";
import { useGetClientsQuery } from "@services/api/client/clientApi";
import "./caseList.scss";
import { CaseTable } from "./caseTable";

const initialCasePaginatedListInput: CasePaginatedListInput = {
  draw: 0,
  start: 0,
  length: 20,
  search: {
    value: "",
    regex: false,
  },
  order: [],
  isActive: true,
};

export const CaseList: React.FC = () => {
  const [activeCasesFilterInput, setActiveCasesFilterInput] = useState<CasePaginatedListInput>({ ...initialCasePaginatedListInput, isActive: true });
  const [closedCasesFilterInput, setClosedCasesFilterInput] = useState<CasePaginatedListInput>({ ...initialCasePaginatedListInput, isActive: false });
  const { data: clients, isLoading: clientsIsLoading } = useGetClientsQuery();
  const [showClosedCases, setShowClosedCases] = useState<boolean>(false);
  const localizer = useLocalization();

  const setFilterInputs = (action: React.SetStateAction<CasePaginatedListInput>) => {
    setActiveCasesFilterInput(action);
    setClosedCasesFilterInput(action);
  };

  // Handlers

  const handleSearchChanged = (search: string) => {
    setFilterInputs((prev) => ({ ...prev, search: { value: search, regex: false }, start: 0 }));
  };

  const handleClientChanged = (selectedClientId: string | undefined) => {
    setFilterInputs((prev) => ({ ...prev, clientId: selectedClientId, start: 0 }));
  };

  const handleToggleShowClosed = () => {
    setShowClosedCases((prev) => !prev);
  };

  return (
    <>
      <div className="case-list-content-item filters">
        <TableFilterRow
          searchFilterOptions={{
            onSearchChanged: handleSearchChanged,
            searchLabel: localizer.search(),
            searchQuery: activeCasesFilterInput.search.value,
          }}
        >
          <label>
            {localizer.client()}
            <Dropdown
              className="min-w-190"
              placeholder={localizer.all()}
              options={clients?.map((c) => ({ label: c.name, value: c.id }))}
              noOptionsMessage={() => localizer.noResultsFound()}
              onChange={(newVal) => handleClientChanged(newVal?.value)}
              isClearable
              isLoading={clientsIsLoading}
            />
          </label>
          <CheckboxSwitch
            id="show-closed-cases-switch"
            label={localizer.showCompletedCases()}
            externalChecked={showClosedCases}
            onchangeCallback={handleToggleShowClosed}
          />
        </TableFilterRow>
      </div>

      <div className="case-list-content-item lists">
        <CaseTable
          title={localizer.ongoingCases()}
          casePaginatedListInput={activeCasesFilterInput}
          setPaginatedListInput={setActiveCasesFilterInput as unknown as React.Dispatch<React.SetStateAction<PaginatedListInput>>}
        />
        {showClosedCases &&
          <CaseTable
            title={localizer.completedCases()}
            casePaginatedListInput={closedCasesFilterInput}
            setPaginatedListInput={setClosedCasesFilterInput as unknown as React.Dispatch<React.SetStateAction<PaginatedListInput>>}
            includeStatusColumn={true}
          />
        }
      </div>
    </>
  );
};
