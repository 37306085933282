import * as yup from "yup";
import { Localizer } from "@components/localization/localizer";
import { createValidationSchema } from "@components/validation/createValidationSchema";

export type ExtractDraftSectionUpdateModel = {
  title: string;
};

export type ExtractDraftSectionOrderUpdateModel = {
  newSortOrder: number;
};

export const updateSectionModelValidation = (localizer: Localizer) => createValidationSchema<ExtractDraftSectionUpdateModel>({
  title: yup.string().max(150, localizer.maxLength150()).required(localizer.titleRequired()),
});
