import classNames from "classnames";
import Modal, { ModalSizeEnum } from "@components/modal/modal";
import { IconButton } from "@components/button/IconButton/IconButton";
import { useLocalization } from "@components/localization/localizationProvider";
import { useGetTableOfContentsUrlExtractDraftQuery } from "@services/api/extractDraft/extractDraftApi";
import { ExtractDraftTableOfContentsModel } from "@services/api/extractDraft/models/extractDraftTableOfContentsModel";
import useElementSize from "src/hooks/useElementSize";
import { ExtractDraftFrontpageModel } from "@services/api/extractDraft/models/extractDraftFrontpageModel";
import { MultiPagePdfView } from "../../MultiPagePdfView/MultiPagePdfView";
import styles from "./TableOfContentsModal.module.scss";
import TableOfContentsSettingsRow from "./TableOfContentsSettingsRow/TableOfContentsSettingsRow";

type TableOfContentsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  extractDraftId: string;
  caseId: string;
  tableOfContents: ExtractDraftTableOfContentsModel;
  frontpage: ExtractDraftFrontpageModel;
};

const TableOfContentsModal = ({ isOpen, onClose, caseId, extractDraftId, tableOfContents, frontpage } : TableOfContentsModalProps) => {
  const localizer = useLocalization();
  const [containerRef, containerSize] = useElementSize();

  const { data: tableOfContentsUrl } = useGetTableOfContentsUrlExtractDraftQuery({ caseId: caseId, extractDraftId: extractDraftId });
  return (
    <Modal size={ModalSizeEnum.ExtraLarge} open={isOpen} onClose={onClose} className="px-3">
      <div className="d-flex justify-content-between p-4 border-bottom">
        <h3>{localizer.tableOfContents()}</h3>
        <IconButton iconName="close" onClick={onClose}/>
      </div>
      <TableOfContentsSettingsRow caseId={caseId} extractDraftId={extractDraftId} frontpage={frontpage} tableOfContents={tableOfContents} />
      <div ref={containerRef} className={classNames(styles.fullSizeScrollable, "p-3 background-gray") }>
        {tableOfContentsUrl &&
          <MultiPagePdfView
            containerSize={containerSize}
            margins={20}
            url={tableOfContentsUrl}
            pageGap={10}
          />
        }
      </div>
    </Modal>
  );
};

export default TableOfContentsModal;

