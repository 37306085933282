import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocalization } from "@components/localization/localizationProvider";
import { useModalMessage } from "@components/modal/modalMessageContext";
import { activeMarkingSelector, removeActiveMarking, setHoveredMarkingId } from "@pages/pdfviewer/component/pdfViewerSlice";

const useSafeMarkingClose = () => {
  const dispatch = useDispatch();
  const localizer = useLocalization();
  const activeInfo = useSelector(activeMarkingSelector);
  const { showModalMessage } = useModalMessage();

  const closeCard = useCallback((discard = false) => {
    if (activeInfo?.unsaved && !discard) {
      showModalMessage({
        title: localizer.unsavedChangesWarningTitle(),
        description: localizer.unsavedChangesWarningText(),
        cancelButtonText: localizer.back(),
        acceptAction: () => closeCard(true),
      });
    } else {
      dispatch(removeActiveMarking());
      dispatch(setHoveredMarkingId(undefined));
    }
  }, [activeInfo?.unsaved, dispatch, localizer, showModalMessage]);

  return { closeCard };
};

export default useSafeMarkingClose;
