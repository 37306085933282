import classNames from "classnames";
import { forwardRef, HTMLAttributes, Ref } from "react";
import { HighlightMarkingModel, MARKING_FADED_COLOR } from "@pages/pdfviewer/component/models/markingModel";
import styles from "./highlightMarking.module.scss";
import SvgPolygons from "./svgPolygons";


interface HighlightMarkingProps extends HTMLAttributes<HTMLDivElement> {
  marking: HighlightMarkingModel<false>;
  scale: number;
  active: boolean;
  faded: boolean;
}

const HighlightMarking = forwardRef((
  { marking, active, scale, faded, className, ...childProps }: HighlightMarkingProps,
  ref: Ref<HTMLDivElement>,
) => {
  return (
    <div
      ref={ref}
      style={{
        top: marking.y * scale,
        left: marking.x * scale,
        fill: faded ? MARKING_FADED_COLOR : marking.data.color,
      }}
      className={classNames(styles.container, className)}
      {...childProps}
    >
      <SvgPolygons
        className="d-block"
        polygons={marking.data.polygons}
        width={marking.data.width * scale}
        height={marking.data.height * scale}
        viewBox={`0 0 ${marking.data.width} ${marking.data.height}`}
      />
    </div>
  );
});

export default HighlightMarking;
