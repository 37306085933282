import { forwardRef, Ref, useEffect, useImperativeHandle } from "react";
import { NotificationModule } from "ditmer-embla";
import FormInput from "@components/forms/FormInput";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import { useAddTitlePageToPresentationMutation } from "@services/api/casePresentation/casePresentationApi";
import FormTextarea from "@components/forms/FormTextarea";
import { SubmitState } from "@components/forms/FormSubmitButton";
import { TitlePageCreateEditValidation, TitlePageModel } from "./titlePageModel";
import { AddToPresentationFormRef } from "./addToPresentation";

type AddTitlePageFormProps = {
  caseId: string;
  presentationId: string;
  setSubmitState: React.Dispatch<React.SetStateAction<SubmitState | undefined>>;
  onSubmitSuccess: () => void;
};

const AddTitlePageForm = forwardRef(({
  caseId,
  presentationId,
  setSubmitState,
  onSubmitSuccess,
}: AddTitlePageFormProps, ref: Ref<AddToPresentationFormRef>) => {
  const localizer = useLocalization();
  const [ addTitlePage, request ] = useAddTitlePageToPresentationMutation();

  const methods = useValidatedForm({
    validationSchema: TitlePageCreateEditValidation(localizer),
    defaultValues: {
      header: undefined,
      contentText: undefined,
    } as TitlePageModel,
  });

  useEffect(() => {
    setSubmitState(() => ({ isSubmitting: request.isLoading, isSubmitSuccessful: request.isSuccess }));
  }, [request, setSubmitState]);

  const onSubmit = (model: TitlePageModel) => {
    addTitlePage({ caseId, presentationId, model })
      .unwrap()
      .then(() => {
        NotificationModule.showSuccessSmall(localizer.thePageWasAddedToThePresentation());
        onSubmitSuccess();
      });
  };

  useImperativeHandle(ref, () => ({
    submit: methods.handleSubmit(onSubmit),
  }));

  return (
    <form
      className="margin-top-m"
      // Prevent auto submit (because both fields are optional)
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="subtle">{localizer.createPresentationTitlePageHint1()}</div>
      <div className="subtle">{localizer.createPresentationTitlePageHint2()}</div>
      <FormInput
        methods={methods}
        id="addTitlePage-form-header"
        name="header"
        label={localizer.header()}
      />
      <FormTextarea
        methods={methods}
        id="addTitlePage-form-contentText"
        name="contentText"
        label={localizer.contentText()}
        useAutoSizeOptions={{
          minRows: 2,
          maxRows: 10,
        }}
      />
    </form>
  );
});

export default AddTitlePageForm;
