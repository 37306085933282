import * as yup from "yup";

import { Localizer } from "@components/localization/localizer";
import { UserProfileModel } from "@models/profile/userProfileModel";
import { createValidationSchema } from "../createValidationSchema";

export const profileValidation = (localizer: Localizer) => createValidationSchema<UserProfileModel>({
  id: yup.string(),
  firstName: yup.string().required(localizer.firstNameRequired()),
  lastName: yup.string().required(localizer.lastNameRequired()),
  email: yup.string().required(localizer.emailRequired()),
});