import classNames from "classnames";
import { useConfig } from "@components/config/configProvider";
import styles from "./enviromentBadge.module.scss";

export enum Enviroment {
  LOCALDEV = "Local development",
  DEVELOPMENT = "Dev",
  TEST = "Test",
  DEMO = "Demo",
  PROD = "Produktion",

}

export const EnviromentBadge: React.FC = () => {
  const config = useConfig();

  const env = config.environment;
  return (
    <div className={classNames(
      styles.badge,
      "d-flex align-items-center",

      {
        [styles.localDevelopmentBadge]: env === Enviroment.LOCALDEV,
        [styles.developmentBadge]: env === Enviroment.DEVELOPMENT,
        [styles.testBadge]: env === Enviroment.TEST,
        [styles.demoBadge]: env === Enviroment.DEMO,
      })}
    >
      {env}
    </div>
  );


};

