import { ButtonHTMLAttributes, ReactNode, useEffect, useRef, useState } from "react";
import Button from "@components/embla/button";
import { StatusType } from "@components/embla/statusIcon";

export interface SubmitState {
  isSubmitting: boolean;
  isSubmitSuccessful: boolean;
}

interface FormSubmitButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  state?: SubmitState;
  isPrimary?: boolean;
  children: ReactNode;
}

const FormSubmitButton = ({ children, state, isPrimary, ...buttonProps }: FormSubmitButtonProps) => {
  const [status, setStatus] = useState<StatusType>();
  const firstRender = useRef(true);
  const wasSubmittingRef = useRef<boolean>();
  useEffect(() => {
    if (firstRender.current) {
      // ignore first render to avoid getting checkmark when form is reused in wizard
      firstRender.current = false;
      return;
    }
    let cleanup: (() => void) | undefined;
    if (state?.isSubmitting) {
      setStatus("loading");
    } else if (wasSubmittingRef.current) {
      // Show success/error status only if "isSubmitting" changed from "true" to "false"
      setStatus(state?.isSubmitSuccessful ? "success" : "error");
      // Clear success/error status after 3 seconds
      const timeout = setTimeout(() => setStatus(undefined), 3000);
      cleanup = () => clearTimeout(timeout);
    }
    wasSubmittingRef.current = state?.isSubmitting;
    return cleanup;
  }, [state?.isSubmitSuccessful, state?.isSubmitting]);

  return (
    <Button
      type="submit"
      theme={isPrimary ? "primary" : "default"}
      status={status}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default FormSubmitButton;
