import React, { ReactNode, useCallback, useContext, useState } from "react";
import ModalMessage, { ModalMessageProps } from "./modalMessage";

type ShowModalMessageProps = Omit<ModalMessageProps, "open" | "onClose"> &  Partial<Pick<ModalMessageProps, "onClose">>;

interface ModalMessageContextType {
  showModalMessage: (props: ShowModalMessageProps) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const ModalMessageContext = React.createContext<ModalMessageContextType | null>(null);

export const ModalMessageProvider = ({ children }: { children: ReactNode }) => {
  const [modal, setModal] = useState<ReactNode>();

  const showModalMessage = useCallback((props: ShowModalMessageProps) => {
    setModal(
      <ModalMessage
        {...props}
        open
        onClose={() => {
          props.onClose?.();
          setModal(undefined);
        }}
      />,
    );
  }, []);

  return (
    <ModalMessageContext.Provider value={{ showModalMessage }}>
      {children}
      {modal}
    </ModalMessageContext.Provider>
  );
};

export const useModalMessage = () => {
  const ctx = useContext(ModalMessageContext);
  if (ctx === null) {
    throw new Error("useModalMessage must be used inside ModalMessageContext");
  }
  return ctx;
};
