import classNames from "classnames";
import React from "react";
import { Color } from "src/utility/constants";
import styles from "./roundBadge.module.scss";

export enum BadgeSize {
  Medium,
  Small,
  ExtraSmall,
  ExtraExtraSmall,
}

interface RoundBadgeProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "color"> {
  size?: BadgeSize;
  color?: Color;
  children?: React.ReactNode;
  title?: string;
}

export const RoundBadge = ({
  size,
  children,
  color = Color.Primary,
  ...otherProps
}: RoundBadgeProps) => {
  return (
    <div
      className={classNames(
        [styles.roundBadge],
        {
          [styles["roundBadge--m"]]: size === BadgeSize.Medium,
          [styles["roundBadge--s"]]: size === BadgeSize.Small,
          [styles["roundBadge--xs"]]: size === BadgeSize.ExtraSmall,
          [styles["roundBadge--xxs"]]: size === BadgeSize.ExtraExtraSmall,
        },
        {
          [styles["roundBadge--primary"]]: color === Color.Primary,
          [styles["roundBadge--secondary"]]: color === Color.Secondary,
          [styles["roundBadge--secondary-light"]]:
            color === Color.SecondaryLight,
        },
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
};
