import { MarkingType } from "./markingType";

// Note: should match the values in the backend
export enum DocumentPageOverlayIconTypeEnum {
  Presentation = 1,
  Comment = 2,
  Highlight = 3,
  Rotation = 4,
}

const getUniqueOverlayIcons = (overlayIcons: DocumentPageOverlayIconTypeEnum[]) => {
  return overlayIcons.reduce((prev: DocumentPageOverlayIconTypeEnum[], cur: DocumentPageOverlayIconTypeEnum) => {
    if (!prev.some((x) => x === cur)) {
      return [...prev, cur];
    }
    return prev;
  }, []);
};

const mapMarkingTypeToOverlayIconType = (type: MarkingType) => {
  switch (type) {
    case MarkingType.Comment:
      return DocumentPageOverlayIconTypeEnum.Comment;
    case (MarkingType.FocusBox || MarkingType.Highlight || MarkingType.VerticalLines):
      return DocumentPageOverlayIconTypeEnum.Highlight;
    default:
      return DocumentPageOverlayIconTypeEnum.Highlight;
  }
};

export const convertMarkingTypesToOverlayIcons = (markingTypes: MarkingType[], generatePresentationIcon?: boolean) => {

  const overlayIcons = [
    ...generatePresentationIcon ? [DocumentPageOverlayIconTypeEnum.Presentation] : [],
    ...markingTypes.map(mapMarkingTypeToOverlayIconType),
  ];

  return getUniqueOverlayIcons(overlayIcons);
};
