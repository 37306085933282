import { useEffect } from "react";
import { DeepPartial, DefaultValues, UnpackNestedValue } from "react-hook-form";
import { AnyObjectSchema } from "yup";
import { MarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import useValidatedForm from "@components/forms/useValidatedForm";

interface Props<T extends MarkingModel> {
  marking: T;
  defaultValues: DefaultValues<T>;
  validationSchema?: AnyObjectSchema;
  onMarkingChange: (m: T) => void;
  onSave?: (data: T) => void;
}

const useMarkingCardForm = <T extends MarkingModel>({ marking, defaultValues, validationSchema, onMarkingChange, onSave }: Props<T>) => {
  const methods = useValidatedForm({
    defaultValues: defaultValues,
    mode: "all",
    validationSchema,
  });

  const { watch } = methods;
  useEffect(() => {
    // watch for form changes to update marking
    const { unsubscribe } = watch((value) => {
      onMarkingChange(fillPartialData<T>(marking, value));
    });
    return unsubscribe;
  }, [watch, marking, onMarkingChange]);

  const handleSave = async (data: UnpackNestedValue<T>) => {
    await onSave?.(fillPartialData<T>(marking, data));
  };

  return { methods, submitHandler: methods.handleSubmit(handleSave) };
};

export const fillPartialData = <T extends MarkingModel>(markingData: T, formData: UnpackNestedValue<T | DeepPartial<T>>) => {
  return {
    ...markingData,
    ...formData,
    data: {
      ...markingData.data,
      ...formData.data,
    },
  };
};

export default useMarkingCardForm;
