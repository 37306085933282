import { debounce } from "lodash";
import { NotificationModule } from "ditmer-embla";
import { useLocalization } from "@components/localization/localizationProvider";
import { useEditParticipantMutation } from "@services/api/participant/caseParticipantApi";
import { ParticipantAccessRightsForm } from "@components/case/participant/accessRights/participantAccessRightsForm";
import { ParticipantAccessRightsModel } from "@components/case/participant/accessRights/participantAccessRightsModel";
import { CaseRight } from "../../../../infrastructure/CaseRight";

type EditParticipantProps = {
  caseId: string;
  participantId: string;
  userId: string;
  caseAccessRights: CaseRight[];
  isReadOnly: boolean;
  canChangeAddUsersAccessRight: boolean;
};

export const EditParticipant: React.FC<EditParticipantProps> = ({ caseId, participantId, userId, caseAccessRights, isReadOnly, canChangeAddUsersAccessRight }) => {
  const localizer = useLocalization();
  const [ editParticipant ] = useEditParticipantMutation();

  // You cannot uncheck your own "AddUsers" right
  const addUsersFormFieldIsDisabled = isReadOnly || !canChangeAddUsersAccessRight;

  const debouncedSubmitForm = debounce(async (accessRightsModel: ParticipantAccessRightsModel) => {
    const request = editParticipant({ caseId, participantId, model: { caseAccessRights: accessRightsModel.caseAccessRights, userId: userId } });
    // OnFulfilled (to show success message, even when component is unmounted)
    request.then(() => NotificationModule.showSuccessSmall(localizer.theParticipantWasUpdated()));
  }, 1500);
  
  return (
    <ParticipantAccessRightsForm
      entityId={participantId}
      initialAccessRights={caseAccessRights}
      onChangeCallback={(model) => debouncedSubmitForm(model)}
      isReadonly={isReadOnly}
      addUsersFormFieldIsDisabled={addUsersFormFieldIsDisabled}
    />
  );
};