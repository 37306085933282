import { useEffect, useState } from "react";
import { MarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import { TempMarkingAction, TempMarkingActionType } from "@pages/pdfviewer/component/models/tempMarkingAction";

const useTempMarkings = (props: { pageIndex: number }) => {
  const [tempMarkings, setTempMarkings] = useState<MarkingModel[]>();

  // clear when page changes
  useEffect(() => setTempMarkings(undefined), [props.pageIndex]);

  const handleTempMarkingAction = (action: TempMarkingAction) => {
    switch (action.type) {
      case TempMarkingActionType.Added:
        setTempMarkings((t) => [...t ?? [], action.marking]);
        break;
      case TempMarkingActionType.Changed:
        setTempMarkings((t) => t?.map((m) => m.id === action.marking.id ? action.marking : m));
        break;
      case TempMarkingActionType.Removed:
        setTempMarkings((t) => t?.filter((m) => m.id !== action.markingId));
        break;
    }
  };
  return {
    tempMarkings,
    handleTempMarkingAction,
  };
};

export default useTempMarkings;
