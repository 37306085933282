import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { setGoToIndex } from "@pages/pdfviewer/component/pdfViewerSlice";
import { useAppDispatch } from "@hooks";

interface UseSearch {
  jumpToMatch(index: number): void;
}

const useSearch = (currentDoc?: DocumentCallback): UseSearch => {

  const dispatch = useAppDispatch();

  const jumpToMatch = (pageIndex: number) => {
    dispatch(setGoToIndex(pageIndex));
  };

  return {
    jumpToMatch,
  };
};

export default useSearch;
