import { MultiPolygon, union } from "polygon-clipping";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { Event } from "@infrastructure/Event";
import { useAppSelector } from "@hooks";
import { MarkingActiveStateType } from "../models/activeMarkingInfo";
import { HighlightMarkingModel } from "../models/markingModel";
import { MarkingType } from "../models/markingType";
import { activeMarkingSelector, activeToolSelector, removeActiveMarking, setActiveMarking } from "../pdfViewerSlice";
import useMarkingMutation from "../hooks/useMarkingMutation";
import { TempMarkingAction, TempMarkingActionType } from "../models/tempMarkingAction";

interface UseHighlightToolProps {
  pageIndex: number;
  disabled?: boolean;
  scale?: number;
  onTempMarkingAction?: (a: TempMarkingAction) => void;
}

const useHighlightTool = ({ pageIndex, disabled, scale = 1, onTempMarkingAction }: UseHighlightToolProps) => {
  const [pdfContainerRef, setPdfContainerRef] = useState<HTMLDivElement | null>(null);
  const activeTool = useAppSelector(activeToolSelector);
  const activeMarking = useAppSelector(activeMarkingSelector);
  const dispatch = useDispatch();
  const { createMarking } = useMarkingMutation();

  const newHighlightingInProgress = activeMarking
    && activeMarking.marking.isNew
    && activeMarking.marking.page === pageIndex
    && activeMarking.marking.type === MarkingType.Highlight;
  const highlightActive = !disabled && (activeTool?.type === MarkingType.Highlight || newHighlightingInProgress);

  /**
   * Clear active selection so that drag would not start instead of text selection
   * if user clicks in the middle of already selected text
   */
  useEffect(() => {
    if (highlightActive && pdfContainerRef) {
      const clearSelection = () => document.getSelection()?.removeAllRanges();
      // when tool becomes active
      clearSelection();
      // when user stops selection
      pdfContainerRef.addEventListener(Event.Pointerup, clearSelection);
      return () => pdfContainerRef.removeEventListener(Event.Pointerup, clearSelection);
    }
  }, [highlightActive, pdfContainerRef]);

  /** Dispatch highlight changes to active marking */
  useEffect(() => {
    if (highlightActive && pdfContainerRef) {
      const listener = () => {
        const selection = document.getSelection();
        if (
          // nothing selected
          !selection?.toString()
          // selection not in pdf
          || !pdfContainerRef.contains(selection.anchorNode)
          || !pdfContainerRef.contains(selection.focusNode)
          || selection?.focusNode?.nodeType === 1 //this prevents highlighting the whole page
        ) {
          return;
        }
        const range = selection.getRangeAt(0);
        const pageBounds = pdfContainerRef.getBoundingClientRect();
        const selectionBounds = range.getBoundingClientRect();

        // Check if selection bounds are within the PDF container bounds
        // Also prevent highlight full page
        const fullPageSelectMarginOfError = 5;
        if (
          pageBounds.top - selectionBounds.top < fullPageSelectMarginOfError &&
          pageBounds.bottom - selectionBounds.bottom < fullPageSelectMarginOfError &&
          pageBounds.left - selectionBounds.left < fullPageSelectMarginOfError &&
          pageBounds.right - selectionBounds.right < fullPageSelectMarginOfError
        ) {
          return;
        }

        // calculate rects union, because chrome returns multiple rects for same text
        const polygons = Array.from(range.getClientRects()).reduce<MultiPolygon>((previous, current) => {
          if (current.x === current.right || current.y === current.bottom) {
            // skip zero width or height rects
            return previous;
          }
          // calculate rectangle coordinates relative to selection bounds
          const x = Math.round((current.x - selectionBounds.x) / scale);
          const y = Math.round((current.y - selectionBounds.y) / scale);
          const right = Math.round((current.right - selectionBounds.x) / scale);
          const bottom = Math.round((current.bottom - selectionBounds.y) / scale);
          // calculate union
          return union(previous, [[[x, y], [right, y], [right, bottom], [x, bottom], [x, y]]]);
        }, []);
        // calculate selection bounds relative to pdf page
        const x = Math.round((selectionBounds.x - pageBounds.x) / scale);
        const y = Math.round((selectionBounds.y - pageBounds.y) / scale);
        const width = Math.round(selectionBounds.width / scale);
        const height = Math.round(selectionBounds.height / scale);
        if (newHighlightingInProgress) {
          const original = activeMarking.marking as HighlightMarkingModel<false>;
          // edit existing highlight marking
          dispatch(setActiveMarking({
            activeState: MarkingActiveStateType.Edit,
            marking: {
              ...original, x, y,
              data: { ...original.data, width, height, polygons },
            },
          }));
        } else if (activeTool.type === MarkingType.Highlight) {
          // add new highlight marking based on template
          dispatch(setActiveMarking({
            activeState: MarkingActiveStateType.Edit,
            marking: {
              ...activeTool.template,
              id: uuid(),
              page: pageIndex,
              creationDate: new Date().toJSON(),
              x, y,
              data: {
                ...activeTool.template.data,
                width,
                height,
                polygons,
              },
            },
          }));
        }
      };
      document.addEventListener(Event.Selectionchange, listener);
      return () => document.removeEventListener(Event.Selectionchange, listener);
    }

  }, [activeMarking?.marking, activeTool, dispatch, highlightActive, newHighlightingInProgress, pageIndex, pdfContainerRef, scale]);

  const tempMarkingActionFnRef = useRef(onTempMarkingAction);
  tempMarkingActionFnRef.current = onTempMarkingAction;

  /** Autosave highlight changes on pointerup event */
  useEffect(() => {
    if (newHighlightingInProgress && pdfContainerRef) {
      const autosaveMarking = () => {
        if (activeMarking.marking.isTemporary) {
          tempMarkingActionFnRef.current?.({ type: TempMarkingActionType.Added, marking: activeMarking.marking });
          dispatch(removeActiveMarking());
        } else {
          createMarking(activeMarking.marking);
          dispatch(removeActiveMarking());
        }
      };
      pdfContainerRef.addEventListener(Event.Pointerup, autosaveMarking);
      return () => pdfContainerRef.removeEventListener(Event.Pointerup, autosaveMarking);
    }
  }, [pdfContainerRef, newHighlightingInProgress, activeMarking, createMarking, dispatch]);

  return {
    setPdfContainerRef,
    highlightActive,
  };
};

export default useHighlightTool;
