import { useSelector } from "react-redux";
import { useVirtualClickOutside } from "../../hooks/useVirtualClickOutside";
import { activeMarkingSelector } from "../../pdfViewerSlice";
import useSafeMarkingClose from "./hooks/useSafeMarkingClose";
import { createMarkingCardId } from "./shared/canvasMarkingCard";

const useMarkingClickOutside = () => {
  const activeMarkingInfo = useSelector(activeMarkingSelector);
  const { closeCard } = useSafeMarkingClose();

  useVirtualClickOutside(
    activeMarkingInfo?.marking && createMarkingCardId(activeMarkingInfo.marking),
    closeCard,
  );
};

export default useMarkingClickOutside;
