import { useMemo } from "react";
import { GridOnItemsRenderedProps, ListOnItemsRenderedProps } from "react-window";
import getPageIndexInGrid from "../utils/getPageIndexInGrid";

export const useVisiblePages = (items: ListOnItemsRenderedProps | GridOnItemsRenderedProps | undefined, gridColumnCount?: number) => {
  return useMemo(() => {
    const visiblePages = [];
    if (items) {
      if ("visibleStartIndex" in items) {
        for (let i = items.visibleStartIndex; i <= items.visibleStopIndex; i++) {
          visiblePages.push(i);
        }
      } else if (gridColumnCount) {
        for (let row = items.visibleRowStartIndex; row <= items.visibleRowStopIndex; row++) {
          for (let column = items.visibleColumnStartIndex; column <= items.visibleColumnStopIndex; column++) {
            visiblePages.push(getPageIndexInGrid(row, column, gridColumnCount));
          }
        }
      }
    }
    return [visiblePages];
  }, [items, gridColumnCount]);
};