import classNames from "classnames";
import { PropsWithChildren } from "react";
import "./pillBadge.scss";

type PillBadgeProps = {
  smallText?: boolean;
  colorOverride?: "secondary" | "secondary-light";
};

export const PillBadge: React.FC<PropsWithChildren<PillBadgeProps>>  = (props) => {
  
  return (
    <span className={classNames(
      "color-secondary",
      "display-3",
      "rounded-pill",
      "pill-badge",
      {
        "small": props.smallText,
      }, props.colorOverride,
    )}>
      {props.children}
    </span>
  );
};