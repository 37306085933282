import { User } from "@models/User";
import { CommentMarkingModel, FocusBoxMarkingModel, HighlightMarkingModel, HIGHLIGHT_COLORS, LineAppearance, LINE_COLORS, MarkingModelBase, VerticalLinesMarkingModel } from "../../../models/markingModel";
import { MarkingType } from "../../../models/markingType";

interface MarkingBaseProps extends Partial<MarkingModelBase<true>> {
  user: User;
}

const createMarkingBase = ({ user, ...baseData }: MarkingBaseProps): Omit<MarkingModelBase<true>, "type"> => {
  return {
    id: undefined,
    isPrivate: false,
    tags: [],
    isNew: true,
    owner: {
      name: user.name,
      userId: user.id,
      employeeId: user.employeeId,
    },
    page: undefined,
    x: undefined,
    y: undefined,
    creationDate: undefined,
    presentationPageList: [],
    ...baseData,
  };
};

export const createCommentMarking = (baseData: MarkingBaseProps): CommentMarkingModel<true> => {
  return {
    ...createMarkingBase(baseData),
    type: MarkingType.Comment,
    data: {
      text: "",
    },
  };
};

export const createHighlightMarking = (baseData: MarkingBaseProps): HighlightMarkingModel<true> => {
  return {
    ...createMarkingBase(baseData),
    type: MarkingType.Highlight,
    data: {
      color: HIGHLIGHT_COLORS.yellow,
      height: undefined,
      width: undefined,
      polygons: undefined,
    },
  };
};

export const createVerticalLinesMarking = (baseData: MarkingBaseProps): VerticalLinesMarkingModel<true> => {
  return {
    ...createMarkingBase(baseData),
    type: MarkingType.VerticalLines,
    data: {
      color: LINE_COLORS.black,
      lines: LineAppearance.Single,
      height: undefined,
      width: undefined,
    },
  };
};

export const createFocusBoxMarking = (baseData: MarkingBaseProps): FocusBoxMarkingModel<true> => {
  return {
    ...createMarkingBase(baseData),
    type: MarkingType.FocusBox,
    data: {
      height: undefined,
      width: undefined,
    },
  };
};
