import classNames from "classnames";
import Button, { ButtonProps } from "@components/embla/button";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import { Tooltip } from "@components/tooltip/tooltip";

interface MarkingCloseBtnProps extends Omit<ButtonProps, "onClick"> {
  close: () => void;
}

const MarkingCloseBtn = ({ className, close, ...buttonProps }: MarkingCloseBtnProps) => {
  const localizer = useLocalization();

  return (
    <Tooltip message={`${localizer.close()} (Esc)`}>
      <Button
        iconBtn
        className={classNames("close", className)}
        onClick={() => close()}
        {...buttonProps}
      >
        <EmblaIcon iconName="close" />
      </Button>
    </Tooltip>
  );
};

export default MarkingCloseBtn;
