import { useEffect, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { AddButtonSlideInForm } from "@components/case/addButtonSlideInForm";
import Modal, { ModalSizeEnum } from "@components/modal/modal";
import { useLocalization } from "@components/localization/localizationProvider";
import { TabItem } from "@components/tab/tabModel";
import { Tabs } from "@components/tab/tabs";
import { Spinner } from "@components/spinner/spinner";
import { apiTags } from "@services/api/baseApi";

import { useAppDispatch } from "@hooks";
import documentApi from "@services/api/document/caseDocumentApi";
import { UploadDocuments } from "./uploadDocuments/uploadDocuments";
import { CreateExtractDraft } from "./createExtractDraft/createExtractDraft";

export interface AddDocumentsModalProps {
  caseId: string;
  modalSize?: ModalSizeEnum;
}

export const AddDocumentsModal = (props: AddDocumentsModalProps) => {
  const localizer = useLocalization();
  const [ isInProgressOfUploading, setIsInProgressOfUploading ] = useState(false);
  const [ modalActionsContainer, setModalActionsContainer ] = useState<HTMLDivElement | null>();
  const [ createdExtractDraftId, setCreatedExtractDraftId ] = useState<string>();
  const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false);
  const [ isInProgressOfCreatingExtractDraft, setIsInProgressOfCreatingExtractDraft ] = useState(false);
  const dispatch = useAppDispatch();

  const setExtractDraftId = (extractDraftId: string | undefined) => {
    setCreatedExtractDraftId(extractDraftId);
    setIsModalOpen(false);
  };

  const onClose = (extractDraftId: string | undefined, inProgressOfCreatingExtractDraft: boolean ) => {
    setCreatedExtractDraftId(extractDraftId);
    setIsInProgressOfCreatingExtractDraft(inProgressOfCreatingExtractDraft);
  };


  const invalidateGetcaseDocuments = () => {
    dispatch(documentApi.util.invalidateTags( [{ type: apiTags.case, id: props.caseId }, apiTags.caseDocument]));
  };

  useEffect(()=> {
    if (isModalOpen === false) {
      onClose(undefined, false);
    }
  }, [isModalOpen]);

  const tabItems: TabItem[] = [
    {
      id: "uploadDocuments",
      title: localizer.uploadFiles(),
      active: true,
      content: (
        <DndProvider backend={HTML5Backend}>
          <UploadDocuments
            caseId={props.caseId}
            setIsInProgressOfUploadingFiles={setIsInProgressOfUploading}
            actionsContainer={modalActionsContainer}
          />
        </DndProvider>
      ),
    },
    {
      id: "createExtractDraft",
      title: localizer.createExtractDraft(),
      active: false,
      content: (
        <CreateExtractDraft
          caseId={props.caseId}
          actionsContainer={modalActionsContainer}
          onSuccess={setExtractDraftId}
          setIsInProgressOfCreatingExtractDraft={setIsInProgressOfCreatingExtractDraft}
        />
      ),
    },
  ];

  return (
    <AddButtonSlideInForm
      isOpen={isModalOpen}
      setIsOpen={setIsModalOpen}
      locked={isInProgressOfUploading}
      size={ModalSizeEnum.Large}
      onCloseEffect={() => {
        setIsModalOpen(false);
        onClose(undefined, false);
        invalidateGetcaseDocuments();
      }}>
      <Modal.Header>{localizer.addDocuments()}</Modal.Header>
      <Modal.Body>
        {isInProgressOfCreatingExtractDraft ? <Spinner /> : (!createdExtractDraftId &&
          <Tabs
            tabs={tabItems}
            hideBorder
            disableTabClick={isInProgressOfUploading}
          />
        ) }
      </Modal.Body>
      <div ref={setModalActionsContainer}/>
    </AddButtonSlideInForm>
  );
};
