import { useDrag, useDrop } from "react-dnd";
import { useState, useRef, useEffect } from "react";
import { ExtractDraftSectionModel } from "@services/api/extractDraft/models/extractDraftSectionModel";
import { useReorderExtractDraftSectionMutation } from "@services/api/extractDraft/extractDraftApi";
import { ExtractDraftModel } from "@services/api/extractDraft/models/extractDraftModel";
import { ExtractDraftSectionOrderUpdateModel } from "@services/api/extractDraft/models/extractDraftSectionUpdateModel";
import { dndItemTypes } from "../components/ExtractSections/DndItemTypes";

export const useDragDropSectionReorder = (sectionData: ExtractDraftSectionModel, extractDraft:ExtractDraftModel, canDrag = true) => {
  // For drag preview
  const [draggedItemWidth, setDraggedItemWidth] = useState(0);
  const [position, setPosition] = useState<"above" | "below" | null>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const [ reorderSection ] = useReorderExtractDraftSectionMutation();

  useEffect(() => {
    if (divRef.current) {
      setDraggedItemWidth(divRef.current.offsetWidth / 2);
    }
  }, [divRef]);

  const [{ isDragging }, drag] = useDrag({
    type: dndItemTypes.section,
    canDrag: canDrag,
    item: { ...sectionData, width: draggedItemWidth },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }, [canDrag, sectionData, draggedItemWidth]);

  const [, dropSection] = useDrop({
    accept: dndItemTypes.section,
    drop: (droppedSection: ExtractDraftSectionModel) => {
      let newSortOrder = sectionData.sortOrder;
      
      if (sectionData.id === droppedSection.id ||
        ((droppedSection.sortOrder - 1 === sectionData.sortOrder) && position === "below") ||
        ((droppedSection.sortOrder + 1 === sectionData.sortOrder) && position === "above")
      ) {
        return;
      }

      if ((droppedSection.sortOrder < sectionData.sortOrder) && position === "above") {
        newSortOrder -= 1;
      }

      if ((droppedSection.sortOrder > sectionData.sortOrder) && position === "below") {
        newSortOrder += 1;
      }
  
      const model: ExtractDraftSectionOrderUpdateModel = {
        newSortOrder,
      };
      reorderSection({
        caseId: extractDraft.caseId,
        extractDraftId: extractDraft.id,
        sectionId: droppedSection.id,
        model,
      });
    },
    hover: (item, monitor) => {
      if (!divRef.current) {
        return;
      }

      const hoverBoundingRect = divRef.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      if (clientOffset) {
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    
        if (hoverClientY < hoverMiddleY) {
          setPosition("above");
        } else {
          setPosition("below");
        }
      }
    },
    collect: (monitor) => {
      if (!monitor.isOver()) {
        setPosition(null);
      }
      return {
        isOver: monitor.isOver(),
      };
    },
  }, [sectionData, position]);

  drag(divRef);
  dropSection(divRef);

  return { divRef, position, isDragging };
};