import { useCallback } from "react";
import { CaseRight } from "@infrastructure/CaseRight";
import useCaseRights from "./useCaseRights";

const useCaseAccess = (caseId?: string) => {
  const { hasCaseRight } = useCaseRights(caseId);

  const canAddAndModifyUsers = useCallback(
    () => hasCaseRight(CaseRight.AddUsers),
    [hasCaseRight],
  );

  const canDeleteDocuments = useCallback(
    () => hasCaseRight(CaseRight.DeleteDocuments),
    [hasCaseRight],
  );

  const canEditDeleteAllMarkings = useCallback(
    () => hasCaseRight(CaseRight.EditDeleteAllMarkings),
    [hasCaseRight],
  );

  const canEditDeletePresentations = useCallback(
    () => hasCaseRight(CaseRight.EditDeletePresentations),
    [hasCaseRight],
  );

  return { canAddAndModifyUsers, canDeleteDocuments, canEditDeleteAllMarkings, canEditDeletePresentations: canEditDeletePresentations };
};

export default useCaseAccess;
