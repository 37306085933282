import classNames from "classnames";
import { HTMLAttributes, useEffect } from "react";
import { useDispatch } from "react-redux";
import { CasePresentationListModel } from "@services/api/casePresentation/models/casePresentationListModel";
import Checkbox from "@components/checkbox/checkbox";
import { PresentationInfo } from "@pages/pdfviewer/component/models/presentationInfo";
import { StorageKeys } from "@infrastructure/storageKeys";
import { CaseSettingsModel } from "@infrastructure/storageModels";
import { removeActivePresentation, setActivePresentation, bulkAddTogglePresentation } from "../../pages/pdfviewer/component/pdfViewerSlice";
import styles from "./bottomBarTabs.module.scss";
import PresentationDropdownMenu from "./PresentationDropdownMenu/PresentationDropdownMenu";

interface BottomBarTabsProps extends HTMLAttributes<HTMLDivElement> {
  casePresentations?: CasePresentationListModel[];
  activePresentation?: PresentationInfo;
  bulkAddPresentations?: string[];
  caseId: string;
  toggleBottomExpansion: () => void;
  isExpanded: boolean;
  documentId?: string;
  redirectAppUrl : string | undefined;
}

const saveCaseSettings = (caseId: string, activePresentationId?: string ) => {
  const key = StorageKeys.caseSettingsStorage(caseId);
  let newCaseSettings: CaseSettingsModel = {};

  const caseSettings = localStorage.getItem(key);
  if (caseSettings !== null) {
    const caseSettingsFromStorage: CaseSettingsModel  = JSON.parse(caseSettings);
    newCaseSettings = { ...caseSettingsFromStorage, activePresentationId: activePresentationId } ;
  } else {
    newCaseSettings = { activePresentationId: activePresentationId } ;
  }
  localStorage.setItem(key, JSON.stringify(newCaseSettings));
};

const getActivePresentation = (caseId: string, casePresentations?: CasePresentationListModel[] ): PresentationInfo | undefined => {
  const key = StorageKeys.caseSettingsStorage(caseId);
  const caseSettingsFromLocal = localStorage.getItem(key);

  let currentPresentation: PresentationInfo | undefined = undefined;
  if (caseSettingsFromLocal !== null) {
    const caseSettingsFromStorage: CaseSettingsModel  = JSON.parse(caseSettingsFromLocal);
    casePresentations?.forEach((casePresentation)=>{

      if (caseSettingsFromStorage.activePresentationId === casePresentation.id) {
        currentPresentation = { id: casePresentation.id, title: casePresentation.title };
      }
    });
  }
  return currentPresentation;
};


const BottomBarTabs = ({
  casePresentations,
  activePresentation,
  bulkAddPresentations,
  className,
  caseId,
  toggleBottomExpansion,
  isExpanded,
  documentId,
  redirectAppUrl,
  ...divProps
}: BottomBarTabsProps) => {
  const dispatch = useDispatch();

  const presentationClick = (seq: CasePresentationListModel) => {
    if (bulkAddPresentations) {
      return;
    }
    const isActivePresentation = activePresentation?.id === seq.id;
    if (isActivePresentation || !isExpanded) {
      toggleBottomExpansion();
    }

    dispatch(setActivePresentation(seq));
    saveCaseSettings(caseId, seq?.id);
  };

  useEffect(()=>{
    const activePresentationFromCaseSettings = getActivePresentation(caseId, casePresentations) ?? casePresentations?.[0];

    if (!activePresentationFromCaseSettings) {
      dispatch(removeActivePresentation());

    } else {
      dispatch(setActivePresentation(activePresentationFromCaseSettings));
    }
  }, [caseId, casePresentations, dispatch]);


  const onCheckboxChange = (seq: CasePresentationListModel) => {
    dispatch(bulkAddTogglePresentation({ id: seq.id }));
  };

  return (
    <div
      className={classNames("d-flex gap-s", styles.container, className)}
      {...divProps}
    >
      {casePresentations?.map((sq) => {
        const isSelected = !bulkAddPresentations && sq.id === activePresentation?.id;
        return <div
          key={sq.id}
          role={bulkAddPresentations ? undefined : "button"}
          className={classNames(
            "rounded px-2",
            styles.tab,
            isSelected && isExpanded ? styles.active : "subtle",
          )}
        >
          {bulkAddPresentations ? (
            <Checkbox
              id={`checkbox-${sq.id}`}
              checked={bulkAddPresentations.includes(sq.id)}
              onChange={() => onCheckboxChange(sq)}
            >
              <span className={classNames("ellipsisContainer", styles.title) } title={sq.title}>
                {sq.title}
              </span>
            </Checkbox>
          ) : (
            <div className="d-flex py-1">
              <span className={classNames("ellipsisContainer", styles.title) } title={sq.title} onClick={() => presentationClick(sq)}>
                {sq.title}
              </span>
              {
                (isSelected && isExpanded) && (
                  <PresentationDropdownMenu
                    presentationId={activePresentation.id}
                    caseId={caseId}
                    redirectAppUrl={redirectAppUrl}
                    documentId={documentId}
                  />
                )
              }
            </div>
          )}
        </div>;
      })}
    </div>
  );
};

export default BottomBarTabs;
