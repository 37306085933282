import { NotificationModule } from "ditmer-embla";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useAuth } from "@components/auth/authProvider";
import Crud from "@components/embla/crud";
import FormCheckbox from "@components/forms/FormCheckbox";
import FormInput from "@components/forms/FormInput";
import FormSubmitButton from "@components/forms/FormSubmitButton";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import ModalMessage from "@components/modal/modalMessage";
import { RoutePaths } from "@components/routing/routes";
import { AccessRight } from "@infrastructure/AccessRight";
import { CaseRight } from "@infrastructure/CaseRight";
import { useDeleteEmployeeMutation, useResendActivationLinkMutation } from "@services/api/employee/employeeApi";
import { CreateEditEmployeeModel } from "@services/api/employee/models/createEditEmployeeModel";
import useDateFormatter from "src/hooks/useDateFormatter";
import Button from "@components/embla/button";
import { FormInputLabel } from "@components/forms/formInputLabel";
import { createEditEmployeeValidation } from "./employeeCreateEditValidation";
import styles from "./employeeCreateEditForm.module.scss";

type Props = {
  initialValues: CreateEditEmployeeModel;
  onSubmit: (model: CreateEditEmployeeModel) => void;
  isEdit: boolean;
  setNoRefetch?: () => void;
};

export const EmployeeCreateEditForm = (props: Props) => {
  const localizer = useLocalization();
  const dateFormatter = useDateFormatter();
  const navigate = useNavigate();
  const [deleteEmployee, { isLoading: deleting }] = useDeleteEmployeeMutation();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [ resendActivationLink, resendActivationLinkRequest ] = useResendActivationLinkMutation();
  const auth = useAuth();

  const isTheOnlyAdministrativeEmployee = props.initialValues.isTheOnlyAdministrativeEmployee;

  const onDelete = async () => {
    if (props.isEdit && !isTheOnlyAdministrativeEmployee) {

      if (props.setNoRefetch) {
        props.setNoRefetch();
      }

      const result = await deleteEmployee(props.initialValues.employee.id).unwrap();

      if (result) {
        NotificationModule.showSuccessSmall(localizer.theUserprofileWasDeleted());
        navigate(RoutePaths.employees.url, { replace: true });
      } else {
        NotificationModule.showErrorSmall(localizer.employeeWasNotDeleted());
      }
    }
  };

  const onActivate = async () => {
    await resendActivationLink(props.initialValues.employee.id).unwrap();
    NotificationModule.showSuccessSmall(localizer.activationEmailSent());
  };

  const methods = useValidatedForm({
    validationSchema: createEditEmployeeValidation(localizer),
    defaultValues: props.initialValues,
    mode: "onTouched",
  });

  return (
    <>
      <form onSubmit={methods.handleSubmit(props.onSubmit)}>
        <Crud
          body={(
            <>
              <FormInput
                methods={methods}
                id="employee-form-fist-name"
                label={localizer.firstName()}
                placeholder={localizer.firstNamePlaceholderEmployee()}
                name="employee.userProfile.firstName"
                required
              />
              <FormInput
                methods={methods}
                id="employee-form-last-name"
                label={localizer.lastName()}
                placeholder={localizer.lastNamePlaceholderEmployee()}
                name="employee.userProfile.lastName"
                required
              />
              <FormInput
                methods={methods}
                id="employee-form-initials"
                label={localizer.initials()}
                placeholder={localizer.initialsPlaceholder()}
                name="employee.initials"
              />
              <FormInput
                methods={methods}
                id="employee-form-email"
                label={localizer.email()}
                inputMode="email"
                placeholder={localizer.emailPlaceholderEmployee()}
                name="employee.userProfile.email"
                required
              />
              <FormInput
                methods={methods}
                id="employee-form-phone"
                label={localizer.phone()}
                inputMode="tel"
                placeholder={localizer.phonePlaceholder()}
                name="employee.phone"
              />
              <p className="margin-top-m margin-bottom-0"><strong>{localizer.userRights()}</strong></p>

              <div className="form-group margin-bottom-m">
                <input
                  type="checkbox"
                  id="default-user-rights"
                  className="form-field"
                  checked
                  disabled
                />
                <FormInputLabel htmlFor="default-user-rights" labelText={localizer.defaultUserRightsLabel()} className={classNames(styles.overrideColor)} />
                <p className="small">
                  {localizer.defaultUserRightsDescription()}
                </p>
              </div>

              {auth.user.hasAccessRight(AccessRight.SysAdmin) &&
                <FormCheckbox
                  methods={methods}
                  id="right-system-admin"
                  label={localizer.systemAdministrator()}
                  name="employee.accessRights"
                  value={AccessRight.SysAdmin}
                  disabled={props.initialValues.isTheOnlySystemAdmin && props.initialValues.employee.accessRights.some((t) => t === AccessRight.SysAdmin)}
                />
              }

              <FormCheckbox
                methods={methods}
                id="right-administer-orgs-and-users"
                label={localizer.administerOrgsAndUsers()}
                name="employee.accessRights"
                value={AccessRight.AdministerOrg}
              />
              <FormCheckbox
                methods={methods}
                id="right-create-case"
                label={localizer.canCreateCase()}
                name="employee.accessRights"
                value={AccessRight.CreateCase}
              />

              <FormCheckbox
                methods={methods}
                id="right-access-to-all-cases"
                label={localizer.accessToAllCases()}
                name="employee.accessRights"
                value={AccessRight.AccessToAllCases}
              />

              <p className="margin-top-m margin-bottom-0"><strong>{localizer.defaultCaseRights()}</strong></p>

              <div className="form-group margin-bottom-m">
                <input
                  type="checkbox"
                  id="default-user-rights"
                  className="form-field"
                  checked
                  disabled
                />
                <FormInputLabel htmlFor="default-user-rights" labelText={localizer.defaultCaseRightsLabel()} className={classNames(styles.overrideColor)} />
                <p className="small">
                  {localizer.defaultCaseRightsDescription()}
                </p>
              </div>

              <FormCheckbox
                methods={methods}
                id="right-add-users"
                label={localizer.caseAccessRightAddUsers()}
                name="employee.caseAccessRights"
                value={CaseRight.AddUsers}
              />
              <FormCheckbox
                methods={methods}
                id="right-delete-documents"
                label={localizer.caseAccessRightDeleteDocuments()}
                name="employee.caseAccessRights"
                value={CaseRight.DeleteDocuments}
              />
              <FormCheckbox
                methods={methods}
                id="right-edit-delete-all-markings"
                label={localizer.caseAccessRightEditDeleteAllMarkings()}
                name="employee.caseAccessRights"
                value={CaseRight.EditDeleteAllMarkings}
              />
              <FormCheckbox
                methods={methods}
                id="right-edit-delete-presentations"
                label={localizer.caseAccessRightEditDeletePresentations()}
                name="employee.caseAccessRights"
                value={CaseRight.EditDeletePresentations}
              />

              <p className="margin-bottom-0 margin-top-m"><strong>{localizer.employeeActivation()}</strong></p>

              {props.initialValues.employee.id &&
                <>
                  {props.initialValues.employee.activatedAt ?
                    <p>
                      {localizer.employeeHasBeenActivatedAt()}{" "}
                      {dateFormatter.date(props.initialValues.employee.activatedAt)}
                    </p>
                    : (
                      <div className="d-flex align-items-center">
                        <div>{localizer.notActivedYet()}</div>
                        <div className="margin-left-s">
                          <FormSubmitButton
                            className="margin-right-s"
                            state={{
                              isSubmitting: resendActivationLinkRequest.isLoading,
                              isSubmitSuccessful: resendActivationLinkRequest.isSuccess,
                            }}
                            disabled={resendActivationLinkRequest.isLoading}
                            onClick={(e)=> {
                              e.preventDefault();
                              onActivate();
                            }
                            }>{localizer.resendActivationLink()}</FormSubmitButton>
                        </div>
                      </div>
                    )
                  }
                </>
              }
              <FormCheckbox
                methods={methods}
                id="employee-active"
                label={localizer.canLogIn()}
                name="employee.active"
              />
            </>
          )}

          footer={(
            <>
              { props.isEdit && auth.user.isSysAdmin() && props.initialValues.employee.accessRights.find((x)=> x === AccessRight.SysAdmin) === undefined &&
                <Button className="margin-right-xs"
                  title="Impersonate"
                  onClick={() => auth.impersonate(props.initialValues.employee.userProfile.id)}
                >
                  {localizer.impersonate()}
                </Button>
              }

              {props.isEdit &&
                <Button
                  theme="danger"
                  disabled={isTheOnlyAdministrativeEmployee}
                  title={isTheOnlyAdministrativeEmployee ? localizer.theEmployeeCantBeDeletedIsTheOnlyAdministrator() : undefined}
                  status={deleting ? "loading" : undefined}
                  onClick={() => setDeleteModalOpen(true)}
                >
                  {localizer.delete()}
                </Button>
              }
              <FormSubmitButton className="margin-left-xs" state={methods.formState}>
                {localizer.save()}
              </FormSubmitButton>
            </>

          )}
        />
      </form>
      <ModalMessage
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title={localizer.areYouSure()}
        description={localizer.areYouSureUserDelete()}
        modalAcceptType="danger"
        acceptButtonText={localizer.delete()}
        acceptAction={onDelete}
      />
    </>
  );
};
