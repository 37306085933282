import { useWizardPanelContext } from "@components/embla/wizard/wizardPanelContextProvider";
import Button from "@components/embla/button";
import { useLocalization } from "@components/localization/localizationProvider";
import FormSubmitButton, { SubmitState } from "@components/forms/FormSubmitButton";
import { WizardStep } from "@components/embla/wizard/wizardPanel";


type WizardActionButtonProps = {
  submitButtonText: string;
  isFinalStep: boolean;
  currentStep: WizardStep;
  submitState?: SubmitState;
};

export const WizardActionButton = ({ isFinalStep, currentStep, submitState, submitButtonText }: WizardActionButtonProps) => {

  const localizer = useLocalization();
  const { state } = useWizardPanelContext();


  return !isFinalStep ? (
    <Button theme={"default"} onClick={currentStep.onNext}>
      {localizer.next()}
    </Button>
  ) : (
    <FormSubmitButton
      state={submitState}
      isPrimary
      onClick={currentStep.onNext}
      disabled={state?.actionButtonDisabledState}
    >
      {submitButtonText}
    </FormSubmitButton>
  );
};
