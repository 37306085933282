import { components, GroupBase, ValueContainerProps } from "react-select";
import React from "react";
import { useLocalization } from "@components/localization/localizationProvider";
import { DropdownOption } from "../dropdown";

type MaxItemsValueContainerProps<Option extends DropdownOption, IsMulti extends boolean> = ValueContainerProps<Option, IsMulti, GroupBase<Option>>;

const MultiValuesAsTextValueContainer = <Option extends DropdownOption, IsMulti extends boolean>({
  children,
  ...props
}: MaxItemsValueContainerProps<Option, IsMulti>) => {
  const localizer = useLocalization();

  // eslint-disable-next-line prefer-const
  let [values, input] = children as [React.ReactNode, HTMLInputElement];

  if (Array.isArray(values)) {
    const count = values.length;
    let valuesDisplay;

    if (count === 1) {
      valuesDisplay = values[0].props.children;
    } else {
      valuesDisplay = `${count} ${localizer.dropdownValuesCountSelectedSuffix()}`;
    }

    values = <span className="font-weight-bold">{valuesDisplay}</span>;
  }
  
  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

export default MultiValuesAsTextValueContainer;