import { useEffect, useState } from "react";
import { ReactComponent as DragIndicator } from "@content/icons/ic_twotone-drag-indicator.svg";
import styles from "./ResizeHandle.module.scss";

type ResizeHandleProps = {
  onResize: (deltaX: number) => void;
  style?: React.CSSProperties;
};

const ResizeHandle = (props: ResizeHandleProps) => {
  const { onResize } = props;
  const [isResizing, setIsResizing] = useState(false);
  const [initialX, setInitialX] = useState(0);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsResizing(true);
    setInitialX(e.clientX);
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isResizing) {
      const deltaX = e.clientX - initialX;

      setInitialX(e.clientX);
      onResize(deltaX);
    }
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  });

  return (
    <div draggable={false} className={styles.resizeHandle} onMouseDown={handleMouseDown} style={props.style}>
      <hr />
      <DragIndicator className="my-2" />
      <hr />
    </div>
  );
};

export default ResizeHandle;
