import classNames from "classnames";

type DragPreviewSectionProps = {
  sectionName: string;
};

const DragPreviewSection = ({ sectionName } : DragPreviewSectionProps) => {
  return <div className={classNames("px-3 py-1")}>
    <p className="m-0">
      {sectionName}
    </p>
  </div>;


};

export default DragPreviewSection;