import { useRef, useState } from "react";
import classNames from "classnames";
import { PageNumberModel } from "@services/api/document/models/documentPageInfoModel";
import { PageNumberFontEnum } from "@services/api/extractDraft/models/extractDraftTableOfContentsModel";
import styles from "./PageNumber.module.scss";
import { usePageNumberDnd } from "./hooks/usePageNumberDnd";
import PageNumberEditingModal from "./PageNumberEditingModal/PageNumberEditingModal";

type PageNumberProps = {
  pageNumberData: PageNumberModel;
  scale: number;
  font?: PageNumberFontEnum;
  disableEditing?: boolean;
  caseId?: string;
  documentId?: string;
};

const PageNumber = ({ pageNumberData, scale, font, caseId, documentId, disableEditing }: PageNumberProps) => {
  const { number, x, y, isAutonomousCoordinates, isContrastColoring, orientation } = pageNumberData;
  
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const numberElRef = useRef<HTMLDivElement>(null);
  
  const { dropBoundaryEl, numberPosition, pageNumberClassName, onPointerDown, isLoading } = usePageNumberDnd({
    scale,
    numberElRef,
    canDrag: !disableEditing,
    numberXY: { x, y },
    number,
    caseId,
    documentId,
    moveOnlyThisNumber: isAutonomousCoordinates,
  });
  
  return (
    <>
      <div
        onDragStart={() => setIsPopoverVisible(false)}
        onPointerDown={onPointerDown}
        onClick={() => setIsPopoverVisible(true)}
        style={{ left: numberPosition.x, top: numberPosition.y, fontFamily: PageNumberFontEnum[font ?? 0] }}
        className={classNames(
          pageNumberClassName,
          styles.container,
          isLoading && styles.disabled,
          isContrastColoring && styles.whiteBackground,
          !disableEditing && styles.border,
        )}
        ref={numberElRef}
      >
        <div style={{ transform: `rotate(${orientation}deg)` }}>
          {number}
        </div>
        {
          (!disableEditing && caseId && documentId) && (
            <PageNumberEditingModal
              numberData={pageNumberData}
              caseId={caseId}
              documentId={documentId}
              font={font}
              numberElRef={numberElRef}
              isVisible={isPopoverVisible}
              onClose={() => setIsPopoverVisible(false)}
            />
          )
        }
      </div>
      {dropBoundaryEl}
    </>
  );
};

export default PageNumber;

