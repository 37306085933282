
import { CreateParticipantsModel } from "@services/api/participant/models/createParticipantsModel";
import { EditParticipantModel } from "@services/api/participant/models/editParticipantModel";
import { CaseBaseReturnModel } from "@services/api/case/models/caseBaseReturnModel";
import baseApi, { apiTags } from "../baseApi";
import { CaseParticipantListModel } from "./models/caseParticipantListModel";

const caseUrl = "/api/case";

const participantUrl = (caseId: string) => `${caseUrl}/${caseId}/participant`;
const addParticipantUrl = (caseId: string) => `${participantUrl(caseId)}/many`;
const editParticipantUrl = (caseId: string, participantId: string) => `${participantUrl(caseId)}/${participantId}`;
const removeParticipantUrl = (caseId: string, participantId: string) => `${participantUrl(caseId)}/${participantId}`;


const caseParticipantApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({

    // Participants
    getCaseParticipants: builder.query<CaseParticipantListModel[], { caseId: string }>({
      query: (model) => participantUrl(model.caseId),
      providesTags: (result) => [apiTags.caseParticipant, ...result?.map((s) => ({ type: apiTags.caseParticipant, id: s.id })) ?? []],
      transformResponse: (original: CaseParticipantListModel[]) => {
        if (!original) {
          return original;
        }

        return Promise.all(original.map((x) => !x.profilePictureUrl ? x : fetch(x.profilePictureUrl)
          .then((response) => response.blob())
          .then((blob) => {
            return {
              ...x,
              profilePictureUrl: URL.createObjectURL(blob),
            };
          }),
        ));
      },
      onCacheEntryAdded: async (_: unknown, api: { cacheDataLoaded: Promise<{ data: CaseParticipantListModel[] }>, cacheEntryRemoved: Promise<void> }) => {
        const { data } = await api.cacheDataLoaded;
        await api.cacheEntryRemoved;
        if (data.length > 0) {
          data.forEach((x) => x.profilePictureUrl && URL.revokeObjectURL(x.profilePictureUrl));
        }
      },

    }),

    addParticipants: builder.mutation<undefined, { caseId: string, model: CreateParticipantsModel }>({
      query: ({ caseId, model }) => ({
        url: addParticipantUrl(caseId),
        method: "POST",
        body: model,
      }),
      invalidatesTags: (_, error, input) => !error ? [
        { type: apiTags.case, id: input.caseId },
        apiTags.caseParticipant,
        apiTags.employee,
        apiTags.caseUserAccessRights,
      ] : [],
    }),

    editParticipant: builder.mutation<undefined, { caseId: string, participantId: string, model: EditParticipantModel }>({
      query: ({ caseId, participantId, model }) => ({
        url: editParticipantUrl(caseId, participantId),
        method: "PUT",
        body: model,
      }),
      //Dont invalidate caseParticipant (refetch), our optimistic update will handle it (fix for flickering profileImages)
      invalidatesTags: (_, error, input) => !error ? [{ type: apiTags.caseUserAccessRights, id: input.participantId }] : [],
      // Optimistic update
      onQueryStarted: async ({ caseId, participantId, model }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          caseParticipantApi.util.updateQueryData("getCaseParticipants", { caseId }, (draft) => {
            const participant = draft.find((x) => x.id === participantId);
            if (participant) {
              participant.caseAccessRights = model.caseAccessRights;
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    removeParticipant: builder.mutation<undefined, { caseId: string, participantId: string }>({
      query: (model) => ({
        url: removeParticipantUrl(model.caseId, model.participantId),
        method: "DELETE",
      }),
      invalidatesTags: (_, error, input) => !error ? [
        { type: apiTags.case, id: input.caseId },
        { type: apiTags.caseParticipant, id: input.participantId },
        { type: apiTags.employee },
        { type: apiTags.caseUserAccessRights, id: input.participantId },
      ] : [],
    }),


  }),
});
export default caseParticipantApi;
export const {
  useGetCaseParticipantsQuery,
  useAddParticipantsMutation,
  useEditParticipantMutation,
  useRemoveParticipantMutation,

} = caseParticipantApi;

