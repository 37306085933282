import { Menu } from "@headlessui/react";
import classNames from "classnames";
import { MouseEvent, useCallback, useEffect, useMemo } from "react";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { Tooltip } from "@components/tooltip/tooltip";
import { useLocalization } from "@components/localization/localizationProvider";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import { ElementSize } from "src/hooks/useElementSize";
import useShortcut from "src/hooks/useShortcut";
import { Event } from "@infrastructure/Event";
import { useZoom } from "../hooks/useZoom";
import { scaleLevels } from "../pdfScales";
import FormattedInput, { ParseError } from "./FormattedInput";
import styles from "./zoomControl.module.scss";

export enum DynamicZoomLevel {
  FitToWidth = "FitToWidth",
  FitToHeight = "FitToHeight",
}

interface ZoomControlProps {
  value: number;
  pdf?: DocumentCallback,
  pdfViewSize: ElementSize,
  disabled?: boolean
  className?: string;
  withMenu?: boolean;
  transparent?: boolean;
}

const formatDisplayValue = (value: number) => Math.round(value * 100) + "%";

const parseInput = (inputValue: string) => {
  const parsed = Number.parseInt(inputValue) / 100;
  if (Number.isNaN(parsed)) {
    throw new ParseError();
  }
  return Math.min(scaleLevels[scaleLevels.length - 1], Math.max(scaleLevels[0], parsed));
};

const ZoomControl = ({ value, pdf, pdfViewSize, disabled, withMenu, className, transparent }: ZoomControlProps) => {
  const localizer = useLocalization();
  const { zoomIn, zoomOut, changeZoom } = useZoom(pdfViewSize, pdf);

  const levels = useMemo(() => [
    { label: "200%", value: 2 },
    { label: "150%", value: 1.5 },
    { label: "125%", value: 1.25 },
    { label: "100%", value: 1 },
    { label: "75%", value: 0.75 },
    { label: "50%", value: 0.5 },
    { label: localizer.zoomFitToWidth(), value: DynamicZoomLevel.FitToWidth },
    { label: localizer.zoomWholePage(), value: DynamicZoomLevel.FitToHeight },
  ], [localizer]);

  useShortcut({ key: "+", callback: zoomIn });
  useShortcut({ key: "-", callback: zoomOut });
  useShortcut({ key: "0", callback: () => changeZoom(1) });

  useEffect(() => {
    const listener = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.deltaY < 0 ? zoomIn() : zoomOut();
        e.preventDefault();
      }
    };
    document.addEventListener(Event.Wheel, listener, { passive: false });
    return () => document.removeEventListener(Event.Wheel, listener);
  }, [value, zoomIn, zoomOut]);

  const onInputClick = useCallback((e: MouseEvent<HTMLInputElement>) => {
    e.currentTarget.select();
  }, []);

  return (
    <div className={classNames("input-group flex-nowrap", className)}>
      <div className="input-group-prepend">
        <Tooltip message={`${localizer.zoomOut()} (-)`} placement="bottom">
          <button
            disabled={disabled}
            type="button"
            className={classNames(styles.transparent, "btn btn-default btn-icon")}
            aria-label="Up"
            onClick={zoomOut}
          >
            <EmblaIcon iconName="minus" color={ transparent ? IconColor.Gray : IconColor.DarkGray } />
          </button>
        </Tooltip>
      </div>
      <div className={styles.dropdown}>
        <FormattedInput
          disabled={disabled}
          className={classNames(styles.input, "form-control", "text-center", {
            "pr-4": withMenu,
            [styles.transparent]: transparent,
            [styles.small]: transparent,
            "px-0": transparent,
          })}
          type="text"
          value={value}
          onChange={changeZoom}
          onClick={onInputClick}
          formatDisplayValue={formatDisplayValue}
          parseValue={parseInput}
        />
        {
          withMenu && (
            <Menu>
              <Menu.Button
                disabled={disabled}
                className={styles.dropdownBtn}
              >
                {({ open }) => <EmblaIcon iconName={open ? "arrow-up" : "arrow-down"} />}
              </Menu.Button>
              <Menu.Items className={classNames(styles.dropdown, "dropdown-menu", "show")}>
                {levels.map((level) =>
                  <Menu.Item key={level.value}>
                    {({ active }) => (
                      <button
                        className={classNames("dropdown-item", active && "active")}
                        onClick={() => changeZoom(level.value)}
                      >
                        {level.label}
                      </button>
                    )}
                  </Menu.Item>,
                )}
              </Menu.Items>
            </Menu>
          )
        }
      </div>
      <div className={"input-group-append"} >
        <Tooltip message={`${localizer.zoomIn()} (+)`} placement="bottom">
          <button
            disabled={disabled}
            type="button"
            className={classNames(styles.transparent, "btn btn-default btn-icon")}
            aria-label="Down"
            onClick={zoomIn}
          >
            <EmblaIcon iconName="plus" color={ transparent ? IconColor.Gray : IconColor.DarkGray } />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default ZoomControl;
