import { useMarkingsPageContext } from "@pages/case/presentations/editPresentationPages/MarkingsPageContext/MarkingsPageContext";
import { useVirtualClickOutside } from "@pages/pdfviewer/component/hooks/useVirtualClickOutside";
import { createMarkingCardId } from "../../shared/canvasMarkingCard";
import useSafeMarkingClose from "./useSafeMarkingsClose";

const useMarkingClickOutside = () => {
  const { activeMarking } = useMarkingsPageContext();
  const { closeCard } = useSafeMarkingClose();

  useVirtualClickOutside(
    activeMarking?.marking && createMarkingCardId(activeMarking.marking),
    closeCard,
  );

};

export default useMarkingClickOutside;