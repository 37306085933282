import { useState } from "react";
import Modal, { ModalSizeEnum } from "@components/modal/modal";
import ExportPresentation from "./exportPresentation/exportPresentation";
import DownloadExportPresentation from "./exportPresentation/downloadExportPresentation";

type ExportPresentationModalProps = {
  openModal: boolean;
  onCloseCallback: () => void;
  caseId: string;
  presentationId: string;
  presentationTitle: string;
  presentPresentation?: boolean;
};

enum ExportPresentationModalTab {
  Export,
  PollDownload,
}

const ExportPresentationModal = ({
  openModal,
  onCloseCallback,
  presentPresentation,
  ...sharedProps
}: ExportPresentationModalProps) => {
  const [ currentTab, setCurrentTab ] = useState<ExportPresentationModalTab>(ExportPresentationModalTab.Export);
  const [ exportPresentationId, setExportPresentationId ] = useState<string>();

  const onExportResult = (exportPresentationIdparam: string) => {
    setExportPresentationId(exportPresentationIdparam);
    setCurrentTab(ExportPresentationModalTab.PollDownload);
  };

  const onCloseModal = () => {
    onCloseCallback();
  };

  return (
    <Modal open={openModal} onClose={onCloseModal} size={ModalSizeEnum.Large}>
      {currentTab === ExportPresentationModalTab.Export &&
        <ExportPresentation {...sharedProps}
          onExportResult={onExportResult} onBackClicked={onCloseModal}/>
      }
      {(currentTab === ExportPresentationModalTab.PollDownload && exportPresentationId) &&
        <DownloadExportPresentation {...sharedProps}
          presentationExportId={exportPresentationId}
          onBackClicked={() => setCurrentTab(ExportPresentationModalTab.Export)}/>
      }
    </Modal>
  );
};

export default ExportPresentationModal;
