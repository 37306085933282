
import { useEffect, useState } from "react";
import { MultiValue, OptionsOrGroups, StylesConfig } from "react-select";
import { useAuth } from "@components/auth/authProvider";
import { Dropdown } from "@components/dropdown/dropdown";
import { useLocalization } from "@components/localization/localizationProvider";
import { Localizer } from "@components/localization/localizer";
import { UserModel } from "@services/api/sharedModels/userModel";
import {
  flattenOptionGroupsToOptions, mapEmployeeToOwnerFilterOption,
  MarkingOwnerFilterOption, MarkingOwnerFilterOptionGroup, MarkingOwnerFilterOptionTypeEnum,
} from "./markingOwnerFilter";

const defaultFilterOptions = (localizer: Localizer) => ([
  { label: localizer.all(), value: `${MarkingOwnerFilterOptionTypeEnum.All}`, type: MarkingOwnerFilterOptionTypeEnum.All },
  { label: localizer.none(), value: `${MarkingOwnerFilterOptionTypeEnum.None}`, type: MarkingOwnerFilterOptionTypeEnum.None },
] as MarkingOwnerFilterOption[]);

interface MarkingDropdownProps {
  markingOwners: UserModel[];
  onChangeCallback: (selectedOwnerEmployeeIds: string[]) => void;
  className?: string;
  stylesOverride?: StylesConfig;
}

const MarkingOwnerDropdown = ({
  markingOwners,
  onChangeCallback,
  ...dropdownProps
}: MarkingDropdownProps) => {
  const localizer = useLocalization();
  const { user: currentUser } = useAuth();
  const [filtrationMarkingOptions, setFiltrationMarkingOptions] = useState<OptionsOrGroups<MarkingOwnerFilterOption, MarkingOwnerFilterOptionGroup>>([]);
  const [selectedMarkingFilters, setSelectedMarkingFilters] = useState<MarkingOwnerFilterOption[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const resolveMarkingOptions = () => {
      const ownerMarkingFilterOptions: MarkingOwnerFilterOption[] =
        markingOwners?.map((x) => (mapEmployeeToOwnerFilterOption(x.employeeId, x.employeeId === currentUser.employeeId ? localizer.me() : x.name))) ?? [];
      const ownerMarkingFilterOptionGroup: MarkingOwnerFilterOptionGroup = {
        options: [
          ...ownerMarkingFilterOptions,
        ],
      };

      return [
        ...defaultFilterOptions(localizer),
        ownerMarkingFilterOptionGroup,
      ];
    };

    const options = resolveMarkingOptions();
    setFiltrationMarkingOptions(options);

    if (!isInitialized && markingOwners.length > 0) {
      const defaultSelectedOptions = flattenOptionGroupsToOptions(options).filter((x) => x.type === MarkingOwnerFilterOptionTypeEnum.Owner);
      setSelectedMarkingFilters(defaultSelectedOptions);
      onChangeCallback(defaultSelectedOptions.map((x) => x.filter?.ownerEmployeeId ?? ""));
      setIsInitialized(true);
    }

  }, [markingOwners, currentUser.employeeId, localizer, onChangeCallback, isInitialized]);

  const transformToMarkingOwnerFilters = (selectedValues: MarkingOwnerFilterOption[]) => {
    const defaultOptionsSelected = selectedValues.filter((x) => x.type !== MarkingOwnerFilterOptionTypeEnum.Owner);

    if (defaultOptionsSelected.length > 0) {
      const firstDefaultOption = defaultOptionsSelected[0];

      switch (firstDefaultOption.type) {
        case MarkingOwnerFilterOptionTypeEnum.All:
          return flattenOptionGroupsToOptions(filtrationMarkingOptions).filter((x) => x.type === MarkingOwnerFilterOptionTypeEnum.Owner);
        case MarkingOwnerFilterOptionTypeEnum.None:
          return [];
        default:
          return [];
      }
    }
    return selectedValues;
  };

  const markingFiltrationChanged = (newVal: MultiValue<MarkingOwnerFilterOption>) => {
    const selectedValues = newVal.map((x) => x);
    const ownerFilters = transformToMarkingOwnerFilters(selectedValues);
    setSelectedMarkingFilters(ownerFilters);
    onChangeCallback(ownerFilters.map((x) => x.filter?.ownerEmployeeId ?? ""));
  };

  return (
    <Dropdown
      options={filtrationMarkingOptions}
      onChange={markingFiltrationChanged}
      value={selectedMarkingFilters}
      renderMultiValuesAsText={true}
      isClearable={false}
      hideSelectedOptions={false}
      placeholder={localizer.none()}
      minWidth={130}
      isMulti
      closeMenuOnOptions={[`${MarkingOwnerFilterOptionTypeEnum.All}`, `${MarkingOwnerFilterOptionTypeEnum.None}`]}
      {...dropdownProps}
    />
  );
};

export default MarkingOwnerDropdown;
