import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@app";
import { DynamicMarkingsOverride } from "./useDynamicPresentationPages";

interface PausedPresentationState {
  presentationId: string;
  pageIndex: number;
  pageId: string;
  title: string;
  markingsOverride: DynamicMarkingsOverride;
}

interface TempPresentationPageState {
  pageIndex: number;
  documentId: string;
}


interface PresentationState {
  pausedState?: PausedPresentationState;
  tempPageShownState?: TempPresentationPageState;
}

const initialState: PresentationState = {};

export const presentationSlice = createSlice({
  name: "presentation",
  initialState,
  reducers: {
    setPausedPresentationState: (state, action: PayloadAction<PausedPresentationState>) => ({
      ...state,
      pausedState: action.payload,
    }),
    clearPausedPresentationState: (state) => ({
      ...state,
      pausedState: undefined,
    }),
    setTempPresentationPageState: (state, action: PayloadAction<TempPresentationPageState>) => ({
      ...state,
      tempPageShownState: action.payload,
    }),
    clearTempPresentationPageState: (state) => ({
      ...state,
      tempPageShownState: undefined,
    }),
  },
});

export const { setPausedPresentationState, clearPausedPresentationState, setTempPresentationPageState, clearTempPresentationPageState } = presentationSlice.actions;

export const presentationStateSelector = (state: RootState) => state.presentation;
export const pausedPresentationStateSelector = (state: RootState) => presentationStateSelector(state).pausedState;
export const tempPresentationPageStateSelector = (state: RootState) => presentationStateSelector(state).tempPageShownState;

export default presentationSlice.reducer;
