/* eslint-disable react/jsx-no-useless-fragment */
import {
  Route,
  Routes,
} from "react-router-dom";
import { RequireAuth } from "@components/auth/requireAuth";
import { RoutePaths } from "@routes";
import { EmailTemplatesPage } from "@pages/emailTemplates/emailTemplatesPage";
import { EmailTemplateEditPage } from "@pages/emailTemplates/edit/emailTemplateEditPage";

const EmailRouting: React.FC = () => {
  return (
    <Routes>
      <Route
        index
        element={(
          <EmailTemplatesPage/>
        )}
      />
      <Route
        path={RoutePaths.emailTemplateEdit.path}
        element={(
          <RequireAuth>
            <EmailTemplateEditPage/>
          </RequireAuth>
        )}
      />
    </Routes>
  );
};

export default EmailRouting;
