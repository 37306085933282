import { RequiredSymbol } from "./requiredSymbol/requiredSymbol";

type FormInputLabelProps = {
  htmlFor: string;
  labelText: string;
  className?: string;
  isRequired?: boolean;
};

export const FormInputLabel = ({ htmlFor, labelText, className, isRequired }: FormInputLabelProps) => {
  return (
    <label htmlFor={htmlFor} className={className}>
      {labelText}
      {isRequired && <RequiredSymbol/>}
    </label>
  );
};