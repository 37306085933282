import classNames from "classnames";
import { HTMLAttributes, useEffect, useState } from "react";
import { useLocalization } from "@components/localization/localizationProvider";
import { MinimalPresentationPageModel } from "@services/api/case/models/casePresentationPageModel";
import styles from "./pageInfoHeader.module.scss";

interface PageInfoHeaderProps extends HTMLAttributes<HTMLDivElement> {
  pageIndex: number;
  pageData?: MinimalPresentationPageModel;
}

const PageInfoHeader = ({
  pageIndex,
  pageData,
  className,
  ...divProps
}: PageInfoHeaderProps) => {
  const localizer = useLocalization();

  if (!pageData || pageData.isTitlePage) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.container,
        "text-nowrap",
        className,
        "d-flex",
        "justify-content-center",
        "gap-m",
        "align-items-baseline",
      )}
      {...divProps}
    >
      <h1 className={classNames(styles.header, "text-light", "text-truncate")}>
        {pageData.fileName}
      </h1>
      <span className={classNames(styles.page, "font-weight-normal")}>
        {`${localizer.pdfPage()} ${pageIndex + 1}`}
      </span>
    </div>
  );
};

export default PageInfoHeader;
