import { useEffect, useState } from "react";
import { useExtractCompositionContext } from "@pages/extractCompositionPage/extractCompositionContextProvider";

export const useExtractDraftStatus = (isLoading: boolean, isError: boolean) => {
  const { statusReducer: extractStatusReducer } = useExtractCompositionContext();

  const [personalIsLoading, setPersonalIsLoading] = useState(isLoading);
  const [personalIsError, setPersonalIsError] = useState(isError);

  useEffect(() => {
    if (isError !== personalIsError || isLoading !== personalIsLoading) {
      extractStatusReducer.dispatch({ isLoading: isLoading, isError: isError });
      setPersonalIsError(isError);
      setPersonalIsLoading(isLoading);

    }
  }, [extractStatusReducer, isError, isLoading, personalIsError, personalIsLoading]);
};


