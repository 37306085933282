import { RefObject, useEffect } from "react";

export const getRowHeight = (textAreaElement: HTMLTextAreaElement) => {
  const lineHeightString = window
    .getComputedStyle(textAreaElement)
    .lineHeight;

  return Number(lineHeightString.replace("px", ""));
};

export const getPaddingHeight = (textAreaElement: HTMLTextAreaElement) => {
  const paddingsInPxArray = window
    .getComputedStyle(textAreaElement)
    .paddingBlock
    .replaceAll(" ", "")
    .replaceAll("px", ",")
    .split(",", 2)
    .filter((value) => value !== "")
    .map((value) => Number(value));

  if (paddingsInPxArray.length === 0)
    return 0;

  const paddingHeight = paddingsInPxArray.length > 1
    ? paddingsInPxArray[0] + paddingsInPxArray[1]
    : paddingsInPxArray[0] * 2;

  return paddingHeight;
};

export const calculateRowsHeight = (rowHeight: number, paddingHeight: number, rows?: number) => {
  return rows ? (rows * rowHeight) + paddingHeight : undefined;
};

const calculateDesiredHeight = (scrollHeight: number, minHeight?: number, maxHeight?: number) => {
  if (!!minHeight && scrollHeight < minHeight) {
    return minHeight;
  }
  if (!!maxHeight && scrollHeight >= maxHeight) {
    return maxHeight;
  }
  return scrollHeight;
};

export type AutoSizeTextAreaOptions = {
  minRows: number;
  maxRows?: number;
};

const useAutosizeTextArea = (
  useAutoSize: boolean,
  textAreaRef: RefObject<HTMLTextAreaElement>,
  valueToWatch?: string,
  autoSizeOptions?: AutoSizeTextAreaOptions,
) => {

  useEffect(() => {
    if (useAutoSize && textAreaRef.current) {
      // Reset height, so scrollHeight corresponds to the desired height
      textAreaRef.current.style.height = "0px";
      const scrollHeight = textAreaRef.current.scrollHeight;
      const rowHeight = getRowHeight(textAreaRef.current);
      const paddingHeight = getPaddingHeight(textAreaRef.current);
      const minHeight = calculateRowsHeight(rowHeight, paddingHeight, autoSizeOptions?.minRows);
      const maxHeight = calculateRowsHeight(rowHeight, paddingHeight, autoSizeOptions?.maxRows);
      const resizedHeight = calculateDesiredHeight(scrollHeight, minHeight, maxHeight);

      textAreaRef.current.style.height = `${resizedHeight}px`;
      textAreaRef.current.style.overflow = (!maxHeight || resizedHeight < maxHeight) ? "hidden" : "auto";
    }
  }, [textAreaRef, useAutoSize, valueToWatch, autoSizeOptions?.minRows, autoSizeOptions?.maxRows]);
};

export default useAutosizeTextArea;
