import { Middleware } from "@reduxjs/toolkit";
import { HeaderKeys } from "@infrastructure/headerKeys";
import { StorageKeys } from "@infrastructure/storageKeys";
import localizationApi from "@services/api/localizationApi";
import { apiTags } from "@services/api/baseApi";

export const localizationMiddleware: Middleware =
  (api) => (next) => (action) => {
    const backendLocalizationVersion = action?.meta?.baseQueryMeta?.response?.headers.get(HeaderKeys.localizationVersion);

    if (backendLocalizationVersion) {
      const localLocalizationVersion = localStorage.getItem(StorageKeys.localizationVersion);

      if (localLocalizationVersion !== backendLocalizationVersion) {
        localStorage.setItem(StorageKeys.localizationVersion, backendLocalizationVersion);
        api.dispatch(localizationApi.util.invalidateTags([apiTags.localization]));
      }
    }

    return next(action);
  };
