import classNames from "classnames";
import { InputHTMLAttributes } from "react";
import { FieldValues, get  } from "react-hook-form";
import { FormControlProps } from "./FormControlProps.model";
import { FormInputLabel } from "./formInputLabel";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormInput = <T extends FieldValues = any>({
  methods: { register, formState },
  label,
  name,
  required,
  disabled,
  hideErrorText,
  formGroupClassName,
  ...inputProps
}: FormControlProps<T, InputHTMLAttributes<HTMLInputElement>>) => {
  const error = get(formState.errors, name);
  return (
    <div className={classNames("form-group position-relative", formGroupClassName)}>
      {label &&
        <FormInputLabel
          htmlFor={inputProps.id}
          labelText={label}
          isRequired={required}
        />
      }

      <input
        className={`form-control ${error ? "input-validation-error" : ""}`}
        {...register(name, { disabled })}
        {...inputProps}
      />

      {error && !hideErrorText && <span className="errorMessage field-validation-error">{error.message}</span>}
    </div>
  );
};

export default FormInput;

