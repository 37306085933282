export type MentionModel = {
  mentionedUserId: string;
  referenceId: string;
  mentionType: MentionTypeEnum;
};


export enum MentionTypeEnum{
  Comment = 1,
  Reply = 2,
}
