import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Language } from "@infrastructure/language";
import { StorageKeys } from "@infrastructure/storageKeys";
import type { AppThunkAction, RootState } from "@app";

export interface LocalizationState {
  language: Language
}

const getDefaultLanguage = (): Language => {
  const storedLanguage = localStorage.getItem(StorageKeys.currentLanguage);

  const browserLanguage = navigator.language;
  let defaultLanguage = Language.Danish;

  if (storedLanguage)
    defaultLanguage = parseInt(storedLanguage, 10) as Language;
  else if (browserLanguage && browserLanguage === "da-DK")
    defaultLanguage = Language.Danish;
  else if (browserLanguage && browserLanguage.startsWith("en"))
    defaultLanguage = Language.English;

  return defaultLanguage;
};

const initialState: LocalizationState = {
  language: getDefaultLanguage(),
};

export const localizationSlice = createSlice({
  name: "localization",
  initialState,
  reducers: {
    setLanguageToState: (state, action: PayloadAction<Language>) => {
      state.language = action.payload;
    },
  },
});

export const setLanguage = (lang: Language): AppThunkAction => (dispatch) => {
  localStorage.setItem(StorageKeys.currentLanguage, lang.toString());
  dispatch(localizationSlice.actions.setLanguageToState(lang));
};

export const localizationStateSelector = (state: RootState) => state.localization;
export default localizationSlice.reducer;
