import React, { useCallback, useEffect, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrop } from "react-dnd";
import { v4 as uuid } from "uuid";
import { useLocalization } from "@components/localization/localizationProvider";
import { useLazyGetTableOfContentsInfoExtractDraftQuery } from "@services/api/extractDraft/extractDraftApi";
import { Spinner } from "@components/spinner/spinner";
import ExtractDraftBundleDnD from "@pages/extractCompositionPage/components/bundleSplitter/extractDraftBundleDnD";
import { ExtractDraftBundleModel } from "@services/api/case/models/exportExtractDraftModel";
import {
  TableOfContentItemModel,
  TableOfContentSectionModel,
} from "@services/api/extractDraft/models/tableOfContentSectionModel";
import ExtractDraftBundleItemDnD
  from "@pages/extractCompositionPage/components/bundleSplitter/extractDraftBundleItemDnD";
import Button from "@components/embla/button";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { TableOfContentsInfoResponseModel } from "@services/api/extractDraft/models/tableOfContentsInfoResponseModel";

const calculateLastBundleItem = (tocData:  TableOfContentSectionModel[]) => {
  const flatBundleItems = tocData.flatMap((b) => b.children);
  const lastBundleItem = flatBundleItems.reduce((prev, current) => (prev && prev.pageNumber > current.pageNumber) ? prev : current);

  return lastBundleItem;
};

const calculateBundleEnding = (startIndex: number, bundles: ExtractDraftSplitterBundleModel[], tocData:  TableOfContentSectionModel[]) => {

  const nextBundle = bundles.find((b) => b.extractStartIndex > startIndex);
  const flatTocDocuments = tocData.flatMap((b) => b.children).sort((a, b) => a.pageNumber - b.pageNumber);

  if (nextBundle) {

    const nextBundleDocumentIndex = flatTocDocuments.findIndex((b) => b.extractDraftDocumentId === nextBundle.firstExtractDraftDocumentId);

    if (nextBundleDocumentIndex === -1) {
      throw new Error();
    }

    const lastBundleItem = flatTocDocuments[nextBundleDocumentIndex - 1];

    return { endIndex: nextBundle.extractStartIndex - 1, endExtractDocumentId: lastBundleItem.extractDraftDocumentId };
  } else if (tocData) {
    const lastBundleItem = calculateLastBundleItem(tocData);

    return { endIndex: (lastBundleItem.pageNumber - 1) + lastBundleItem.pageCount - 1, endExtractDocumentId: lastBundleItem.extractDraftDocumentId };
  } else {
    //shouldn't happen

    throw new Error();
  }
};

const hasBundlesChanged = (oldBundles: ExtractDraftSplitterBundleModel[], newBundles: ExtractDraftSplitterBundleModel[]) => {
  for (const b of newBundles) {
    const oldBundle = oldBundles.find((x) => x.id === b.id);

    const hasChanged = oldBundle?.extractStartIndex !== b.extractStartIndex || oldBundle?.extractEndIndex !== b.extractEndIndex || oldBundle?.firstExtractDraftDocumentId !== b.firstExtractDraftDocumentId || oldBundle?.lastExtractDraftDocumentId !== b.lastExtractDraftDocumentId;

    if (hasChanged) {
      return true;
    }
  }

  return false;
};

const recalibrateBundles = (bundlesToCalibrate: ExtractDraftSplitterBundleModel[], tocData:  TableOfContentSectionModel[] ) => {
  const newBundles = [...bundlesToCalibrate];
  const flatTocData = tocData.flatMap((b) => b.children).sort((a, b) => a.pageNumber - b.pageNumber);

  const calibratedBundles = newBundles.map((b) => {
    const bundleDocumentStart = flatTocData.find((x) => x.extractDraftDocumentId === b.firstExtractDraftDocumentId);

    if (!bundleDocumentStart)
      throw new Error();

    const bundleEnding = calculateBundleEnding(b.extractStartIndex, newBundles, tocData ?? []);
    return { ...b, extractEndIndex: bundleEnding.endIndex, lastExtractDraftDocumentId: bundleEnding.endExtractDocumentId, extractStartIndex: bundleDocumentStart?.pageNumber - 1 };
  });


  return hasBundlesChanged(bundlesToCalibrate, calibratedBundles) ? calibratedBundles : bundlesToCalibrate;
};

const findBundleIndex = (bundleItem: TableOfContentItemModel, bundles: ExtractDraftSplitterBundleModel[]) => {
  return bundles.findIndex((b) => b.firstExtractDraftDocumentId === bundleItem.extractDraftDocumentId);
};

const generateInitialBundles = (tocDataResponse: TableOfContentsInfoResponseModel, initialBundlePageCount: number) => {
  const initialBundles: ExtractDraftSplitterBundleModel[] = [];

  //Add top bundle
  const initialBundleEnding = calculateBundleEnding(tocDataResponse.sections[0].pageNumber - 1, initialBundles, tocDataResponse.sections);
  initialBundles.push({ extractStartIndex: tocDataResponse.sections[0].children[0].pageNumber - 1, extractEndIndex: initialBundleEnding.endIndex, id: uuid(), firstExtractDraftDocumentId: tocDataResponse.sections[0].children[0].extractDraftDocumentId, lastExtractDraftDocumentId: initialBundleEnding.endExtractDocumentId });

  const flatTocDocuments = tocDataResponse.sections.flatMap((b) => b.children).sort((a, b) => a.pageNumber - b.pageNumber);
  const lastTocChild = flatTocDocuments[flatTocDocuments.length - 1];
  if (initialBundlePageCount < lastTocChild.pageNumber) {

    let bundleCounter = 1 + tocDataResponse.pageCount;
    for (const tocDocument of flatTocDocuments) {
      bundleCounter += tocDocument.pageCount;

      if (bundleCounter >= initialBundlePageCount) {
        bundleCounter = 1 + tocDataResponse.pageCount + tocDocument.pageCount;

        const bundleEnding = calculateBundleEnding(tocDocument.pageNumber - 1, initialBundles, tocDataResponse.sections);
        initialBundles.push({ extractStartIndex: tocDocument.pageNumber - 1, extractEndIndex: bundleEnding.endIndex, id: uuid(), firstExtractDraftDocumentId: tocDocument.extractDraftDocumentId, lastExtractDraftDocumentId: bundleEnding.endExtractDocumentId });
      }
    }
  }

  return recalibrateBundles(initialBundles, tocDataResponse.sections);
};

interface BundleSplitterProps {
  initialBundlePageCount: number;
  caseId: string;
  extractId: string;
  bundleChangeCallback: (bundles: ExtractDraftBundleModel[]) => void;
}

export interface ExtractDraftSplitterBundleModel extends ExtractDraftBundleModel {
  id: string;
  extractStartIndex: number;
  extractEndIndex: number;
}


const ExtractDraftBundleSplitter = ({ initialBundlePageCount, bundleChangeCallback, caseId, extractId }: BundleSplitterProps) => {
  const localizer = useLocalization();

  const [bundles, setBundles] = useState<ExtractDraftSplitterBundleModel[]>([]);
  const [getTocData] = useLazyGetTableOfContentsInfoExtractDraftQuery();
  const memoizedGetTocData = useCallback(getTocData, [getTocData]);
  const [tocData, setTocData] = useState<TableOfContentsInfoResponseModel | undefined>(undefined);

  const [hasDroppedRecently, setHasDroppedRecently] = useState(false);

  useEffect(() => {
    if (bundles.length > 0) {

      bundleChangeCallback(bundles);

      if (hasDroppedRecently) {
        memoizedGetTocData({ caseId: caseId, extractDraftId: extractId, bundles }, true).unwrap().then((response) => {
          setTocData(response);

          setBundles((x) => recalibrateBundles(x, response.sections));
          setHasDroppedRecently(false);
        });
      }
    }
  }, [bundleChangeCallback, bundles, caseId, extractId, hasDroppedRecently, memoizedGetTocData]);

  const dropCallback = useCallback(() => {
    setHasDroppedRecently(true);
  }, []);

  useEffect(() => {
    const initializeBundles = async () => {
      const tocDataResponse = await getTocData({ caseId: caseId, extractDraftId: extractId, bundles }, true).unwrap();
      const initialBundles = generateInitialBundles(tocDataResponse, initialBundlePageCount);

      const tocDataResponse2 = await getTocData({ caseId: caseId, extractDraftId: extractId, bundles: initialBundles }, true).unwrap();
      const calibratedBundles = generateInitialBundles(tocDataResponse2, initialBundlePageCount);

      setBundles(calibratedBundles);
      setTocData(tocDataResponse2);
    };

    if (bundles.length === 0) {
      initializeBundles();
    }
  }, [bundles, bundles.length, caseId, dropCallback, extractId, getTocData, initialBundlePageCount, tocData]);

  const bundleItemHoverCallback = useCallback((bundle: ExtractDraftSplitterBundleModel, bundleHoverInfo: { hoverIndex: number, hoverDocumentId: string }) => {
    setBundles((prev) => {
      const newBundles = [...prev].sort((a, b) => a.extractStartIndex - b.extractStartIndex);
      const bundleIndex = newBundles.findIndex((b) => b.id === bundle.id);

      const aboveBundle = bundleIndex > 0 ? newBundles[bundleIndex - 1] : undefined;
      const belowBundle = bundleIndex < newBundles.length - 1 ? newBundles[bundleIndex + 1] : undefined;
      if ((aboveBundle && aboveBundle.extractStartIndex >= bundleHoverInfo.hoverIndex) || (belowBundle && belowBundle?.extractStartIndex <= bundleHoverInfo.hoverIndex)) {
        //If a bundle is already at the hover index, do nothing
        return prev;
      }

      const bundleId = newBundles[bundleIndex].id;

      if (bundleIndex >= 0) {
        newBundles.splice(bundleIndex, 1);

        const bundleEnding = calculateBundleEnding(bundleHoverInfo.hoverIndex, newBundles, tocData?.sections ?? []);
        const newBundle: ExtractDraftSplitterBundleModel = { extractStartIndex: bundleHoverInfo.hoverIndex, extractEndIndex: bundleEnding.endIndex, id: bundleId, firstExtractDraftDocumentId: bundleHoverInfo.hoverDocumentId, lastExtractDraftDocumentId: bundleEnding.endExtractDocumentId, title: bundle.title };
        newBundles.splice(bundleIndex, 0, newBundle);

        const calibratedBundles = recalibrateBundles(newBundles, tocData?.sections ?? []);

        return hasBundlesChanged(prev, calibratedBundles) ? calibratedBundles : prev;
      } else {
        throw new Error("bundle doesn't exist");
      }
    });
  }, [tocData]);


  //Hack to get a bigger dropzone
  const [{ isOver }, drop] = useDrop({
    accept: "bundle",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: () => {
      dropCallback();
    },
  }, [dropCallback]);

  const addNewBundle = useCallback(() => {
    setBundles((prev) => {
      const newBundles = [...prev];
      const lastBundle = newBundles[newBundles.length - 1];

      const lastBundleItem = calculateLastBundleItem(tocData?.sections ?? []);

      if (lastBundleItem.pageNumber - 1 !== lastBundle.extractStartIndex) {
        const id: string = uuid();
        const bundleEnding = calculateBundleEnding(lastBundle.extractEndIndex + 1, newBundles, tocData?.sections ?? []);
        const newBundle: ExtractDraftSplitterBundleModel = { extractStartIndex: lastBundleItem.pageNumber - 1, extractEndIndex: bundleEnding.endIndex, id: id, firstExtractDraftDocumentId: lastBundleItem.extractDraftDocumentId, lastExtractDraftDocumentId: bundleEnding.endExtractDocumentId };
        newBundles.push(newBundle);

        return recalibrateBundles(newBundles, tocData?.sections ?? []);
      } else {
        return prev;
      }
    });
  }, [tocData]);

  const deleteBundle = (bundle: ExtractDraftSplitterBundleModel) => {
    setBundles((prev) => {
      const newBundles = [...prev].filter((b) => b.id !== bundle.id);
      return recalibrateBundles(newBundles, tocData?.sections ?? []);
    });
  };

  const handleBundleTitleChange = (title: string, bundleItem: TableOfContentItemModel) => {
    setBundles((prev) => {
      const newBundles = [...prev];
      const bundleIndex = findBundleIndex(bundleItem, newBundles);
  
      if (bundleIndex >= 0) {
        const updatedBundle = {
          ...newBundles[bundleIndex],
          title: title,
        };

        newBundles[bundleIndex] = updatedBundle;
        return newBundles;
      } else {
        return prev;
      }
    });
  };


  return !tocData ? (<Spinner />) :
    (
      <div>
        <p>{localizer.extractDraftBundleInfo1()}</p>
        <div className="alert alert-info p-2 rounded my-4">
          <p>{localizer.extractDraftBundleInfo2()}</p>
          <div>
            <div className="d-flex justify-content-between">
              <span>
                <span>•</span>
                <span className="col-8">{localizer.frontpage()}</span>
              </span>
              <span>1-1</span>
            </div>
            <div className="d-flex justify-content-between">
              <span>
                <span>•</span>
                <span className="col-8">{localizer.tableOfContents()}</span>
              </span>
              <span>2-{1 + tocData.pageCount}</span>
            </div>
          </div>
        </div>
        <DndProvider backend={HTML5Backend}>
          <ul className="list-unstyled" ref={drop} >
            {tocData.sections.map((bundleSection, sectionIndex) => (
              <li key={bundleSection.title + sectionIndex}>
                {findBundleIndex(bundleSection.children[0], bundles) >= 0 && (
                  <ExtractDraftBundleDnD
                    key={"bundleDnd" + findBundleIndex(bundleSection.children[0], bundles)}
                    bundleModel={bundles[findBundleIndex(bundleSection.children[0], bundles)]}
                    bundleNumber={findBundleIndex(bundleSection.children[0], bundles) + 1}
                    deleteCallback={deleteBundle}
                    tableOfContentsPageCount={tocData.pageCount}
                    additionalClassNames={"mb-2"}
                    onChangeBundleTitle={(title) => handleBundleTitleChange(title, bundleSection.children[0])}
                  />
                )}
                <span className="d-flex">
                  <span>• </span>
                  <span className="font-weight-bold ml-2">{bundleSection.title}</span>
                </span>
                <ul className="list-unstyled">
                  {bundleSection.children.map((bundleItem, itemIndex) => (
                    <div key={"childList" + itemIndex + bundleItem.extractDraftDocumentId}>
                      {findBundleIndex(bundleItem, bundles) >= 0 && bundleSection.children[0] !== bundleItem && (
                        <ExtractDraftBundleDnD
                          key={"bundleDnd" + findBundleIndex(bundleItem, bundles)}
                          bundleModel={bundles[findBundleIndex(bundleItem, bundles)]}
                          onChangeBundleTitle={(title) => handleBundleTitleChange(title, bundleItem)}
                          bundleNumber={findBundleIndex(bundleItem, bundles) + 1}
                          deleteCallback={deleteBundle}
                          tableOfContentsPageCount={tocData.pageCount}
                        />
                      )}
                      <ExtractDraftBundleItemDnD bundleItem={bundleItem} itemIndex={itemIndex} dndHoverCallback={bundleItemHoverCallback}/>
                    </div>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <div className="background-gray rounded" role="button" onClick={addNewBundle}>
            <Button borderless className="d-flex text-center justify-content-center align-content-center">
              <EmblaIcon iconName="plus"/>
              <span className="ml-2">{localizer.addBundle()}</span>
            </Button>
          </div>
        </DndProvider>
      </div>
    );
};

export default ExtractDraftBundleSplitter;

