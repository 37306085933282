import { IMergeFieldModel, MergefieldPlugin, RichTextEditorModule } from "ditmer-embla";
import { useEffect, useRef, useState } from "react";
import { emptyString } from "react-select/dist/declarations/src/utils";

type EditorType = "single-line" | "multi-line";

export type MergefieldEditorProps = {
  id: string;
  className?: string,
  type: EditorType
  toolbarItems?: string[];
  mergefields: IMergeFieldModel[];
  mergefieldAllowLinks: boolean;
  defaultValue?: string;
  onChange?: (value: string) => void;
  disableMergePlugin?: boolean;
};

export const MergefieldEditor: React.FC<MergefieldEditorProps> = ({
  id,
  className,
  type,
  toolbarItems = [],
  mergefields,
  mergefieldAllowLinks,
  defaultValue,
  onChange,
  disableMergePlugin,
}) => {
  const [editor, setEditor] = useState<toastui.Editor>();
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  useEffect(() => {
    const initializeEditor = () => {
      if (mergefields.length <= 0 && !disableMergePlugin) {
        return undefined;
      }

      const mergefieldPlugin = new MergefieldPlugin({
        mergeFields: mergefields,
        allowLinks: mergefieldAllowLinks,
      });

      const sanitzeHtmlContent = (content: string) => {
        // Editor encodes brackets inside links as "%5B" and "%5D"
        // Convert it back:
        const sanitizedContent = content.replaceAll("%5B", "[").replaceAll("%5D", "]");
        return sanitizedContent;
      };

      const singleLine = type === "single-line";

      const module: RichTextEditorModule = new RichTextEditorModule({
        textArea: "#" + id,
        minHeight: singleLine ? "auto" : "300",
        preventNewLine: singleLine,
        customPlugins: disableMergePlugin ? undefined : [mergefieldPlugin.getPlugin()],
        additionalRichTextEditorSettings: {
          customHTMLSanitizer: sanitzeHtmlContent,
        } as toastui.EditorOptions,
        toolbarItems: {
          customToolbar: disableMergePlugin ? toolbarItems : [...toolbarItems, "mergefields"],
        },
        onEditorChanged: () => onChangeRef.current?.(module.editor.getMarkdown()),
      });
      return module;
    };

    if (!editor) {
      const editorModule = initializeEditor();
      setEditor(editorModule?.editor);
    }
  }, [editor, id, mergefieldAllowLinks, mergefields, toolbarItems, type]);

  useEffect(() => {
    if (!defaultValue || defaultValue?.length === 0 )
      editor?.reset();
  }, [editor, defaultValue]);

  return (
    <textarea
      id={id}
      defaultValue={defaultValue}
      className={className}
    />
  );
};
