import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";

export type BackBtnCallbackResult = "skip-back-navigation" | "" | string;

type BackButtonProps = {
  onClickCallback?: () => BackBtnCallbackResult;

} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const BackButton = ({ onClickCallback, className, ...buttonProps }: BackButtonProps) => {
  const navigate = useNavigate();
  const localizer = useLocalization();

  const onClick = () => {
    if (onClickCallback) {
      const result = onClickCallback();

      if (result === "skip-back-navigation") {
        return;
      }

      navigate(result);
      return;
        
    }
    navigate(-1);
  };

  return (
    <button
      type="button"
      className={classNames("btn btn-default btn-icon", className)}
      aria-label={localizer.goBack()}
      onClick={onClick}
      {...buttonProps}
    >
      <EmblaIcon iconName="back" />
    </button>
  );
};

export default BackButton;
