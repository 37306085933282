import { MarkingActiveStateType } from "@pages/pdfviewer/component/models/activeMarkingInfo";
import { CommentMarkingModel } from "../../../models/markingModel";
import CardProps from "../shared/cardProps.model";
import CommentEdit from "./commentEdit";
import CommentPreview from "./commentPreview";

const CommentCardContent = ({ activeState, ...props }: CardProps<CommentMarkingModel>) => {
  return activeState === MarkingActiveStateType.Edit
    ? <CommentEdit {...props} />
    : <CommentPreview marking={props.marking} onReplyCreateEdit={props.onReplyCreateEdit} editMarkingClicked={props.editMarkingClicked} repliesOpen={activeState === MarkingActiveStateType.Replies}/>;
};

export default CommentCardContent;
