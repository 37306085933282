import { useDrag, useDrop } from "react-dnd";
import { useState, useRef, useEffect } from "react";
import { useAddExtractDraftDocumentsMutation, useDeleteDocumentFromSectionExtractDraftMutation, useReorderExtractDraftSectionDocumentMutation } from "@services/api/extractDraft/extractDraftApi";
import { ExtractDraftSectionDocumentModel } from "@services/api/extractDraft/models/extractDraftSectionDocumentModel";
import { CaseDocumentModel } from "@services/api/case/models/caseDocumentModel";
import { DocumentSortType } from "@services/api/extractDraft/models/extractDraftSectionModel";
import { IntervalExtractionType } from "@components/case/extractDraft/addDocumentsToDraftModel";
import { dndItemTypes } from "../components/ExtractSections/DndItemTypes";
import { useExtractCompositionContext } from "../extractCompositionContextProvider";

export type ReorderExtractDraftSectionDocument = {
  document: ExtractDraftSectionDocumentModel;
  sectionId: string;
};

type UseDragDropSectionDocumentReorder = {
  documentData: ExtractDraftSectionDocumentModel,
  sectionId: string, extractDraftId:string,
  caseId: string,
  documentSortType: DocumentSortType,
  index: number,
  onlySelectVerticalLines:boolean
};

export const pageIntervalsToString = (intervals: {
  firstPageIndex: number;
  lastPageIndex: number;
}[]): string => {
  return intervals.map((interval) => `${interval.firstPageIndex + 1}-${interval.lastPageIndex + 1}`).join(", ");
};


export const useDragDropSectionDocumentReorder = ({ caseId, documentData, documentSortType, extractDraftId, index, onlySelectVerticalLines, sectionId }: UseDragDropSectionDocumentReorder) => {
  // For drag preview
  const [draggedItemWidth, setDraggedItemWidth] = useState(0);
  const [position, setPosition] = useState<"above" | "below" | null>(null);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const [droppedDocument, setDroppedDocument] = useState<CaseDocumentModel | ReorderExtractDraftSectionDocument | null>(null);

  const { selectedDocumentsReducer } = useExtractCompositionContext();
  
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      setDraggedItemWidth(divRef.current.offsetWidth / 2);
    }
  }, [divRef]);
  
  const [ deleteDocument ] = useDeleteDocumentFromSectionExtractDraftMutation();
  const [ addDocumentsToDraft ] = useAddExtractDraftDocumentsMutation();
  const [ reorder ] = useReorderExtractDraftSectionDocumentMutation();
  
  const moveDocument = async () => {
    if (droppedDocument === null) {
      return;
    }
    
    let newSortOrder = index;

    if ("sectionId" in droppedDocument) {
      const { document } = droppedDocument;
      
      
      if (droppedDocument.sectionId === sectionId) {
        if (document.sortOrder > documentData.sortOrder && position === "below") {
          newSortOrder += 1;
        }

        reorder({
          caseId,
          extractDraftId,
          documentId: document.id,
          newSortOrder,
          sectionId: droppedDocument.sectionId,
        });
      } else {
        if (position === "below") {
          newSortOrder += 1;
        }
        deleteDocument({
          caseId: caseId,
          documentId: document.id,
          extractDraftId,
          sectionId: droppedDocument.sectionId,
        });

        const addDocumentModel = {
          sectionId,
          documents: [
            {
              caseDocumentId: document.caseDocumentId,
              pageIntervals: pageIntervalsToString(document.pageIntervals),
              intervalExtractionType: document.intervalExtractionType,
              sortOrder: newSortOrder,
            },
          ],
        };

        addDocumentsToDraft({
          caseId,
          extractDraftId,
          model: addDocumentModel,
        });
      }
    } else {

      if (position === "below") {
        newSortOrder += 1;
      }

      const selectedDocuments = selectedDocumentsReducer.state.selectedDocuments;

      const model = {
        sectionId,
        documents: [{
          caseDocumentId: droppedDocument.id,
          pageIntervals: "",
          intervalExtractionType: onlySelectVerticalLines ? IntervalExtractionType.PagesWithVerticalLines : IntervalExtractionType.AllDocument,
          sortOrder: newSortOrder,
        }],
      };

      if (selectedDocuments.length > 0) {
        const documents = selectedDocuments.map((doc, i) => ({
          caseDocumentId: doc.id,
          pageIntervals: "",
          intervalExtractionType: onlySelectVerticalLines ? IntervalExtractionType.PagesWithVerticalLines : IntervalExtractionType.AllDocument,
          sortOrder: newSortOrder + i,
        }));
        model.documents = documents;
      }


      await addDocumentsToDraft({
        caseId,
        extractDraftId,
        model,
      });

      selectedDocumentsReducer.dispatch({ selectedDocuments: [] });
    }
  };

  useEffect(()=> {
    if (droppedDocument === null) {
      return;
    }
    if ("sectionId" in droppedDocument) {
      if (droppedDocument.sectionId === sectionId && documentSortType === DocumentSortType.Custom) {
        moveDocument();
      } else if (droppedDocument.sectionId === sectionId && documentSortType !== DocumentSortType.Custom) {
        setShowWarningModal(true);
      } else if (droppedDocument.sectionId !== sectionId) {
        moveDocument();
      }
    } else {
      moveDocument();
    }
  }, [droppedDocument]);

  const [{ isDragging }, drag] = useDrag({
    type: dndItemTypes.document,
    item: {
      document: documentData,
      sectionId,
      width: draggedItemWidth,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropDocument] = useDrop({
    accept: dndItemTypes.document,
    drop: async (dropped: CaseDocumentModel | ReorderExtractDraftSectionDocument) => {
      setDroppedDocument(dropped);
    },
    hover: (item, monitor) => {
      if (!divRef.current) {
        return;
      }
      const hoverBoundingRect = divRef.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      if (clientOffset) {
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      
        if (hoverClientY < hoverMiddleY) {
          setPosition("above");
        } else {
          setPosition("below");
        }
      }
    },
    collect: (monitor) => {
      if (!monitor.isOver()) {
        setPosition(null);
      }
      return {
        isOver: monitor.isOver(),
      };
    },
  });

  drag(divRef);
  dropDocument(divRef);

  return { divRef, position, moveDocument, showWarningModal, setShowWarningModal, isDragging };
};