import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@app";

export interface PdfSidebarState {
  sidebarCollapsed: boolean;
}

const initialState: PdfSidebarState = {
  sidebarCollapsed: true,
};

export const pdfSidebarSlice = createSlice({
  name: "pdfsidebar",
  initialState,
  reducers: {
    setSidebarCollapsed: (state, action: PayloadAction<boolean>) => {
      state.sidebarCollapsed = action.payload;
    },
  },
});

export const {
  setSidebarCollapsed,
} = pdfSidebarSlice.actions;

export const pdfSidebarStateSelector = (state: RootState) => state.pdfSidebar;

export default pdfSidebarSlice.reducer;
