import { ReactNode, Ref, forwardRef, useMemo } from "react";
import { Dimensions } from "@components/resizable/resizable";
import { ElementSize } from "src/hooks/useElementSize";
import styles from "./zoomedPdfContainer.module.scss";

interface ZoomedPdfContainerProps {
  zoomArea?: Dimensions;
  containerSize: ElementSize;
  scale?: number;
  className?: string;
  children: ReactNode;
}

const ZoomedPdfContainer = forwardRef((
  {
    zoomArea,
    containerSize,
    scale = 1,
    className,
    children,
  }: ZoomedPdfContainerProps,
  ref: Ref<HTMLDivElement>,
) => {
  const scaledZoomArea: Dimensions | undefined = useMemo(() => {
    return zoomArea && ({
      x: zoomArea.x * scale,
      y: zoomArea.y * scale,
      height: zoomArea.height * scale,
      width: zoomArea.width * scale,
    });
  }, [zoomArea, scale]);

  if (!scaledZoomArea) {
    return (
      <div className={className} ref={ref}>
        {children}
      </div>
    );
  }

  return (
    <div
      className={className}
      style={{
        position: "absolute",
        // zoom area position minus half of the height/width difference to center it 
        top: -1 * (scaledZoomArea.y - (containerSize.height - scaledZoomArea.height) / 2),
        left: -1 * (scaledZoomArea.x - (containerSize.width - scaledZoomArea.width) / 2),
      }}
      ref={ref}
    >
      {children}
      <div
        className={styles.zoomArea}
        style={{
          top: scaledZoomArea.y,
          left: scaledZoomArea.x,
          width: scaledZoomArea.width,
          height: scaledZoomArea.height,
        }}
      />
    </div>
  );
});

export default ZoomedPdfContainer;
