import { PaginatedListOutput } from "@models/paginatedList/paginatedListOutput";
import { CaseBaseReturnModel } from "@services/api/case/models/caseBaseReturnModel";
import { CaseCreateModel, CaseEditModel } from "@services/api/case/models/caseModel";
import baseApi, { apiTags } from "../baseApi";
import { MarkingTag } from "../sharedModels/markingTagModel";
import { buildQueryString } from "../queryStringBuilder";
import { CaseAccessRightsModel } from "./models/caseAccessRightsModel";
import { CasePaginatedListInput } from "./models/casePaginatedListInput";
import { CasePaginatedListModel } from "./models/casePaginatedListModel";


const caseUrl = "/api/case";
const caseDetailsUrl = (caseId: string) => `${caseUrl}/${caseId}`;
const caseAccessRightsUrl = (caseId: string) => `${caseDetailsUrl(caseId)}/caseAccessRights`;
const caseMarkingTagsUrl = (caseId: string) => `${caseDetailsUrl(caseId)}/marking/tags`;
const casesPaginatedUrl = (casePaginatedListInput: CasePaginatedListInput) => `${caseUrl}/paginatedList?${buildQueryString(casePaginatedListInput)}`;

const editCaseUrl = (caseId: string) => `${caseUrl}/${caseId}`;
const deleteCaseUrl = (caseId: string) => `${caseUrl}/${caseId}`;

const caseApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({

    // Case
    getCase: builder.query<CaseBaseReturnModel, string>({
      query: (caseId) => ({
        url: caseDetailsUrl(caseId),
        method: "GET",
      }),
      transformResponse: (original: CaseBaseReturnModel) => {
        if (!original) {
          return original;
        }

        return Promise.all(original.caseParticipants.map((x) => !x.profilePictureUrl ? Promise.resolve(x) : fetch(x.profilePictureUrl)
          .then((response) => response.blob())
          .then((blob) => {
            return {
              ...x,
              profilePictureUrl: URL.createObjectURL(blob),
            };
          }),
        )).then((result) => {
          return {
            ...original,
            caseParticipants: result,
          };
        });
      },
      onCacheEntryAdded: async (_: unknown, api: { cacheDataLoaded: Promise<{ data: CaseBaseReturnModel }>, cacheEntryRemoved: Promise<void> }) => {
        const { data } = await api.cacheDataLoaded;
        await api.cacheEntryRemoved;
        if (data.caseParticipants.length > 0) {
          data.caseParticipants.forEach((x) => x.profilePictureUrl && URL.revokeObjectURL(x.profilePictureUrl));
        }
      },
      providesTags: (result) => result ? [{
        type: apiTags.case,
        id: result.id,
      }] : [],
    }),

    getCaseRights: builder.query<CaseAccessRightsModel, string>({
      query: (caseId) => ({
        url: caseAccessRightsUrl(caseId),
        method: "GET",
      }),
      providesTags: (result) => result ? [{
        type: apiTags.caseUserAccessRights,
        id: result.participantId,
      }] : [],
    }),

    getCaseMarkingTags: builder.query<MarkingTag[], { caseId: string }>({
      query: ({ caseId }) => caseMarkingTagsUrl(caseId),
      providesTags: (result) => result ? [...result.map((m) => ({ type: apiTags.markingTag, id: m.id })), apiTags.markingTag] : [],
    }),

    getCases: builder.query<PaginatedListOutput<CasePaginatedListModel>, CasePaginatedListInput>({
      query: (input) => ({
        url: casesPaginatedUrl(input),
        method: "GET",
      }),
      providesTags: (result) => [apiTags.case, ...result?.data.map((c) => ({ type: apiTags.case, id: c.id })) ?? []],
    }),

    createCase: builder.mutation<undefined, CaseCreateModel>({
      query: (model) => ({
        url: caseUrl,
        method: "POST",
        body: model,
      }),
      invalidatesTags: () => [apiTags.case, apiTags.client],
    }),

    editCase: builder.mutation<CaseEditModel, CaseEditModel>({
      query: (model) => ({
        url: editCaseUrl(model.id),
        method: "PATCH",
        body: model,
      }),
      invalidatesTags: (result) => result ? [{ type: apiTags.case, id: result.id }, apiTags.case, apiTags.client] : [], //Rerender case lists: Extra apiTags.case
    }),

    deleteCase: builder.mutation<undefined, { caseId: string }>({
      query: (model) => ({
        url: deleteCaseUrl(model.caseId),
        method: "DELETE",
      }),
      invalidatesTags: (_, error) => !error ? [apiTags.case] : [],
    }),
  }),
});
export default caseApi;
export const {
  useGetCaseQuery,
  useGetCaseRightsQuery,
  useGetCaseMarkingTagsQuery,
  useGetCasesQuery,
  useCreateCaseMutation,
  useEditCaseMutation,
  useDeleteCaseMutation,
} = caseApi;
export const { resetApiState } = caseApi.util;
