import * as yup from "yup";
import { Localizer } from "@components/localization/localizer";
import { createValidationSchema } from "@components/validation/createValidationSchema";
import { EditCaseDocumentModel } from "@services/api/document/models/editCaseDocumentModel";

export const editCaseDocumentValidation = (localizer: Localizer) => createValidationSchema<EditCaseDocumentModel>({
  documentName: yup.string().required(localizer.documentTitleRequired()).max(2000, localizer.maxLength2000()),
  type: yup.number(),
  date: yup.date().nullable(),
  caseDocumentNumber:yup.string().nullable().max(15, localizer.maxLength15()),
});
