import { AccessRight } from "@infrastructure/AccessRight";
import { UserProfileModel } from "@models/profile/userProfileModel";
import { UserProfilePictureModel } from "@models/profile/userProfilePictureModel";
import { UserInfoModel } from "@models/userInfoModel";
import { getFormData } from "@services/helpers/formData";
import baseApi, { apiTags } from "../baseApi";
import { ChangeLanguageModel } from "./models/changeLanguageModel";
import { ChangePasswordModel } from "./models/changePasswordModel";

const loginUrl = "/token";
const getUserInfoUrl = "/api/user/current";
const getProfileUrl = () => "/api/user/profile";
const updateProfileUrl = (id: string) => `/api/user/profile/${id}`;
const profilePictureUrl = (id: string) => `/api/user/profile/${id}/profilepicture`;
const profilePictureUrlUrl = (id: string) => `/api/user/profile/${id}/profilepictureurl`;
const updatePasswordUrl = "/api/user/password";
const updateLanguageUrl = "/api/user/language";

const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<{ token: string }, { username: string, password: string }>({
      query: (model) => ({
        url: loginUrl,
        method: "POST",
        body: model,
      }),
    }),
    getUserInfo: builder.query<UserInfoModel, void>({
      query: () => getUserInfoUrl,
      transformResponse: (userResponse: UserInfoModel) => {
        if (
          userResponse.accessRights.includes(AccessRight.SysAdmin)
          && !userResponse.accessRights.includes(AccessRight.AdministerOrg)
        ) {
          return {
            ...userResponse,
            accessRights: [...userResponse.accessRights, AccessRight.AdministerOrg],
          };
        }
        return userResponse;
      },
      providesTags: (result) => result ? [{
        type: apiTags.user,
        id: result.id,
      }] : [],
    }),
    getProfile: builder.query<UserProfileModel, void>({
      query: () => getProfileUrl(),
      providesTags: (result) => result ? [{
        type: apiTags.profile,
        id: result.id,
      }] : [],
    }),
    updateProfile: builder.mutation<UserProfileModel, UserProfileModel>({
      query: (model) => ({
        url: updateProfileUrl(model.id),
        method: "PUT",
        body: model,
      }),
      invalidatesTags: (result) => result ? [
        { type: apiTags.user, id: result.id },
        { type: apiTags.profile, id: result.id },
        apiTags.employee,
      ] : [],
    }),
    getProfilePictureUrl: builder.query<string, string>({
      query: (id) => ({
        url: profilePictureUrlUrl(id),
        responseHandler: (response) => response.text(),
      }),
      transformResponse: (original: string) => {
        if (!original) {
          return original;
        }
        return fetch(original)
          .then((response) => response.blob())
          .then((blob) => URL.createObjectURL(blob));
      },
      onCacheEntryAdded: async (_: unknown, api: { cacheDataLoaded: Promise<{ data: string }>, cacheEntryRemoved: Promise<void> }) => {
        const { data } = await api.cacheDataLoaded;
        await api.cacheEntryRemoved;

        if (data) {
          URL.revokeObjectURL(data);
        }
      },

      providesTags: () =>  [{
        type: apiTags.profilePicture,
      }],
    }),
    updateProfilePicture: builder.mutation<boolean, UserProfilePictureModel>({
      query: (model) => ({
        url: profilePictureUrl(model.id),
        method: "POST",
        body: getFormData(model),
      }),
      invalidatesTags: (result, _, model) => result ? [
        { type: apiTags.employee, id: model.employeeId },
        apiTags.profilePicture,
        apiTags.caseParticipant,
        apiTags.case,
      ] : [],
    }),
    deleteProfilePicture: builder.mutation<boolean, { userId: string, employeeId: string }>({
      query: (model) => ({
        url: profilePictureUrl(model.userId),
        method: "DELETE",
      }),
      invalidatesTags: (result, _, model) => [
        { type: apiTags.employee, id: model.employeeId },
        apiTags.profilePicture,
        apiTags.caseParticipant,
        apiTags.case,
      ],
    }),
    updatePassword: builder.mutation<boolean, ChangePasswordModel>({
      query: (model) => ({
        url: updatePasswordUrl,
        method: "PUT",
        body: model,
      }),
    }),
    updateLanguage: builder.mutation<boolean, ChangeLanguageModel>({
      query: (model) => ({
        url: updateLanguageUrl,
        method: "PUT",
        body: model,
      }),
    }),
  }),
});

export default userApi;
export const { useGetProfileQuery, useUpdatePasswordMutation, useUpdateLanguageMutation, useUpdateProfileMutation, useUpdateProfilePictureMutation,
  useGetProfilePictureUrlQuery, useGetUserInfoQuery, useLazyGetUserInfoQuery, useLoginMutation, useDeleteProfilePictureMutation } = userApi;
export const { resetApiState } = userApi.util;
