import classNames from "classnames";
import { forwardRef, Ref } from "react";
import "./card.scss";

const Card = forwardRef(({ className, ...htmlDivProps }: React.HTMLAttributes<HTMLDivElement>, ref: Ref<HTMLDivElement>) => (
  <div className={classNames("card", className)} ref={ref} {...htmlDivProps} />
));

const Header: React.FC<React.HTMLProps<HTMLDivElement>> = ({ children, className, ...htmlDivProps }) => (
  <div className={classNames("card-header", className)} {...htmlDivProps}>
    <h4 className="card-title">
      {children}
    </h4>
  </div>
);

const Body: React.FC<React.HTMLProps<HTMLDivElement>> = ({ className, ...htmlDivProps }) => (
  <div className={classNames("card-body", className)} {...htmlDivProps} />
);

const Footer: React.FC<React.HTMLProps<HTMLDivElement> & { alignRight?: boolean }> = ({ alignRight = true, className, ...htmlDivProps }) => (
  <div className={classNames("card-body", alignRight && "text-right", className)} {...htmlDivProps} />
);


// eslint-disable-next-line @typescript-eslint/naming-convention
export default Object.assign(Card, { Header, Body, Footer });
