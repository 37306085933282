import * as yup from "yup";
import { Localizer } from "@components/localization/localizer";
import { createValidationSchema } from "@components/validation/createValidationSchema";

export type ExtractDraftSectionCreateModel = {
  title: string;
  parentId?: string;
};


export const addSectionModelValidation = (localizer: Localizer) => createValidationSchema<ExtractDraftSectionCreateModel>({
  title: yup.string().required(localizer.titleRequired()).max(150, localizer.titleLengthValidation()),
  parentId: yup.string(),
});

