import classNames from "classnames";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import styles from "./addPageOutline.module.scss";

type AddPageOutlineProps = {
  onClick?: () => void;
  additionalClasses?: string;
};

const AddPageOutline = ({ onClick, additionalClasses }: AddPageOutlineProps) => {
  return (
    <div
      onClick={onClick}
      className={
        classNames(
          styles.outlineContainer,
          additionalClasses,
          onClick && styles.clickable,
        )
      }
    >
      <EmblaIcon iconName="plus" color={IconColor.Gray}/>
    </div>
  );
};

export default AddPageOutline;
