import { Fragment, useMemo } from "react";
import { Column } from "react-table";
import { useNavigate } from "react-router-dom";
import { nameof } from "ts-simple-nameof";
import { documentTabId } from "@pages/case/casePage";
import Card from "@components/embla/card";
import PaginatedTable from "@components/table/paginatedTable";
import { PaginatedListInput } from "@models/paginatedList/paginatedListInput";
import { useLocalization } from "@components/localization/localizationProvider";
import { CasePaginatedListModel } from "@services/api/case/models/casePaginatedListModel";
import { CasePaginatedListInput } from "@services/api/case/models/casePaginatedListInput";
import { useGetCasesQuery } from "@services/api/case/caseApi";
import { RoutePaths } from "@components/routing/routes";
import useDateFormatter from "src/hooks/useDateFormatter";
import ClampedParagraph from "@components/clampedParagraph/clampedParagraph";
import { ParticipantBadge } from "@components/case/participant/participantBadge/participantBadge";
import { BadgeSize } from "@components/badge/RoundBadgge";

type CaseTableProps = {
  title: string;
  casePaginatedListInput: CasePaginatedListInput;
  setPaginatedListInput: React.Dispatch<React.SetStateAction<PaginatedListInput>>;
  includeStatusColumn?: boolean;
};

export const CaseTable: React.FC<CaseTableProps> = ({ title, casePaginatedListInput, setPaginatedListInput, includeStatusColumn }) => {
  const { data, isLoading, isFetching } = useGetCasesQuery(casePaginatedListInput);
  const dateFormatter = useDateFormatter();
  const showMaxParticipants = 2;

  const navigate = useNavigate();
  const localizer = useLocalization();

  const getColumns = useMemo(() => {
    const columns: Column<CasePaginatedListModel>[] = [
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.title(),
        accessor: "title",
        width: 300,
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.courtCaseNumber(),
        accessor: "courtCaseNumber",
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.client(),
        accessor: "clientName",
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.counterparts(),
        accessor: (dto) => (dto.counterpartNames ?? []).join(", "),
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.description(),
        accessor: "description",
        width: includeStatusColumn ? 250 : 350,
      },
      {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.participants(),
        accessor: (dto) => (
          <div className="d-flex">
            {dto.participants
              .slice(0, showMaxParticipants)
              .map((participant, index) => (
                <ParticipantBadge
                  key={index}
                  name={participant.name}
                  initials={participant.initials}
                  badgeContainerClassNames="margin-right-xs"
                  hideNameField={true}
                  size={BadgeSize.Small}
                />
              ))}
            {showMaxParticipants < dto.participants.length && (
              <ParticipantBadge
                key="dummyParticipantBadge"
                name={`+${dto.participants.length - showMaxParticipants}`}
                initials={`+${dto.participants.length - showMaxParticipants}`}
                badgeContainerClassNames="margin-right-xs"
                hideNameField={true}
                size={BadgeSize.Small}
              />
            )}
          </div>
        ),
        disableSortBy: true,
      },
    ];

    if (includeStatusColumn) {
      columns.push({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Header: localizer.status(),
        accessor: "statusDisplayString",
        disableSortBy: true,
        width: 100,
      });
    }

    columns.push({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Header: localizer.date(),
      accessor: (dto) => dateFormatter.shortDate(dto.date),
      id: nameof<CasePaginatedListModel>((c) => c.date), // define which column to sort on
    });
    return columns;
  }, [dateFormatter, includeStatusColumn, localizer]);

  return (
    <Card>
      <Card.Header>
        {data?.recordsFiltered} {title}
      </Card.Header>
      <PaginatedTable
        initialSort={{ columnName: nameof<CasePaginatedListModel>((c) => c.date), direction: "desc" }}
        columns={getColumns}
        data={data?.data ?? []}
        paginatedListInput={casePaginatedListInput}
        setPaginatedListInput={setPaginatedListInput}
        rowOnClick={(c) => navigate(RoutePaths.caseDetails.url(c.id, documentTabId))}
        noResultsOptions={{
          noResultsMessage: localizer.noResultsFound(),
        }}
        isLoading={isLoading}
        isFetching={isFetching}
        totalRecords={data?.recordsTotal ?? 0}
        hidePagination={!data || data.recordsFiltered <= casePaginatedListInput.length}
        hideSearchFilter={true}
        hidePageSizeFilter={true}
      />
    </Card>
  );
};
