import { useEffect,  useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { EmblaIcon } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import { useAppSelector } from "@hooks";
import { Localizer } from "@components/localization/localizer";
import { joyrideConstants } from "@components/joyrideOverlay/joyrideConstants";
import { PageDimensionsArray } from "../pageDimensions";
import { pdfSidebarStateSelector, setSidebarCollapsed } from "./pdfSidebarSlice";
import { PdfSidebarPages } from "./pdfSidebarPages";
import { PdfSidebarBookmarks } from "./pdfSidebarBookmarks";
import styles from "./pdfSidebar.module.scss";

enum SidebarContent {
  Pages = 0,
  Bookmarks = 1,
}

const getSidebarTitle = (content: SidebarContent, localizer: Localizer) => {
  switch (content) {
    case SidebarContent.Bookmarks: return localizer.pdfTableOfContents();
    case SidebarContent.Pages: return localizer.pdfPages();
  }
};

export const PdfSidebar = (props: { pdfContainerHeight: number, fileUrl: string, pdfDimensions: PageDimensionsArray, documentId?: string }) => {
  const pdfSidebarState = useAppSelector(pdfSidebarStateSelector);

  const dispatch = useDispatch();
  const localizer = useLocalization();

  const [sidebarContent, setSidebarContent] = useState<SidebarContent>(SidebarContent.Pages);
  const [sidebarTitle, setSidebarTitle] = useState<string>(localizer.pdfPages());


  const onThumbnailHeaderClick = () => {
    dispatch(setSidebarCollapsed(!pdfSidebarState.sidebarCollapsed));
  };

  const setSidebarContentClick = (e: React.MouseEvent<HTMLElement>, content: SidebarContent) => {
    setSidebarContent(content);
    e.stopPropagation();
  };

  useEffect(
    () => {
      setSidebarTitle(getSidebarTitle(sidebarContent, localizer));
    }, [sidebarContent, localizer]);

  useEffect(() => {
    if (pdfSidebarState.sidebarCollapsed && sidebarContent == SidebarContent.Bookmarks) {
      setSidebarContent(SidebarContent.Pages);
    }
  }, [pdfSidebarState.sidebarCollapsed, sidebarContent]);

  return (
    <div className={classNames(
      "card",
      joyrideConstants.documentViewerSidebarHeader,
      styles.documentWrapper,
      !pdfSidebarState.sidebarCollapsed && styles.expanded,
    )}>

      <div className={classNames("card-header")} role="button" onClick={onThumbnailHeaderClick}>
        <h4 className={classNames( "card-title")}>{sidebarTitle}</h4>

        {!pdfSidebarState.sidebarCollapsed &&
          <div>
            <div className="btn-group margin-left-s" role="group">
              <button  type="button" className="btn btn-default btn-icon" aria-label="Up" onClick={(e) => setSidebarContentClick(e, SidebarContent.Pages)}>
                <EmblaIcon iconName="document" />
              </button>
              <button type="button" className="btn btn-default btn-icon" aria-label="Down" onClick={(e) => setSidebarContentClick(e, SidebarContent.Bookmarks)}>
                <EmblaIcon iconName="list" />
              </button>
            </div>
          </div>
        }

        <EmblaIcon iconName={!pdfSidebarState.sidebarCollapsed ? "arrow-left" : "arrow-right"} />
      </div>
      <PdfSidebarPages pdfDimensions={props.pdfDimensions} fileUrl={props.fileUrl} documentId={props.documentId} className={classNames(sidebarContent !== SidebarContent.Pages && "d-none")}/>
      <PdfSidebarBookmarks className={classNames(sidebarContent !== SidebarContent.Bookmarks && "d-none")}/>
    </div>
  );
};
