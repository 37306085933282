import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { RootState } from "@app";

import { ThunkExtras } from "@store";

const createBaseQuery = (baseUrl?: string) => {
  const rawBaseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers, localApi) => {
      const state = localApi.getState() as RootState;
  
      const thunkExtras = localApi.extra as ThunkExtras;
      const tokenFromServices = await thunkExtras.userService?.getUserToken();
  
  
      if (state.localization.language)
        headers.append("Language", state.localization.language.toString());
  
  
      if (tokenFromServices)
        headers.append("Authorization", `bearer ${tokenFromServices}`);
  
      return headers;
    },
  });

  return rawBaseQuery;
};

export default createBaseQuery;