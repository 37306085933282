import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ParticipantBadge } from "@components/case/participant/participantBadge/participantBadge";
import { MarkingReplyModel } from "@pages/pdfviewer/component/models/markingModel";
import { useGetEmployeeQuery } from "@services/api/employee/employeeApi";
import useDateFormatter from "src/hooks/useDateFormatter";

type RepliesContainerProps = {
  reply: MarkingReplyModel;
};

const CommentReply = ({ reply } : RepliesContainerProps) => {
  const { data: employee } = useGetEmployeeQuery(!reply.profilePictureUrl ?  reply.owner.employeeId : skipToken);

  const { shortDateWithoutYearWithShortTime } = useDateFormatter();

  return (
    <div className="mw-100">
      <hr className="m-0"/>
      <div className="p-2 d-flex gap-8">
        <ParticipantBadge
          name={reply.owner.name}
          profilePictureUrl={reply.profilePictureUrl ?? reply.profilePictureUrl ?? employee?.profilePictureUrl}
          hideNameField={true}
        />
        <div className="d-flex flex-column">
          <p className="m-0 subtle small">{reply.owner.name}</p>
          <p className="m-0 small">{shortDateWithoutYearWithShortTime(reply.creationDate)}</p>
        </div>
      </div>
      <div>
        <p className="px-2 py-1">{reply.content}</p>
      </div>
    </div>
  );
};

export default CommentReply;