import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Row } from "react-table";
import classNames from "classnames";
import { TableRow } from "./tableRow";
import styles from "./draggableTableRow.module.css";

type DraggableTableRowProps<T extends object> = {
  row: Row<T>
  rowIndex: number;
  onClick?: (rowData: T) => void;
  onPreviewDropRow: (dragIndex: number, hoverIndex: number) => void;
  onDropRow?: (rowData: T, dropIndex: number) => void;
  disableDrag?: boolean;
};

const DND_ITEM_TYPE = "row";

type DndItem<T extends object> = {
  index: number;
  rowData: T;
};

// With inspiration from: https://react-table-v7.tanstack.com/docs/examples/row-dnd
export const DraggableTableRow = <T extends object> ({ row, rowIndex, onClick, onDropRow, onPreviewDropRow, disableDrag }: DraggableTableRowProps<T>) => {
  const rowRef = useRef<HTMLTableRowElement>(null);


  const [{ isOver }, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover: (item: DndItem<T>) => {
      if (!rowRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = rowIndex;

      if (dragIndex === hoverIndex) {
        return;
      }

      if (onPreviewDropRow) {
        onPreviewDropRow(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: DND_ITEM_TYPE,
    item: { index: rowIndex, rowData: row.original } as DndItem<T>,
    canDrag: !disableDrag,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    // Called after the hover-preview already have been called
    end: (draggedItem) => {
      const newIndex = draggedItem.index; // Because preview have already been called, the draggedItem already has its new index
      const draggedItemData = draggedItem.rowData;

      if (onDropRow) {
        onDropRow(draggedItemData, newIndex);
      }
    },
  });


  drop(rowRef);
  drag(rowRef);

  return (
    <TableRow
      row={row}
      onClickCallback={onClick}
      additionalClasses={classNames(
        !disableDrag && styles.draggable,
        {
          [styles.isDragging]: isDragging,
          [styles.isOver]: isOver,
        })}
      ref={rowRef}
    />
  );
};
