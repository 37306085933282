import baseApi from "../baseApi";
import { DownloadDocumentPageFileUrlModel } from "./models/downloadDocumentPageFileUrlModel";

const baseUrl = "/api";
const getDocumentDownloadUrlUrl = (documentId: string, pageIndex: number) => `${baseUrl}/documentpage/downloadurl?documentId=${documentId}&pageIndex=${pageIndex}`;

const documentPageApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDocumentPageDownloadUrl: builder.query<DownloadDocumentPageFileUrlModel, { documentId: string, pageIndex: number }>({
      query: ({ documentId, pageIndex }) => getDocumentDownloadUrlUrl(documentId, pageIndex),
    }),
  }),
});
export default documentPageApi;
export const {
  useGetDocumentPageDownloadUrlQuery,
} = documentPageApi;
export const { resetApiState } = documentPageApi.util;


