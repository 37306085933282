import Button, { ButtonProps } from "@components/embla/button";
import { useLocalization } from "@components/localization/localizationProvider";
import { Tooltip } from "@components/tooltip/tooltip";
import { Key } from "@infrastructure/Key";
import useShortcut from "src/hooks/useShortcut";

interface MarkingSaveBtnProps extends Omit<ButtonProps, "onClick"> {
  submit: () => void;
}

const MarkingSaveBtn = ({ submit, ...btnProps }: MarkingSaveBtnProps) => {
  const localizer = useLocalization();

  useShortcut({ key: Key.Enter, modifier: true, allowOnInputs: true, callback: submit });

  return (
    <Tooltip message={`${localizer.saveAndClose()} (Ctrl + Enter)`}>
      <Button
        theme="primary"
        onClick={submit}
        {...btnProps}
      >
        {localizer.save()}
      </Button>
    </Tooltip>
  );
};

export default MarkingSaveBtn;
