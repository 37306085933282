import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { StylesConfig } from "react-select";
import MarkingOwnerDropdown from "@components/dropdown/markingDropdown/markingOwnerDropdown";
import { useAuth } from "@components/auth/authProvider";
import useMarkingOwners from "../hooks/useMarkingOwners";
import { setMarkingOwnerFilters } from "../pdfViewerSlice";

type MarkingOwnerFilterDropdownProps = {
  className?: string;
  stylesOverride?: StylesConfig;
};

const MarkingOwnerFilterDropdown = (props: MarkingOwnerFilterDropdownProps) => {
  const { markingOwners } = useMarkingOwners();
  const dispatch = useDispatch();
  const { user: currentUser } = useAuth();

  const markingOwnersWithCurrenUser = useMemo(() => {
    return markingOwners.filter((p) => p.employeeId !== currentUser.employeeId).concat({ employeeId: currentUser.employeeId, userId: currentUser.id, name: currentUser.name }) ?? [];
  }, [currentUser.employeeId, currentUser.id, currentUser.name, markingOwners]);

  const onSelectedOwnersChanged = useCallback((newMarkingOwnerIds: string[]) => {
    dispatch(setMarkingOwnerFilters(newMarkingOwnerIds));
  }, [dispatch]);

  return <MarkingOwnerDropdown markingOwners={markingOwnersWithCurrenUser} onChangeCallback={onSelectedOwnersChanged} {...props} />;
};

export default MarkingOwnerFilterDropdown;
