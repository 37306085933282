import { useRef, useState } from "react";
import Button from "@components/embla/button";
import FormSubmitButton, { SubmitState } from "@components/forms/FormSubmitButton";
import { useLocalization } from "@components/localization/localizationProvider";
import Modal from "@components/modal/modal";
import { TabItem } from "@components/tab/tabModel";
import { Tabs } from "@components/tab/tabs";
import AddTitlePageForm from "./addTitlePageForm";

type AddToPresentationProps = {
  caseId: string;
  presentationId: string;
  presentationTitle: string;
  onCancelClicked: () => void;
};

export interface AddToPresentationFormRef {
  submit: (e?: React.BaseSyntheticEvent) => Promise<void>
}

const AddToPresentation = ({
  caseId,
  presentationId,
  presentationTitle,
  onCancelClicked,
}: AddToPresentationProps) => {
  const localizer = useLocalization();
  const [ submitState, setSubmitState ] = useState<SubmitState>();
  const formRef = useRef<AddToPresentationFormRef>(null);

  const tabItems: TabItem[] = [
    //TODO: Create new tab with pagesFromDocument here (future task)
    // e.g.: { id: "pagesFromDocument", title: localizer.pagesFromDocument(), active: true, content: <p className="subtle margin-top-m">Sider fra dokument åbent</p> },
    {
      id: "createTitlePage",
      title: localizer.createPresentationTitlePage(),
      active: true,
      content: <AddTitlePageForm caseId={caseId} presentationId={presentationId} ref={formRef} setSubmitState={setSubmitState} onSubmitSuccess={onCancelClicked}/> },
  ];

  return (
    <>
      <Modal.Header>
        {localizer.addToPresentation() + ": " + presentationTitle}
      </Modal.Header>
      <Modal.Body>
        <Tabs tabs={tabItems} hideBorder/>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancelClicked}>{localizer.cancel()}</Button>
        <FormSubmitButton
          isPrimary={true}
          state={submitState}
          onClick={formRef.current?.submit}
        >
          {localizer.add()}
        </FormSubmitButton>
      </Modal.Footer>
    </>
  );
};

export default AddToPresentation;
