import { useEffect, useState } from "react";

interface HookProps extends IntersectionObserverInit {
  disabled?: boolean;
  index?: number;
}

const useElementOnScreen = (props: HookProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [onScreenElement, setOnScreenElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (props.disabled || !onScreenElement) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      {
        root: props.root,
        rootMargin: props.rootMargin,
        threshold: props.threshold,
      },
    );
    observer.observe(onScreenElement);

    return () => observer.unobserve(onScreenElement);
  }, [props.disabled, props.index, props.root, props.rootMargin, props.threshold, onScreenElement]);

  return { setOnScreenElement, isVisible, onScreenElement };
};

export default useElementOnScreen;
