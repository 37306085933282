import React, { useCallback, useMemo } from "react";
import { UseFormReturn } from "react-hook-form/dist/types/form";
import { FormInputLabel } from "@components/forms/formInputLabel";
import MarkingOwnerDropdown from "@components/dropdown/markingDropdown/markingOwnerDropdown";
import FormSwitch from "@components/forms/formSwitch";
import { convertMarkingTypeToString, MarkingType } from "@pages/pdfviewer/component/models/markingType";
import { useLocalization } from "@components/localization/localizationProvider";
import { useAuth } from "@components/auth/authProvider";
import { UserModel } from "@services/api/sharedModels/userModel";
import { ExportBaseModel } from "@services/api/case/models/exportBaseModel";
import { Spinner } from "@components/spinner/spinner";
import { CaseParticipantListModel } from "@services/api/participant/models/caseParticipantListModel";


export type ExportBaseFormFieldsProps<TFormModel extends ExportBaseModel>  = {
  methods: UseFormReturn<TFormModel & any>;
  fieldIdPrepend: string;
  participants?: CaseParticipantListModel[];
  excludeComments?: boolean;
};

export const ExportBaseFormFields = <TFormModel extends ExportBaseModel> ({
  methods,
  fieldIdPrepend,
  participants,
  excludeComments,
}: ExportBaseFormFieldsProps<TFormModel>) => {

  const localizer = useLocalization();
  const { user: currentUser } = useAuth();


  const markingOwners = useMemo(() => {
    return participants?.filter((p) => p.employeeId !== currentUser.employeeId)
      .map((participant) =>
        ({
          userId: participant.userId,
          employeeId: participant.employeeId,
          name: participant.name,
        } as UserModel),
      ).concat({ employeeId: currentUser.employeeId, userId: currentUser.id, name: currentUser.name }) ?? [];
  }, [currentUser.employeeId, currentUser.id, currentUser.name, participants]);

  const onMarkingOwnersSelected = useCallback((ownerEmployeeIds: string[]) => {
    const newMarkingOwners = ownerEmployeeIds.map((employeeId) => markingOwners.find((owner) => owner.employeeId === employeeId)) as UserModel[];
    methods.setValue("markingOwners", newMarkingOwners);
  }, [markingOwners, methods]);

  return (
    <>
      <div className="row mt-4">
        <div className="col-lg-12">
          <h4>{localizer.markingOwnersTitle()}</h4>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-lg-5">
          <FormInputLabel
            htmlFor="markingOwners"
            labelText={localizer.markingOwnersLabel()}
          />
          <div className="d-flex">
            <MarkingOwnerDropdown
              markingOwners={markingOwners}
              onChangeCallback={onMarkingOwnersSelected}
              className="w-75"
            />
            {!participants &&
              <Spinner size={"extra-small"}/>
            }
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-12">
          <h4>{localizer.markingTypesTitle()}</h4>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-lg-12">
          {!excludeComments &&
            <FormSwitch
              methods={methods}
              id={`${fieldIdPrepend}-marking-type-comment`}
              label={convertMarkingTypeToString(MarkingType.Comment, localizer)}
              name="markingTypes"
              value={MarkingType.Comment}
              overwriteMarginTopBottom="xs"
              defaultLabelMarginLeft
            />
          }
          <FormSwitch
            methods={methods}
            id={`${fieldIdPrepend}-marking-type-highlight`}
            label={convertMarkingTypeToString(MarkingType.Highlight, localizer)}
            name="markingTypes"
            value={MarkingType.Highlight}
            overwriteMarginTopBottom="xs"
            defaultLabelMarginLeft
          />
          <FormSwitch
            methods={methods}
            id={`${fieldIdPrepend}-marking-type-verticalLines`}
            label={convertMarkingTypeToString(MarkingType.VerticalLines, localizer)}
            name="markingTypes"
            value={MarkingType.VerticalLines}
            overwriteMarginTopBottom="xs"
            defaultLabelMarginLeft
          />
          <FormSwitch
            methods={methods}
            id={`${fieldIdPrepend}-marking-type-focusbox`}
            label={convertMarkingTypeToString(MarkingType.FocusBox, localizer)}
            name="markingTypes"
            value={MarkingType.FocusBox}
            overwriteMarginTopBottom="xs"
            defaultLabelMarginLeft
          />
        </div>
      </div>
    </>
  );
};
