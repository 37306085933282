import { ChangeEvent, forwardRef, InputHTMLAttributes, KeyboardEvent, Ref, useEffect, useState } from "react";

export class ParseError extends Error { }

interface FormattedInputProps<T extends string | number> extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  value: T,
  onChange: (value: T) => void,
  formatDisplayValue: (value: T) => string,
  parseValue: (value: string) => T,
}

const FormattedInput = forwardRef(<T extends string | number>(
  { value, onChange, formatDisplayValue, parseValue, ...inputProps }: FormattedInputProps<T>,
  ref: Ref<HTMLInputElement>,
) => {
  const [inputValue, setInputValue] = useState(formatDisplayValue(value));

  useEffect(() => {
    setInputValue(formatDisplayValue(value));
  }, [value, formatDisplayValue]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputKeydown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      try {
        const parsed = parseValue(inputValue);
        if (parsed === value) {
          setInputValue(formatDisplayValue(value));
        } else {
          onChange(parsed);
        }
      } catch (err) {
        if (err instanceof ParseError) {
          setInputValue(formatDisplayValue(value));
        } else {
          throw err;
        }
      }
    }
  };

  const handleInputBlur = () => {
    setInputValue(formatDisplayValue(value));
  };

  return (
    <input
      ref={ref}
      type="text"
      value={inputValue}
      onChange={handleInputChange}
      onKeyDown={handleInputKeydown}
      onBlur={handleInputBlur}
      {...inputProps}
    />
  );
});

export default FormattedInput;
