import Reacts from "react";
import { useLocalization } from "@components/localization/localizationProvider";
import { Tabs } from "@components/tab/tabs";
import { TabItem } from "@components/tab/tabModel";
import ExportExtractDraft from "@pages/extractCompositionPage/components/export/exportExtractDraft";
import CompleteExtractDraft from "@pages/extractCompositionPage/components/export/completeExtractDraft";

interface ApproveOrExportExtractDraftProps {
  caseId: string;
  extractId: string;
  initialTab?: "export" | "complete" | undefined;
  submitPortal: Element;
  headerPortal: Element;
}

const CompleteOrExportExtractDraft = ({ caseId, extractId, submitPortal, headerPortal, initialTab }: ApproveOrExportExtractDraftProps) => {
  const localizer = useLocalization();

  const tabItems: TabItem[] = [
    {
      id: "extractExportTab",
      title: localizer.exportAsPdf(),
      active: initialTab !== "complete",
      content: <ExportExtractDraft caseId={caseId} extractId={extractId} submitPortal={submitPortal} headerPortal={headerPortal} />,
    },
    {
      id: "extractApproveTab",
      title: localizer.approveExtractDraft(),
      active: initialTab === "complete",
      content: <CompleteExtractDraft caseId={caseId} extractId={extractId} submitPortal={submitPortal} headerPortal={headerPortal} />,
    },
  ];
  return (
    <Tabs tabs={tabItems} hideBorder/>
  );
};

export default CompleteOrExportExtractDraft;

