import classNames from "classnames";
import { ReactNode } from "react";
import Button, { ButtonProps } from "@components/embla/button";
import Card from "@components/embla/card";
import { Tooltip } from "@components/tooltip/tooltip";
import styles from "./toolboxCard.module.scss";

const ToolboxCard = (props: { children: ReactNode }) => {
  return (
    <Card className="my-2">
      <Card.Body
        className={classNames(
          styles.card,
          "d-flex",
          "flex-column",
          "align-items-center",
        )}
      >
        {props.children}
      </Card.Body>
    </Card>
  );
};

interface BtnProps extends ButtonProps {
  active?: boolean;
  tooltip?: string;
}

const Btn = ({ active, tooltip, className, ...btnProps }: BtnProps) => (
  <Tooltip
    message={tooltip}
    distance={15}
  >
    <Button
      iconBtn
      borderless
      className={classNames(
        styles.button,
        active && styles.active,
        className,
      )}
      {...btnProps} />
  </Tooltip>
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export default Object.assign(ToolboxCard, { Btn });
