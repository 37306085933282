import classNames from "classnames";
import { useSelector } from "react-redux";
import { pausedPresentationStateSelector } from "@pages/present/presentationSlice";
import { useLocalization } from "@components/localization/localizationProvider";
import { Spinner } from "@components/spinner/spinner";
import { ReactComponent as PlayIconDefault } from "@content/icons/play_default.svg";
import { ReactComponent as PlayIconWhite } from "@content/icons/play_white.svg";
import Button from "@components/embla/button";
import { ReactComponent as PresentIcon } from "@content/icons/present-icon.svg";
import useElectronApi from "../../../hooks/useElectronApi";
import usePresent from "../../../hooks/usePresent";
import styles from "./presentButton.module.scss";

export type PresentButtonProps = {
  presentationId : string;
  redirectAppUrl : string | undefined;
  theme?: "default" | "primary"
};

export const PresentButton = ( { presentationId, redirectAppUrl, theme = "primary" }: PresentButtonProps) => {
  const pausedPresentation = useSelector(pausedPresentationStateSelector);
  const present = usePresent({ presentationId, directToApp: redirectAppUrl });
  const electronApi = useElectronApi();
  const localizer = useLocalization();

  const presentClicked = () => {
    present.startPresentation();
  };

  return (
    <>
      {!pausedPresentation &&
        <>
          {present.isWating &&
            <Spinner text={localizer.preparePresentation()} size="extra-small" className="d-flex"/>
          }

          {!present.isWating && electronApi &&
            <Button
              type="button"
              iconBtn
              className={styles.presentButton}
              onClick={presentClicked}
            >
              <PresentIcon/>
            </Button>
          }

          {!present.isWating && !electronApi &&
            <button onClick={presentClicked} className={classNames("d-flex",
              "btn",
              `btn-${theme}`)}
            >
              <div className={classNames(styles.icon)}>
                {theme === "primary" ? <PlayIconWhite/> : <PlayIconDefault/>}
              </div>

              <div className={styles.playTextDiv}>{localizer.playInApp()}</div>
            </button>
          }
        </>
      }
    </>
  );
};
