import { CasePresentationEventModel } from "@pages/pdfviewer/component/models/casePresentationEventModel";
import { DownloadFileUrlViewModel } from "@services/api/document/models/downloadFileUrlViewModel";
import { PresentationHubEventType } from "@services/signalRClient/presentationHubConnectionManager";
import { ThunkExtras } from "@store";
import { PresentationFileUrlViewModel, PresentationPageFileUrlModel } from "@services/api/case/models/PresentationFileUrlViewModel";
import cacheFileDownloadUrl, { cachePresentationFilesDownloadUrl, cachePresentationPageFileDownloadUrl } from "@services/api/cacheFileDownloadUrl";
import baseApi, { apiTags } from "../baseApi";
import { CasePresentationPageModel } from "../case/models/casePresentationPageModel";

const baseUrl = "api/presentation";
const presentationPagesUrl = (presentationId: string) => `${baseUrl}/${presentationId}`;
const getPresentationPagesUrl = (presentationId: string) => `${presentationPagesUrl(presentationId)}/pages`;

const getPresentfile = (presentationId: string) => `${presentationPagesUrl(presentationId)}/presentFile`;
const getPresentfileExists = (presentationId: string) => `${presentationPagesUrl(presentationId)}/presentFileExist`;

const getPresentationPageFile = (presentationPageId: string) => `/api/presentation/presentationPage/${presentationPageId}/file`;
const getPresentationPageFiles = (presentationId: string) => `${presentationPagesUrl(presentationId)}/presentationPages/files`;
const getPresentationPageFilesExists = (presentationId: string) => `${presentationPagesUrl(presentationId)}/presentationPages/files/allExist`;

const presentationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPresentationPages: builder.query<CasePresentationPageModel[], string>({
      query: (presentationId) => getPresentationPagesUrl(presentationId),
      providesTags: (result) => result ? [...result.map((p) => ({ type: apiTags.casePresentationPage, id: p.id })), apiTags.casePresentationPage] : [apiTags.casePresentationPage],
      transformResponse: (original: CasePresentationPageModel[]) => {
        if (!original) {
          return original;
        }

        return Promise.all(original.map((x) => !x.thumbnailUrl ? Promise.resolve(x) : fetch(x.thumbnailUrl)
          .then((response) => response.blob())
          .then((blob) => {
            return {
              ...x,
              thumbnailUrl: URL.createObjectURL(blob),
            };
          })));
      },
      onCacheEntryAdded: async (presentationId, { cacheDataLoaded, cacheEntryRemoved, dispatch, extra }) => {
        const { presentationHubConnectionManager } = (extra as ThunkExtras);

        const { data } = await cacheDataLoaded;
        try {
          // wait for the initial query to resolve before proceeding
          await presentationHubConnectionManager.startConnection(presentationId);

          presentationHubConnectionManager.on(
            presentationId,
            PresentationHubEventType.PresentationPagesEvent,
            (e: CasePresentationEventModel) => {
              dispatch(presentationApi.util.invalidateTags([
                { type: apiTags.casePresentation, id: presentationId },
                { type: apiTags.casePresentationPdfFilesUrl, id: presentationId },
                { type: apiTags.caseDocumentPage, id: e.documentPageId },
                apiTags.casePresentationPage,
                apiTags.markings,
              ]));
            });
        } catch {
          //  "cacheDataLoaded" will throw in case "cacheEntryRemoved" resolves first
        }
        await cacheEntryRemoved;
        //revoke cached thumbnails
        data.forEach((x) => x.thumbnailUrl && URL.revokeObjectURL(x.thumbnailUrl));

        // cleanup after the cache subscription is no longer active
        await presentationHubConnectionManager.stopConnection(presentationId);
      },
    }),
    getPresentfile: builder.query<DownloadFileUrlViewModel, string>({
      query: (presentationId) => getPresentfile(presentationId),
      providesTags: (result, error, presentationId) => [apiTags.casePresentation, { type: apiTags.casePresentation, id: presentationId }],
      ...cacheFileDownloadUrl,
    }),
    presentfileExists: builder.mutation<boolean, string>({
      query: (presentationId) => ({
        url: getPresentfileExists(presentationId),
        method: "GET",
      }),
    }),
    getPresentationPageFile: builder.query<PresentationPageFileUrlModel, string>({
      query: (presentationPageId) => getPresentationPageFile(presentationPageId),
      providesTags: (result, error, presentationPageId) => [{ type: apiTags.casePresentationPage, id: presentationPageId }],
      ...cachePresentationPageFileDownloadUrl,
    }),
    getPresentationPageFiles: builder.query<PresentationFileUrlViewModel | undefined, string>({
      query: (presentationId) => getPresentationPageFiles(presentationId),
      providesTags: (result, error, presentationId) => [{ type: apiTags.casePresentationPdfFilesUrl, id:presentationId }],
      ...cachePresentationFilesDownloadUrl,
    }),
    presentationPageFilesExists: builder.mutation<boolean, string>({
      query: (presentationId) => ({
        url: getPresentationPageFilesExists(presentationId),
        method: "GET",
      }),
    }),
  }),
});

export default presentationApi;
export const {
  useGetPresentationPagesQuery,

  useGetPresentfileQuery,
  usePresentfileExistsMutation,

  useGetPresentationPageFileQuery,
  useGetPresentationPageFilesQuery,
  usePresentationPageFilesExistsMutation,
} = presentationApi;
