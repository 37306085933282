import { GridChildComponentProps } from "react-window";
import { CSSProperties } from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { PageOrientation } from "@services/api/document/models/rotateCaseDocumentModel";
import { PageDimensionsArray } from "../../pageDimensions";
import getPageIndexInGrid from "../../utils/getPageIndexInGrid";
import PageRenderer from "../PageRenderer/PageRenderer";

export interface PageGridChildData {
  getPageClassName?: (pageIndex: number) => string | undefined;
  isThumbnail?: boolean;
  pdfDimensions: PageDimensionsArray;
  scale?: number;
  renderAnnotationLayer?: boolean;
  visiblePages: number[];
  pageMargin: number;
  pageOrientations?: PageOrientation[];
  onDocumentLoaded?: () => void;
  documentId: string;
  gridColumnCount: number;
  draggable?: boolean;
}

const PageGrid = ({
  columnIndex,
  data,
  rowIndex,
  style,
  isScrolling,
}: GridChildComponentProps<PageGridChildData>) => {
  const {
    documentId,
    gridColumnCount,
    pageMargin,
    pdfDimensions,
    pageOrientations,
    draggable = false,
    isThumbnail,
  } = data;

  
  const pageIndex = getPageIndexInGrid(
    rowIndex,
    columnIndex,
    gridColumnCount,
  );
  const pageDimensions = pdfDimensions[pageIndex];
  const orientation = pageOrientations?.[pageIndex];
  const isHorizontal = orientation === 90 || orientation === 270;
  // When thumbnail pages have weird sizes the margins are not calculated correctly so we remove them
  const marginTop = isThumbnail ? 0 : isHorizontal ? pageMargin + pageDimensions?.height / 5 : pageMargin;
  
  const gridStyle: CSSProperties = {
    ...style,
    position: "absolute",
    width: pageDimensions?.width,
    height: pageDimensions?.height,
    marginTop: marginTop,
    marginLeft: pageMargin,
  };

  const [ , drag, preview ] = useDrag({
    type: "page",
    canDrag: draggable,
    item: { documentId, index: pageIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }, [draggable]);

  preview(getEmptyImage(), { captureDraggingState: true });

  return (
    <PageRenderer
      ref={drag}
      data={data}
      pageIndex={pageIndex}
      pageDimensions={pageDimensions}
      style={gridStyle}
      thumbnailMargin={marginTop}
      orientation={orientation}
    />
  );
};

export default PageGrid;
