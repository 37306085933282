import React, { createContext, useContext, useState } from "react";
import { ActiveMarkingInfo } from "@pages/pdfviewer/component/models/activeMarkingInfo";

type MarkingsPageContextType = {
  hoveredMarkingId: string | undefined;
  setHoveredMarkingId: React.Dispatch<React.SetStateAction<string | undefined>>;
  activeMarking: ActiveMarkingInfo | undefined;
  setActiveMarking: React.Dispatch<React.SetStateAction<ActiveMarkingInfo | undefined>>;
  isDraggingMarking: boolean;
  setIsDraggingMarking: React.Dispatch<React.SetStateAction<boolean>>;
  pageSize: { width: number, height: number } | undefined;
  setPageSize: React.Dispatch<React.SetStateAction<{ width: number, height: number } | undefined>>;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const MarkingsPageContext = createContext<MarkingsPageContextType | undefined>(undefined);

type MarkingsPageProviderProps = {
  children: React.ReactNode;
};

export const MarkingsPageProvider = ({ children }: MarkingsPageProviderProps) => {
  const [hoveredMarkingId, setHoveredMarkingId] = useState<string | undefined>(undefined);
  const [activeMarking, setActiveMarking] = useState<ActiveMarkingInfo | undefined>(undefined);
  const [isDraggingMarking, setIsDraggingMarking] = useState(false);
  const [pageSize, setPageSize] = useState<{ width: number, height: number } | undefined>(undefined);


  return (
    <MarkingsPageContext.Provider value={{ hoveredMarkingId, setHoveredMarkingId, activeMarking, setActiveMarking, isDraggingMarking, setIsDraggingMarking, pageSize, setPageSize }}>
      {children}
    </MarkingsPageContext.Provider>
  );
};

export const useMarkingsPageContext = () => {
  const context = useContext(MarkingsPageContext);
  if (context === undefined) {
    throw new Error("useMarkingsPageContext must be used within a MarkingsPageProvider");
  }
  return context;
};