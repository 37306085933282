import classNames from "classnames";
import { useDrag } from "react-dnd";
import { useEffect, useRef, useState } from "react";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import { CaseDocumentModel } from "@services/api/case/models/caseDocumentModel";
import { useExtractCompositionContext } from "@pages/extractCompositionPage/extractCompositionContextProvider";
import { dndItemTypes } from "../../ExtractSections/DndItemTypes";
import styles from "./Document.module.scss";

type DocumentProps = {
  document: CaseDocumentModel;
  index: number;
  lastSelectedIndex: number;
  setLastSelectedIndex: (index: number) => void,
  isCtrlPressed: boolean;
  isShiftPressed: boolean;
  allUnusedDocuments: CaseDocumentModel[];
};

export const Document = ({ document, index, setLastSelectedIndex, isCtrlPressed, isShiftPressed, lastSelectedIndex, allUnusedDocuments }: DocumentProps) => {
  const [draggedItemWidth, setDraggedItemWidth] = useState(0);
  const { selectedDocumentsReducer } = useExtractCompositionContext();
  const isSelected = selectedDocumentsReducer.state.selectedDocuments.some((selectedDocument) => selectedDocument.index === index);

  const divRef = useRef<HTMLDivElement>(null);
  
  const [ { isDragging }, drag, preview] = useDrag({
    type: dndItemTypes.document,
    item: { ...document, width: draggedItemWidth },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }, [document, draggedItemWidth]);

  useEffect(() => {
    if (divRef.current) {
      setDraggedItemWidth(divRef.current.offsetWidth / 2);
    }
  }, [divRef]);
  

  const handleClick = () => {
    if (isCtrlPressed) {
      if (!isSelected) {
        const selectedDocumentsCopy = [...selectedDocumentsReducer.state.selectedDocuments];
        selectedDocumentsCopy.push({ ...document, index });
        selectedDocumentsReducer.dispatch({ selectedDocuments: selectedDocumentsCopy });
      } else {
        const selectedDocumentsCopy = selectedDocumentsReducer.state.selectedDocuments.filter((selectedDocument) => selectedDocument.index !== index);
        selectedDocumentsReducer.dispatch({ selectedDocuments: selectedDocumentsCopy });
      }
      setLastSelectedIndex(index);
    } else if (isShiftPressed) {
      const selectedDocumentsCopy = [...selectedDocumentsReducer.state.selectedDocuments];
      const minIndex = Math.min(lastSelectedIndex, index);
      const maxIndex = Math.max(lastSelectedIndex, index);
      for (let i = minIndex; i <= maxIndex; i++) {
        if (!selectedDocumentsCopy.some((selectedDocument) => selectedDocument.index === i)) {
          selectedDocumentsCopy.push({ ...allUnusedDocuments[i], index: i });
        }
      }
      selectedDocumentsReducer.dispatch({ selectedDocuments: selectedDocumentsCopy });
      setLastSelectedIndex(index);
    } else {
      selectedDocumentsReducer.dispatch({ selectedDocuments: [] });
    }
  };
  
  const emptyImage = new Image();
  preview(emptyImage, { captureDraggingState: true });

  drag(divRef);

  return (
    <div
      ref={divRef}
      title={document.documentName}
      onClick={handleClick}
      className={classNames(styles.container, "d-flex align-items-center px-3 py-2 pointer w-100", {
        [styles.selected]: isSelected,
        [styles.dragging]: isDragging,
      } )} >
      <span className="py-1">
        <EmblaIcon iconName="document" color={IconColor.Gray}/>
      </span>
      <p className={classNames(styles.text, "ml-2 mb-0")}>{document.documentName}</p>
    </div>
  );
};
