import { useSelector } from "react-redux";
import { useLocalization } from "@components/localization/localizationProvider";
import { MarkingModel } from "@pages/pdfviewer/component/models/markingModel";
import { pdfTitleSelector, totalPagesSelector } from "../../../pdfViewerSlice";
import styles from "./markingMetaBlock.module.scss";

const MarkingMetaBlock = ({ marking }: { marking: MarkingModel }) => {
  const localizer = useLocalization();
  const totalPages = useSelector(totalPagesSelector);
  const title = useSelector(pdfTitleSelector);

  if (!marking.page) {
    return null;
  }

  return (
    <div className={styles.block}>
      <div className="font-weight-light subtle margin-bottom-xs">{title}</div>
      <div className="small">
        {localizer.pdfPage()} {marking.page + 1}
        {" "}{localizer.outOf()} {totalPages}
      </div>
    </div>
  );
};

export default MarkingMetaBlock;
