import { ReactNode } from "react";
import Card from "@components/embla/card";
import { useLocalization } from "@components/localization/localizationProvider";
import styles from "./mentionListContainer.module.scss";

export type MentionListContainerProps = {
  children: ReactNode
};

const MentionListContainer = ({ children }: MentionListContainerProps) => {
  const localizer = useLocalization();

  return (
    <Card className={styles.mentionCard} onPointerDown={(e) => e.stopPropagation()}>
      <h5 className="margin-s">{localizer.mention()}...</h5>
      <Card.Body className={styles.mentionCardBody}>
        {children}
      </Card.Body>
    </Card>
  );
};

export default MentionListContainer;
