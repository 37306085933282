import React, { createContext, useContext, useReducer } from "react";

interface WizardPanelContextType {
  actionButtonDisabledState: boolean
}
// eslint-disable-next-line @typescript-eslint/naming-convention
const WizardPanelContextContext = createContext<{ state: WizardPanelContextType | null, dispatch: React.Dispatch<WizardPanelContextType> } | undefined>(undefined);
const wizardPanelContextReducer = (state: WizardPanelContextType, action: WizardPanelContextType): WizardPanelContextType => {
  return { ...state, actionButtonDisabledState: action.actionButtonDisabledState };
};
export const useWizardPanelContext = () => {
  const context = useContext(WizardPanelContextContext);
  if (!context) {
    throw new Error("useWizardPanelContext must be used in WizardPanelContextProvider");
  }
  return context;
};
const WizardPanelContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(wizardPanelContextReducer, { actionButtonDisabledState: false } as WizardPanelContextType);
  return (
    <WizardPanelContextContext.Provider value={{ state, dispatch }}>
      {children}
    </WizardPanelContextContext.Provider>
  );
};
export default WizardPanelContextProvider;
