import classNames from "classnames";
import { useDrop } from "react-dnd";
import { useState } from "react";
import { EmblaIcon, IconColor } from "@components/embla/emblaIcon";
import { useLocalization } from "@components/localization/localizationProvider";
import { ExtractDraftModel } from "@services/api/extractDraft/models/extractDraftModel";
import {
  useDeleteFrontpageExtractDraftMutation,
  useGetFrontpageUrlExtractDraftQuery,
  useUpdateFrontpageExtractDraftMutation,
} from "@services/api/extractDraft/extractDraftApi";
import { CaseDocumentModel } from "@services/api/case/models/caseDocumentModel";
import { IconButton } from "@components/button/IconButton/IconButton";
import { useExtractCompositionContext } from "@pages/extractCompositionPage/extractCompositionContextProvider";
import DocumentModal from "../../DocumentModal/DocumentModal";
import { dndItemTypes } from "../DndItemTypes";
import styles from "./DndFrontpage.module.scss";

type DndFrontpageProps = {
  name: string;
  extractDraft: ExtractDraftModel;
};

export const DndFrontpage = ({ name, extractDraft }: DndFrontpageProps) => {
  const [updateFrontpage] = useUpdateFrontpageExtractDraftMutation();
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { selectedDocumentsReducer } = useExtractCompositionContext();
  const selectedDocuments = selectedDocumentsReducer.state.selectedDocuments;

  const documentExists = !!extractDraft.frontpage.caseDocumentId;
  const localizer = useLocalization();

  const [deleteFrontpage] = useDeleteFrontpageExtractDraftMutation();

  const [ { isOver, canDrop }, drop] = useDrop({
    accept: dndItemTypes.document,
    drop: (droppedDocument: CaseDocumentModel) => {
      updateFrontpage({
        caseId: extractDraft.caseId,
        extractDraftId: extractDraft.id,
        caseDocumentId: droppedDocument.id,
      });
    },
    canDrop: () => selectedDocuments.length <= 1,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const handleDeleteFrontpage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    deleteFrontpage({ caseId: extractDraft.caseId, extractDraftId: extractDraft.id });
  };
  
  return (
    <>
      <div
        onMouseOver={() => setShowDeleteButton(documentExists)}
        onMouseLeave={() => setShowDeleteButton(false)}
        onClick={() => setIsModalOpen(documentExists)}
        ref={drop}
        className={classNames(styles.container, {
          [styles.noDocument]: !documentExists,
          [styles.withDocument]: documentExists,
          [styles.hoverWithDocument]: isOver && canDrop,
          [styles.dropNotAllowed]: !canDrop && isOver,
        })}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-8">
            <EmblaIcon color={IconColor.Gray} iconName="document" />
            <h4 className="m-0">{name}</h4>
          </div>
          <div className="flex-grow-1 text-center">{!documentExists && <span>{localizer.dragFileHereFromCase()}</span>}</div>
          <div className={classNames("d-flex")}>
            {documentExists && <span>{extractDraft.frontpage.caseDocumentName}</span>}
            <div className={styles.deleteIconContainer}>
              {showDeleteButton && <IconButton onClick={handleDeleteFrontpage} iconName="delete" color={IconColor.Gray} />}
            </div>
          </div>
        </div>
      </div>
      {documentExists && (
        <DocumentModal
          isFrontPage
          title={localizer.frontpage()}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          documentQuery={useGetFrontpageUrlExtractDraftQuery}
          documentQueryParams={{
            caseDocumentId: extractDraft.frontpage.caseDocumentId as string,
            caseId: extractDraft.caseId,
            extractDraftId: extractDraft.id,
          }}
        />
      )}
    </>
  );
};
