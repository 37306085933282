import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@components/embla/button";
import { useLocalization } from "@components/localization/localizationProvider";
import { RoutePaths, isDocumentViewerPage } from "@components/routing/routes";
import { ReactComponent as PresentIcon } from "@content/icons/present-icon.svg";
import { useAppDispatch, useAppSelector } from "@hooks";
import presentationApi from "@services/api/presentation/presentationApi";
import { pdfViewerStateSelector } from "@pages/pdfviewer/component/pdfViewerSlice";
import useBroadcastChannel from "src/hooks/useBroadcastChannel";
import styles from "./pausedPresentationOverlay.module.scss";
import { clearTempPresentationPageState, pausedPresentationStateSelector, setTempPresentationPageState } from "./presentationSlice";
import { PRESENTATION_BROADCAST_CHANNEL, PresentationAction, PresentationActionType } from "./presentationBroadcastActions";


const PausedPresentationOverlay = () => {
  const localizer = useLocalization();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const pausedPresentation = useAppSelector(pausedPresentationStateSelector);
  const pdfViewerState = useAppSelector(pdfViewerStateSelector);
  const { pathname: currentPath } = useLocation();


  const { postMessage } = useBroadcastChannel<PresentationAction>({ name: PRESENTATION_BROADCAST_CHANNEL });

  if (!pausedPresentation) {
    return null;
  }

  const resumePresentation = () => {
    dispatch(presentationApi.util.invalidateTags(["CasePresentation"]));
    postMessage({ type: PresentationActionType.StopShowTempPage });
    dispatch(clearTempPresentationPageState());
    navigate(RoutePaths.present.url(pausedPresentation.presentationId, pausedPresentation.pageIndex));
  };

  const presentPageTemporarily = () => {
    if (!pdfViewerState.pdfTypeId) {
      throw new Error("pdfTypeId must have a value");
    }

    postMessage({ type: PresentationActionType.ShowTempPage, action: { caseDocumentId: pdfViewerState.pdfTypeId, pageIndex: pdfViewerState.mostVisiblePageIndex } });
    dispatch(setTempPresentationPageState({ documentId: pdfViewerState.pdfTypeId, pageIndex: pdfViewerState.mostVisiblePageIndex }));
    
    navigate(RoutePaths.present.url(pausedPresentation.presentationId, pausedPresentation.pageIndex));
  };

  return (
    <div className={classNames(styles.container, "rounded-top", "padding-m", "d-flex", "align-items-center", "gap-m")}>
      <p className="lead text-white m-0">{localizer.nowPlaying()}: <strong>{pausedPresentation.title}</strong></p>

      {isDocumentViewerPage(currentPath) &&
        <Button
          noTheme={true}
          className={classNames(styles.temporaryPageBtn)}
          onClick={presentPageTemporarily}
        >
          <PresentIcon className="embla-icon icon-left" />{" "}
          {
            `${localizer.presentPage()} ${pdfViewerState.mostVisiblePageIndex + 1}`
          }
        </Button>
      }
      <Button
        theme="primary"
        onClick={resumePresentation}
      >
        <PresentIcon className="embla-icon icon-left" />{" "}
        {localizer.resumePlayback()}
      </Button>
    </div>
  );
};

export default PausedPresentationOverlay;
