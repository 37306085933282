import { useExtractCompositionContext } from "@pages/extractCompositionPage/extractCompositionContextProvider";
import { CaseDocumentModel } from "@services/api/case/models/caseDocumentModel";
import { ReorderExtractDraftSectionDocument } from "@pages/extractCompositionPage/hooks/useDragDropSectionDocumentReorder";
import { ExtractDraftSectionModel } from "@services/api/extractDraft/models/extractDraftSectionModel";
import { dndItemTypes } from "../ExtractSections/DndItemTypes";
import DragPreviewMultipleDocument from "./DragPreviewMultipleDocument/DragPreviewMultipleDocument";
import DragPreviewSingleDocument from "./DragPreviewSingleDocument/DragPreviewSingleDocument";
import DragPreviewSection from "./DragPreviewSection/DragPreviewSection";

type DragPreviewProps = {
  itemType: string;
  item: unknown;
};

const DragPreview = ({ itemType, item }: DragPreviewProps) => {
  const { selectedDocumentsReducer } = useExtractCompositionContext();
  const selectedDocuments = selectedDocumentsReducer.state.selectedDocuments;

  const getPreview = () => {
    if (selectedDocuments.length > 0 && itemType === dndItemTypes.document) {
      return <DragPreviewMultipleDocument documentsAmount={selectedDocuments.length}/>;
    }
    if (selectedDocuments.length === 0 && itemType === dndItemTypes.document) {
      return <DragPreviewSingleDocument document={item as CaseDocumentModel | ReorderExtractDraftSectionDocument}/>;
    }
    if (itemType === dndItemTypes.section) {
      const section = item as ExtractDraftSectionModel;
      return <DragPreviewSection sectionName={section.title}/>;
    }

    return null;
  };

  return (
    <div
      className={"card gap-8 px-3 py-1"}
    >
      {getPreview()}
    </div>
  );
};

export default DragPreview;
