import classNames from "classnames";
import { ParticipantBadge } from "@components/case/participant/participantBadge/participantBadge";
import ClampedParagraph from "@components/clampedParagraph/clampedParagraph";
import { useLocalization } from "@components/localization/localizationProvider";
import DropdownMenu from "@components/menuButton/dropdownMenu";
import { MenuDotButton } from "@components/menuButton/dropdownMenuItems/menuDotButton";
import { CaseBaseReturnModel } from "@services/api/case/models/caseBaseReturnModel";
import useDateFormatter from "src/hooks/useDateFormatter";
import { IconTextCallbackItem } from "@components/menuButton/dropdownMenuItems/IconTextDropdownItem";
import { EmblaIcon } from "@components/embla/emblaIcon";
import Card from "@components/embla/card";
import InfoNote from "@components/infoNote/InfoNote";
import { TextBreak } from "src/utility/constants";
import styles from "./CaseInfo.module.scss";

type CaseInfoProps = {
  caseModel: CaseBaseReturnModel;
  setEditModalOpen: (value: boolean) => void;
  setDeleteModalOpen: (value: boolean) => void;
};

const CaseInfo = ({
  caseModel,
  setDeleteModalOpen,
  setEditModalOpen,
}: CaseInfoProps) => {
  const localizer = useLocalization();
  const dateFormatter = useDateFormatter();

  return (
    <Card className={classNames(styles.card, "p-2 mb-0")} >
      <Card.Header>
        <div className="d-flex justify-content-between">
          <h3 className="m-0 text-break">
            {caseModel.title}
          </h3>
          <DropdownMenu
            buttonNode={<MenuDotButton />}
            menuItems={[
              {
                component: (
                  <IconTextCallbackItem
                    icon={<EmblaIcon iconName="edit" />}
                    text={localizer.editDetails()}
                    callback={() => setEditModalOpen(true)}
                  />
                ),
                key: localizer.editDetails(),
              },
              {
                component: (
                  <IconTextCallbackItem
                    icon={<EmblaIcon iconName="delete" />}
                    text={localizer.deleteCase()}
                    callback={() => setDeleteModalOpen(true)}
                  />
                ),
                key: localizer.deleteCase(),
              },
            ]}
          />
        </div>
      </Card.Header>
      <Card.Body>
        <div
          className={classNames(
            "d-flex align-items-start px-2 pt-1 gap-m justify-content-between",
          )}
        >
          <div className="d-flex px-1 gap-m">
            {caseModel.caseParticipants.map((participant) => (
              <ParticipantBadge
                key={participant.id}
                name={participant.name}
                initials={participant.initials}
                profilePictureUrl={participant.profilePictureUrl}
                nameFieldClassNames="m-0"
                hideNameField={true}
              />
            ))}
          </div>
          <InfoNote title={localizer.creationDate() + ":"}>{dateFormatter.date(caseModel.creationDate)}</InfoNote>
          <InfoNote title={localizer.courtCaseNumber() + ":"}>{caseModel.courtCaseNumber}</InfoNote>
          <InfoNote title={localizer.status() + ":"}>{caseModel.statusDisplayString}</InfoNote>
          <InfoNote title={localizer.client() + ":"} textBreak={TextBreak.Truncate}>{caseModel.client.name}</InfoNote>
          <InfoNote title={localizer.counterparts() + ":"} children={caseModel.counterParts.map((part) => part.name)} textBreak={TextBreak.Truncate} />
        </div>
        <div className="px-2">
          <ClampedParagraph className="margin-top-l" lineClamp={3}>
            {caseModel.description}
          </ClampedParagraph>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CaseInfo;
