import { AccessRight } from "@infrastructure/AccessRight";
import { UserInfoModel } from "./userInfoModel";

export class User implements UserInfoModel {
  authenticated = false;
  id = "";
  employeeId = "";
  name = "";
  email = "";
  accessRights: AccessRight[] = [];
  impersonate: boolean;


  constructor(userModel: UserInfoModel | null) {
    if (userModel) {
      this.id = userModel.id;
      this.employeeId = userModel.employeeId;
      this.email = userModel.email;
      this.name = userModel.name;
      this.accessRights = userModel.accessRights;
      this.authenticated = true;
      this.impersonate = userModel.impersonate;
    }
  }

  public isAdmin(): boolean {
    return this.hasAccessRight(AccessRight.SysAdmin) || this.hasAccessRight(AccessRight.AdministerOrg);
  }

  public isSysAdmin(): boolean {
    return this.hasAccessRight(AccessRight.SysAdmin);
  }

  public isImpersonate(): boolean {
    return this.impersonate;
  }

  public isAdminAllCases(): boolean {
    return this.isAdmin() && this.hasAccessRight(AccessRight.AccessToAllCases);
  }

  public hasAccessRight(right: AccessRight) {
    if (right == null) {
      return true;
    }

    // If a right is given, and user has no rights, return false
    if (!this.accessRights) {
      return false;
    }

    return this.accessRights.includes(right);
  }
}
