import { useParams } from "react-router-dom";
import classNames from "classnames";
import { useAppSelector } from "@hooks";
import { pdfTypeIdSelector } from "@pages/pdfviewer/component/pdfViewerSlice";
import useElementSize from "src/hooks/useElementSize";
import { BookmarkTree } from "@pages/pdfviewer/component/pdfSidebar/bookmarks/bookmarkTree";
import styles from "./pdfSidebar.module.scss";


export const PdfSidebarBookmarks = (props: { className?: string }) => {
  const [scrollContainerRef] = useElementSize();

  const documentId = useAppSelector(pdfTypeIdSelector);
  const { caseId } = useParams();

  if (!caseId) {
    throw Error("Param \"caseId\" is required");
  }

  if (!documentId) {
    throw Error("Param \"documentId\" is required");
  }

  return (
    <div
      className={classNames(styles.sidebarScroll, props.className, "scrollbar overflow-auto")}
      ref={scrollContainerRef}
    >
      <BookmarkTree caseId={caseId} documentId={documentId}/>
    </div>
  );
};
