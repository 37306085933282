import { ReactNode, useCallback, useEffect, useState } from "react";
import { AddButton } from "@components/button/addButton/addButton";
import Modal, { ModalProps, ModalSizeEnum } from "@components/modal/modal";

interface AddButtonSlideInFormProps extends Omit<ModalProps, "open" | "onClose"> {
  children: ReactNode;
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
  onCloseEffect?: () => void;
  modalSize?: ModalSizeEnum;
  requireButton? : boolean;
}

/// Shows AddButton, and shows the modal, if its open.
export const AddButtonSlideInForm = ({ children, onCloseEffect, isOpen, setIsOpen, requireButton, ...modalProps }: AddButtonSlideInFormProps) => {
  const [openModal, setOpenModal] = useState(isOpen || false);

  const onClosedCallback = useCallback(() => {
    setOpenModal(false);
    onCloseEffect && onCloseEffect();
  }, [onCloseEffect]);

  const handleOpenModal = () => {
    setOpenModal(true);
    setIsOpen && setIsOpen(true);
  };

  useEffect(() => {
    setOpenModal(isOpen || false);
  }, [isOpen]);
  

  return (
    <>
      {requireButton !== false && <AddButton onClick={handleOpenModal} />}

      <Modal
        open={openModal}
        onClose={onClosedCallback}
        locked={modalProps.locked}
        size={modalProps.modalSize}
        {...modalProps}

      >
        {children}
      </Modal>
    </>
  );
};
