import classNames from "classnames";
import { HTMLAttributes, useEffect, useState } from "react";
import { debounce } from "lodash";
import styles from "./checkboxSwitch.module.scss";
import InlineSwitch from "./inlineSwitch";

interface CheckboxProps extends HTMLAttributes<HTMLInputElement> {
  id: string,
  externalChecked? : boolean,
  label?: string,
  onchangeCallback? : (value : boolean) => void;
  useFlex?: boolean;
  disabled?: boolean;
  debounceLength?: number;
}


export const CheckboxSwitch = ({
  id,
  externalChecked,
  label,
  onchangeCallback,
  useFlex = true,
  disabled = false,
  debounceLength = 0,
  ...htmlProps
}: CheckboxProps) => {
  const [checkedValue, setCheckedValue] = useState(false);

  useEffect(() => {
    if (externalChecked !== undefined) {
      setCheckedValue(externalChecked);
    }
  }, [externalChecked]);

  const onChange = debounce(() => {
    setCheckedValue(!checkedValue);

    if (onchangeCallback)
      onchangeCallback(!checkedValue);
  }, debounceLength);

  return (
    <div className={classNames(useFlex && "d-flex flex-column")}>
      {label && (
        <label className={classNames(styles.switchLabel)} htmlFor={id}>
          {label}
        </label>
      )}
      <InlineSwitch {...htmlProps} id={id} onChange={onChange} checked={checkedValue} disabled={disabled} />
    </div>
  );
};
