import { NotificationModule } from "ditmer-embla";
import { KeyboardEventHandler, useEffect } from "react";
import FormInput from "@components/forms/FormInput";
import useValidatedForm from "@components/forms/useValidatedForm";
import { useLocalization } from "@components/localization/localizationProvider";
import { useUpdateExtractDraftMutation } from "@services/api/extractDraft/extractDraftApi";

import {
  UpdateCaseExtractDraftModel,
  updateExtractDraftValidation,
} from "@services/api/extractDraft/models/updateCaseExtractDraftModel";
import useAutosave from "../../../../../hooks/useAutosave";

type UpdateExtractDraftProps = {
  caseId: string;
  extractDraftId: string;
  initialValues: UpdateCaseExtractDraftModel;
  updateExtractDraftRequestStatusChanged?: (isLoading: boolean, isError: boolean) => void;
};

export const UpdateExtractDraft = ({
  caseId,
  extractDraftId,
  initialValues,
  updateExtractDraftRequestStatusChanged,
}: UpdateExtractDraftProps) => {

  const localizer = useLocalization();
  const [ updateExtractDraft, updateExtractDraftRequest ] = useUpdateExtractDraftMutation();
  
  useEffect(() => {
    if (updateExtractDraftRequestStatusChanged) {
      updateExtractDraftRequestStatusChanged(updateExtractDraftRequest.isLoading, updateExtractDraftRequest.isError);
    }
  }, [updateExtractDraftRequest.isLoading, updateExtractDraftRequest.isError, updateExtractDraftRequestStatusChanged]);

  const methods = useValidatedForm({
    validationSchema: updateExtractDraftValidation(localizer),
    defaultValues: initialValues,
  });

  const formFieldIdPrepend = "extractdraft-edit-form";

  const { getFieldId } = useAutosave({
    queryStatus: updateExtractDraftRequest.status,
    fieldIdPrefix: formFieldIdPrepend,
    watch: methods.watch,
    onSubmit: methods.handleSubmit((model) => {
      updateExtractDraft({ caseId: caseId, extractDraftId: extractDraftId, model }).unwrap().catch((e) => {
        methods.reset();
      });
    }),
  });

 
  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter")
      e.preventDefault();
  };

  return (
    <form onKeyDown={onKeyDownHandler}>
      <FormInput
        methods={methods}
        id={getFieldId("title")}
        name="title"
        label={localizer.title()}
        placeholder={localizer.extractDraftTitlePlaceholder()}
        required
      />
    </form>
  );
};

