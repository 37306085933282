import { MarkingType } from "@pages/pdfviewer/component/models/markingType";


export type DocumentSearchResultModel = {
  documentHits: DocumentContentHit[]
  markingHits: MarkingHit[]
};

export type MarkingHit = {
  documentId: string
  owner: string
  pageIndex: number
  markingType: MarkingType
  commentHighlight: string
  score: number
};

export type DocumentContentHit = {
  documentId: string
  pageIndex: number
  highlight: string
  score: number
};

export enum DocumentSearchSortOrder {
  Score = 1,
  Page = 2,
}