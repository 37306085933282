import { MarkingModel } from "./markingModel";

export enum TempMarkingActionType {
  Added,
  Removed,
  Changed,
}

export interface TempMarkingAddChangeAction {
  type: TempMarkingActionType.Added | TempMarkingActionType.Changed,
  marking: MarkingModel;
}

export interface TempMarkingRemoveAction {
  type: TempMarkingActionType.Removed,
  markingId: string;
}

export type TempMarkingAction
  = TempMarkingAddChangeAction
  | TempMarkingRemoveAction;
