import { useEffect, useState } from "react";
import useUserAgentPlatform, { PlatformType } from "./useUserAgentPlatform";

const latestYamlWindowsFileName = "latest.yml";
const latestYamlMacOsFileName = "latest-mac.yml";

const yamlMimeType = "text/yaml";

const pathValueDescriptor = "path: ";
const nextDescriptorAfterPathDescriptor = "sha512: ";

const useElectronInstallerPath = (skip?: boolean) => {
  const [latestInstallerPath, setLatestInstallerPath] = useState<string>();
  const userAgentPlatform = useUserAgentPlatform();

  const electronFeedFolderPath = `${process.env.PUBLIC_URL}/electron/`;

  useEffect(() => {
    const getLatestInstallerPath = async () => {

      const latestYamlFileName = userAgentPlatform === PlatformType.MacOs ? latestYamlMacOsFileName : latestYamlWindowsFileName;
      const latestYamlFilePath = `${electronFeedFolderPath}${latestYamlFileName}`;

      const latestYamlFile = await fetch(latestYamlFilePath + "?date=" + Date.now())
        .then((res) => res.blob());

      if (latestYamlFile.type !== yamlMimeType) { return; }

      const latestYamlFileContent = await latestYamlFile.text();

      const indexOfPathValueStart = latestYamlFileContent.indexOf(pathValueDescriptor) + pathValueDescriptor.length;
      const indexOfPathValueEnd = latestYamlFileContent.indexOf(nextDescriptorAfterPathDescriptor, indexOfPathValueStart) - 1;
      const yamlPathValue = latestYamlFileContent.substring(indexOfPathValueStart, indexOfPathValueEnd);

      const latestInstallerAbsolutePath = yamlPathValue ? `${electronFeedFolderPath}${yamlPathValue}` : undefined;
      setLatestInstallerPath(latestInstallerAbsolutePath);
    };
    if (!skip) {
      getLatestInstallerPath();
    }
  }, [electronFeedFolderPath, skip, userAgentPlatform]);

  return latestInstallerPath;
};

export default useElectronInstallerPath;
