import { useMemo } from "react";
import { SpeakerNoteSizeEnum } from "@services/api/case/models/casePresentationPageModel";
import Button from "@components/embla/button";
import { ReactComponent as TextIncreaseIcon } from "@content/icons/text_increase.svg";
import { ReactComponent as TextDecreaseIcon } from "@content/icons/text_decrease.svg";
import { useEditPresentationPageNoteSizeMutation } from "@services/api/casePresentation/casePresentationApi";

export type MarkdownSizeButtonsProps  = {
  noteSize?: SpeakerNoteSizeEnum,
  presentationId: string,
  caseId: string,
  presentationPageId: string
};

const MarkdownSizeButtons = ({
  noteSize = SpeakerNoteSizeEnum.Medium,
  caseId,
  presentationPageId,
  presentationId,
}: MarkdownSizeButtonsProps) => {

  const [ editNoteSize, editNoteSizeRequest ] = useEditPresentationPageNoteSizeMutation();
  const canIncreaseNoteSize = useMemo(() => {
    const newNoteSize = noteSize + 1;

    return newNoteSize <= SpeakerNoteSizeEnum.Large && !editNoteSizeRequest.isLoading;
  }, [editNoteSizeRequest.isLoading, noteSize]);

  const canDecreaseNoteSize = useMemo(() => {
    const newNoteSize = noteSize - 1;

    return newNoteSize >= SpeakerNoteSizeEnum.Smallest && !editNoteSizeRequest.isLoading;
  }, [editNoteSizeRequest.isLoading, noteSize]);

  const editNoteSizeCallback = (noteSizeMultiplier: number) => {
    if ((noteSizeMultiplier > 0 && canIncreaseNoteSize) || (noteSizeMultiplier < 0 && canDecreaseNoteSize)) {
      const newNoteSize = noteSize + noteSizeMultiplier;
      editNoteSize({ caseId, presentationId, presentationPageId, model: { noteSize: newNoteSize } });
    }
  };

  return (
    <>
      <Button iconBtn onClick={() => editNoteSizeCallback(-1)} disabled={!canDecreaseNoteSize}>
        <TextDecreaseIcon />
      </Button>
      <Button iconBtn onClick={() => editNoteSizeCallback(1)} disabled={!canIncreaseNoteSize}>
        <TextIncreaseIcon />
      </Button>
    </>
  );
};

export default MarkdownSizeButtons;
